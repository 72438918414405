import * as React from "react";
import { Box, Radio, Typography, FormControlLabel } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CandidatavatarIcon from "../../imgs/imgV2/base-account-type_avatars.svg";
import entrepriseavatarIcon from "../../imgs/imgV2/entrepriseAvatar.svg";
import publierOffreIcon from "../../imgs/imgV2/publierOffreIcon.svg";
import republierOffreIcon from "../../imgs/imgV2/republierOffreIcon.svg";
import etoileIcon from "../../imgs/imgV2/etoiles.svg";
import RienIcon from "../../imgs/imgV2/NoCv.svg";
import { colors } from "../../theme/Colors";

export default function OverlayRadio({ onClick, val, check, title, subtitle, icon, children }) {
  const icons = {};
  icons["candidat"] = CandidatavatarIcon;
  icons["entreprise"] = entrepriseavatarIcon;
  icons["publier"] = publierOffreIcon;
  icons["republier"] = republierOffreIcon;
  icons["etoile"] = etoileIcon;
  icons["Rien"] = RienIcon;

  return (
    <Box sx={{ cursor: "pointer" }} onClick={onClick} maxWidth="400px" padding="20px 16px" borderRadius="6px" border={check === val ? `2px solid ${colors.main}` : "1px solid #DEDFE4"} backgroundColor={check === val ? "#FDEFE7" : "white"} display="flex" flexDirection="column" alignItems="center">
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="start">
        <Box gap="12px" display="flex" alignItems="center">
          <Box>
            <img src={icons[`${icon}`]} alt="avatar-icon" />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography fontSize="14px" fontWeight="600" lineHeight="22.4px">
              {title}
            </Typography>
            <Typography fontSize="11px" lineHeight="17.6px" fontWeight="400">
              {subtitle}
            </Typography>
          </Box>
        </Box>
        <Box>
          <FormControlLabel sx={{ height: "8px", width: "8px" }} value={val} control={<Radio checked={check === val ? true : false} sx={{ height: "8px", width: "8px" }} checkedIcon={<CheckCircleRoundedIcon />} />} />
        </Box>
      </Box>
      <Box width="100%" paddingTop="16px">
        {children}
      </Box>
    </Box>
  );
}
