import React, { useState } from "react";
import { Box, Typography, FormControl, RadioGroup, Button, TextField, InputAdornment, Autocomplete, Container, Divider, FormControlLabel, Checkbox } from "@mui/material";
import { useField, Form, FormikProps, Formik } from "formik";
import { colors } from "../../../theme/Colors";
import BoxContainer from "../../../components/ui/BoxContainer";
import ThreeDIcon from "../../../components/ui/ThreeDIcon";
import MyChip from "../../../components/ui/MyChip";
import CheckedCircle from "../../../components/ui/CheckedCircle";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import dropDownIcon from "../../../imgs/imgV2/DropDownIcon.svg";
import RadioComponent from "../../../components/ui/RadioComponent";
import { FamilyRestroomOutlined, Padding } from "@mui/icons-material";
import downloadIcon from "../../../imgs/imgV2/downloadGray.svg";
import lampIcon from "../../../imgs/imgV2/Lamp.svg";
import DocumentIcon from "../../../imgs/imgV2/document.svg";
import MySwitch from "../../../components/ui/MySwitch/MySwitch";
import MyTextField from "../../../components/ui/MyTextField";
import { Field } from "formik";
import Certification from "../../../components/fonctionnel/candidat/MonProfil/Certification";
import Scolarite from "../../../components/fonctionnel/candidat/MonProfil/Scolarite";
import InfoAdministrative from "../../../components/fonctionnel/candidat/MonProfil/InfoAdministrative";
import Metier from "../../../components/fonctionnel/candidat/MonProfil/Metier";
import InfosEntreprise from "../../../components/fonctionnel/entreprise/MonProfil/InfosEntreprise";
import InfoAdministrativeEntreprise from "../../../components/fonctionnel/entreprise/MonProfil/InfoAdministrativeEntreprise";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";

import InfosRecruteurC from "../../../components/fonctionnel/entreprise/MonProfil/InfosRecruteurC";
import PropositionCom from "../../../components/fonctionnel/entreprise/MonProfil/PropositionCom";
import OffreComponent from "../../../components/ui/OffreComponent";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useAuthContext } from "../../../hooks/useAuthContext";
import MyDPFullDate from "../../../components/ui/MyDPFullDate";
import { toast } from "react-toastify";

function Tests() {
  const [checked, setChecked] = useState("candidat");
  const [selectButton, setSelectButton] = useState("suggest");
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [checked2, setChecked2] = useState("publier");
  const [top100Films, setFilms] = useState([
    { label: "The Shawshank Redemption", year: 1994 },
    { label: "The Godfather", year: 1972 },
    { label: "The Godfather: Part II", year: 1974 },
    { label: "The Dark Knight", year: 2008 },
    { label: "12 Angry Men", year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: "Pulp Fiction", year: 1994 },
    {
      label: "The Lord of the Rings: The Return of the King",
      year: 2003,
    },
    { label: "The Good, the Bad and the Ugly", year: 1966 },
    { label: "Fight Club", year: 1999 },
    {
      label: "The Lord of the Rings: The Fellowship of the Ring",
      year: 2001,
    },
    {
      label: "Star Wars: Episode V - The Empire Strikes Back",
      year: 1980,
    },
    { label: "Forrest Gump", year: 1994 },
    { label: "Inception", year: 2010 },
    {
      label: "The Lord of the Rings: The Two Towers",
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: "Goodfellas", year: 1990 },
    { label: "The Matrix", year: 1999 },
    { label: "Seven Samurai", year: 1954 },
    {
      label: "Star Wars: Episode IV - A New Hope",
      year: 1977,
    },
    { label: "City of God", year: 2002 },
    { label: "Se7en", year: 1995 },
    { label: "The Silence of the Lambs", year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: "Life Is Beautiful", year: 1997 },
    { label: "The Usual Suspects", year: 1995 },
    { label: "Léon: The Professional", year: 1994 },
    { label: "Spirited Away", year: 2001 },
    { label: "Saving Private Ryan", year: 1998 },
    { label: "Once Upon a Time in the West", year: 1968 },
    { label: "American History X", year: 1998 },
    { label: "Interstellar", year: 2014 },
    { label: "Casablanca", year: 1942 },
    { label: "City Lights", year: 1931 },
    { label: "Psycho", year: 1960 },
    { label: "The Green Mile", year: 1999 },
    { label: "The Intouchables", year: 2011 },
    { label: "Modern Times", year: 1936 },
    { label: "Raiders of the Lost Ark", year: 1981 },
    { label: "Rear Window", year: 1954 },
    { label: "The Pianist", year: 2002 },
    { label: "The Departed", year: 2006 },
    { label: "Terminator 2: Judgment Day", year: 1991 },
    { label: "Back to the Future", year: 1985 },
    { label: "Whiplash", year: 2014 },
    { label: "Gladiator", year: 2000 },
    { label: "Memento", year: 2000 },
    { label: "The Prestige", year: 2006 },
    { label: "The Lion King", year: 1994 },
    { label: "Apocalypse Now", year: 1979 },
    { label: "Alien", year: 1979 },
    { label: "Sunset Boulevard", year: 1950 },
    {
      label: "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
      year: 1964,
    },
    { label: "The Great Dictator", year: 1940 },
    { label: "Cinema Paradiso", year: 1988 },
    { label: "The Lives of Others", year: 2006 },
    { label: "Grave of the Fireflies", year: 1988 },
    { label: "Paths of Glory", year: 1957 },
    { label: "Django Unchained", year: 2012 },
    { label: "The Shining", year: 1980 },
    { label: "WALL·E", year: 2008 },
    { label: "American Beauty", year: 1999 },
    { label: "The Dark Knight Rises", year: 2012 },
    { label: "Princess Mononoke", year: 1997 },
    { label: "Aliens", year: 1986 },
    { label: "Oldboy", year: 2003 },
    { label: "Once Upon a Time in America", year: 1984 },
    { label: "Witness for the Prosecution", year: 1957 },
    { label: "Das Boot", year: 1981 },
    { label: "Citizen Kane", year: 1941 },
    { label: "North by Northwest", year: 1959 },
    { label: "Vertigo", year: 1958 },
    {
      label: "Star Wars: Episode VI - Return of the Jedi",
      year: 1983,
    },
    { label: "Reservoir Dogs", year: 1992 },
    { label: "Braveheart", year: 1995 },
    { label: "M", year: 1931 },
    { label: "Requiem for a Dream", year: 2000 },
    { label: "Amélie", year: 2001 },
    { label: "A Clockwork Orange", year: 1971 },
    { label: "Like Stars on Earth", year: 2007 },
    { label: "Taxi Driver", year: 1976 },
    { label: "Lawrence of Arabia", year: 1962 },
    { label: "Double Indemnity", year: 1944 },
    {
      label: "Eternal Sunshine of the Spotless Mind",
      year: 2004,
    },
    { label: "Amadeus", year: 1984 },
    { label: "To Kill a Mockingbird", year: 1962 },
    { label: "Toy Story 3", year: 2010 },
    { label: "Logan", year: 2017 },
    { label: "Full Metal Jacket", year: 1987 },
    { label: "Dangal", year: 2016 },
    { label: "The Sting", year: 1973 },
    { label: "2001: A Space Odyssey", year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: "Toy Story", year: 1995 },
    { label: "Bicycle Thieves", year: 1948 },
    { label: "The Kid", year: 1921 },
    { label: "Inglourious Basterds", year: 2009 },
    { label: "Snatch", year: 2000 },
    { label: "3 Idiots", year: 2009 },
    { label: "Monty Python and the Holy Grail", year: 1975 },
    { label: "", year: 0 },
  ]);
  const [metierListe, setMetierListe] = useState([]);
  const [selected, setSelected] = useState([]);
  const [value, setValue] = useState("");
  const [titreObj, setTitreObj] = useState("");
  //console.log(selected, "selected items");
  const [change, setChange] = useState(false);
  //console.log(checked, checked2);
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        //console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="text_xs" color={colors.text_subtitle}>
        this is a subtitle
      </Typography>
      <Typography variant="text_sm" color={colors.job_description}>
        This is a normal text{" "}
      </Typography>
      <Typography variant="text_md">this is a medium text </Typography>
      <Typography variant="text_lg">this is a large text</Typography>
      <Typography variant="text_xl">this is even larger text</Typography>
      <Typography variant="text_xxl">This is the largest text</Typography>
      <Typography variant="text_clickable">This a clickable text</Typography>

      <Typography variant="text_xxl" fontWeight="700" mt={10}>
        Préférences
      </Typography>
      <Typography variant="text_lg" fontWeight="700">
        Motion graphic designer
      </Typography>
      <Typography variant="text_sm" fontWeight="600">
        Thomas Gonzalez
      </Typography>
      <Typography variant="text_normal">Rue Paul Lelong, Paris</Typography>
      <Typography variant="text_normal">22 mars 1995</Typography>
      <Typography variant="text_clickable">thomas@gonzalez.com</Typography>
      <Typography variant="text_clickable">+33 515 151 11</Typography>
      <Typography variant="text_normal" color={colors.job_description}>
        Neque vulputate lorem nunc in rhoncus scelerisque nisi proin dui. Ac aliquam risus eget viverra ut lacus quis id est. Aliquet sociis vulputate sed nisi non risus amet. Elit non donec massa a. Morbi.
      </Typography>
      <Typography variant="text_normal" color={colors.job_description}>
        Text chip
      </Typography>

      <BoxContainer display="flex" flexDirection="column">
        <Typography variant="text_xxl" fontWeight="700">
          Documents
        </Typography>
        <Typography variant="text_normal" color={colors.job_description}>
          Neque vulputate lorem nunc in rhoncus scelerisque nisi proin dui. Ac aliquam risus eget viverra ut lacus quis id est. Aliquet sociis vulputate sed nisi non risus amet. Elit non donec massa a. Morbi.
        </Typography>
      </BoxContainer>
      <FormControl>
        <Box sx={{ margin: "10px 0px", display: "flex", justifyContent: "center" }}>
          <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "10px" }} aria-labelledby="demo-radio-buttons-group-label" value="candidat" onChange={(e) => setChecked(e.target.value)} name="radio-buttons-group">
            <RadioComponent val="candidat" check={checked} icon="candidat" title="Je suis un intérimaire" subtitle="Je recherche du travail." />
            <RadioComponent val="entreprise" check={checked} icon="entreprise" title="Nous sommes une entreprise" subtitle="On recherche des talents à recruter." />
          </RadioGroup>
        </Box>
      </FormControl>

      <FormControl>
        <Box sx={{ margin: "10px 0px" }}>
          <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "10px" }} aria-labelledby="demo-radio-buttons-group-label" value="publier" onChange={(e) => setChecked2(e.target.value)} name="radio-buttons-group">
            <RadioComponent val="publier" check={checked2} icon="publier" title="Nouvelle demande" subtitle="Je souhaite publier une nouvelle offre." />
            <RadioComponent val="republier" check={checked2} icon="republier" title="Republier" subtitle="Je souhaite republier une de mes offres." />
          </RadioGroup>
        </Box>
      </FormControl>

      <ThreeDIcon name="check" />
      <ThreeDIcon name="error" />
      <ThreeDIcon name="message" />

      <Box display="flex" flexWrap="wrap">
        <Box>
          <MyChip variant="active_offer" label="Active" />
        </Box>
        <MyChip variant="ended_offer" label="Terminée" />
        <MyChip variant="rejected_offer" label="Rejetée" />
        <MyChip variant="id" label="Rejetée" />
        <MyChip variant="skill" label="Nuxt.js" />
        <MyChip variant="matching_parfait" label="🥇 Matching parfait" />
        <MyChip variant="matching_satisfaisant" label="👌 Matching satisfaisant" />
        <CheckedCircle checked={true} />
        <CheckedCircle checked={false} />
      </Box>
      <Box margin="10px 0px">
        <Formik
          initialValues={{
            password: "",
          }}
          onSubmit={() => {
            //console.log("submited");
          }}
        >
          {({ values, errors, setFieldValue, touched }) => (
            <Form>
              <div style={{ maxWidth: "300px", margin: "10px 0px" }} className="field">
                <MyTextField setFieldValue={setFieldValue} value={values.password} name="password" placeholder="password" endAdornment="download" startAdornment="download" error={errors.password && touched.password ? true : false} />
              </div>
              <Button variant="mj_primary_sm" type="submit">
                valider
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
      <Box margin="10px 0px">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={null}
          blurOnSelect={true}
          onChange={(e, value) => {
            if (value) {
              setSelected(selected.concat([value]));
              setFilms(top100Films.filter((film) => film.label !== value.label));
              setChange(!change);
              //console.log("films after selecting a value", top100Films);
            }
          }}
          options={top100Films}
          getOptionLabel={(Option) => Option.label}
          renderInput={(params) => (
            <TextField
              value={value}
              {...params}
              sx={{ maxWidth: "400px" }}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={RechercheIcon} alt="end" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={dropDownIcon} alt="end" />
                  </InputAdornment>
                ),
              }}
              placeholder="Movies"
            />
          )}
        />
        <Box maxWidth="400px" flexWrap="wrap" display="flex" gap="10px" margin="10px 0px">
          {selected?.map((select) => (
            <MyChip
              variant="skill"
              label={select.label}
              onDelete={() => {
                setFilms(top100Films.concat([select]));
                setSelected(selected.filter((sel) => sel.label !== select.label));
                //console.log("films after deleting a selected value", top100Films);
              }}
            />
          ))}
        </Box>
      </Box>
      <ButtonSquare action="download" />
      <ButtonSquare action="modify" />
      <ButtonSquare action="download-orange" />
      <ButtonSquare action="download-blue" />
      <ButtonSquare action="add" />
      <ButtonSquare action="add-outlined" />
      <ButtonSquare action="add-orange" />
      <ButtonSquare action="refuse" />
      <ButtonSquare action="delete" />

      <Box>
        <Button variant="mj_secondary_sm">Hello</Button>
        <Button variant="mj_primary_sm">Hello</Button>
      </Box>

      <Box>
        <MySwitch isOn={checked} onChange={() => setChecked(!checked)} />
      </Box>
      <Box marginBottom="20px" display="flex" flexDirection="column" gap="20px">
        <Certification />
        <Scolarite />
        <InfoAdministrative />
        <Metier />
        <InfosEntreprise />
        <InfoAdministrativeEntreprise />
        <InfosRecruteurC />
        <PropositionCom />
      </Box>
      <Box display="flex" justifyContent="flex-end" marginBottom="42.5px">
        <Button variant={selectButton === "choice1" ? "mj_primary_choice_active" : "mj_primary_choice"} onClick={() => setSelectButton("choice1")}>
          <img src={lampIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Demandes suggérées
        </Button>
        <Button variant={selectButton === "choice2" ? "mj_primary_choice_active" : "mj_primary_choice"} onClick={() => setSelectButton("choice2")}>
          <img src={DocumentIcon} alt="lamp-icon" style={{ marginRight: "4px" }} /> Toutes les demandes
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" gap="24px">
        <Box flex={2}>
          <BoxContainer>
            <Formik
              initialValues={{
                titre: { id: null, name: "" },
                start_exp: "",
                end_exp: "",
                start_taux: "",
                end_taux: "",
                start_date: null,
                end_date: null,
                perimetre: "",
                primes: false,
                panier: false,
              }}
              onSubmit={(values) => {
                //console.log(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Box>
                      <Typography fontSize="20px" lineHeight="32px" fontWeight="700">
                        🔍 Filtres
                      </Typography>
                    </Box>
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Métier
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          blurOnSelect={true}
                          value={null}
                          onChange={(e, value) => {
                            //console.log(value);
                            const exist = selected?.filter((select) => select.id === value.id);
                            if (!exist[0]) {
                              setSelected(selected.concat([value]));
                            }
                            setMetierListe([]);
                          }}
                          options={metierListe}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={value}
                              {...params}
                              onChange={(e) => {
                                //console.log(e.target.value);
                                if (e.target.value.length > 3) {
                                  searchMetier(e.target.value);
                                }
                              }}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Rechercher des métiers..."
                            />
                          )}
                        />
                      </Box>
                      <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px 0px 0px">
                        {selected?.map((select) => (
                          <MyChip
                            variant="skill"
                            label={select.name}
                            onDelete={() => {
                              setSelected(selected.filter((sel) => sel.id !== select.id));
                            }}
                          />
                        ))}
                      </Box>
                    </Box>
                    <Divider />
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Expérience
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap="16px">
                        <Box flex={1}>
                          <MyTextField endAdornment="ans" setFieldValue={setFieldValue} value={values.start_exp} name="start_exp" placeholder="0" />
                        </Box>
                        <Box>
                          <img src={arrowRightIcon} alt="icon-point" />
                        </Box>
                        <Box flex={1}>
                          <MyTextField endAdornment="ans" setFieldValue={setFieldValue} value={values.end_exp} name="end_exp" placeholder="0" />
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Taux horaire
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap="16px">
                        <Box flex={1}>
                          <MyTextField endAdornment="euro" setFieldValue={setFieldValue} value={values.start_taux} name="start_taux" placeholder="0" />
                        </Box>
                        <Box>
                          <img src={arrowRightIcon} alt="icon-point" />
                        </Box>
                        <Box flex={1}>
                          <MyTextField endAdornment="euro" setFieldValue={setFieldValue} value={values.end_taux} name="end_taux" placeholder="0" />
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Dates
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap="16px">
                        <Box flex={1}>
                          <Field value={values.start_date} setFieldValue={setFieldValue} name="start_date" placeholder="Date..." maxDate={values.end_date} as={MyDPFullDate}></Field>
                        </Box>
                        <Box>
                          <img src={arrowRightIcon} alt="icon-point" />
                        </Box>
                        <Box flex={1}>
                          <Field value={values.end_date} setFieldValue={setFieldValue} name="end_date" placeholder="Date..." minDate={values.start_date} as={MyDPFullDate}></Field>
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Adresse
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          blurOnSelect={true}
                          value={null}
                          onChange={(e, value) => {
                            //console.log(value);
                            const exist = selected?.filter((select) => select.id === value.id);
                            if (!exist[0]) {
                              setSelected(selected.concat([value]));
                            }
                            setMetierListe([]);
                          }}
                          options={metierListe}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={value}
                              {...params}
                              onChange={(e) => {
                                //console.log(e.target.value);
                                if (e.target.value.length > 3) {
                                  searchMetier(e.target.value);
                                }
                              }}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Rechercher des villes..."
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Divider />
                    <Box width="100%">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Périmètre
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" gap="16px">
                        <Box flex={1}>
                          <MyTextField endAdornment="km" setFieldValue={setFieldValue} value={values.perimetre} name="perimetre" placeholder="0" />
                        </Box>
                        <Box></Box>
                        <Box flex={1}></Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box>
                      <Box width="100%">
                        <FormControlLabel
                          onChange={(e) => {
                            setFieldValue("primes", e.target.checked);
                          }}
                          control={<Checkbox checked={values.primes} />}
                          label="Primes"
                        />
                      </Box>
                      <Box width="100%">
                        <FormControlLabel
                          onChange={(e) => {
                            setFieldValue("panier", e.target.checked);
                          }}
                          control={<Checkbox checked={values.panier} />}
                          label="Panier"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
        <Box flex={6}>
          <Box sx={{ marginBottom: "20px", marginLeft: "0px" }} maxWidth="lg">
            <OffreComponent offreName="test offre name" posteName="test poste name" />
            <OffreComponent offreName="test offre name" posteName="test poste name" />
            <OffreComponent offreName="test offre name" posteName="test poste name" />
            <OffreComponent offreName="test offre name" posteName="test poste name" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Tests;
