import React from "react";

import { colors } from "../../../../theme/Colors";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import { Box, Button, Typography, Modal, Grid, Divider, TextField, InputAdornment, Autocomplete, Icon } from "@mui/material";
import downloadBlack from "../../../../imgs/imgV2/downloadBlack.svg";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import styled from "@emotion/styled";
import leftArrowIcon from "../../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../../imgs/imgV2/arrowRightBlavk.svg";
import { modalStyle } from "../../../../theme/ModalStyle";
import { Field, ErrorMessage, Form, Formik } from "formik";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import Card from "@mui/material/Card";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Table from "@mui/material/Table";
import MyChip from "../../../../components/ui/MyChip";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import moment from "moment";

function DemandeDacompte() {
  const svgIcon = (
    <Icon>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img alt="download" src={downloadBlack} />
      </Box>
    </Icon>
  );

  //invoke user
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //modal state
  const [openFicheDePaieDetail, setOpenFicheDePaieDetail] = useState(false);

  //filter states
  const [searchedAcompte, setSearchedAcompte] = useState(null);
  const [selectedAcompteState, setSelectedAcompteState] = useState(null);

  const [numberOfResults, setNumberOfResults] = useState();
  const [demandeAcompte, setDemandeAcompte] = useState([]);
  const [data, setData] = useState();

  const [idDemandeAcompte, setidDemandeAcompte] = useState("351");

  const [openMotifRefus, setOpenMotifRefus] = useState(false);
  const [openDemandeAcompte, setOpenDemandeAcompte] = useState(false);
  const [refusalMotif, setRefusalMotif] = useState("");

  //initial values
  const initialValues = {
    amount: "0",
  };

  //validation schema acompte
  const validationSchema = Yup.object({
    amount: Yup.number().typeError("Ce champ doit être numérique").positive("le montant doit être supérieure à 0"),
  });

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FAFAFB",
      color: `#0D173A`,
      fontSize: 12,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });

  const demandeAcompteStates = [
    {
      id: "ACCEPTED",
      name: "Acceptées",
    },
    {
      id: "REFUSED",
      name: "Refusées",
    },
    {
      id: "PENDING",
      name: "En cours de traitement",
    },
  ];

  const chipStateSelector = (state, refusalMotif) => {
    switch (state) {
      case "PENDING":
        return <MyChip variant="pending_contract" label="En cours de traitement" />;
      case "ACCEPTED":
        return <MyChip variant="ended_offer" label="Acceptée" />;
      case "REFUSED":
        return (
          <MyChip
            variant="rejected_offer"
            label="Refusée"
            onClick={() => {
              if (refusalMotif) {
                setRefusalMotif(refusalMotif);
              }

              setOpenMotifRefus(true);
            }}
          />
        );
      default:
        return "En cours de traitement";
    }
  };

  // const demandeAcompteDummy = [
  //   { ficheDePaie: 12356, date: new Date(), etat: "PENDING", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "ACCEPTED", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "ACCEPTED", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "ACCEPTED", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "ACCEPTED", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "REFUSED", montant: 500 },
  //   { ficheDePaie: 12356, date: new Date(), etat: "REFUSED", montant: 500 },
  // ];

  const [loading, setLoading] = useState(true);

  const onSubmitDemandeAcompte = (values) => {
    Api.post(
      Urls?.POST_DEMANDER_UN_ACOMPTE + user?.user?.id + "/advances",
      {
        amount: values.amount,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        setOpenDemandeAcompte(false);
      })
      .catch((err) => {
        setOpenDemandeAcompte(false);
      });
  };

  // get liste des demandes acomptes

  useEffect(() => {
    Api.get("/advances", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        id: searchedAcompte ? searchedAcompte : null,
        status: selectedAcompteState ? selectedAcompteState : null,
      },
    })
      .then((res) => {
        //console.log(res);
        setData(res?.data);
        setDemandeAcompte(res?.data?.advances);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [searchedAcompte, selectedAcompteState]);

  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setDemandeAcompte(res.data?.advances);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setDemandeAcompte([]);
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* title */}
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography variant="text_xl" fontWeight="700">
          Mes demandes d’acompte
        </Typography>
        <Button variant="mj_primary_md" onClick={() => setOpenDemandeAcompte(true)}>
          Faire une demande
        </Button>
      </Box>
      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={9}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher une demande
                </Typography>
              </Box>
              <TextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSearchedAcompte(event.target.value);
                  } else {
                    setSearchedAcompte(null);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                options={demandeAcompteStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting acompte state value", value);
                    //console.log("selecting acompte state value", value.id);
                    setSelectedAcompteState(value ? [value.id] : "");
                    //console.log("selectedAcompteState", selectedAcompteState);
                  } else {
                    setSelectedAcompteState(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* liste */}
      {!loading ? (
        <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DEDFE4` }}>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width="9.5%">#</StyledTableCell>
                <StyledTableCell width="72%">Date</StyledTableCell>
                <StyledTableCell width="6%" align="center">
                  Etat
                </StyledTableCell>
                <StyledTableCell width="9.5%%" align="right">
                  Montant
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {demandeAcompte?.map((fp) => (
                <StyledTableRow
                  key={fp.id}
                  onClick={() => {
                    setOpenFicheDePaieDetail(true);
                    setidDemandeAcompte(fp?.id);
                  }}
                >
                  <StyledTableCell align="left">
                    <Typography fontWeight="600" color="black" fontSize="14px">
                      {fp?.id}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography fontWeight="400" color="#575E76" fontSize="14px">
                      {moment(fp?.created_at).format("yyyy MMMM DD")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">{chipStateSelector(fp.control_status, fp?.advance_refusal_reason?.name)}</StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography fontWeight="600" color="black" fontSize="14px">
                      {fp?.amount}€
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {/* pagination */}
      {demandeAcompte?.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
      {demandeAcompte?.length === 0 && !loading && <Typography textAlign="center">Aucune demande d'acompte</Typography>}
      {demandeAcompte?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link, index) => (
              <ButtonSquare
                key={index}
                onClick={() => {
                  handlePagination(link.url);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}

      {/* faire une demande */}
      <Modal open={openDemandeAcompte} onClose={() => setOpenDemandeAcompte(false)}>
        <Box sx={modalStyle}>
          <Box padding="40px 40px 0px 40px">
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
                <Box display="flex" gap="10px" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    Demande d'acompte
                  </Typography>
                </Box>
                <Box>
                  <ButtonSquare onClick={() => setOpenDemandeAcompte(false)} action="close" />
                </Box>
              </Box>

              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitDemandeAcompte}>
                {({ values }) => (
                  <Form>
                    <Box maxWidth="700px">
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="amount" name="amount">
                          Montant
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="amount" name="amount" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box marginBottom="20px">
                        <Box width="100px">
                          <Field name="amount" type="input" placeholder="0" id="amount" as={TextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="amount" />
                      </Box>
                      <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                        <Button onClick={() => setOpenDemandeAcompte(false)} variant="mj_secondary_md">
                          Annuler
                        </Button>
                        <Button variant="mj_primary_md" type="submit">
                          Enregistrer
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* motif refus demande d'acompte */}
      <Modal open={openMotifRefus} onClose={() => setOpenMotifRefus(false)}>
        <Box sx={modalStyle}>
          <Box padding="40px 40px 0px 40px">
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="24px">
                <Box display="flex" gap="10px" alignItems="center">
                  <Typography variant="text_xxl" fontWeight="700">
                    ⛔ Motif du refus
                  </Typography>
                </Box>
                <Box>
                  <ButtonSquare onClick={() => setOpenMotifRefus(false)} action="close" />
                </Box>
              </Box>

              <Box maxWidth="700px">
                <Typography>{refusalMotif}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default DemandeDacompte;
