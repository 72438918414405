import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import calendarIcon from "../../imgs/imgV2/calendarIcon.svg";
import { colors } from "../../theme/Colors";
import moment from "moment";

const MyDP = ({ children, setFieldValue, color, minDate, maxDate, ...props }) => {
  return (
    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        views={["month", "year"]}
        disabled={props.disabled}
        components={{ OpenPickerIcon: CalendarMonthIcon }}
        name={props.name}
        minDate={minDate}
        maxDate={maxDate}
        value={props.value}
        inputFormat="MM-yyyy"
        onChange={(value) => {
          setFieldValue(props.name, moment(value).format("yyyy-MM-DD"));
        }}
        InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${color}`, height: "14px", width: "14px" } } }}
        renderInput={(params) => {
          return (
            <TextField
              type="time"
              fullWidth
              {...params}
              error={false}
              inputProps={{
                ...params.inputProps,
                placeholder: "Mois/Année",
              }}
            />
          );
        }}
      >
        {children}
      </DesktopDatePicker>
    </LocalizationProvider>
  );
};

export default MyDP;
