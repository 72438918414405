import React from "react";
import { MapContainer, TileLayer, Polygon, Marker, Popup, useMap, CircleMarker, Circle, useMapEvent } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { colors } from "../../theme/Colors";

function MapComponent({ is_radius, radius, position, selectedAdresse, zoom_level, radiusInKm, setZoom_level, setRadiusInKm }) {
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
  function RecenterMap() {
    const map = useMap();
    useMapEvent({
      zoomemd: (e) => {
        setZoom_level(e.target.getZoom());
      },
    });
    useEffect(() => {
      if (position) {
        map.setView(L.latLng(position[0], position[1]), map.getZoom(), {
          animate: true,
        });
      }
    }, [position]);
    return null;
  }
  function calculate_pixels_in_screen(km, zoom_level) {
    if (is_radius) {
      const circumference_of_earth = 40075.004;
      const pixels_per_km = (circumference_of_earth / 2 ** zoom_level) * 256;
      const pixels_in_screen = km * pixels_per_km;
      return pixels_in_screen;
    }
    return;
  }
  useEffect(() => {
    if (radius && is_radius) {
      setRadiusInKm(calculate_pixels_in_screen(radius, zoom_level));
    }
  }, [zoom_level]);
  return (
    <Box display="flex" justifyContent="center">
      {position && (
        <MapContainer style={{ height: { lg: "400px", md: "400px", sm: "350px", xs: "300px" }, width: { lg: "400px", md: "400px", sm: "350px", xs: "300px" } }} center={position} zoom={zoom_level}>
          <TileLayer attribution='&copy; <a href="https://ohmyjob-frontweb.digitupcompany.com">OhMyJob</a>' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={position}>
            <Popup>{selectedAdresse?.display_name}</Popup>
          </Marker>
          {is_radius && <Circle radius={radiusInKm} pathOptions={{ color: colors.main }} center={position}></Circle>} <RecenterMap />
        </MapContainer>
      )}
    </Box>
  );
}

export default MapComponent;
