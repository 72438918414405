import { useAuthContext } from "./hooks/useAuthContext";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import Login from "./pages/authentification/Login";
import Signup from "./pages/authentification/Singup";
import SignupEntreprise from "./pages/authentification/SignupEntreprise";
import SignupCandidat from "./pages/authentification/SignupCandidat";
import ForgotPassword from "./pages/authentification/ForgotPassword";
import EnterOtpForgotPassword from "./pages/authentification/EnterOtpForgotPassword";
import ChangePassword from "./pages/authentification/ChangePassword";
import ValidateEmail from "./pages/authentification/ValidateEmail";
import CandidatSharedLayout from "./pages/candidat/CandidatSharedLayout";
import EntrepriseSharedLayout from "./pages/entreprise/EntrepriseSharedLayout";
//import HomeCandidat from "./pages/candidat/content/HomeCandidat";
import TableauDeBord from "./pages/entreprise/content/tableau-de-bord/TableauDeBord";
import ProfileCandidat from "./pages/candidat/content/ProfileCandidat";
import ProfileEntreprise from "./pages/entreprise/content/ProfileEntreprise";
// import Test from "./pages/Test";

import MesOffres from "./pages/entreprise/content/offres/MesOffres";
import CreerOffres from "./pages/entreprise/content/offres/CreerOffres";
import OfferDetails from "./pages/entreprise/content/offres/OfferDetails";
import "./App.css";

import HistoriqueInterimaires from "./pages/entreprise/content/HistoriqueInterimaires";
import ListeMissionsRenard from "./pages/candidat/content/ListeMissionsRenard";

import DetailOffre from "./pages/candidat/content/DetailOffre";
import MesCandidatures from "./pages/candidat/content/MesCandidatures";
import OffresSuggereesMatching from "./pages/candidat/content/OffresSuggereesMatching";
import FAQCandidat from "./pages/candidat/content/faq/FAQCandidat";
import FAQEntreprise from "./pages/entreprise/content/faq/FAQEntreprise";
import MesFacturesEntreprise from "./pages/entreprise/content/Factures/MesFacturesEntreprise";
import FicheDePaie from "./pages/candidat/content/fiches-de-paie/FicheDePaie";
import DemandeDacompte from "./pages/candidat/content/demande-acompte/DemandeDacompte";
import Tests from "./pages/candidat/content/Tests";
import AccessPlatform from "./pages/authentification/AccessPlatform";
import AccessPlatformChangedPassword from "./pages/authentification/AccessPlatformChangedPassword";
import Parametres from "./pages/candidat/content/Parametres";
import CreateOfferSelect from "./pages/entreprise/content/offres/CreateOfferSelect";
import SuccessCreateOffer from "./pages/entreprise/content/offres/SuccessCreateOffer";
import FailureCreateOffer from "./pages/entreprise/content/offres/FailureCreateOffer";
import Contrats from "./pages/candidat/content/Contrats";
import RepublishOffer from "./pages/entreprise/content/offres/RepublishOffer";
import ParametresEnt from "./pages/entreprise/content/ParametresEnt";
import TableauDeBordCandidat from "./pages/candidat/content/tableau-de-bord/TableauDeBordCandidat";
import OBSelector from "./pages/entreprise/content/onboarding/OBSelector";
import OBselector from "./pages/candidat/content/onboarding/OBselector";
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

function App() {
  const { user } = useAuthContext();
  //console.log("user is :", user);
  const userType = user?.user?.account_type;
  //console.log("USER TYPE IS:", userType);

  // console.log("app user", user);
  // console.log("app user type", userType);
  return (
    <>
      <Routes>
        <Route path="" element={<Navigate to="login" />} />
        <Route path="*" element={<h1 style={{ marginTop: "30px", textAlign: "center" }}>404 Introuvable</h1>} />
        <Route path="/" element={<Outlet />}>
          <Route path="signup" element={user?.access_token && userType ? <Navigate to={`/${userType === "ENTERPRISE" ? "entreprise/tableau-de-bord" : "renard/tableau-de-bord"}`} replace /> : <Signup />} />
          <Route path="signup-entreprise" element={user?.access_token && userType ? <Navigate to={`/${userType === "ENTERPRISE" ? "entreprise/tableau-de-bord" : "renard/tableau-de-bord"}`} replace /> : <SignupEntreprise />} />
          <Route path="signup-candidat" element={user?.access_token && userType ? <Navigate to={`/${userType === "ENTERPRISE" ? "entreprise/tableau-de-bord" : "renard/tableau-de-bord"}`} replace /> : <SignupCandidat />} />
          <Route path="login" element={user?.access_token && userType ? <Navigate to={`/${userType === "ENTERPRISE" ? "entreprise/tableau-de-bord" : "renard/tableau-de-bord"}`} replace /> : <Login />} />
          <Route path="verified-email" element={user?.message ? <AccessPlatform /> : <div>unauthorized</div>} />
          <Route path="updated-password" element={user?.message ? <AccessPlatformChangedPassword /> : <div>unauthorized</div>} />
          <Route path="password-recovery" element={<ForgotPassword />} />
          <Route path="validate-email" element={<ValidateEmail />} />
          <Route path="password-change" element={<ChangePassword user={user} />} />
          <Route path="password-verification" element={<EnterOtpForgotPassword />} />

          <Route path="/entreprise" element={user?.access_token && userType === "ENTERPRISE" ? <EntrepriseSharedLayout /> : <div>unauthorized</div>}>
            <Route path="onboarding" element={<OBSelector />} />
            <Route path="tests" element={<Tests />} />
            <Route path="tableau-de-bord" element={<TableauDeBord />} />
            <Route path="FAQ" element={<FAQEntreprise />} />
            <Route path="parametres" element={<ParametresEnt />} />
            <Route path="historique-interimaires" element={<HistoriqueInterimaires />} />

            <Route path="offres">
              <Route index element={<MesOffres />} />
              <Route path="/entreprise/offres/offre-cree" element={<SuccessCreateOffer />} />
              <Route path="/entreprise/offres/erreur-creation" element={<FailureCreateOffer />} />
              <Route path="/entreprise/offres/option-create-offer" element={<CreateOfferSelect />} />
              <Route path="/entreprise/offres/creer-offre" element={<CreerOffres />} />
              <Route path="/entreprise/offres/republier-offre" element={<RepublishOffer />} />
              <Route path="/entreprise/offres/:id" element={<OfferDetails />} />
            </Route>
            <Route path="compte">
              <Route index element={<ProfileEntreprise />} />
            </Route>
            <Route path="mes-factures">
              <Route index element={<MesFacturesEntreprise />} />
            </Route>
            {/* <Route path="test" element={<Test />} /> */}
          </Route>

          <Route path="/renard" element={user?.access_token && userType === "INTERIM" ? <CandidatSharedLayout /> : <div>unauthorized</div>}>
            <Route path="onboarding" element={<OBselector />} />
            <Route path="/renard/tableau-de-bord" element={<TableauDeBordCandidat />} />
            <Route path="parametres" element={<Parametres />} />
            <Route path="tests" element={<Tests />} />
            <Route path="FAQ" element={<FAQCandidat />} />
            <Route path="fiche-de-paie" element={<FicheDePaie />} />
            <Route path="demande-acompte" element={<DemandeDacompte />} />
            <Route path="offres">
              <Route path="/renard/offres/:id" element={<DetailOffre />} />
              <Route path="/renard/offres/offres-suggerees" element={<OffresSuggereesMatching />} />
            </Route>
            <Route path="mes-candidatures">
              <Route index element={<MesCandidatures />} />
            </Route>
            <Route path="mes-missions">
              <Route index element={<ListeMissionsRenard />} />
              <Route path="/renard/mes-missions/contrats/:idmission" element={<Contrats />} />
            </Route>
            <Route path="compte">
              <Route index element={<ProfileCandidat />} />
            </Route>
            <Route path="mes-fiches-de-paie">
              <Route index element={<FicheDePaie />} />
            </Route>
            {/* <Route path="test" element={<Test />} /> */}
          </Route>
        </Route>
      </Routes>

      {/* <Routes>
        <Route path="/" element={<Outlet />}>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
          <Route path="password-recovery" element={<ForgotPassword />} />
          <Route path="validate-email" element={<ValidateEmail />} />
          <Route path="password-change" element={<ChangePassword user={user} />} />
          <Route path="password-verification" element={<EnterOtpForgotPassword />} />

          <Route element={<RequireAuth />}>
            <Route path="/" element={<Outlet />} />
            <Route path="/entreprise" element={<EntrepriseSharedLayout />} />
            <Route path="/candidat" element={<CandidatSharedLayout />} />
          </Route>
        </Route>
      </Routes> */}
      <div className="App"></div>
    </>
  );
}

export default App;
