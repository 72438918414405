import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";

export const useSignupEntreprise = (email, password) => {
  const navigate = useNavigate();
  const [errorSignupEntreprise, setErrorEntreprise] = useState(null);
  const [isLoadingEntreprise, setisLoadingEntreprise] = useState(null);
  const [successSignupEntreprise, setSuccessSignupEntreprise] = useState(null);

  const signupEntreprise = async (email, password, name, siret) => {
    setisLoadingEntreprise(true);
    setErrorEntreprise(null);
    setSuccessSignupEntreprise(null);

    const response = await Api.post(
      Urls.SIGNUP_URL_ENTREPRISE,
      { email, password, name, siret },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          setSuccessSignupEntreprise(response.statusText);

          // save the user to local storage
          //localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          //dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setisLoadingEntreprise(false);
          navigate("/validate-email", { state: email });
        }
      })
      .catch((error) => {
        setErrorEntreprise(error.response.data.message);
        setisLoadingEntreprise(false);
      });
  };

  return {
    signupEntreprise,
    isLoadingEntreprise,
    errorSignupEntreprise,
    successSignupEntreprise,
  };
};
