import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useValidateEmailOtp = (email, otp) => {
  const [errorValidateEmailOtp, setErrorValidateEmailOtp] = useState(null);
  const [successValidateEmailOtp, setSuccessValidateEmailOtp] = useState(null);
  const [isLoadingValidateEmailOtp, setIsLoadingValidateEmailOtp] = useState(null);

  //invoke useNavigate
  const navigate = useNavigate();

  //invoke authcontext
  const { dispatch } = useAuthContext();

  const validateEmailOtp = async (email, otp) => {
    setIsLoadingValidateEmailOtp(true);
    setErrorValidateEmailOtp(null);
    setSuccessValidateEmailOtp(null);

    const response = await Api.post(
      Urls.EMAIL_VALIDATION,
      { email, otp },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          //save to local storage
          localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state if we have a response
          setIsLoadingValidateEmailOtp(false);
          setSuccessValidateEmailOtp(response.data.message);
          toast.success(response.data.message);

          //get the user to decide where to redirect
          const user = JSON.parse(localStorage.getItem("user"));

          if (user?.message !== null) {
            navigate("/verified-email", { replace: true });
          }
          //navigate to entreprise if user is entreprise
          if (user?.message !== null) {
            navigate("/verified-email", { resplace: true });
          }
        }
      })
      .catch((error) => {
        setIsLoadingValidateEmailOtp(false);
        setErrorValidateEmailOtp(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return {
    validateEmailOtp,
    isLoadingValidateEmailOtp,
    errorValidateEmailOtp,
    successValidateEmailOtp,
  };
};
