import React from "react";
import { Box, FormControlLabel, Typography, Checkbox, Divider, CircularProgress, Button, RadioGroup, FormControl, Modal } from "@mui/material";
import ProfilePropComponent from "../../../components/ui/ProfilePropComponent";
import BoxContainer from "../../../components/ui/BoxContainer";
import { CheckBox } from "@mui/icons-material";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useEffect } from "react";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { useState } from "react";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { modalStyle2 } from "../../../theme/ModalStyle";
import DetailsProfileInterim from "./DetailsProfileInterim";
function ProfileProp({ id }) {
  const { user } = useAuthContext();
  const [candidatures, setCandidatures] = useState([]);
  const access_token = user?.access_token;
  const [loading, setLoading] = useState(true);
  const [motifs, setMotifs] = useState([]);
  const [change, setChange] = useState(false);
  const [matching, setMatching] = useState();
  const [status, setStatus] = useState();

  const [data, setData] = useState({});
  useEffect(() => {
    Api.get(Urls.PROFILE_PROPOS + id + "/applications", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(status ? { status: status } : null),
        ...(matching ? { matching: matching } : null),
      },
    })
      .then((res) => {
        //console.log(res, "liste des candidatures");
        setCandidatures(res?.data?.applications);
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [change, status, matching]);
  useEffect(() => {
    Api.get(Urls.GET_REFUSAL_REASONS_ENTREPRISE, {
      headers: { "content-type": "appication/json", authorization: `Bearer ${user?.access_token}` },
    })
      .then((res) => {
        //console.log(res);
        setMotifs(res?.data?.enterprise_refusal_reasons);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setCandidatures(res.data?.applications);
      })
      .catch((err) => {
        //console.log(err);
        setCandidatures([]);
      });
  };
  return (
    <Box display="flex" gap="24px">
      <Box flex={1}>
        <BoxContainer>
          <Box display="flex" flexDirection="column" gap="24px">
            <Box>
              <Typography fontSize="20px" lineHeight="32px" fontWeight="700">
                🔍 Filtres
              </Typography>
            </Box>
            <Box display="flex" flexDirection="column" gap="18px">
              <Typography fontSize="14px" fontWeight="500">
                Matching
              </Typography>
              <Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (matching !== "perfect" ? setMatching("perfect") : setMatching(""))} checked={matching === "perfect" ? true : false} />} label="🥇 Parfait" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (matching !== "suitable" ? setMatching("suitable") : setMatching(""))} checked={matching === "suitable" ? true : false} />} label="👌 Satisfaisant" />
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box display="flex" flexDirection="column" gap="18px">
              <Typography fontSize="14px" fontWeight="500"></Typography>
              <Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "PENDING" ? setStatus("PENDING") : setStatus(""))} checked={status === "PENDING" ? true : false} />} label="En attente" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "ACCEPTED" ? setStatus("ACCEPTED") : setStatus(""))} checked={status === "ACCEPTED" ? true : false} />} label="Offre envoyée" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "APPLICATION_REJECTED" ? setStatus("APPLICATION_REJECTED") : setStatus(""))} checked={status === "APPLICATION_REJECTED" ? true : false} />} label="Refusée" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "OFFER_REJECTED" ? setStatus("OFFER_REJECTED") : setStatus(""))} checked={status === "OFFER_REJECTED" ? true : false} />} label="Proposition refusée" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "DOCUMENTS_PENDING_VALIDATION" ? setStatus("DOCUMENTS_PENDING_VALIDATION") : setStatus(""))} checked={status === "DOCUMENTS_PENDING_VALIDATION" ? true : false} />} label="En attente de documents" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "WAITING_INTERIM_SIGNATURE" ? setStatus("WAITING_INTERIM_SIGNATURE") : setStatus(""))} checked={status === "WAITING_INTERIM_SIGNATURE" ? true : false} />} label="En attente de signature (Intérimaire)" />
                </Box>
                <Box width="100%">
                  <FormControlLabel control={<Checkbox onChange={() => (status !== "WAITING_ENTERPRISE_SIGNATURE" ? setStatus("WAITING_ENTERPRISE_SIGNATURE") : setStatus(""))} checked={status === "WAITING_ENTERPRISE_SIGNATURE" ? true : false} />} label="En attente de signature (Entreprise)" />
                </Box>
              </Box>
            </Box>
          </Box>
        </BoxContainer>
      </Box>
      <Box flex={3}>
        {loading ? (
          <Box display="fex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box>
              {candidatures?.map((candidature) => (
                <ProfilePropComponent
                  offre={candidature?.offer}
                  key={candidature?.id}
                  contractID={candidature?.contract?.id}
                  etat={candidature?.status}
                  motifsR={motifs}
                  setChange={setChange}
                  change={change}
                  id={candidature?.id}
                  actions={candidature?.enterprise_actions}
                  matching={candidature?.matching_level}
                  start_date={candidature?.accepted_by_admin_at}
                  titre={candidature?.user?.metier}
                  idUser={candidature?.user?.id}
                  nombre_de_mission={candidature?.user?.missions_count}
                />
              ))}
            </Box>
            {candidatures?.length > 0 && <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />}
            {candidatures?.length === 0 && <Typography textAlign="center">Aucun profile proposé</Typography>}
            {candidatures?.length > 0 && (
              <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
                <Box flex={1}>
                  {data?.links?.prev && (
                    <>
                      <Button
                        sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                        onClick={() => {
                          handlePagination(data?.links?.prev);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        variant="mj_secondary_md"
                      >
                        <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                      </Button>
                      <Button
                        sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                        onClick={() => {
                          handlePagination(data?.links?.prev);

                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        variant="mj_secondary_md"
                      >
                        <img src={leftArrowIcon} alt="arrow-left" />
                      </Button>
                    </>
                  )}
                </Box>
                <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
                  {data?.meta?.links?.map((link) => (
                    <ButtonSquare
                      onClick={() => {
                        handlePagination(link.url);
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      action={link?.active ? "number-active" : "number"}
                      number={link.label}
                    />
                  ))}
                </Box>
                <Box flex={1}>
                  {data?.links?.next && (
                    <>
                      <Button
                        sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                        onClick={() => {
                          handlePagination(data?.links?.next);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        variant="mj_secondary_md"
                      >
                        Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                      </Button>
                      <Button
                        sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                        onClick={() => {
                          handlePagination(data?.links?.next);
                          window.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          });
                        }}
                        variant="mj_secondary_md"
                      >
                        <img src={arrowRightIcon} alt="arrow-right" />
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default ProfileProp;
