import React from "react";
import { Box, Typography, FormControl, RadioGroup, Container, Button, TextField, InputAdornment, Autocomplete } from "@mui/material";
import RadioComponent from "../../../../components/ui/RadioComponent";
import { useState } from "react";
import { colors } from "../../../../theme/Colors";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";

function CreateOfferSelect() {
  const navigate = useNavigate();
  const [checked, setChecked] = useState("publier");
  const [searchedOffer, setSearchedOffer] = useState(null);
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState();
  const [data, setData] = useState();

  const { user } = useAuthContext();
  const access_token = user?.access_token;

  useEffect(() => {
    Api.get(Urls.GET_OFFRE_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        searchKey: searchedOffer,
      },
    })
      .then((response) => {
        // //console.log(response);
        setOffers(response?.data?.offers);
        // setData(response?.data);
      })
      .catch((error) => {
        //console.log(error);
      });
  }, [searchedOffer]);

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
        <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "68px 16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box width="100%">
            <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
              <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                Publier une demande
              </Typography>
              <Typography variant="text_normal" color={colors.text_subtitle}>
                Souhaitez-vous reprendre une de vos demandes ?
              </Typography>
            </Box>

            <Box marginBottom="32px">
              <Box sx={{ margin: "10px 0px" }}>
                <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "10px" }} aria-labelledby="demo-radio-buttons-group-label" value="publier" onChange={(e) => setChecked(e.target.value)} name="radio-buttons-group">
                  <RadioComponent onClick={() => setChecked("publier")} val="publier" check={checked} icon="publier" title="Nouvelle demande" subtitle="Je souhaite publier une nouvelle demande." />
                  <RadioComponent onClick={() => setChecked("republier")} val="republier" check={checked} icon="republier" title="Republier" subtitle="Je souhaite republier une de mes demandes.">
                    {checked === "republier" && (
                      <>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600">
                            Offre
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Autocomplete
                          options={offers}
                          getOptionLabel={(o) => "#" + o.id + " " + o.job_title}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          onChange={(event, value) => {
                            if (value !== null) {
                              setSelectedOffer(value.id);
                            } else {
                              setSelectedOffer(null);
                            }
                          }}
                          onInputChange={(event, value) => {
                            if (value) {
                              setSearchedOffer(event?.target?.value);
                              //console.log("searching for :", event?.target?.value);
                              //console.log(value);
                            } else {
                              setSearchedOffer(null);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              value={selectedOffer}
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Rechercher des certifications..."
                            />
                          )}
                        />
                      </>
                    )}
                  </RadioComponent>
                </RadioGroup>
              </Box>
            </Box>

            <Button
              disabled={checked === null || (checked === "republier" && !selectedOffer) ? true : false}
              fullWidth
              variant="mj_primary_md"
              onClick={() => (checked === "publier" ? navigate("/entreprise/offres/creer-offre") : navigate("/entreprise/offres/republier-offre", { state: { id: selectedOffer } }))}
            >
              Suivant
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CreateOfferSelect;
