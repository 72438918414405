import { Container } from "@mui/system";
import { Box, Button, TextField, Typography, IconButton, InputAdornment, Modal } from "@mui/material";
import React from "react";
import BoxContainer from "../../../components/ui/BoxContainer";
import { useState } from "react";
import EmailGrayIcon from "../../../imgs/imgV2/emailGray.svg";
import EmailBlackIcon from "../../../imgs/imgV2/emailBlack.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import MdpBlackIcon from "../../../imgs/imgV2/mdpBlack.svg";
import mdpGrayIcon from "../../../imgs/imgV2/mdpGray.svg";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import MyTextField from "../../../components/ui/MyTextField";
import { colors } from "../../../theme/Colors";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import { modalStyle } from "../../../theme/ModalStyle";
import { useNavigate } from "react-router-dom";

function Parametres() {
  const [selected, setSelected] = useState("mot de passe");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordDel, setShowPasswordDel] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const id = user?.user?.id;

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  function handleMouseDownPassword(event) {
    event.preventDefault();
  }
  function handleClickShowNewPassword() {
    setShowNewPassword(!showNewPassword);
  }

  function handleMouseDownNewPassword(event) {
    event.preventDefault();
  }
  const updatePassword = (values) => {
    Api.patch(
      Urls.UPDATE_PASSWORD_USER + id + "/password",
      {
        old_password: values.old_password,
        new_password: values.new_password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //validation schema
  const validationSchema = Yup.object({
    old_password: Yup.string().required("Ce champ est obligatoire"),
    new_password: Yup.string()
      .required("Ce champ est obligatoire")
      .notOneOf([Yup.ref("old_password"), null], "Le nouveau mot de passe doit être différent de l'ancient"),
  });
  //validation schema
  const validationSchemaDel = Yup.object({
    password: Yup.string().required("Ce champ est obligatoire"),
  });
  const deleteAccount = (values) => {
    Api.delete(
      Urls.DELETE_USER + user?.user?.id,
      {
        password: values.password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        localStorage.removeItem("user");
        navigate("/login");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Container maxWidth="md">
      <ToastContainer />
      <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box display="flex" flexDirection="column" gap="24px">
          <Box sx={{ cursor: "default", opacity: { xs: "1", sm: "1", lg: "0", md: "0" } }}>
            <Typography fontWeight="700" fontSize="28px" lineHeight="44.8px">
              Paramètres
            </Typography>
          </Box>
          <BoxContainer>
            <Box sx={{ width: { xs: "150px", sm: "200px", md: "250px", lg: "274px" } }} display="flex" flexDirection="column" gap="16px">
              <Box onClick={() => setSelected("email")} sx={{ cursor: "pointer" }} display="flex" alignItems="center" gap="8px">
                <img src={selected === "mot de passe" ? EmailGrayIcon : EmailBlackIcon} alt="email icon" />
                <Typography color={selected === "mot de passe" ? "#757B8F" : "black"} fontSize="14px" fontWeight="400" lineHeight="22.4px">
                  Email
                </Typography>
              </Box>
              <Box onClick={() => setSelected("mot de passe")} sx={{ cursor: "pointer" }} display="flex" alignItems="center" gap="8px">
                <img src={selected === "mot de passe" ? MdpBlackIcon : mdpGrayIcon} alt="email icon" />

                <Typography color={selected === "mot de passe" ? "black" : "#757B8F"} fontSize="14px" fontWeight="400" lineHeight="22.4px">
                  Mot de passe
                </Typography>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }} fontWeight="700" fontSize="28px" lineHeight="44.8px">
            Paramètres
          </Typography>
          {selected === "email" && (
            <BoxContainer>
              <Box sx={{ width: { sx: "200px", sm: "250px", md: "350px", lg: "472px" } }} display="flex" flexDirection="column" gap="24px">
                <Box>
                  <Typography lineHeight="32px" fontWeight="700" fontSize="20px">
                    Modifier votre email
                  </Typography>
                </Box>
                <Box>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    onSubmit={() => {
                      console.log("submited");
                    }}
                  >
                    {({ values, errors, setFieldValue, touched }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Email
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <MyTextField disabled={true} setFieldValue={setFieldValue} value={values.email} name="email" placeholder="Email..." />
                              {errors.email && touched.email ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.email}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="end" alignItems="center">
                            <Button type="submit" variant="mj_primary_md">
                              Enregistrer
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </BoxContainer>
          )}
          {selected === "mot de passe" && (
            <BoxContainer>
              <Box sx={{ width: { sx: "200px", sm: "250px", md: "350px", lg: "472px" } }} display="flex" flexDirection="column" gap="24px">
                <Box>
                  <Typography lineHeight="32px" fontWeight="700" fontSize="20px">
                    Modifier votre mot de passe
                  </Typography>
                </Box>
                <Box>
                  <Formik
                    initialValues={{
                      old_password: "",
                      new_password: "",
                    }}
                    onSubmit={(values) => {
                      updatePassword(values);
                    }}
                    validationSchema={validationSchema}
                  >
                    {({ values, errors, setFieldValue, touched }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Mot de passe actuel
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field name="old_password" id="password">
                                {({ field, form, meta }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    placeholder="Saisir votre mot de passe"
                                    autoComplete="Off"
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                          <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                            {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </Field>{" "}
                              {errors.old_password && touched.old_password ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.old_password}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Nouveau mot de passe
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>

                              <Field name="new_password" id="password">
                                {({ field, form, meta }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    placeholder="Saisir votre mot de passe"
                                    autoComplete="Off"
                                    type={showNewPassword ? "text" : "password"}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                          <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownNewPassword} edge="end">
                                            {showNewPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              </Field>
                              {errors.new_password && touched.new_password ? (
                                <Typography color="red" fontSize="12px">
                                  {errors.new_password}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="end" alignItems="center">
                            <Button type="submit" variant="mj_primary_md">
                              Enregistrer
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </BoxContainer>
          )}
          <Box>
            <BoxContainer>
              <Box display="flex" flexDirection="column" gap="24px">
                <Typography lineHeight="32px" fontWeight="700" fontSize="20px">
                  Supprimer votre compte
                </Typography>
                <Box>
                  <Button onClick={() => setOpen(true)} variant="mj_red_md">
                    Supprimer
                  </Button>
                </Box>
              </Box>
            </BoxContainer>
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Supprimer votre compte
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Formik
                initialValues={{
                  password: "",
                }}
                onSubmit={(values) => {
                  deleteAccount(values);
                }}
                validationSchema={validationSchemaDel}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box marginBottom="10px">
                      <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%" }}>Attention, cette action est irréversible, vous ne pourrez pas récupérer votre compte.</Typography>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box marginTop="24px" display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_red_md">
                        Supprimer mon compte
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Container>
  );
}

export default Parametres;
