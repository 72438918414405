export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  boxShadow: 24,
  p: 0,
  minWidth: { xs: "300px", sm: "400px", md: "600px", lg: "700px" },
  maxHeight: "95vh",
  overflowY: "scroll",
  PointerEvent: "auto",
  zIndex: 0,
};
export const modalStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "white",
  borderRadius: "6px",
  boxShadow: 24,
  p: 0,
  minWidth: { lg: "80%", xs: "98%" },
  height: "95vh",
  overflowY: "scroll",
  PointerEvent: "auto",
  zIndex: 0,
};
