import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, FormControl, RadioGroup, FormControlLabel, Divider, Radio, InputAdornment, IconButton, Autocomplete, Grid } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../../../theme/Colors";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import checkLinkicon from "../../../../imgs/imgV2/checkLinkIcon.svg";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import { useLayoutEffect } from "react";
import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";

function OBInfosAdministratives({ setCurrentStep }) {
  const [hover, onHover] = useState(false);

  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const handleNext = () => {
    setCurrentStep(3);
  };

  const handlePrevious = () => {
    setCurrentStep(1);
  };

  const onSubmit = (values) => {
    //console.log("values are : ", values);
    handleNext();
  };

  const validationSchemaMandat = Yup.object({
    password: Yup.string().required("Ce champ est obligatoire"),
  });
  const validationSchema = Yup.object({
    form_jur: Yup.string().required("Ce champ est obligatoire"),
  });
  const [openSign, setOpenSign] = useState(false);
  const [userInfos, setUserInfos] = useState({});
  const [typePaiement, setTypePaiement] = useState("virement_bancaire");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFormeJuridique, setSelectedFormeJuridique] = useState();
  const [formesJuridiques, setFormesJuridiques] = useState([]);
  const [loading, setLoading] = useState(true);
  const [change, setChange] = useState(false);
  const formRef = useRef();
  //get formes juridiques
  useEffect(() => {
    Api.get(Urls.GET_FORME_JURIDIQUE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "forme");
        setFormesJuridiques(res?.data?.forme_juridiques);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [change]);
  const [initialValues, setInitialValuesinfoAdmin] = useState({
    rib: "",
    form_jur: "",
    code_ape: "",
    num_tva: "",
  });
  useLayoutEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUserInfos(res?.data?.user);
        setInitialValuesinfoAdmin({
          rib: res?.data?.user?.enterprise?.rib ? res?.data?.user?.enterprise?.rib : "",
          form_jur: res?.data?.user?.enterprise?.forme_juridique?.id ? res?.data?.user?.enterprise?.forme_juridique?.id : 1,
          code_ape: res?.data?.user?.enterprise?.code_ape ? res?.data?.user?.enterprise?.code_ape : "",
          num_tva: res?.data?.user?.enterprise?.num_tva ? res?.data?.user?.enterprise?.num_tva : "",
        });
        setSelectedFormeJuridique(res?.data?.user?.enterprise?.forme_juridique ? res?.data?.user?.enterprise?.forme_juridique : null);
        setLoading(false);
        setTypePaiement(res?.data?.user?.enterprise?.payment_mode);
      })
      .catch((err) => setLoading(false));
  }, [change]);
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }
  const ModifyProfile = (values) => {
    //console.log("submitted", values);
    if (formRef?.current?.dirty) {
      Api.patch(
        Urls?.UPDATE_PROFILE_ENTREPRISE + user?.user?.enterprise?.id,
        {
          forme_juridique_id: values.form_jur,
          ...(values.rib === "" ? { rib: null } : { rib: values.rib }),
          ...(values.num_tva === "" ? { num_tva: null } : { num_tva: values.num_tva }),
          ...(values.code_ape === "" ? { code_ape: null } : { code_ape: values.code_ape }),
        },
        {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          //console.log(res);
          toast.success(res?.data?.message);
          handleNext();
        })
        .catch((err) => {
          //console.log(err);
          toast.error(err?.response?.data?.message);
        });
    } else {
      handleNext();
    }
  };
  const SelectPayement = () => {
    if (userInfos?.enterprise?.payment_mode !== typePaiement) {
      Api.patch(
        Urls.METHODE_PAYEMENT + user?.user?.enterprise?.id + "/payment_mode",
        {
          payment_mode: typePaiement,
        },
        {
          headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
        }
      )
        .then((res) => {
          toast.success(res?.data?.message);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };
  const SignMandat = (values) => {
    Api.post(
      Urls.SIGN_MADNDAT_SEPA + user?.user?.enterprise?.id + "/sign-sepa",
      {
        password: values.password,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        SelectPayement();
        setOpenSign(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <>
      {/* form */}
      <Box>
        <ToastContainer />
        <Formik
          onSubmit={(values) => {
            ModifyProfile(values);
            SelectPayement();
          }}
          innerRef={formRef}
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={initialValues}
        >
          <Form>
            <ToastContainer />
            <Box display="flex" flexDirection="column">
              {/* forme juridique */}
              {!loading && (
                <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                  <Box sx={{ marginBottom: "6px" }}>
                    <Typography variant="text_normal" fontWeight="600" htmlFor="forme_jur" name="forme_jur">
                      Forme juridique
                    </Typography>
                    <Typography variant="text_normal" fontWeight="600" htmlFor="forme_jur" name="forme_jur" style={{ color: colors.main }}>
                      *
                    </Typography>
                  </Box>

                  <Field name="form_jur">
                    {({ field, form }) => (
                      <Autocomplete
                        {...field}
                        fullWidth
                        value={selectedFormeJuridique}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value) {
                            form.setFieldValue("form_jur", value.id);
                            setSelectedFormeJuridique(value);
                            //console.log("selected forme juridique is ::", value);
                          } else {
                            setSelectedFormeJuridique(null);
                            form.setFieldValue("form_jur", "");
                          }
                        }}
                        // onInputChange={(event, value) => {
                        //   setSearchedMetier(event?.target?.value);
                        //   //console.log("searching for :", event?.target?.value);
                        //   //console.log(value);
                        //   if (event?.target?.value === "") {
                        //     form.setFieldValue("metier", "");
                        //   }
                        // }}
                        options={formesJuridiques}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            value={selectedFormeJuridique}
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={RechercheIcon} alt="end" />
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Séléctionner la forme juridique..."
                          />
                        )}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="form_jur" component={FormikErrorText} />
                </Box>
              )}
              {/* RIB */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="rib" name="rib">
                    RIB
                  </Typography>
                  {/* <Typography variant="text_normal" fontWeight="600" htmlFor="rib" name="rib" style={{ color: colors.main }}>
                    *
                  </Typography> */}
                </Box>
                <Field autoComplete="off" name="rib" type="email" placeholder="Saisir votre RIB..." id="rib" as={TextField}></Field>
                <ErrorMessage name="rib" component={FormikErrorText} />
              </Box>

              {/* code APE */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="code_ape" name="code_ape">
                    Code APE
                  </Typography>
                  {/* <Typography variant="text_normal" fontWeight="600" htmlFor="code_ape" name="code_ape" style={{ color: colors.main }}>
                    *
                  </Typography> */}
                </Box>
                <Field autoComplete="off" name="code_ape" placeholder="Saisir votre code APE..." id="code_ape" as={TextField}></Field>
                <ErrorMessage name="code_ape" component={FormikErrorText} />
              </Box>

              {/* numero tva  */}
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="tva" name="tva">
                    Numéro de TVA
                  </Typography>
                  {/* <Typography variant="text_normal" fontWeight="600" htmlFor="tva" name="tva" style={{ color: colors.main }}>
                    *
                  </Typography> */}
                </Box>
                <Field autoComplete="off" name="num_tva" placeholder="Saisir votre numéro de TVA..." id="tva" as={TextField}></Field>
                <ErrorMessage name="num_tva" component={FormikErrorText} />
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>

      {/* divider */}
      <Divider sx={{ margin: "32px 0px" }} />

      {/* reglement */}
      <Box>
        <Box display="fex" gap="10px" alignItems="center" marginBottom="20px">
          <Typography fontSize="20px" fontWeight="700" lineHeight="32px">
            Reglement
          </Typography>
          {userInfos?.enterprise?.sepa && (
            <Box onClick={() => window.open(userInfos?.enterprise?.sepa?.url_file, "_blank")} marginTop="2px" alignItems="center" display="flex" gap="7px">
              <Typography variant="text_clickable">Consulter le fichier</Typography>
              <img src={checkLinkicon} alt="link-icon" />
            </Box>
          )}
        </Box>
        <Box marginBottom="6px">
          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
            Sélectionner une méthode
          </Typography>
          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
            *
          </Typography>
        </Box>
        <FormControl sx={{ marginBottom: "40px" }}>
          <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={typePaiement} onChange={(e) => setTypePaiement(e.target.value)}>
            <Box display="flex" gap="24px" alignItems="center">
              <FormControlLabel
                disabled={!userInfos?.enterprise?.sepa && !userInfos?.enterprise?.rib ? true : false}
                onMouseEnter={() => {
                  if (!userInfos?.enterprise?.rib && !userInfos?.enterprise?.sepa?.id) {
                    onHover(true);
                  }
                }}
                onMouseLeave={() => {
                  onHover(false);
                }}
                value="SEPA"
                control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />}
                label="Mandat SEPA"
              />
              <Box>
                {hover && (
                  <Box padding="8px" border="1px solid gray" borderRadius="6px">
                    <Typography fontSize="15px" fontWeight="500">
                      {" "}
                      Veuillez enregistrer votre adresse et votre rib
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <FormControlLabel value="VIREMENT" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Virement bancaire" />
            {typePaiement === "SEPA" && userInfos?.enterprise?.rib && !userInfos?.enterprise?.sepa?.id && (
              <Formik
                initialValues={{
                  password: "",
                }}
                onSubmit={(values) => {
                  //console.log(values);
                  SignMandat(values);
                }}
                validationSchema={validationSchemaMandat}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box marginBottom="6px">
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                        Mot de passe
                      </Typography>
                      <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                        *
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap="10px">
                      <Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                      {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box display="flex" gap="10px">
                        <Button onClick={() => setOpenSign(false)} variant="mj_secondary_md">
                          Annuler
                        </Button>
                        <Button type="submit" variant="mj_primary_md">
                          Signer
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
          </RadioGroup>
        </FormControl>

        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Button sx={{ width: "118px" }} onClick={() => handlePrevious()} variant="mj_secondary_md">
              Précédent
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button sx={{ width: "118px" }} type="submit" variant="mj_green_md" onClick={() => formRef?.current?.handleSubmit()}>
              Enregistrer
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button sx={{ width: "118px" }} variant="mj_primary_md" onClick={() => handleNext()}>
              Suivant
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OBInfosAdministratives;
