import { Box, Typography } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import styled from "@emotion/styled";
import MyChip from "../../../../components/ui/MyChip";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

function EntretienPrevuSection() {
  const { user } = useAuthContext();
  const [interviews, setInteriews] = useState([]);
  const access_token = user?.access_token;
  useEffect(() => {
    Api.get(Urls.LISTE_ENTRETIENS, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInteriews(res?.data?.interviews);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  const data = [
    { interimaire: "Varsha Devi", photo: "https://picsum.photos/id/123/200", montant: 116 },
    { interimaire: "Olivia Zajac", photo: "https://picsum.photos/id/232/200", montant: 508 },
    { interimaire: "Ahmad Aziz", photo: "https://picsum.photos/id/465/200", montant: 738 },
  ];

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FAFAFB",
      color: `#0D173A`,
      fontSize: 12,
      fontWeight: 500,
      padding: "6px 20px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
      padding: "10px 20px",
      border: "none",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });

  return (
    <Box sx={{ border: "1px solid #DEDFE4", borderRadius: "6px" }}>
      {/* header */}
      <ToastContainer />
      <Box padding="24px" display="flex" flexDirection="row" gap="14px" sx={{ borderBottom: `1px solid #DEDFE4` }}>
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          👔 Entretiens prévus
        </Typography>
      </Box>

      <TableContainer sx={{ borderRadius: "0px 0px 6px 6px" }}>
        <StyledTable aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell width="10%">Offre</StyledTableCell>
              <StyledTableCell width="10%">Entreprise</StyledTableCell>
              <StyledTableCell width="70%" align="left">
                Date
              </StyledTableCell>
              <StyledTableCell width="10%" align="right">
                Lien
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interviews?.map((item, index) => (
              <StyledTableRow
                key={index}
                // onClick={() => {
                //   setOpenFactureDetail(true);
                //   setIdFacture(facture?.facture);
                // }}
              >
                <StyledTableCell align="left">
                  <Typography fontWeight="600" color="black" fontSize="12px">
                    {item?.offer_id}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography fontWeight="400" color="#575E76" fontSize="12px">
                    {item?.enterprise_id}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Typography fontWeight="400" color="#575E76" fontSize="12px">
                    {moment(item?.date_time).format("dddd DD MMMM YYYY hh:mm")}
                  </Typography>
                </StyledTableCell>

                <StyledTableCell align="right">
                  <Typography fontWeight="600" color="black" fontSize="12px">
                    {item?.address}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Box>
  );
}

export default EntretienPrevuSection;
