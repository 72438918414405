import React from "react";
import { Box, Typography, Button, Icon, Chip, Modal, FormControlLabel, Checkbox } from "@mui/material";
import MyChip from "../../../../components/ui/MyChip";
import lockIcon from "../../../../imgs/imgV2/lockIcon.svg";
import alertIcon from "../../../../imgs/imgV2/alertIcon.svg";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import moment from "moment";
import spec from "../../../../imgs/imgV2/spec2.svg";
import RightArrowTime from "../../../../imgs/imgV2/RightArrowTime.svg";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { modalStyle, modalStyle2 } from "../../../../theme/ModalStyle";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { CheckBox } from "@mui/icons-material";
import DetailOffre from "../DetailOffre";

function OffresSuggereSection() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const navigate = useNavigate();
  const [offres, setOffres] = useState([]);
  const [data, setData] = useState([]);
  const perPage = 8;
  const [change, setChange] = useState(false);
  const [checkedPostuler, setCheckedPostuler] = useState(false);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [metierListe, setMetierListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [idOffer, setIDOffer] = useState(null);
  const [numberOfResults, setNumberOfResults] = useState(0);
  function formatDateFront(date) {
    moment.locale("fr");
    return moment(date)
      .format("DD MMMM YYYY")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  function isEqualDate(date1, date2) {
    return date1.getDate() >= date2.getDate() && date1.getMonth() >= date2.getMonth() && date1.getFullYear() >= date2.getFullYear();
  }
  useEffect(() => {
    setIsLoading(true);
    Api.get(`${Urls.GET_OFFRE_LIST}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
        onlyMatched: 1,
      },
    })
      .then((res) => {
        setData(res?.data);
        setOffres(res.data?.offers);
        setIsLoading(false);
        setNumberOfResults(res?.data?.meta?.total);
      })
      .catch((err) => {
        setIsLoading(false);
        setOffres([]);
        setData(null);
      });
  }, []);
  //handle postuler
  const [openPostuler, setOpenPostuler] = useState(false);
  const [postulerIDS, setPostulerIDS] = useState([]);
  const handlePostuler = (id, index) => {
    return new Promise((resolve, reject) => {
      Api.post(Urls.POSTULER_OFFRE_ID + user?.user?.id + "/offers/" + id + "/apply", null, {
        headers: { "Content-Type": "application/json", authorization: `Bearer ${user?.access_token}` },
      })
        .then((res) => {
          toast.success(res?.data?.message);
          setPostulerIDS(postulerIDS.concat(id));
          setOpenPostuler(false);
          resolve(true);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          reject(false);
        });
    });
  };
  //HANDLE CLICK OFFER
  const handelClickOffer = (id, index) => {
    setIDOffer(id);
    setOpen(true);
  };
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    if (minutes === 1) {
      return "Postée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Postée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Postée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Postée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Postée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Postée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Postée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Postée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Postée il y a 1 mois";
    } else {
      return `Postée il y a ${mois} mois`;
    }
  }

  const chipSelector = (status) => {
    switch (status) {
      case 1:
        return { variant: "active_offer", label: "Active" };
      case 0:
        return { variant: "ended_offer", label: "Terminée" };
      default:
        return {};
    }
  };

  return (
    <Box>
      <Box sx={{ padding: "24px", display: "flex", flexDirection: { md: "row", xs: "column" }, border: "1px solid #DEDFE4", marginTop: "-1px", justifyContent: { md: "space-between" }, gap: "16px", alignItems: { md: "center" } }} borderRadius="6px 6px 0px 0px">
        <Box>
          <Typography fontSize="20px" fontWeight="700">
            💡 Demandes suggérées
          </Typography>
        </Box>
        <Box
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography onClick={() => navigate("/renard/offres/offres-suggerees")} color="#0065FF" fontSize="12px" fontWeight="500">
            Voir toutes les demandes
          </Typography>
        </Box>
      </Box>
      {offres?.map((m, index) => (
        <Box sx={{ cursor: "pointer", padding: "40px", border: "1px solid #DEDFE4", marginTop: "-1px", justifyContent: { md: "space-between" }, gap: "16px", alignItems: { md: "center" } }} borderRadius={index > offres?.length - 1 ? "0px 0px 6px 6px " : "0px 0px 0px 0px"}>
          {/* infos top */}
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box display="flex" flexDirection="column" gap="4px">
              <Box>
                <MyChip variant="matching_parfait" label="💡 Offre suggérée" />
              </Box>
              <Box onClick={() => handelClickOffer(m.id)} width="70%">
                <Typography fontSize="18px" fontWeight="500">
                  {m.job_title}
                </Typography>
              </Box>
              <Box>
                <MyChip icon={spec} variant="skill" label={m.metier.name} />
              </Box>
              <Box>
                <Typography variant="text_md" color="#4A526C">
                  {afficherDifferenceDate(m.created_at)}
                </Typography>
              </Box>
            </Box>

            <Box>
              {postulerIDS?.includes(m.id) && <MyChip variant="skill" label="Vous avez postulé" />}
              {!postulerIDS?.includes(m.id) && (
                <Button
                  variant="mj_primary_md"
                  onClick={() => {
                    setOpenPostuler(true);
                    setIDOffer(m.id);
                  }}
                >
                  Postuler
                </Button>
              )}
            </Box>
          </Box>

          <Box display="flex" gap="8px" sx={{ flexDirection: { md: "row", xs: "column" } }}>
            <Box>
              <Typography fontWeight="500">💰 {m.basic_hourly_rate}€/h</Typography>
            </Box>
            <Box>
              <Typography variant="text_md" color="#666D83" fontWeight="500" sx={{ display: { xs: "none", md: "flex" } }}>
                ・
              </Typography>
            </Box>

            <Box>
              <Typography fontWeight="500">📍{m.address}</Typography>
            </Box>
            <Box>
              <Typography variant="text_md" color="#666D83" fontWeight="500" sx={{ display: { xs: "none", md: "flex" } }}>
                ・
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
              <Typography variant="text_md" fontWeight="500">
                🗓️
              </Typography>
              <Typography variant="text_md" fontWeight="500">
                {isEqualDate(new Date(), moment(m.start_date).toDate()) ? "Dès que possible" : formatDateFront(m.end_date)}
              </Typography>
              <img src={RightArrowTime} width="15px" alt="" />
              <Typography variant="text_md" fontWeight="500">
                {formatDateFront(m.end_date)}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpen(false)} action="close" />

                <Typography variant="text_xxl" fontWeight="700">
                  Offre #{idOffer}
                </Typography>
              </Box>
              {postulerIDS?.includes(idOffer) ? (
                <MyChip variant="skill" label="Vous avez postulé" />
              ) : (
                <Button variant="mj_primary_md" onClick={() => setOpenPostuler(true)}>
                  Postuler
                </Button>
              )}
            </Box>
            <DetailOffre id={idOffer} />
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openPostuler}
        onClose={() => {
          setOpenPostuler(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box display="flex" justifyContent="end" alignItems="end">
              <ButtonSquare onClick={() => setOpenPostuler(false)} action="close" />
            </Box>
            <Typography marginBottom="16px" variant="text_xxl" fontWeight="700">
              Voulez-vous postuler pour ce poste ?
            </Typography>
            <Box display="flex" flexDirection="column" gap="22px" marginBottom="46px">
              <Box marginBottom="10px" display="flex" justifyContent="center">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir postuler pour ce poste ?</Typography>
              </Box>
              <Box>
                <FormControlLabel
                  onChange={(e) => {
                    setCheckedPostuler(e.target.checked);
                  }}
                  control={<Checkbox checked={checkedPostuler} />}
                  label="Je possède les certifications demandées."
                />
              </Box>
            </Box>
            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
              <Button
                onClick={() => {
                  setOpenPostuler(false);
                }}
                variant="mj_secondary_md"
              >
                Annuler
              </Button>
              <Button
                disabled={!checkedPostuler}
                onClick={() => {
                  handlePostuler(idOffer)
                    .then((res) => {
                      setOpenPostuler(false);
                    })
                    .catch((err) => {
                      setOpenPostuler(true);
                    });
                }}
                variant="mj_primary_md"
              >
                Envoyer
              </Button>{" "}
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default OffresSuggereSection;
