import React, { useRef } from "react";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/Colors";

import { TextField } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import Button from "@mui/material/Button";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";
import logo from "../../imgs/imgV2/OhMyJob.svg";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { ToastContainer, toast } from "react-toastify";

function ForgotPassword() {
  //refs
  const alertErrorRef = useRef();
  const alertSuccessRef = useRef();
  const buttonRef = useRef();

  //initialize error, isLoading, and login function
  const { forgotPassword, errorForgotPassword, isLoadingForgotPassword, successForgotPassword, isWaitingForResend } = useForgotPassword();

  //validation for fields
  const validationSchema = Yup.object({
    email: Yup.string().email("Le format de l'email est invalide").required("Ce champs est obligatoire"),
  });

  //Form initial values
  const initialValues = {
    email: "",
  };

  //invoke forgotPassword that we get from useForgotPassword
  const onSubmitForgotPassword = async (values, errorForgotPassword, successForgotPassword, isLoadingForgotPassword, isWaitingResend) => {
    await forgotPassword(values.email);
  };

  //remove alerts if unncessary
  const removeAlerts = () => {
    if (alertSuccessRef) alertErrorRef.removeChild();
  };

  //invoke useNavigate hook

  return (
    <Container maxWidth={"xl"}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>
          {/* FORM BEGINNING */}
          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                  Mot de passe oublié
                </Typography>
                <Typography variant="text_normal" color={colors.text_subtitle}>
                  Récupérer votre compte
                </Typography>
              </Box>
              {/* FORM FOR SENDING THE OTP */}
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitForgotPassword}>
                {({ values, error }) => (
                  <Form>
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email">
                          Email
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="email" type="email" placeholder="votre@email.com" id="email" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="email" />
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button ref={buttonRef} disabled={isLoadingForgotPassword} className="submit" type="submit" variant="mj_primary_md" fullWidth>
                        Demander un code
                      </Button>
                    </Box>
                    {/* {errorForgotPassword && (
                      <Alert
                        ref={alertErrorRef}
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorForgotPassword}
                      </Alert>
                    )} */}

                    {/* {successForgotPassword && (
                      <>
                        <Alert
                          ref={alertSuccessRef}
                          sx={{
                            border: `1px solid  ${colors.mjGreenOutline}`,
                            borderRadius: "12px",
                          }}
                          severity="success"
                        >
                          {successForgotPassword}
                        </Alert>
                      </>
                    )} */}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          {/* FORM END */}
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  );
}

export default ForgotPassword;
