import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, Grid, Divider, TextField, InputAdornment, Autocomplete } from "@mui/material";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import styled from "@emotion/styled";
import leftArrowIcon from "../../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../../imgs/imgV2/arrowRightBlavk.svg";

import { useState, useEffect } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Table from "@mui/material/Table";
import MyChip from "../../../../components/ui/MyChip";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import moment from "moment";

function FicheDePaie() {
  //invoke user
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //modal state

  //filter states
  const [searchedFicheDePaie, setSeachedFicheDePaie] = useState(null);
  const [selectedFicheDePaieState, setSelectedFicheDePaieState] = useState(null);

  const [fichesDePaie, setFichesDePaie] = useState([]);
  const [data, setData] = useState();

  //table style
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FAFAFB",
      color: `#0D173A`,
      fontSize: 12,
      fontWeight: 500,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      color: `#666D83`,
      fontWeight: "500",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  }));

  const StyledTable = styled(Table)({
    borderRadius: "6px",
  });

  const ficheDePaieStates = [
    {
      id: "NOT_PAYED",
      name: "Non encaissée",
    },
    {
      id: "PAYED",
      name: "Encaissée",
    },
  ];

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls?.GET_FICHE_DE_PAIE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(searchedFicheDePaie ? { number: searchedFicheDePaie } : null),
        ...(selectedFicheDePaieState ? { status: selectedFicheDePaieState } : null),
      },
    })
      .then((res) => {
        //console.log(res);
        setData(res?.data);
        setFichesDePaie(res?.data?.payslips);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [searchedFicheDePaie, selectedFicheDePaieState]);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setFichesDePaie(res.data?.payslips);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setFichesDePaie([]);
      });
  };

  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* title */}
      <Box>
        <Typography variant="text_xl" fontWeight="700">
          Mes fiches de paie
        </Typography>
      </Box>
      {/* filter */}
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={9}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher une fiche de paie
                </Typography>
              </Box>
              <TextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    setSeachedFicheDePaie(event.target.value);
                  } else {
                    setSeachedFicheDePaie(null);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                options={ficheDePaieStates}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(e) => e.name}
                onChange={(event, value) => {
                  if (value !== null) {
                    //console.log("selecting facture state value", value);
                    //console.log("selecting facture state value", value.id);
                    setSelectedFicheDePaieState(value ? value.id : "");
                    //console.log("selectedFicheDePaieState", selectedFicheDePaieState);
                  } else {
                    setSelectedFicheDePaieState(null);
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* liste */}
      {!loading ? (
        <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DEDFE4` }}>
          <StyledTable aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width="9.5%">Fiche de paie</StyledTableCell>
                <StyledTableCell width="72%">Date</StyledTableCell>
                <StyledTableCell width="6%" align="center">
                  Etat
                </StyledTableCell>
                <StyledTableCell width="9.5%%" align="right">
                  Montant
                </StyledTableCell>
                <StyledTableCell width="1%" align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fichesDePaie?.map((fp, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="left">
                    <Typography fontWeight="600" color="black" fontSize="14px">
                      {fp?.number}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Typography fontWeight="400" color="#575E76" fontSize="14px">
                      {moment(fp?.date).format("yyyy MMMM DD")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <MyChip variant={fp?.status === "PAYED" ? "ended_offer" : "rejected_offer"} label={fp?.status === "PAYED" ? "Encaissée" : "Non encaissée"} />
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <Typography fontWeight="600" color="black" fontSize="14px">
                      {fp?.amount}€
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <ButtonSquare onClick={() => window.open(fp?.url_file, "_blank")} action="download-blue" />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      {/* pagination */}
      {fichesDePaie?.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
      {fichesDePaie?.length === 0 && !loading && <Typography textAlign="center">Aucune fiche de paie</Typography>}
      {fichesDePaie?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      {/*
      <Modal open={openFicheDePaieDetail} onClose={() => setOpenFicheDePaieDetail(false)}>
        <Box sx={modalStyle2}>
          <Box padding="40px">
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                  <ButtonSquare onClick={() => setOpenFicheDePaieDetail(false)} action="close" />
                  <Typography variant="text_xxl" fontWeight="700">
                    Fiche de paie #{idFicheDePaie}
                  </Typography>
                </Box>
                <Box>
                  <Button variant="mj_secondary_md" startIcon={svgIcon}>
                    Télécharger
                  </Button>
                </Box>
              </Box>
              <Box display="flex" gap="10px" alignItems="center">
                <MyChip variant={idFicheDePaie ? "ended_offer" : "rejected_offer"} label={idFicheDePaie ? "Encaissée" : "Non encaissée"} />
                <Typography fontSize="14px" color="#4A526C" fontWeight="400" lineHeight="160%">
                  ・ {FichesDePaieDummy[0].date} ・ 💸️{FichesDePaieDummy[0].montant}€ total
                </Typography>
              </Box>
            </Box>
            <DetailFicheDePaie id={idFicheDePaie} />
          </Box>
        </Box>
                </Modal>*/}
    </Box>
  );
}

export default FicheDePaie;
