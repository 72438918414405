import React from "react";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function FAQCandidat() {
  const questions = [
    {
      id: "1",
      nom: "Comment se connecter et se deconnecter",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "2",
      nom: "Comment changer de mot de passe ",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "3",
      nom: "Comment valider votre compte",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "4",
      nom: "Comment editer vos informations du compte",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "5",
      nom: "Comment supprimer votre compte",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "6",
      nom: "Comment editer vos informations du compte",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
    {
      id: "7",
      nom: "Comment supprimer votre compte",
      description: "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression. Le Lorem Ipsum est le faux texte standard de l'imprimerie depuis les années 1500, quand un imprimeur anonyme assembla ensemble des morceaux",
    },
  ];

  return (
    <Box padding="100px 0px">
      {/* header */}
      <Box marginBottom="100px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box>
          <Typography variant="text_xxl" fontWeight="800">
            Les questions les plus fréquentes
          </Typography>
        </Box>
        <Box>
          <Typography variant="text_sm" color="#666D83">
            Vous avez une question ? Nous sommes là pour y répondre.
          </Typography>
        </Box>
      </Box>

      <Box sx={{ padding: { md: "0px 100px", sm: "0px 0px" } }}>
        {questions.map((q) => (
          <Accordion key={q.id} style={{ boxShadow: "none", padding: "10px" }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="text_md" fontWeight={600}>
                {q.nom}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="text_sm" color="#2E3755">
                {q.description}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
}
export default FAQCandidat;
