import React from "react";
import BoxContainer from "../../../ui/BoxContainer";
import { Avatar, Box, Typography } from "@mui/material";

import locationIcon from "../../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../../imgs/imgV2/TelIcon.svg";
import WebSiteIcon from "../../../../imgs/imgV2/website.svg";

import PersonIcon from "@mui/icons-material/Person";

import { ToastContainer } from "react-toastify";

function InfosEntrepriseCN({ entreprise }) {
  //min date disponibility
  const today = new Date();

  // add one day to the date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Box position="relative">
      <ToastContainer />
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" gap="20px">
          <Box>
            <Box display="flex" alignItems="center" gap="20px">
              <Avatar sx={{ height: "80px", width: "80px", position: "relative" }}>{entreprise?.url_logo ? <img src={entreprise?.url_logo} alt="profil picture" style={{ height: "80px", width: "80px" }} /> : <PersonIcon fontSize="large" />}</Avatar>
              <Box display="flex" flexDirection="column">
                <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                  {entreprise?.name}
                </Typography>
                <Typography fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#666D83">
                  {entreprise?.user?.verification_data_status === "VALID" ? "Compte valide" : "En attente de validation"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <img src={locationIcon} alt="location-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{entreprise?.address ? entreprise?.address : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{entreprise?.user?.email ? entreprise?.user?.email : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{entreprise?.phone_number ? entreprise?.phone_number : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={WebSiteIcon} alt="website-icon" />
              <Typography variant="text_clickable">{entreprise?.website ? entreprise?.website : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4PX" }}>Email de contact:</Typography>
              <Typography variant="text_clickable">{entreprise?.contact_email ? entreprise?.contact_email : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Email de facturation:</Typography>
              <Typography variant="text_clickable">{entreprise?.facturation_email ? entreprise?.facturation_email : ""}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
    </Box>
  );
}

export default InfosEntrepriseCN;
