import React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import OnBoardingCoordonnee from "./OnBoardingCoordonnee";
import OnBoadingpref from "./OnBoadingpref";
import TableauDeBord from "../tableau-de-bord/TableauDeBordCandidat";

import OnBoardingCV from "./OnBoardingCV";
import OnBoardingCertif from "./OnBoardingCertif";
import OnBoardinginfosAdmin from "./OnBoardinginfosAdmin";
import { useLocation } from "react-router-dom";

function OBselector() {
  const location = useLocation();
  const step = location?.state?.step;
  const [onBoardingStep, setOnBoardingStep] = useState(step ? step : 1);
  const steps = [
    { stepNumber: 1, title: "Coordonnées", subtitle: "Renseignez vos coordonnées" },
    { stepNumber: 2, title: "Préférences", subtitle: "Précisez vos préférences" },
    { stepNumber: 3, title: "CV", subtitle: "Importez ou rédigez votre CV" },
    { stepNumber: 4, title: "Certifications / Habilitations & Scolarité", subtitle: "Veuillez fournir les détails de vos certifications et de votre parcours scolaire." },
    { stepNumber: 5, title: "Informations administratives", subtitle: "Fournissez vos informations administratives" },
  ];
  const boardSelector = (step) => {
    switch (step) {
      case 1:
        return <OnBoardingCoordonnee setCurrentStep={setOnBoardingStep} />;
      case 2:
        return <OnBoadingpref setCurrentStep={setOnBoardingStep} />;
      case 3:
        return <OnBoardingCV setCurrentStep={setOnBoardingStep} />;
      case 4:
        return <OnBoardingCertif setCurrentStep={setOnBoardingStep} />;
      case 5:
        return <OnBoardinginfosAdmin setCurrentStep={setOnBoardingStep} />;
      default:
        return <TableauDeBord />;
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ maxWidth: "400px", padding: "84px 0px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "40px",
              justifyContent: "center",
              width: "100%",
              gap: "8px",
            }}
          >
            {steps.map((currentStep) => {
              return (
                <Box key={currentStep.stepNumber}>
                  <Box
                    flex={1}
                    sx={{
                      borderRadius: "4px",
                      height: "4px",
                      bgcolor: currentStep.stepNumber <= onBoardingStep ? colors.main : "#EBEBEE",
                    }}
                    width="94px"
                  />
                </Box>
              );
            })}
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "32px", textAlign: "center" }}>
          <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
            {steps[onBoardingStep - 1].title}
          </Typography>
          <Typography variant="text_normal" color={colors.text_subtitle}>
            {steps[onBoardingStep - 1].subtitle}
          </Typography>
        </Box>

        {boardSelector(onBoardingStep)}
      </Box>
    </Box>
  );
}

export default OBselector;
