import React, { useState } from "react";
import BoxContainer from "../../../ui/BoxContainer";
import ButtonSquare from "../../../ui/ButtonSquare";
import { Avatar, Box, Button, Divider, Modal, Typography, FormControlLabel, Radio, RadioGroup, FormControl, Checkbox, CircularProgress, TextField, InputAdornment, Autocomplete } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";

import { modalStyle } from "../../../../theme/ModalStyle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import dropDownIcon from "../../../../imgs/imgV2/DropDownIcon.svg";
import { Formik, Form, Field } from "formik";
import MyTextField from "../../../ui/MyTextField";
import endicapeIcon from "../../../../imgs/imgV2/endicapeIcon.svg";
import locationIcon from "../../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../../imgs/imgV2/TelIcon.svg";
import PersonIcon from "@mui/icons-material/Person";
import BirthIcon from "../../../../imgs/imgV2/birthdayIcon.svg";
import { debounce } from "lodash";

import { useRef } from "react";
import * as Yup from "yup";
import MyDPFullDate from "../../../ui/MyDPFullDate";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useEffect } from "react";
import axios from "axios";
import MapComponent from "../../../mapComponent/MapComponent";
import { useMemo } from "react";
function InfosPersonnelle({ updateBord, setUpdateBoard }) {
  const { user } = useAuthContext();
  const [userFetch, setUSerFetch] = useState();
  const [change, setChange] = useState(false);
  var timer = null;
  const [radius, setRadius] = useState(0);
  const [position, setPosition] = useState([48.864716, 2.349014]);
  const access_token = user?.access_token;
  const [selectedAdresseInfoP, setselectedAdresseInfoP] = useState({});
  const [zoom_level, setZoom_level] = useState(15);
  //console.log(user, "this is our user");
  const [adresse, setAdresse] = useState("");
  const [radiusInKm, setRadiusInKm] = useState();
  const [adresses, setAdresses] = useState([]);
  const [adressesInfoP, setAdressesInfoP] = useState([]);

  const [adresseInfoP, setAdresseinfoP] = useState("");
  const [selectedAdresse, setselectedAdresse] = useState({});
  const [open, setOpen] = useState(false);
  const [openPref, setOpenPref] = useState(false);
  const formikRef = useRef();
  const [OpenAvailable, setOpenAvailable] = useState(false);
  const [availability, setAvailability] = useState(user?.user?.profile?.availability);
  const [avatarImage, setAvatarImage] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const [error, setError] = useState("");
  const [openAd, setOpenAd] = useState(false);
  const [progressProfilePix, setProgressProfilePic] = useState(0);
  const [image, setImage] = useState();

  const handleAvatarChange = (e) => {
    setAvatarImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
    const file = e.target.files[0];
    //console.log(file.size, "size of the file");
    if (file.size > 5 * 1024 * 1024) {
      //console.log("file trop volumineux");
      setError("Image trop volumineuse");
    } else {
      setError("");
    }
  };
  //function to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    setOpen(true);
    setOpenAd(false);
  };
  //function to handle when click on the modify icon
  const handleModifyClickPref = (cert) => {
    setOpenPref(true);
  };
  //function to handle when click on the add icon

  //handle click icon
  const handleClickIcon = () => {
    const input = document.getElementById("avatar-input");
    input.click();
  };
  //initial values for the preference form
  const [initialValues, setInitialValues] = useState({});
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    adresse: Yup.string().required("Ce champ est obligatoire"),
    rayon: Yup.number().required("Ce champ est obligatoire").positive("Rayon invalide"),
    taux_horraire: Yup.number().required("Ce champ est obligatoire").positive("Taux invalide"),
  });
  //initial values for the infos personnelle form
  const [initialValuesInfoP, setInitialValuesinfoP] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUSerFetch(res?.data);
        setAdresseinfoP(res?.data?.user?.address ? res?.data?.user?.address : "");
        setInitialValues({
          adresse: res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address,
          rayon: res?.data?.user?.profile?.travel_radius,
          taux_horraire: res?.data?.user?.profile?.desired_hourly_rate,
        });
        setselectedAdresseInfoP({
          id: "",
          display_name: res?.data?.user?.address ? res?.data?.user?.address : "",
        });
        setselectedAdresse({
          id: "",
          display_name: res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address,
          lat: res?.data?.user?.profile?.travel_latitude ? res?.data?.user?.profile?.travel_latitude : 48.864716,
          lon: res?.data?.user?.profile?.travel_longitude ? res?.data?.user?.profile?.travel_longitude : 2.349014,
        });
        setRadius(res?.data?.user?.profile?.travel_radius ? res?.data?.user?.profile?.travel_radius : 0);
        setRadiusInKm(res?.data?.user?.profile?.travel_radius ? calculate_pixels_in_screen(res?.data?.user?.profile?.travel_radius, zoom_level) : 0);
        setPosition([res?.data?.user?.profile?.travel_latitude ? res?.data?.user?.profile?.travel_latitude : 48.864716, res?.data?.user?.profile?.travel_longitude ? res?.data?.user?.profile?.travel_longitude : 2.349014]);
        setAdresse(res?.data?.user?.profile?.preferred_address ? res?.data?.user?.profile?.preferred_address : res?.data?.user?.address);

        setInitialValuesinfoP({
          nom: res?.data?.user?.last_name,
          prenom: res?.data?.user?.first_name,
          date_de_naissance: res?.data?.user?.birthday,
          adresse: res?.data?.user?.address,
          numerp_de_tel: res?.data?.user?.phone_number,
          has_andicape: res?.data?.user?.profile?.is_handicapped,
        });
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, [change]);

  //validation schema for the infos personnelle form
  const validationSchemaInfoPers = Yup.object({
    nom: Yup.string().required("Ce champ est obligatoire"),
    prenom: Yup.string().required("Ce champ est obligatoire"),
    adresse: Yup.string().required("Ce champ est obligatoire"),
    date_de_naissance: Yup.date().required("Ce champ est obligatoire"),
    numerp_de_tel: Yup.string()
      .required("Ce champ est obligatoire")
      .matches(/^(\+\d{1,3})?\s*(\d{9,10})$/, "Numéro de téléphone invalide"),
  });
  //min date disponibility
  const today = new Date();

  // add one day to the date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  //function to modify the profile
  const ModifyProfile = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.MODIFY_INTERIMAIRE + user?.user?.id + "/interim",
      {
        first_name: values.nom,
        last_name: values.prenom,
        birthday: values.date_de_naissance,
        address: values.adresse,
        phone_number: values.numerp_de_tel,
        is_handicapped: values.has_andicape ? 1 : 0,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpen(false);
        setUpdateBoard(!updateBord);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.data?.message);
        setOpen(false);
      });
  };
  //function to modify the profile
  const ModifyPref = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.MODIFY_PROFILE + user?.user?.profile?.id,
      {
        travel_longitude: selectedAdresse?.lon,
        travel_latitude: selectedAdresse?.lat,
        travel_radius: values.rayon,
        preferred_address: values.adresse,
        desired_hourly_rate: values.taux_horraire,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpenPref(false);
        setUpdateBoard(!updateBord);

        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setOpenPref(false);
      });
  };
  //function to modify the profile
  const ModifyAvailablity = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.MODIFY_INTERIMAIRE + user?.user?.id + "/interim",
      {
        availability: availability,
        ...(values.aval !== "" && { availability_date: values.aval }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpenPref(false);
        setOpenAvailable(false);

        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.data?.message);
        setOpenPref(false);
        setOpenAvailable(false);
      });
  };

  //config for the image
  var configsProfile = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressProfilePic(percentCompleted);
    },
  };
  //save image profile
  const saveImage = () => {
    Api.post(
      Urls.MODIFY_PROFILE_PIc + user?.user?.id + "/avatar",
      {
        avatar: image,
      },
      configsProfile
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setProgressProfilePic(0);
        setImage(null);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.data?.message);
        setProgressProfilePic(0);
      });
  };
  const searchPlaces = (adresse) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            countrycodes: "fr",
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdresses(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const searchPlacesInfosP = (adresse) => {
    if (adresse !== "") {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            countrycodes: "fr",
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdressesInfoP(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  function calculate_pixels_in_screen(km, zoom_level) {
    const circumference_of_earth = 40075.004;
    const pixels_per_km = (circumference_of_earth / 2 ** zoom_level) * 256;
    const pixels_in_screen = km * pixels_per_km;
    return pixels_in_screen;
  }
  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse) => {
      searchPlaces(adresse);
    }, 700);
  }, []);
  const debouncedSearchInfoP = React.useMemo(() => {
    return debounce((adresse) => {
      searchPlacesInfosP(adresse);
    }, 700);
  }, []);

  useEffect(() => {
    debouncedSearch(adresse);
  }, [adresse]);
  useEffect(() => {
    debouncedSearchInfoP(adresseInfoP);
  }, [adresseInfoP]);
  console.log(adresses, "new adresses");
  return (
    <Box position="relative">
      <ToastContainer />
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" justifyContent="end">
          <ButtonSquare onClick={handleModifyClick} action="modify" />
        </Box>
        <Box display="flex" flexDirection="column" gap="20px">
          <Box>
            <Box display="flex" alignItems="center" gap="20px">
              <Avatar onMouseOver={() => setIsHover(true)} onMouseOut={() => setIsHover(false)} sx={{ height: "80px", width: "80px", position: "relative", filter: `${isHover ? "brightness(0.5)" : "none"}`, transition: "0.5s" }}>
                {avatarImage ? (
                  <img src={avatarImage} style={{ height: "80px", width: "80px", borderRadius: "50%" }} alt="profil picture" />
                ) : userFetch?.user?.url_avatar ? (
                  <img src={userFetch?.user?.url_avatar} style={{ height: "80px", width: "80px", borderRadius: "50%" }} alt="profil picture" />
                ) : (
                  <PersonIcon fontSize="large" />
                )}
                <input accept="image/*" style={{ opacity: "0", zIndex: "0", height: "100%", width: "100%", position: "absolute" }} id="avatar-input" type="file" onChange={handleAvatarChange} />
                {isHover ? <CameraAltIcon onClick={handleClickIcon} fontSize="large" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "black", transition: "0.5s" }} /> : null}
              </Avatar>
              <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                {userFetch?.user?.first_name} {userFetch?.user?.last_name}
              </Typography>
              {image && progressProfilePix === 0 && (
                <Button onClick={saveImage} disabled={error ? true : false} variant="mj_primary_md">
                  Enregistrer
                </Button>
              )}
              {progressProfilePix !== 0 && (
                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                  <CircularProgress />
                  <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                    {progressProfilePix}%
                  </Typography>
                </Box>
              )}
            </Box>
            {error ? (
              <Typography color="red" fontSize="12px">
                {error}
              </Typography>
            ) : null}
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            {userFetch?.user?.profile?.is_handicapped && (
              <Box display="flex" alignItems="center" gap="4px">
                <img src={endicapeIcon} alt="endicape-icon" />
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#003E9C" }}>Travailleur handicapé</Typography>
              </Box>
            )}
            <Box display="flex" gap="4px">
              <img src={locationIcon} alt="location-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.address ? userFetch?.user?.address : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.email ? userFetch?.user?.email : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.phone_number ? userFetch?.user?.phone_number : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={BirthIcon} alt="tel-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.birthday ? userFetch?.user?.birthday : ""}</Typography>
            </Box>
          </Box>
          <Box maxWidth="300px">
            <Button sx={{ display: `${OpenAvailable ? "none" : "flex"}`, width: "100%" }} flexDirection="row" justifyContent="space-between" onClick={() => setOpenAvailable(true)} variant="mj_secondary_drop">
              Je suis {userFetch?.user?.profile?.availability === "AVAILABLE" ? "disponible" : user?.user?.profile?.availability === "UNAVAILABLE" ? "indisponible" : "disponible à partir de ..."}
              <img style={{ margin: "0px 0px 0px 100px" }} src={dropDownIcon} alt="drop-down icon" />
            </Button>
          </Box>
          <Box flexDirection="column" sx={{ display: `${OpenAvailable ? "flex" : "none"}` }}>
            <Box marginBottom="6px">
              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                Je suis ...
              </Typography>
            </Box>
            <Box sx={{ marginLeft: "13px" }}>
              <FormControl>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={availability} onChange={(e) => setAvailability(e.target.value)}>
                  <FormControlLabel value="AVAILABLE" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Disponible" />
                  <FormControlLabel value="AVAILABLE_AFTER" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Disponible à partir de ..." />
                  <FormControlLabel value="UNAVAILABLE" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Indisponible" />
                </RadioGroup>
              </FormControl>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  aval: userFetch?.user?.profile?.availability_date,
                }}
                onSubmit={(values) => {
                  ModifyAvailablity(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box display="flex" flexDirection="column" gap="20px">
                      {availability === "AVAILABLE_AFTER" && <Field value={values.aval} minDate={tomorrow} name="aval" as={MyDPFullDate} setFieldValue={setFieldValue} />}
                      <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                        <Button onClick={() => setOpenAvailable(false)} variant="mj_secondary_md">
                          Annuler
                        </Button>
                        <Button disabled={availability === "AVAILABLE_AFTER" && values.aval === "" ? true : false} type="submit" variant="mj_primary_md">
                          Enregistrer
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          <Divider sx={{ margin: "10px 0px" }} />
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ fontSize: "16", fontWeight: "700", lineHeight: "25.6px" }}>Préférences</Typography>
              <ButtonSquare onClick={handleModifyClickPref} action="modify" />
            </Box>
            <Box display="flex" gap="5px">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Adresse:</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.profile?.preferred_address ? userFetch?.user?.profile?.preferred_address : userFetch?.user?.address ? userFetch?.user?.address : ""}</Typography>
            </Box>

            <Box display="flex" gap="5px" alignItems="center">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Rayon de déplacement:</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.profile?.travel_radius ? userFetch?.user?.profile?.travel_radius : ""} KM</Typography>
            </Box>
            <Box display="flex" gap="5px" alignItems="center">
              <Typography sx={{ fontSize: "14px", fontWeight: "500", lineHeight: "22.4px" }}>Taux horaire:</Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.profile?.desired_hourly_rate ? userFetch?.user?.profile?.desired_hourly_rate : ""}€</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle} maxWidth="700px">
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Informations personnelles
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValuesInfoP}
              onSubmit={(values) => {
                ModifyProfile(values);
              }}
              validationSchema={validationSchemaInfoPers}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Nom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.nom} name="nom" placeholder="Nom..." />
                        {errors.nom && touched.nom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.nom}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Prénom
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.prenom} name="prenom" placeholder="Prénom..." />
                        {errors.prenom && touched.prenom ? (
                          <Typography color="red" fontSize="12px">
                            {errors.prenom}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Numéro de téléphone
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.numerp_de_tel} name="numerp_de_tel" placeholder="Numéro de téléphone..." />
                        {errors.numerp_de_tel && touched.numerp_de_tel ? (
                          <Typography color="red" fontSize="12px">
                            {errors.numerp_de_tel}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Date de naissance
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field value={values.date_de_naissance} setFieldValue={setFieldValue} name="date_de_naissance" placeholder="Date..." as={MyDPFullDate}></Field>
                        {errors.date_de_naissance && touched.date_de_naissance ? (
                          <Typography color="red" fontSize="12px">
                            {errors.date_de_naissance}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Adresse
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <TextField
                          sx={{ width: "100%" }}
                          value={adresseInfoP}
                          type="text"
                          onClick={() => {
                            setOpenAd(true);
                          }}
                          fullWidth
                          onChange={(e) => {
                            setAdresseinfoP(e.target.value);
                          }}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                            endAdornment: null,
                          }}
                          placeholder="Adresse..."
                        />

                        {openAd && adressesInfoP && (
                          <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                            {adressesInfoP?.map((ad) => (
                              <Box
                                onClick={() => {
                                  setAdresseinfoP(ad?.display_name);
                                  setFieldValue("adresse", ad?.display_name);
                                  setOpenAd(false);
                                }}
                                padding="5px"
                                sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                              >
                                <Typography key={ad?.id}>{ad.display_name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                        {errors.adresse && touched.adresse ? (
                          <Typography color="red" fontSize="12px">
                            {errors.adresse}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box width="100%">
                      <FormControlLabel
                        onChange={(e) => {
                          setFieldValue("has_andicape", e.target.checked);
                        }}
                        control={<Checkbox />}
                        checked={values.has_andicape}
                        label="Travailleur handicapé"
                      />
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openPref} onClose={() => setOpenPref(false)}>
        <Box sx={modalStyle} maxWidth="700px">
          <BoxContainer>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                ModifyPref(values);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="text_xxl" fontWeight="700">
                        Préférences
                      </Typography>
                      <ButtonSquare onClick={() => setOpenPref(false)} action="close" />
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Adresse
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        {/*<Autocomplete
                          disablePortal
                          ListboxProps={{ style: { maxHeight: "180px" } }}
                          fullWidth
                          id="combo-box-demo"
                          value={selectedAdresse}
                          onChange={(e, value) => {
                            if (value) {
                              setFieldValue("adresse", value?.display_name);
                              setselectedAdresse(value);
                              setPosition([value?.lat, value?.lon]);
                            }
                          }}
                          options={adresses?.to}
                          getOptionLabel={(option) => option.display_name}
                        renderInput={(params) => (*/}
                        <TextField
                          sx={{ width: "100%" }}
                          value={adresse}
                          type="text"
                          onClick={() => {
                            setOpenAd(true);
                          }}
                          fullWidth
                          onChange={(e) => {
                            setAdresse(e.target.value);
                          }}
                          variant="outlined"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                            endAdornment: null,
                          }}
                          placeholder="Adresse..."
                        />

                        {openAd && adresse && (
                          <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                            {adresses?.map((ad) => (
                              <Box
                                onClick={() => {
                                  setAdresse(ad?.display_name);
                                  setFieldValue("adresse", ad?.display_name);
                                  setselectedAdresse(ad);
                                  setPosition([ad?.lat, ad?.lon]);
                                  setOpenAd(false);
                                }}
                                padding="5px"
                                sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                              >
                                <Typography key={ad?.id}>{ad.display_name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {/*
                          })}
                        />*/}
                        {errors.adresse && touched.adresse ? (
                          <Typography color="red" fontSize="12px">
                            {errors.adresse}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box maxWidth="300px">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Rayon de déplacement
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <TextField
                          onChange={(e) => {
                            setFieldValue("rayon", e.target?.value);
                            setRadiusInKm(calculate_pixels_in_screen(e.target.value, zoom_level));
                            setRadius(e.target.value);
                          }}
                          endAdornment="km"
                          value={values.rayon}
                          name="rayon"
                          placeholder="Rayon..."
                        />
                        {errors.rayon && touched.rayon ? (
                          <Typography color="red" fontSize="12px">
                            {errors.rayon}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <MapComponent is_radius={true} radius={radius} position={position} setRadiusInKm={setRadiusInKm} setZoom_level={setZoom_level} zoom_level={zoom_level} radiusInKm={radiusInKm} selectedAdresse={selectedAdresse} />
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box maxWidth="300px">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Tranche de taux horaire (Euro)
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField endAdornment="hour" setFieldValue={setFieldValue} value={values.taux_horraire} name="taux_horraire" placeholder="Taux..." />
                        {errors.taux_horraire && touched.taux_horraire ? (
                          <Typography color="red" fontSize="12px">
                            {errors.taux_horraire}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenPref(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default InfosPersonnelle;
