import { React, useEffect } from "react";
import { colors } from "../../theme/Colors";
import { Container, Box, Stack, Button, Typography, Modal } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import NavbarCandidat from "../../components/ui/NavbarCandidat";
import styled from "@emotion/styled";
import arrowIcon from "../../imgs/UparrowFooter.svg";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Urls } from "../../api/Urls";
import ElipseIcon from "../../imgs/imgV2/Ellipse.svg";
import Pusher from "pusher-js";
import Api from "../../api/Api";
import IconOffres from "../../imgs/offres.svg";
import IconMissions from "../../imgs/missions.svg";
import IconHome from "../../imgs/home.svg";
import messagerieIcon from "../../imgs/imgV2/messagerieIcon.svg";
import Xicon from "../../imgs/imgV2/XIconMsg.svg";
import moment from "moment";

import IconFaq from "../../imgs/faq.svg";
import IconAcompte from "../../imgs/c_acompte.svg";
import IconCandidature from "../../imgs/c_candidatures.svg";
import IconCv from "../../imgs/c_cv.svg";
import IconFichePaie from "../../imgs/c_fichepaie.svg";
import IconMessagerie from "../../imgs/c_messagerie.svg";
import IconVilles from "../../imgs/c_location.svg";
import IconAgenda from "../../imgs/c_agenda.svg";
import { useState } from "react";
import { useRef } from "react";
import ButtonSquare from "../../components/ui/ButtonSquare";

const MuiIconOffres = <img src={IconOffres} />;

const StyledBox = styled(Box)({
  borderRadius: "10px",
  textTransform: "none",
  color: colors.textDark,
  margin: "0.3rem",
  padding: "0.6rem 1rem",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: colors.paperLightOrangeHover,
  },
});

const LeftMenue = ["Mes candidatures", "Mon CV", "Mon agenda", "Mes villes souhaitées", "Demande d’acompte", "Messagerie", "Fiche de paie", "FAQ", "RUN TESTS"];

function CandidatSharedLayout() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [listeMessage, setListeMessage] = useState([]);
  const [data, setData] = useState({});
  const bottomRef = useRef();
  const [change, setChange] = useState(false);
  let length = 0;

  const [message, setMessage] = useState("");
  const [openSupport, setOpenSupport] = useState(false);
  const navigate = useNavigate();

  const navigateMonCv = () => {
    navigate("/renard/mon-cv");
  };
  const navigateMonAgenda = () => {
    navigate("/renard/mon-agenda");
  };
  const navigateMesVillesSouhaites = () => {
    navigate("/renard/mes-villes-souhaites");
  };
  const navigateToMesCandidatures = () => {
    navigate("/renard/mes-candidatures");
  };
  const NavigateToFaq = () => {
    navigate("/renard/FAQ");
  };
  const NavigateToAide = () => {
    navigate("/renard/Aide");
  };
  const navigateToFicheDePaie = () => {
    navigate("/renard/fiche-de-paie");
  };
  const navigateToDemandeDacompte = () => {
    navigate("/renard/demande-acompte");
  };
  const navigateToMessagerie = () => {
    navigate("/renard/Messagerie");
  };
  const navigateToTESTS = () => {
    navigate("/renard/tests");
  };

  useEffect(() => {
    Api.get(Urls.GET_MESSAGES, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    })
      .then((res) => {
        length += res.data.messages.length;
        setListeMessage(res?.data?.messages);
        setData(res?.data);
        if (res?.data?.messages.length > 0) {
          //console.log(res.data);
          setTimeout(() => {
            bottomRef.current.scrollTop = 492 * length;
          }, 1000);
        }
      })
      .catch((err) => {
        //console.log(err);
        setListeMessage([]);
      });
  }, [change, openSupport]);
  const [pusherShare, setPusherShare] = useState();
  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: "eu",
    });
    setPusherShare(pusher);
    const channel = pusher.subscribe(`messaging.user.${user?.user?.id}`);
    channel?.bind("NewMessageEvent", function (data) {
      setListeMessage((listeMessage) => [data?.message, ...listeMessage]);
      setOpenSupport(true);
    });

    return () => {
      pusher.unsubscribe(`messaging.user.${user?.user?.id}`);
    };
  }, []);
  //handle message pagination
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        length += res.data.messages.length;
        setListeMessage((listeMessage) => listeMessage.concat(res?.data?.messages));
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err.response.data.message);
      });
  };
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const Auj = moment(new Date()).format("yyyy-MM-DD");
    const dateF = moment(date).format("yyyy-MM-DD");
    //console.log(dateF, Auj);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    //console.log("nombre de jours", jours);
    if (dateF === Auj) {
      return date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
    } else {
      if (dateF !== Auj && hours < 24) {
        return "Hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours === 1) {
        return "Hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours === 2) {
        return "Avant hier à " + date?.split(" ")[1].split(":")[0] + "h" + date?.split(" ")[1].split(":")[1];
      }
      if (jours > 2) {
        return moment(date).format("dddd DD MMMM yyyy");
      }
    }
  }
  const sendAmessage = () => {
    Api.post(
      Urls?.SEND_MESSAGE_POST,
      {
        body: message,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        setMessage("");
        setListeMessage((listeMessage) => [res?.data?.data, ...listeMessage]);
      })
      .catch((err) => {
        //console.log(err);
        setMessage("");
      });
  };
  return (
    <Box width="100%">
      <NavbarCandidat pusher={pusherShare} />
      <Container maxWidth={false} sx={{ minHeight: "80vh", display: { xs: "column" } }}>
        {/* OUTLET THAT CONTAINS CONTENT + RIGHTBAR*/}
        <Box minHeight="80vh" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }} maxWidth="95%" margin="0 auto" justifyContent="center" flexDirection="row">
          <Box flex={10}>
            <Outlet />
          </Box>
          <Box
            flex={1}
            onClick={() => {
              if (openSupport) {
                setOpenSupport(false);
              } else {
                setOpenSupport(true);
              }
            }}
            sx={{ display: "flex", justifyContent: "center", right: "3%", cursor: "pointer", height: "fit-content", position: "sticky", top: "600px" }}
          >
            <img src={openSupport ? Xicon : messagerieIcon} alt="msg-icon" />
          </Box>
        </Box>
        <Modal open={openSupport} onClose={() => setOpenSupport(false)}>
          <Box display="flex" flexDirection="column" minWidth="350px" maxHeight="500px" minHeight="500px" backgroundColor="white" position="absolute" top="70px" right="8%" zIndex="9999999999999999999999999999999999999999999999999999">
            <Box sx={{ width: "100%", border: "1px solid #DEDFE4", padding: "12px", borderTopLeftRadius: "6px", borderTopRightRadius: "6px" }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <Typography fontSize="18px" fontWeight="600" lineHeight="160%">
                    👨‍💻 Support
                  </Typography>
                </Box>
                <ButtonSquare
                  action="close"
                  onClick={() => {
                    setOpenSupport(false);
                  }}
                />
              </Box>
            </Box>
            <Box flex={15} display="flex" flexDirection="column-reverse" ref={bottomRef} sx={{ overflowY: "scroll", width: "100%", borderBottom: "1px solid #DEDFE4", borderRight: "1px solid #DEDFE4", borderLeft: "1px solid #DEDFE4" }}>
              {listeMessage?.map((disc, index) => (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {listeMessage[index + 1] && disc?.created_at?.split(" ")[0] !== listeMessage[index + 1]?.created_at?.split(" ")[0] && (
                    <Box sx={{ marginBottom: "20px" }} display="flex" alignItems="center">
                      <Box style={{ flex: "3", margin: "0px 5px", padding: "5px", display: "flex", justifyContent: "center" }}>
                        <Typography variant="text_gray" sx={{ textAlign: "center" }}>
                          {index !== listeMessage?.length ? afficherDifferenceDate(listeMessage[index]?.created_at) : null}
                          {listeMessage?.length === 1 ? afficherDifferenceDate(listeMessage[index]?.created_at) + "a" : null}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {listeMessage?.length >= 1 && index === listeMessage?.length - 1 && (
                    <Box sx={{ marginBottom: "20px" }} display="flex" alignItems="center">
                      <Box style={{ flex: "3", margin: "0px 5px", padding: "5px", display: "flex", justifyContent: "center" }}>
                        <Typography variant="text_gray" sx={{ textAlign: "center" }}>
                          {afficherDifferenceDate(listeMessage[index]?.created_at)}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  {disc.sent_by_user_id === user?.user?.id ? (
                    <Box key={disc.id} display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" gap="0.3rem" margin="2px 20px">
                      <Box padding="10px" backgroundColor={colors.main} sx={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomLeftRadius: "20px", borderBottomRightRadius: "6px", margin: "0", width: "fit-content" }}>
                        <Typography sx={{ color: "white", overflowWrap: "break-word", whiteSpace: "pre-line", maxWidth: "150px", display: "block" }}>{disc?.body}</Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box key={disc.id} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" gap="0.3rem" margin="2px 20px">
                      <Box backgroundColor="rgba(245, 245, 247, 1)" borderRadius="20px 20px 20px 4px" padding="10px" sx={{ magin: "0", width: "fit-content" }}>
                        {" "}
                        <Typography sx={{ overflowWrap: "break-word", whiteSpace: "pre-line", maxWidth: "150px", display: "block" }}>{disc?.body}</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
              {data?.links?.next ? (
                <Stack margin="1REM 0REM" direction="column" justifyContent="center" alignItems="center">
                  <Button onClick={handlePagination} variant="mj_secondary_md">
                    Changer plus de messages
                  </Button>
                </Stack>
              ) : null}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              sx={{ zIndex: "999999999999999999999999", borderBottomLeftRadius: "6px", borderBottomRightRadius: "6px", width: "100%", borderBottom: "1px solid #DEDFE4", borderRight: "1px solid #DEDFE4", borderLeft: "1px solid #DEDFE4", padding: "12px" }}
            >
              <Box sx={{ zIndex: "999999999999999999999999" }} flex={4}>
                <input
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  type="text"
                  placeholder="Votre message"
                  style={{ fontSize: "14px", fontWeight: "400", width: "100%", height: "30px", outline: "none", border: "none" }}
                />
              </Box>
              <Box>
                <ButtonSquare
                  onClick={() => {
                    if (message) sendAmessage();
                  }}
                  action="send-message"
                />
              </Box>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
}

export default CandidatSharedLayout;
