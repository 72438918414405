import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import React from "react";
import plusIconBlue from "../../../../imgs/imgV2/plusIconBlue.svg";
import docGray from "../../../../imgs/imgV2/DocGray.svg";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useEffect } from "react";
import { useState } from "react";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";

function StatistiquesSection() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [statistics, setStats] = useState({});
  const user_id = user?.user?.id;

  const data = [
    { stat: "Nbr.missions réalisées", subvalue: "Top 5%", value: "45" },
    { stat: "Nbr.d'heures travaillées", subvalue: "Top 20%", value: "590h" },
    { stat: "Total des sommes touchés", subvalue: null, value: "1200€" },
    { stat: "Est. du salaire pour le mois en cours", subvalue: null, value: "960€" },
  ];
  //get statistics
  useEffect(() => {
    Api.get(Urls?.GET_STATISTICS + user_id + "/statistics", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setStats(res?.data?.user);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box sx={{ border: "1px solid #DEDFE4", borderRadius: "6px" }}>
      {/* header */}
      <ToastContainer />
      <Box padding="24px" display="flex" flexDirection="row" gap="14px">
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          📊 Statistiques
        </Typography>
      </Box>

      {/* entreprises */}
      <Box display="flex" flexDirection="column" padding="6px 0px">
        <Box>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 24px">
            <Box display="flex" flexDirection="row" gap="6px" alignItems="center">
              <Typography variant="text_md" color="#4A526C">
                Nbr. missions réalisées
              </Typography>
              <Typography color="#0065FF" fontWeight="500" variant="text_normal"></Typography>
            </Box>

            <Box>
              <Typography variant="text_md" fontWeight="600">
                {statistics?.missions_count}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 24px">
            <Box display="flex" flexDirection="row" gap="6px" alignItems="center">
              <Typography variant="text_md" color="#4A526C">
                Nbr. d’heures travaillées
              </Typography>
              <Typography color="#0065FF" fontWeight="500" variant="text_normal"></Typography>
            </Box>

            <Box>
              <Typography variant="text_md" fontWeight="600">
                {statistics?.total_hours_worked}h
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 24px">
            <Box display="flex" flexDirection="row" gap="6px" alignItems="center">
              <Typography variant="text_md" color="#4A526C">
                Total des sommes touchés
              </Typography>
              <Typography color="#0065FF" fontWeight="500" variant="text_normal"></Typography>
            </Box>

            <Box>
              <Typography variant="text_md" fontWeight="600">
                {statistics?.payslips_sum_amount}€
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 24px">
            <Box display="flex" flexDirection="row" gap="6px" alignItems="center">
              <Typography variant="text_md" color="#4A526C">
                Est. du salaire pour le mois en cours
              </Typography>
              <Typography color="#0065FF" fontWeight="500" variant="text_normal"></Typography>
            </Box>

            <Box>
              <Typography variant="text_md" fontWeight="600">
                {statistics?.total_month_amount}€
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default StatistiquesSection;
