import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export const useLogin = (email, password) => {
  const [errorLogin, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const { dispatch } = useAuthContext();
  const { user } = useAuthContext();

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);

    //remove const response = await
    const response = await Api.post(
      Urls.LOGIN_URL,
      { email, password },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        //console.log("RESPONSE LOGIN", response);

        if (response.status !== 200) {
          setIsLoading(false);
          setError(response.data.message);
        }
        if (response.status === 200) {
          toast(response?.data?.message);
          // save the user to local storage
          localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setIsLoading(false);

          //get the user to decide where to redirect
          //const { user } = JSON.parse(localStorage.getItem("user"));
          //console.log(user, "after dispatching");

          if (user?.user?.email_verified_at === null) {
            navigate("/validate-email", { state: email });
          }

          //console.log("ACCOUNT TYPE ///", user?.user?.account_type);
          //console.log("ACCESS TOKEN  ///", user?.access_token);
          //console.log("ACCESS TOKEN  ///", user?.user?.email_verified_at);

          //navigate to entreprise if user is entreprise
          if (response?.data?.user?.email_verified_at && response?.data?.access_token && response?.data?.user?.account_type === "ENTERPRISE") {
            if (response?.data?.is_first_login) {
              navigate("/entreprise/onboarding", { replace: true });
            } else {
              navigate("/entreprise/tableau-de-bord", { replace: true });
            }
          }
          //navigate to entreprise if user is entreprise
          if (response?.data?.user?.email_verified_at && response?.data?.access_token && response?.data?.user?.account_type === "INTERIM") {
            //console.log("user is candidat lets send him to candidat");
            if (response?.data?.is_first_login) {
              navigate("/renard/onboarding", { replace: true });
            } else {
              navigate("/renard/tableau-de-bord", { replace: true });
            }
          }
        }
      })
      .catch((error) => {
        //console.log(error.response.status);
        toast.error(error.response.data.message);
        // update loading state
        setIsLoading(false);
        if (error.response.status === 412) {
          navigate("/validate-email", { state: email });
        }
      });
  };

  return { login, isLoading, errorLogin };
};
