import React, { useEffect, useState } from "react";
import { Box, Typography, Avatar, Button, Modal, TextField, IconButton, InputAdornment } from "@mui/material";
import MyChip from "../../../../components/ui/MyChip";

import ButtonSquare from "../../../../components/ui/ButtonSquare";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";

import CheckedCircle from "../../../../components/ui/CheckedCircle";
import PersonIcon from "@mui/icons-material/Person";

import arrowRightIcon from "../../../../imgs/imgV2/arrowRightBlavk.svg";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import docgrayIcon from "../../../../imgs/imgV2/DocGray.svg";
import dollarIcon from "../../../../imgs/imgV2/dollarIcon.svg";
import plusIconBleu from "../../../../imgs/imgV2/plusIconBlue.svg";
import { Urls } from "../../../../api/Urls";
import { modalStyle, modalStyle2 } from "../../../../theme/ModalStyle";
import DetailOffreFinale from "../../../candidat/content/DetailOffreFinale";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { colors } from "../../../../theme/Colors";
import { Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
//table style
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FAFAFB",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
    padding: "6px 20px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
    padding: "16px 20px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border

  cursor: "pointer",
}));

const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function ContratsSection() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [openAccepter, setOpenAccepter] = useState(false);
  const [idContrat, setIdContrat] = useState();
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);

  const [contrats, setContrats] = useState();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState();
  const [showPasswordDel, setShowPasswordDel] = useState(false);

  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const [data, setData] = useState();
  function formatDateFront(date) {
    moment.locale("fr");
    return moment(date)
      .format("DD MMMM YYYY")
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }
  function afficherDifferenceDate(date) {
    const now = moment();
    const diffe = moment(date);
    const minutes = now.diff(diffe, "minutes");
    const hours = now.diff(diffe, "hours");
    const jours = now.diff(diffe, "days");
    const semaines = now.diff(diffe, "weeks");
    const mois = now.diff(diffe, "months");

    if (minutes === 1) {
      return "Créé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Créé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Créé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Créé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Créé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Créé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Créé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Créé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Créé il y a 1 mois";
    } else {
      return `Créé il y a ${mois} mois`;
    }
  }
  useEffect(() => {
    //console.log("rafraichir");
    Api.get(Urls.GET_CONTRATS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        filter: "default",
        perPage: 10,
      },
    })
      .then((res) => {
        //console.log(res, "liste des contrats");
        setContrats(res?.data?.contracts);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [change]);

  const AccepterCandidature = (id, password) => {
    Api.patch(
      Urls.SIGN_CONTRAT_ID + id + "/sign",
      {
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <Box>
      <Box sx={{ padding: "24px", display: "flex", flexDirection: { md: "row", xs: "column" }, border: "1px solid #DEDFE4", justifyContent: { md: "space-between" }, gap: "16px", alignItems: { md: "center" } }} borderRadius="6px 6px 0px 0px">
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          📋 Contrats
        </Typography>
      </Box>
      <TableContainer sx={{ borderRadius: "0px 0px 6px 6px", border: `1px solid #DEDFE4`, marginTop: "-1px" }}>
        <StyledTable aria-label="customized table">
          {contrats?.map((c, index) => (
            <TableBody key={c.id}>
              <StyledTableRow

              // onClick={() => {
              //   setOpenFactureDetail(true);
              //   setIdFacture(facture?.facture);
              // }}
              >
                <StyledTableCell
                  onClick={() => {
                    setOpen(true);
                    setIdContrat(c);
                  }}
                  align="left"
                >
                  <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                    <Box>
                      <img src={c?.contract_type === "INIT" ? docgrayIcon : c?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
                    </Box>
                    <Box>
                      <Typography fontWeight="600" color="#575E76" fontSize="14px">
                        {c?.id}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    setOpen(true);
                    setIdContrat(c);
                  }}
                  align="left"
                >
                  <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                    <Avatar sx={{ height: "32px", width: "32px", position: "relative" }}>{c?.user?.url_avatar ? <img src={c?.user?.url_avatar} alt="profile-pic" style={{ height: "32px", width: "32px", borderRadius: "50%" }} /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Avatar>{" "}
                    <Typography fontWeight="500" color="black" fontSize="14px">
                      {c?.user?.first_name} {c?.user?.last_name}
                    </Typography>
                  </Box>
                </StyledTableCell>

                <StyledTableCell
                  onClick={() => {
                    setOpen(true);
                    setIdContrat(c);
                  }}
                  align="left"
                >
                  {c?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                  {c?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                  {c?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                  {c?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}

                  {c?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                  {c?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                  {c?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}
                </StyledTableCell>

                <StyledTableCell
                  onClick={() => {
                    setOpen(true);
                    setIdContrat(c);
                  }}
                  align="left"
                >
                  <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
                    <Typography fontWeight="400" color="#575E76" fontSize="12px">
                      {formatDateFront(c?.start_date)}
                    </Typography>
                    <Box>
                      <img src={arrowRightIcon} alt="icon-point" />
                    </Box>
                    <Typography color="#575E76" fontSize="12px">
                      {formatDateFront(c.end_date)}
                    </Typography>
                  </Box>
                </StyledTableCell>

                <StyledTableCell align="left">
                  {(c?.signed_by_enterprise_at || c?.status !== "PENDING") && (
                    <Box
                      onClick={() => {
                        setOpen(true);
                        setIdContrat(c);
                      }}
                      display="flex"
                      alignItems="center"
                      gap="6px"
                      justifyContent="center"
                    >
                      <CheckedCircle bgcolor={c?.signed_by_interim_at && c?.signed_by_enterprise_at && c?.signed_by_admin_at ? null : "#EA5B0C"} checked={c?.signed_by_interim_at ? true : false} height="12px" width="12px" />
                      <CheckedCircle bgcolor={c?.signed_by_interim_at && c?.signed_by_enterprise_at && c?.signed_by_admin_at ? null : "#EA5B0C"} checked={c?.signed_by_enterprise_at ? true : false} height="12px" width="12px" />
                      <CheckedCircle bgcolor={c?.signed_by_interim_at && c?.signed_by_enterprise_at && c?.signed_by_admin_at ? null : "#EA5B0C"} checked={c?.signed_by_admin_at ? true : false} height="12px" width="12px" />
                    </Box>
                  )}
                  {!c?.signed_by_enterprise_at && c?.status === "PENDING" && (
                    <Box>
                      <Button
                        variant="mj_primary_md"
                        onClick={() => {
                          window.open("https://auth.coffreo.cc/", "_blank");
                          setIdContrat(c);
                        }}
                      >
                        Signer
                      </Button>
                    </Box>
                  )}
                </StyledTableCell>

                <StyledTableCell align="left">
                  <Typography fontWeight="600" color="black" fontSize="14px">
                    310€
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          ))}
        </StyledTable>
      </TableContainer>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box zIndex={2} sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                  <ButtonSquare onClick={() => setOpen(false)} action="close" />
                  <Typography variant="text_xxl" fontWeight="700">
                    Contrat #{idContrat?.id}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" gap="14px" alignItems="center">
                {idContrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                {idContrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                {idContrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
                {idContrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                {idContrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                {idContrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                {idContrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}{" "}
                <Typography fontSize="12px" color="#4A526C" fontWeight="400" lineHeight="160%">
                  {afficherDifferenceDate(date)}
                </Typography>
              </Box>
              <Typography marginTop="10px" fontSize="12px" fontWeight="600" color="red">
                {idContrat?.suspended_at && "Suspendu le : " + moment(idContrat?.suspended_at).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box>
              <DetailOffreFinale setDate={setDate} type="contratFinale" id={idContrat?.id} />
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(idContrat?.id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Box>
                      <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                    </Box>
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{idContrat?.id}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ContratsSection;
