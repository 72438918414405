import React from 'react'
import error from "../../imgs/error.png"
import message from "../../imgs/message.png"
import check from "../../imgs/check.png"
import { Box } from "@mui/material";

function ThreeDIcon({name}) {

    const icons = {};
    icons["error"] = error;
    icons["message"] = message;
    icons["check"] = check;

    return (
    <Box>
        <img height="100px" src={icons[`${name}`]} alt="" />
    </Box> )
  
}

export default ThreeDIcon