import React, { useState } from "react";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { colors } from "../../theme/Colors";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useChangePassword } from "../../hooks/useChangePassword";

import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";
import logo from "../../imgs/imgV2/OhMyJob.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ToastContainer } from "react-toastify";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  //initialize error, isLoadingChangePassword, and ChangePassword function
  const { resetPassword, errorChangePassword, isLoadingChangePassword, successChangePassword } = useChangePassword();

  //validation for fields
  const validationSchema = Yup.object({
    password: Yup.string().required("Ce champs est obligatoire").min(8, "Mot de passe trop court"),
    // passwordConfirmation: Yup.string()
    //   .required("Ce champs est obligatoire")
    //   .oneOf([Yup.ref("password"), null], "Le mot de passe ne correspond pas"),
  });
  //Form initial values
  const initialValues = {
    password: "",
    // passwordConfirmation: "",
  };

  const location = useLocation();
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //invoke ChangePassword function that we get from useChangePassword
  const onSubmitChangePassword = async (values, errorChangePassword, isLoadingChangePassword) => {
    await resetPassword(values.password, location?.state?.email, location?.state?.otp);
  };

  return location?.state?.email && location?.state?.otp ? (
    <Container maxWidth={"xl"}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>
          {/* FORM BEGINNING */}
          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                  Réinitialisation de votre mot de passe
                </Typography>
                <Typography variant="text_normal" color={colors.text_subtitle}>
                  Entrez votre nouveau mot de passe
                </Typography>
              </Box>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitChangePassword}>
                {({ values, error }) => (
                  <Form>
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="password" name="password">
                          Nouveau mot de passe
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field as={TextField} name="password" placeholder="Saisir votre mot de passe" id="password">
                        {({ field, form, meta }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder="Saisir votre mot de passe"
                            autoComplete="Off"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                  <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                    {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="password" />
                    </Box>

                    {/* <div className="field">
                      <label htmlFor="password" name="passwordConfirmation">
                        Confirmer nouveau mot de passe
                      </label>
                      <Field autoComplete="off" name="passwordConfirmation" type="password" placeholder="Nouveau mot de passe" id="passwordConfirmation" as={TextField} />
                      <ErrorMessage name="passwordConfirmation" />
                    </div> */}

                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button variant="mj_primary_md" disabled={isLoadingChangePassword} className="submit" type="submit" fullWidth>
                        Enregistrer
                      </Button>
                    </Box>
                    {/* {errorChangePassword && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorChangePassword}
                      </Alert>
                    )} */}
                    {/* {successChangePassword && (
                      <Box>
                        <Box display="flex" alignItems="center" justifyContent="center" marginRight="auto"></Box>
                        <Alert
                          sx={{
                            border: `1px solid  ${colors.mjGreenOutline}`,
                            borderRadius: "12px",
                          }}
                          severity="success"
                        >
                          {successChangePassword}
                        </Alert>
                      </Box>
                    )} */}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
          {/* FORM END */}
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  ) : (
    "error 404"
  );
}

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.main};

  .formContainer {
    border-radius: 24px;
    align-items: center;
    padding: 1.5rem 3rem;
    background: white;
    width: 550px;
    min-height: 600px;
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .submit {
    margin-bottom: 1rem;
  }

  label {
    margin-bottom: 0.5rem;
  }
`;

export default ChangePassword;
