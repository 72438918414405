import React from "react";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { Box, Button, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, Menu, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { Field, Form, Formik, useFormik } from "formik";
import { colors } from "../../../../theme/Colors";
import { useRef } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import checkLinkicon from "../../../../imgs/imgV2/checkLinkIcon.svg";
import plusIcon from "../../../../imgs/imgV2/plusIcon.svg";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import * as Yup from "yup";

import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MyTextField from "../../../../components/ui/MyTextField";

import ButtonSquare from "../../../../components/ui/ButtonSquare";
import moment from "moment";
import MySwitch from "../../../../components/ui/MySwitch/MySwitch";
import MyDPFullDate from "../../../../components/ui/MyDPFullDate";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { useNavigate } from "react-router-dom";
function OnBoardinginfosAdmin({ setCurrentStep }) {
  const [typeSoc, setTypeSoc] = useState("");
  const [openPermit, setOpenPermit] = useState(false);
  const [change, setChange] = useState(false);
  const [UserFetch, setUserFetch] = useState();
  const [userDocs, setUserDocs] = useState([]);
  const navigate = useNavigate();
  const [listePermisToCompare, setListePrmisToCompare] = useState([]);
  const [userDocIdentity, setUserDocIdentity] = useState();
  const [userDocPassport, setUserDocPassport] = useState();
  const [userDoctitreSejour, setUserDocTitreSejour] = useState();
  const [userDocRecep, setUserDocRecep] = useState();
  const [userDocSecSocial, setUserDocSecSocial] = useState({});
  const [proofOfAddress, setUserProofOfAddress] = useState({});
  const [userDocVitalCard, setUserDocVitalCard] = useState({});
  const [userDocMut, setUserDocMut] = useState();
  const [permisListe, setPermisLost] = useState([]);
  const [permisID, setPermisID] = useState("first");
  const [loading, setLoading] = useState(true);
  //initiating some constantns
  const FILE_SIZE = 5000 * 1024;
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [file, setFile] = useState();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  //
  //
  //state for the switch component

  const [checkedSecSocial, setCheckedSecSocial] = useState(true);
  const [checkedJustifDom, setCheckedJustifDom] = useState(true);
  const [checkedMutuelle, setCheckedMutuelle] = useState(true);
  const [checkedMed, setCheckedMed] = useState(true);
  //
  //
  //validation schema add permis
  //
  const validationSchemaPermis = Yup.object({
    cat_permis: Yup.string().required("ce champ est obligaoire"),
    date_em: Yup.date().typeError("Valeur invalide").required("ce champ est obligaoire"),
    justif: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });
  //
  //
  //
  //
  //validation Schema mutuelle
  const validationSchemaMutuelle = Yup.object({
    mut_nom_org: Yup.string().required("Ce champ est obligatoire"),
    mut_date_val: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
  });
  //
  //
  // validation shema justificatif domicile
  const validationSchemaJustificatifDomicile = Yup.object({
    justificatif_domicile: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });

  //
  //
  // VALIDATION SCHEMA SECURITE SOCIAL
  const validationSchemaSecuSocial = Yup.object({
    justificatif_sec_social: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    num_sec_social: Yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire").positive("Numéro invalide"),
  });

  // VALIDATION SCHEMA SECURITE SOCIAL update
  const validationSchemaSecuSocialUpdate = Yup.object({
    num_sec_social: Yup.number().typeError("Valeur invalide").required("Ce champ est obligatoire").positive("Numéro invalide"),
  });
  //
  //
  //
  /// validation Schema rib
  const validationSchemaRIb = Yup.object({
    rib: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
  });
  //
  //
  //
  // validation schema carte identité
  const validationSchemaCarteIdent = Yup.object({
    piece_did_recto: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    piece_did_verso: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    num_carte: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_exp_carte: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  // validation schema carte identité update
  const validationSchemaCarteIdentUpdate = Yup.object({
    num_carte: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_exp_carte: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  // validation schema passeport
  const validationSchemaPasseport = Yup.object({
    num_passport: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_em_passport: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    date_exp_passport: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    file_passport: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  // validation schema passeport update
  const validationSchemaPasseportUpdate = Yup.object({
    num_passport: Yup.number().typeError("Valeur invalide").required("Ce champ est oblgatoire").positive("Numéro invalide"),
    date_em_passport: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    date_exp_passport: Yup.date().typeError("Valeur invalide").required("Ce champ est obligatoire"),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  //
  // validation schema titre de sejour
  const validationSchemaTitreSejour = Yup.object({
    sejour_recto: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    sejour_verso: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  // validation schema titre de sejour udate
  const validationSchemaTitreSejourUpdate = Yup.object({
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //
  //
  // validation schema res
  const validationSchemaRes = Yup.object({
    piece_res: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  // validation schema res updqte
  const validationSchemaResUpdate = Yup.object({
    lieu_de_naissance: Yup.string().required("Ce champ est obligatoire"),
  });
  //listes de permis
  const [permis, setPermis] = useState([]);
  //
  //
  //
  //function to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    setOpen(true);
  };
  //
  //
  //

  const [initialValuesCarteIdentite, setInitialValuesCarteIdentite] = useState({
    piece_did_recto: "",
    piece_did_verso: "",
    num_carte: "",
    date_exp_carte: "",
    lieu_de_naissance: "",
    link_verso: "",
    link_recto: "",
  });
  const [initialValuesPasseport, setInitialValuesPasseport] = useState({
    num_passport: "",
    date_em_passport: "",
    date_exp_passport: "",
    file_passport: "",
    lieu_de_naissance: "",
    link_file: "",
  });
  const [initialValuesTitreSejour, setInitialValuesTitreSejour] = useState({
    sejour_recto: "",
    sejour_verso: "",
    lieu_de_naissance: "",
    link_verso: "",
    link_recto: "",
  });
  const [initialValuesRes, setInitialValuesRes] = useState({
    piece_res: "",
    lieu_de_naissance: "",
    link_file: "",
  });
  const [initialValuesRib, setInitialValuesRib] = useState({
    rib: UserFetch ? UserFetch : "",
  });
  const [initialValuesSecSocial, setInitialValuesSecSocial] = useState({
    type_sec_social: "",
    num_sec_social: "",
    justificatif_sec_social: "",
    link_file: "",
  });
  const [initialValuesJustifDom, setInitialValuesJustifDom] = useState({
    justificatif_domicile: "",
    file_link: "",
  });
  const [initialValuesMutuelle, setInitialValueMutuelle] = useState({
    mut_nom_org: "",
    mut_date_val: "",
  });
  const [initialValuesVisiteMed, setInitialValueVisiteMed] = useState({
    vis_med_just_1: "",
    date_med: "",
    link_med: "",
  });
  const formRib = useFormik({
    initialValues: initialValuesRib,
    validationSchema: validationSchemaRIb,
    enableReinitialize: true,
    onSubmit: (values) => {
      //console.log(values);
      patchRib(values);
    },
  });
  const formSecSocial = useFormik({
    initialValues: initialValuesSecSocial,
    enableReinitialize: true,

    validationSchema: !userDocSecSocial?.new ? validationSchemaSecuSocialUpdate : validationSchemaSecuSocial,
    onSubmit: (values) => {
      if (!userDocSecSocial.new && typeSoc === "social_security_certificate") {
        secSocialPatch(values, userDocSecSocial.id);
      } else {
        if (userDocSecSocial.new && typeSoc === "social_security_certificate") {
          secSocialAdd(values);
        }
      }
      if (!userDocVitalCard.new && typeSoc === "vital_card") {
        VitalCardUpdate(values, userDocVitalCard.id);
      } else {
        if (userDocVitalCard.new && typeSoc === "vital_card") {
          VitalCardAdd(values);
        }
      }
    },
  });
  const formcPassport = useFormik({
    initialValues: initialValuesPasseport,
    enableReinitialize: true,

    validationSchema: !userDocPassport?.new ? validationSchemaPasseportUpdate : validationSchemaPasseport,
    onSubmit: (values) => {
      if (userDocPassport.new) {
        passeportAdd(values);
      } else {
        passeportAdd(values);
        passeportPatch(values, userDocPassport?.id);
      }
    },
  });
  const formtitreSejour = useFormik({
    initialValues: initialValuesTitreSejour,
    enableReinitialize: true,

    validationSchema: !userDoctitreSejour?.new ? validationSchemaTitreSejourUpdate : validationSchemaTitreSejour,
    onSubmit: (values) => {
      if (userDoctitreSejour?.new) {
        TitreSejourAdd(values);
        //console.log("updating");
      } else {
        TitreSejourPatch(values, userDoctitreSejour.id);
      }
    },
  });
  const formrec = useFormik({
    initialValues: initialValuesRes,
    enableReinitialize: true,

    validationSchema: !userDocRecep?.new ? validationSchemaResUpdate : validationSchemaRes,
    onSubmit: (values) => {
      if (userDocRecep?.new) {
        recipAdd(values);
      } else {
        recipPatch(values, userDocRecep?.id);
      }
    },
  });
  const formCarteIdentite = useFormik({
    initialValues: initialValuesCarteIdentite,
    enableReinitialize: true,

    validationSchema: !userDocIdentity?.new ? validationSchemaCarteIdentUpdate : validationSchemaCarteIdent,
    onSubmit: (values) => {
      if (userDocIdentity?.new) {
        IndentityAdd(values);
      } else {
        IndentityPatch(values, userDocIdentity?.id);
      }
    },
  });
  const formJustifDom = useFormik({
    initialValues: initialValuesJustifDom,
    enableReinitialize: true,

    validationSchema: !proofOfAddress?.new ? null : validationSchemaJustificatifDomicile,
    onSubmit: (values) => {
      if (proofOfAddress) {
        domPatch(values, proofOfAddress?.id);
      } else {
        domAdd(values);
      }
    },
  });
  const formMutuelle = useFormik({
    initialValues: initialValuesMutuelle,
    enableReinitialize: true,

    validationSchema: validationSchemaMutuelle,
    onSubmit: (values) => {
      if (userDocMut?.new) {
        MutAdd(values);
      } else {
        MutPatch(values, userDocMut?.id);
      }
    },
  });
  const [newPermis, setNewPermis] = useState([]);
  const [permistoDelete, setPermisToDelete] = useState([]);

  const handleChange = (e) => {
    setTypeID(e.target.value);
  };
  const handleChangeSoc = (e) => {
    if (e.target.value === "social_security_certificate") {
      setsecSocial(userDocSecSocial);
    } else {
      if (e.target.value === "vital_card") {
        setVitalCard(userDocVitalCard);
      }
    }
    setTypeSoc(e.target.value);
  };
  //console.log(listePermisToCompare, "liste permis to compare");
  const setIdentityCardFields = (values) => {
    //console.log(values, "values in the fuction setIdentitycardfields");
    formCarteIdentite.setFieldValue("date_exp_carte", values?.expiration_date ? values.expiration_date : null);
    formCarteIdentite.setFieldValue("lieu_de_naissance", values?.place_of_birth ? values.place_of_birth : "");
    formCarteIdentite.setFieldValue("link_recto", values?.file_side1 ? values.file_side1 : "");
    formCarteIdentite.setFieldValue("link_verso", values?.file_side2 ? values.file_side2 : "");
    formCarteIdentite.setFieldValue("num_carte", values?.document_number ? values.document_number : "");
    formCarteIdentite.setFieldValue("piece_did_recto", "");
    formCarteIdentite.setFieldValue("piece_did_verso", "");
  };
  const setPassportFields = (values) => {
    //console.log(values, "values in the fuction setPassportFields");
    formcPassport.setFieldValue("date_exp_passport", values?.expiration_date ? values.expiration_date : null);
    formcPassport.setFieldValue("date_em_passport", values?.obtained_date ? values.obtained_date : null);
    formcPassport.setFieldValue("lieu_de_naissance", values?.place_of_birth ? values.place_of_birth : "");
    formcPassport.setFieldValue("link_file", values?.url_file ? values.url_file : "");
    formcPassport.setFieldValue("num_passport", values?.document_number ? values.document_number : "");
    formcPassport.setFieldValue("file_passport", "");
  };
  const setTitreSejourFields = (values) => {
    //console.log(values, "values in the fuction setTitreSejourFields");
    formtitreSejour.setFieldValue("sejour_recto", "");
    formtitreSejour.setFieldValue("sejout_verso", "");
    formtitreSejour.setFieldValue("lieu_de_naissance", values?.place_of_birth ? values.place_of_birth : "");
    formtitreSejour.setFieldValue("link_recto", values?.url_file_side1 ? values.url_file_side1 : "");
    formtitreSejour.setFieldValue("link_verso", values?.url_file_side2 ? values.url_file_side2 : "");
  };
  const setRecepFields = (values) => {
    //console.log("values for the recep setFieldValues function", values);
    formrec.setFieldValue("lieu_de_naissance", values?.place_of_birth ? values.place_of_birth : "");
    formrec.setFieldValue("link_file", values?.url_file ? values.url_file : "");
    formrec.setFieldValue("piece_res", "");
  };
  const setsecSocial = (values) => {
    //console.log("this is the values of the securiter social", values);
    formSecSocial.setFieldValue("num_sec_social", values?.document_number ? values.document_number : "");
    formSecSocial.setFieldValue("link_file", values?.url_file ? values.url_file : "");
    formSecSocial.setFieldValue("justificatif_sec_social", "");
  };
  const setVitalCard = (values) => {
    //console.log("this is the values of the vital social", values);

    formSecSocial.setFieldValue("num_sec_social", values?.document_number ? values.document_number : "");
    formSecSocial.setFieldValue("link_file", values?.url_file ? values.url_file : "");
    formSecSocial.setFieldValue("justificatif_sec_social", "");
  };
  const setJustifDomField = (values) => {
    formJustifDom.setFieldValue("file_link", values?.url_file ? values.url_file : "");
    formJustifDom.setFieldValue("justificatif_domicile", "");
  };
  const setMutuelleField = (values) => {
    formMutuelle.setFieldValue("mut_date_val", values?.expiration_date ? values.expiration_date : "");
    formMutuelle.setFieldValue("mut_nom_org", values?.organization_name ? values.organization_name : "");
  };

  //userDoc identity
  const [typeID, setTypeID] = useState("identity_card");
  //GET USER
  useLayoutEffect(() => {
    setLoading(true);
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUserFetch(res?.data?.user?.profile?.rib);
        setLoading(false);

        formRib.setFieldValue("rib", res?.data?.user?.profile?.rib);
      })
      .catch((err) => setLoading(false));
  }, [change]);
  //GET USER DOCUMENTS
  useLayoutEffect(() => {
    Api.get(Urls.GET_USER_DOCUMENT + user?.user?.id + "/administrativeDocuments", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user docs");
        setPermisToDelete([]);
        setNewPermis([]);
        setUserDocs(res?.data?.user?.administrative_documents);
        setUserDocIdentity(
          res?.data?.user?.administrative_documents?.national_identity_card
            ? res?.data?.user?.administrative_documents?.national_identity_card
            : {
                document_number: "",
                expiration_date: "",
                place_of_birth: "",
                new: true,
              }
        );
        setUserDocPassport(
          res?.data?.user?.administrative_documents?.passport
            ? res?.data?.user?.administrative_documents?.passport
            : {
                obtained_date: "",
                document_number: "",
                expiration_date: "",
                place_of_birth: "",
                new: true,
              }
        );
        setUserDocTitreSejour(
          res?.data?.user?.administrative_documents?.residence_permit
            ? res?.data?.user?.administrative_documents?.residence_permit
            : {
                place_of_birth: "",
                new: true,
              }
        );
        setUserDocRecep(
          res?.data?.user?.administrative_documents?.residence_permit_receipt
            ? res?.data?.user?.administrative_documents?.residence_permit_receipt
            : {
                place_of_birth: "",
                new: true,
              }
        );
        setUserDocSecSocial(
          res?.data?.user?.administrative_documents?.social_security_certificate
            ? res?.data?.user?.administrative_documents?.social_security_certificate
            : {
                document_number: "",
                new: true,
              }
        );
        setUserProofOfAddress(res?.data?.user?.administrative_documents?.address_proof);
        setUserDocMut(
          res?.data?.user?.administrative_documents?.mutual
            ? res?.data?.user?.administrative_documents?.mutual
            : {
                organization_name: "",
                expiration_date: "",
                new: true,
              }
        );
        setTypeID(
          res?.data?.user?.administrative_documents?.national_identity_card
            ? "identity_card"
            : res?.data?.user?.administrative_documents?.passport
            ? "passport"
            : res?.data?.user?.administrative_documents?.residence_permit
            ? "residence_permit"
            : res?.data?.user?.administrative_documents?.residence_permit_receipt
            ? "residence_permit_receipt"
            : "identity_card"
        );
        setTypeSoc(res?.data?.user?.administrative_documents?.social_security_certificate ? "social_security_certificate" : res?.data?.user?.administrative_documents?.vital_card ? "vital_card" : "vital_card");
        setIdentityCardFields(res?.data?.user?.administrative_documents?.national_identity_card);
        setPassportFields(res?.data?.user?.administrative_documents?.passport);
        setTitreSejourFields(res?.data?.user?.administrative_documents?.residence_permit);
        setRecepFields(res?.data?.user?.administrative_documents?.residence_permit_receipt);
        setUserDocVitalCard(
          res?.data?.user?.administrative_documents?.vital_card
            ? res?.data?.user?.administrative_documents?.vital_card
            : {
                document_number: "",
                new: true,
              }
        );
        if (res?.data?.user?.administrative_documents?.social_security_certificate) {
          setsecSocial(res?.data?.user?.administrative_documents?.social_security_certificate);
        }
        if (res?.data?.user?.administrative_documents?.vitalCard) {
          setVitalCard(res?.data?.user?.administrative_documents?.vital_card);
        }
        setJustifDomField(res?.data?.user?.administrative_documents?.address_proof);
        setMutuelleField(res?.data?.user?.administrative_documents?.mutual);
        setPermisLost(res?.data?.user?.administrative_documents?.driving_licenses);
        setListePrmisToCompare(res?.data?.user?.administrative_documents?.driving_licenses);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [change, open]);
  //console.log(userDocIdentity?.document_type?.slug);
  //permis form
  const initialValuesPermis = {
    cat_permis: "B",
    date_em: "",
    justif: "",
    link_file: "",
  };
  const formPerm = useFormik({
    initialValues: initialValuesPermis,
    validationSchema: validationSchemaPermis,
    onSubmit: (values) => {
      const obj = {
        id: listePermisToCompare.length + 1,
        obtained_date: values.date_em,
        category: values.cat_permis,
        file: values.justif,
      };
      setNewPermis(newPermis.concat([obj]));
      formPerm.setFieldValue("date_em", null);
      formPerm.setFieldValue("cat_permis", "B");
      formPerm.setFieldValue("justif", "");
      formPerm.setFieldError("date_em", "");
      formPerm.setFieldError("cat_permis", "");
      formPerm.setFieldError("justif", "");
      setOpenPermit(false);
    },
  });
  //console.log(permis);
  const handleDelitePermis = (perm) => {
    Api.delete(Urls.DELETE_PERMIS + perm, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  //config for the identity card files
  const [progressIdentityCard, setProgressIdentitycard] = useState(0);
  var configscardIdentity = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressIdentitycard(percentCompleted);
    },
  };
  const IndentityAdd = (values) => {
    Api.post(
      Urls?.ADD_IDENTITY_CARD,
      {
        user_id: user?.user?.id,
        file_side1: values.piece_did_recto,
        file_side2: values.piece_did_verso,
        document_number: values.num_carte,
        expiration_date: values.date_exp_carte,
        place_of_birth: values.lieu_de_naissance,
      },
      configscardIdentity
    )
      .then((res) => {
        //console.log(res);

        setProgressIdentitycard(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressIdentitycard(0);
      });
  };
  //identity patch
  const IndentityPatch = (values, id) => {
    Api.post(
      Urls?.UPDATE_IDENTITY_CARD + id,
      {
        ...(values.piece_did_recto !== "" && { file_side1: values.piece_did_recto }),
        ...(values.piece_did_verso !== "" && { file_side1: values.piece_did_verso }),
        document_number: values.num_carte,
        expiration_date: values.date_exp_carte,
        place_of_birth: values.lieu_de_naissance,
      },
      configscardIdentity
    )
      .then((res) => {
        //console.log(res);

        setProgressIdentitycard(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressIdentitycard(0);
      });
  };
  //config for the  passport add and files
  const [progressPasseport, setProgressPasseport] = useState(0);
  var configspasseport = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressPasseport(percentCompleted);
    },
  };
  const passeportAdd = (values) => {
    //console.log(typeID);
    Api.post(
      Urls?.ADD_PASSPORT,
      {
        user_id: user?.user?.id,
        obtained_date: values.date_em_passport,
        document_number: values.num_passport,
        expiration_date: values.date_exp_passport,
        place_of_birth: values.lieu_de_naissance,
        file: values.file_passport,
      },
      configspasseport
    )
      .then((res) => {
        //console.log(res);

        setProgressPasseport(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressPasseport(0);
      });
  };
  //config for the  passport PATCH and files
  const passeportPatch = (values, id) => {
    Api.post(
      Urls?.UPDATE_PASSPORT + id,
      {
        obtained_date: values.date_em_passport,
        document_number: values.num_passport,
        expiration_date: values.date_exp_passport,
        place_of_birth: values.lieu_de_naissance,
        ...(file !== "" && { file: values.file_passport }),
      },
      configspasseport
    )
      .then((res) => {
        //console.log(res);

        setProgressPasseport(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressPasseport(0);
      });
  };
  //config for the  titre de sejour add and files
  const [progressTitreSejour, setProgressTitreSejour] = useState(0);
  var configsTitreSejour = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressTitreSejour(percentCompleted);
    },
  };
  const handlePrevious = () => {
    setCurrentStep(4);
  };
  const TitreSejourAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_TITRE_SEJOUR,
      {
        user_id: user?.user?.id,
        file_side1: values.sejour_recto,
        file_side2: values.sejour_verso,
        place_of_birth: values.lieu_de_naissance,
      },
      configsTitreSejour
    )
      .then((res) => {
        //console.log(res);

        setProgressTitreSejour(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressTitreSejour(0);
      });
  };
  //config for the  titre de sejour  patch and files
  const TitreSejourPatch = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    Api.post(
      Urls?.UPDATE_TITRE_SEJOUR + id,
      {
        ...(values.sejour_recto !== "" && { file_side1: values.sejour_recto }),
        ...(values.sejour_verso !== "" && { file_side1: values.sejour_verso }),
        place_of_birth: values.lieu_de_naissance,
      },
      configsTitreSejour
    )
      .then((res) => {
        //console.log(res);

        setProgressTitreSejour(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressTitreSejour(0);
      });
  };
  //config for the  titre de sejour add and files
  const [progressRec, setProgressRec] = useState(0);
  var configsRec = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressRec(percentCompleted);
    },
  };
  const recipAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_RES,
      {
        user_id: user?.user?.id,
        file: values.piece_res,
        place_of_birth: values.lieu_de_naissance,
      },
      configsRec
    )
      .then((res) => {
        //console.log(res);

        setProgressRec(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressRec(0);
      });
  };
  //config for the  titre de sejour  patch and files
  const recipPatch = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    Api.post(
      Urls?.UPDATE_RES + id,
      {
        ...(values.piece_res !== "" && { file: values.piece_res }),
        place_of_birth: values.lieu_de_naissance,
      },
      configsRec
    )
      .then((res) => {
        //console.log(res);

        setProgressRec(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressRec(0);
      });
  };
  //patch/post rib
  const patchRib = (values) => {
    Api.patch(
      Urls.MODIFY_INTERIMAIRE + user?.user?.id + "/interim",
      {
        rib: values.rib,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);

        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //config for the  SEC SOCIAL and files
  const [progresssecS, setProgressSecS] = useState(0);
  var configsSecSocial = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressSecS(percentCompleted);
    },
  };
  const secSocialAdd = (values) => {
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_SECURITE_SOCIAL,
      {
        user_id: user?.user?.id,
        document_number: values.num_sec_social,
        file: values.justificatif_sec_social,
      },
      configsSecSocial
    )
      .then((res) => {
        //console.log(res);

        setProgressSecS(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressSecS(0);
      });
  };
  //config for the  sec social  patch and files
  const secSocialPatch = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    Api.post(
      Urls?.UPDATE_SEC_SOCIAL + id,
      {
        document_number: values.num_sec_social,
        ...(values.justificatif_sec_social !== "" && { file: values.justificatif_sec_social }),
      },
      configsSecSocial
    )
      .then((res) => {
        //console.log(res);

        setProgressSecS(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressSecS(0);
      });
  };
  //handle delete permis
  const handleDelitePerm = (perm) => {
    setPermisToDelete(permistoDelete.concat([perm]));
    const filteredListe = listePermisToCompare.filter((permis) => permis.id !== perm);
    const filteredListe2 = permisListe.filter((permis) => permis.id !== perm);
    setListePrmisToCompare(filteredListe);
    setPermisLost(filteredListe2);
  };
  //handle delete permis
  const handleDeliteNewPerm = (perm) => {
    const filteredListe = newPermis.filter((permis) => permis.id !== perm);
    setNewPermis(filteredListe);
  };
  //config for the  titre de sejour add and files
  var vitalCard = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressSecS(percentCompleted);
    },
  };
  const VitalCardAdd = (values) => {
    Api.post(
      Urls?.ADD_VITAL_CARD,
      {
        user_id: user?.user?.id,
        document_number: values.num_sec_social,
        file: values.justificatif_sec_social,
      },
      vitalCard
    )
      .then((res) => {
        //console.log(res);

        setProgressSecS(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressSecS(0);
      });
  };
  //config for the  sec social  patch and files
  const VitalCardUpdate = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    Api.post(
      Urls?.UPDATE_VITAL_CARD + id,
      {
        document_number: values.num_sec_social,
        ...(values.justificatif_sec_social !== "" && { file: values.justificatif_sec_social }),
      },
      vitalCard
    )
      .then((res) => {
        //console.log(res);

        setProgressSecS(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressSecS(0);
      });
  };
  //console.log(progresssecS);
  //config for the  justificatif de domicile  add and files
  const [progresssDom, setProgressDom] = useState(0);
  var configsDom = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressDom(percentCompleted);
    },
  };
  const domAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_JUSTIF_DOM,
      {
        user_id: user?.user?.id,
        file: values.justificatif_domicile,
      },
      configsDom
    )
      .then((res) => {
        //console.log(res);

        setProgressDom(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressDom(0);
      });
  };
  //config for the  justificatif de domicile  patch and files
  const domPatch = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    if (values.justificatif_domicile) {
      Api.post(
        Urls?.UPDATE_JUSTIF_DOM + id,
        {
          ...(values.justificatif_domicile !== "" && { file: values.justificatif_domicile }),
        },
        configsDom
      )
        .then((res) => {
          //console.log(res);

          setProgressDom(0);
          setChange(!change);
        })
        .catch((err) => {
          //console.log(err);
          toast.error(err?.response?.data?.message);
          setProgressDom(0);
        });
    }
  };
  //config for the  mutuelle  add and files
  const [progresssMut, setProgressMut] = useState(0);
  var configsMut = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressMut(percentCompleted);
    },
  };
  const MutAdd = (values) => {
    //console.log(values, "this is the values and the execution of the function");
    Api.post(
      Urls?.ADD_MUTUELLE,
      {
        user_id: user?.user?.id,
        organization_name: values.mut_nom_org,
        expiration_date: values.mut_date_val,
      },
      configsMut
    )
      .then((res) => {
        //console.log(res);

        setProgressMut(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressMut(0);
      });
  };
  //config for the  mutuelle  patch and files
  const MutPatch = (values, id) => {
    Api.post(
      Urls?.UPDATE_MUTUELLE + id,
      {
        organization_name: values.mut_nom_org,
        expiration_date: values.mut_date_val,
      },
      configsMut
    )
      .then((res) => {
        //console.log(res);

        setProgressMut(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressMut(0);
      });
  };

  //config for the  permis   add and files
  const [progresssPerm, setProgressPerm] = useState(0);
  var configsPerm = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressPerm(percentCompleted);
    },
  };
  const PermisAdd = (values) => {
    //console.log(typeID);
    //console.log(values, "this is the values");
    Api.post(
      Urls?.ADD_PERMIS,
      {
        user_id: user?.user?.id,
        file: values.file,
        category: values.category,
        obtained_date: values.obtained_date,
      },
      configsPerm
    )
      .then((res) => {
        //console.log(res);

        setProgressPerm(0);
        setChange(!change);
        setFilePermis("");
        permisRef.current.values.cat_permis = "B";
        permisRef.current.values.date_em = null;
        permisRef.current.values.justif = "";
        permisRef.current.values.link_file = "";
        document.getElementById("input-permis").value = null;
        setPermisID("first");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressPerm(0);
      });
  };
  //config for the  permis  patch and files
  const PermisPatch = (values, id) => {
    //console.log(typeID);
    //console.log(values);
    Api.post(
      Urls?.UPDATE_PERMIS + id,
      {
        file: values.file,
        category: values.category,
        obtained_date: values.obtained_date,
      },
      configsPerm
    )
      .then((res) => {
        //console.log(res);

        setProgressPerm(0);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
        setProgressPerm(0);
      });
  };
  const permisRef = useRef();
  //handle click permis
  const [filePermis, setFilePermis] = useState();
  const handleClickPermis = (perm) => {
    //console.log(permisRef);
    permisRef.current.values.cat_permis = perm.category;
    permisRef.current.values.date_em = perm.obtained_date;
    permisRef.current.values.link_file = perm.files[0]?.file_url;
    setFilePermis("Piéce jointe");
    setPermisID(perm.id);
  };
  const executeSave = () => {
    //console.log(formSecSocial, "formCarteIdentite");
    if (UserFetch !== formRib?.values?.rib) {
      formRib.handleSubmit();
    }
    if (typeID === "identity_card") {
      if (
        userDocIdentity?.document_number !== formCarteIdentite?.values?.num_carte ||
        userDocIdentity?.expiration_date !== formCarteIdentite?.values?.date_exp_carte ||
        userDocIdentity?.place_of_birth !== formCarteIdentite?.values?.lieu_de_naissance ||
        formCarteIdentite?.values?.piece_did_recto !== "" ||
        formCarteIdentite?.values?.piece_did_verso !== ""
      ) {
        formCarteIdentite.handleSubmit();
      }
    }
    if (typeID === "passport") {
      if (
        userDocPassport?.document_number !== formcPassport?.values?.num_passport ||
        formcPassport?.values?.file_passport !== "" ||
        userDocPassport?.place_of_birth !== formcPassport?.values?.lieu_de_naissance ||
        userDocPassport?.expiration_date !== formcPassport?.values?.date_exp_passport ||
        userDocPassport?.obtained_date !== formcPassport?.values?.date_em_passport
      )
        formcPassport.handleSubmit();
    }
    if (typeID === "residence_permit") {
      if (formtitreSejour?.values?.link_verso !== "" || formtitreSejour?.values?.link_recto !== "" || formtitreSejour?.values?.lieu_de_naissance !== userDoctitreSejour?.place_of_birth) {
        formtitreSejour.handleSubmit();
      }
    }
    if (typeID === "residence_permit_receipt") {
      if (formrec?.values?.lieu_de_naissance !== userDocRecep?.place_of_birth || formrec?.values?.piece_res !== "") {
        formrec.handleSubmit();
      }
    }
    if (checkedSecSocial) {
      if (formSecSocial?.values?.num_sec_social !== userDocSecSocial?.document_number || formSecSocial?.values?.justificatif_sec_social !== "") {
        formSecSocial.handleSubmit();
      }
    }
    if (checkedJustifDom) {
      //console.log("click justificatif dom");
      if (formJustifDom?.values?.justificatif_domicile !== "") {
        formJustifDom.handleSubmit();
      }
    }
    if (checkedMutuelle) {
      if (formMutuelle?.values?.mut_nom_org !== userDocMut?.organization_name || formMutuelle?.values?.mut_date_val !== userDocMut?.expiration_date) formMutuelle.handleSubmit();
    }

    for (let index = 0; index < newPermis.length; index++) {
      PermisAdd(newPermis[index]);
    }
    for (let index = 0; index < permistoDelete.length; index++) {
      handleDelitePermis(permistoDelete[index]);
    }
    let listToUpdate = [];
    for (let i = 0; i < listePermisToCompare?.length; i++) {
      //console.log(permisListe[i].id, listePermisToCompare[i].id, "comparing these ids", permisListe[i], "comparring object", listePermisToCompare[i]);
      if (permisListe[i].id === listePermisToCompare[i]?.id && (permisListe[i]?.obtained_date !== listePermisToCompare[i]?.obtained_date || permisListe[i]?.category !== listePermisToCompare[i]?.category || listePermisToCompare[i].file)) {
        listToUpdate.push(listePermisToCompare[i]);
      }
    }
    for (let index = 0; index < listToUpdate.length; index++) {
      PermisPatch(listToUpdate[index], listToUpdate[index].id);
      setListePrmisToCompare([]);
    }
    setPermisToDelete([]);
    setNewPermis([]);
    navigate("/renard/tableau-de-bord");
  };
  const [data, setData] = useState([]);
  return (
    <>
      <ToastContainer />
      {!loading && (
        <>
          <Box gap="20px" display="flex" flexDirection="column">
            <Box>
              <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Pièce d’identité</Typography>
            </Box>
            <Box>
              <Box marginBottom="6px">
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                  Type
                </Typography>
                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                  *
                </Typography>
              </Box>
              <FormControl>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={typeID} onChange={(e) => handleChange(e)}>
                  <FormControlLabel value="identity_card" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Carte d’identité" />
                  <FormControlLabel value="passport" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Passeport" />
                  <FormControlLabel value="residence_permit" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Titre de séjour" />
                  <FormControlLabel value="residence_permit_receipt" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Récépissé du titre de séjour" />
                </RadioGroup>
              </FormControl>
            </Box>
            {typeID === "identity_card" && (
              <>
                <Formik
                  initialValues={initialValuesCarteIdentite}
                  onSubmit={(values) => {
                    if (userDocIdentity !== null) {
                      IndentityPatch(values, userDocIdentity?.id);
                    } else {
                      IndentityAdd(values);
                    }
                  }}
                  validationSchema={validationSchemaCarteIdent}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Recto
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="piece_did_recto"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formCarteIdentite.setFieldValue("piece_did_recto", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField
                                  setFieldValue={formCarteIdentite.setFieldValue}
                                  value={formCarteIdentite?.values?.piece_did_recto?.name ? formCarteIdentite?.values?.piece_did_recto?.name : userDocIdentity?.new ? "" : "recto"}
                                  endAdornment="download"
                                  name="file"
                                  placeholder="Télécharger..."
                                />
                              </Box>
                              <Box onClick={() => (userDocIdentity?.url_file_side1 ? window.open(userDocIdentity?.url_file_side1, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formCarteIdentite?.errors.piece_did_recto && formCarteIdentite.touched.piece_did_recto ? (
                                <Typography color="red" fontSize="12px">
                                  {formCarteIdentite?.errors.piece_did_recto}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Verso
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="piece_did_verso"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formCarteIdentite.setFieldValue("piece_did_verso", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField
                                  setFieldValue={formCarteIdentite.setFieldValue}
                                  value={formCarteIdentite?.values?.piece_did_verso?.name ? formCarteIdentite?.values?.piece_did_verso?.name : userDocIdentity?.new ? "" : "verso"}
                                  endAdornment="download"
                                  name="file"
                                  placeholder="Télécharger..."
                                />
                              </Box>
                              <Box onClick={() => (userDocIdentity?.url_file_side2 ? window.open(userDocIdentity?.url_file_side2, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formCarteIdentite.errors.piece_did_verso && formCarteIdentite.touched.piece_did_verso ? (
                                <Typography color="red" fontSize="12px">
                                  {formCarteIdentite.errors.piece_did_verso}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Numéro de la carte
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <MyTextField setFieldValue={formCarteIdentite.setFieldValue} value={formCarteIdentite?.values.num_carte} name="num_carte" placeholder="Numéro de la carte..." error={errors.password && touched.password ? true : false} />
                                {formCarteIdentite.errors.num_carte && formCarteIdentite.touched.num_carte ? (
                                  <Typography color="red" fontSize="12px">
                                    {formCarteIdentite.errors.num_carte}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date d’expiration
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field value={formCarteIdentite?.values.date_exp_carte} setFieldValue={formCarteIdentite.setFieldValue} name="date_exp_carte" placeholder="Date..." as={MyDPFullDate}></Field>
                              {formCarteIdentite?.errors.date_exp_carte && formCarteIdentite?.touched.date_exp_carte ? (
                                <Typography color="red" fontSize="12px">
                                  {formCarteIdentite?.errors?.date_exp_carte}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Lieu de naissance
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={formCarteIdentite.setFieldValue} value={formCarteIdentite?.values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                            {formCarteIdentite.errors.lieu_de_naissance && formCarteIdentite.touched.lieu_de_naissance ? (
                              <Typography color="red" fontSize="12px">
                                {formCarteIdentite.errors.lieu_de_naissance}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            {progressIdentityCard !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progressIdentityCard}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </form>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {typeID === "passport" && (
              <>
                <Formik
                  initialValues={initialValuesPasseport}
                  onSubmit={(values) => {
                    if (userDocIdentity !== null) {
                      passeportPatch(values, userDocIdentity?.id);
                    } else {
                      passeportAdd(values);
                    }
                  }}
                  validationSchema={validationSchemaPasseport}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Numéro du passeport
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <MyTextField setFieldValue={formcPassport?.setFieldValue} value={formcPassport?.values?.num_passport} name="num_passport" placeholder=" Numéro du passeport..." />
                                {formcPassport.errors.num_passport && formcPassport.touched.num_passport ? (
                                  <Typography color="red" fontSize="12px">
                                    {formcPassport.errors.num_passport}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date d’émission
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field maxDate={formcPassport.values.date_exp_passport} value={formcPassport.values.date_em_passport} setFieldValue={formcPassport.setFieldValue} name="date_em_passport" placeholder="Date..." as={MyDPFullDate}></Field>
                              {formcPassport.errors.date_em_passport && formcPassport.touched.date_em_passport ? (
                                <Typography color="red" fontSize="12px">
                                  {formcPassport.errors.date_em_passport}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date d’expiration
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field value={formcPassport.values.date_exp_passport} setFieldValue={formcPassport.setFieldValue} name="date_exp_passport" placeholder="Date..." minDate={formcPassport.values.date_em_passport} as={MyDPFullDate}></Field>
                              {formcPassport.errors.date_exp_passport && formcPassport.touched.date_exp_passport ? (
                                <Typography color="red" fontSize="12px">
                                  {formcPassport.errors.date_exp_passport}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Pièce jointe
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="file_passport"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formcPassport.setFieldValue("file_passport", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField setFieldValue={formcPassport.setFieldValue} value={formcPassport.values?.file_passport?.name ? formcPassport.values?.file_passport?.name : userDocPassport ? "Pièce jointe" : ""} endAdornment="download" name="file" placeholder="Télécharger..." />
                              </Box>
                              <Box onClick={() => (userDocPassport?.url_file ? window.open(userDocPassport?.url_file, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formcPassport.errors.file_passport && formcPassport.touched.file_passport ? (
                                <Typography color="red" fontSize="12px">
                                  {formcPassport.errors.file_passport}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Lieu de naissance
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={formcPassport.setFieldValue} value={formcPassport.values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                            {formcPassport.errors.lieu_de_naissance && formcPassport.touched.lieu_de_naissance ? (
                              <Typography color="red" fontSize="12px">
                                {formcPassport.errors.lieu_de_naissance}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            {progressPasseport !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progressPasseport}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </form>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {typeID === "residence_permit" && (
              <>
                <Formik
                  initialValues={initialValuesTitreSejour}
                  onSubmit={(values) => {
                    if (userDocIdentity !== null) {
                      TitreSejourPatch(values, userDocIdentity?.id);
                    } else {
                      TitreSejourAdd(values);
                    }
                  }}
                  validationSchema={validationSchemaTitreSejour}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Recto
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="sejour_recto"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formtitreSejour.setFieldValue("sejour_recto", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField
                                  setFieldValue={formtitreSejour.setFieldValue}
                                  value={formtitreSejour.values?.sejour_recto?.name ? formtitreSejour.values?.sejour_recto?.name : userDoctitreSejour?.url_file_side1 ? "document Titre de séjour" : ""}
                                  endAdornment="download"
                                  name="file"
                                  placeholder="Télécharger..."
                                />
                              </Box>
                              <Box onClick={() => (userDoctitreSejour?.url_file_side1 ? window.open(userDoctitreSejour?.url_file_side1, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formtitreSejour.errors.sejour_recto && formtitreSejour.touched.sejour_recto ? (
                                <Typography color="red" fontSize="12px">
                                  {formtitreSejour.errors.sejour_recto}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Verso
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="sejour_verso"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formtitreSejour.setFieldValue("sejour_verso", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField
                                  setFieldValue={formtitreSejour.setFieldValue}
                                  value={formtitreSejour.values?.sejour_verso?.name ? formtitreSejour.values?.sejour_verso?.name : userDoctitreSejour?.url_file_side2 ? "document Titre de séjour" : ""}
                                  endAdornment="download"
                                  name="file"
                                  placeholder="Télécharger..."
                                />
                              </Box>
                              <Box onClick={() => (userDoctitreSejour?.url_file_side2 ? window.open(userDoctitreSejour?.url_file_side2, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formtitreSejour.errors.sejour_verso && formtitreSejour.touched.sejour_verso ? (
                                <Typography color="red" fontSize="12px">
                                  {formtitreSejour.errors.sejour_verso}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Lieu de naissance
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={formtitreSejour.setFieldValue} value={formtitreSejour.values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                            {formtitreSejour.errors.lieu_de_naissance && formtitreSejour.touched.lieu_de_naissance ? (
                              <Typography color="red" fontSize="12px">
                                {formtitreSejour.errors.lieu_de_naissance}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            {progressTitreSejour !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progressTitreSejour}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </form>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            {typeID === "residence_permit_receipt" && (
              <>
                <Formik
                  initialValues={initialValuesRes}
                  onSubmit={(values) => {
                    if (userDocIdentity !== null) {
                      recipPatch(values, userDocIdentity?.id);
                    } else {
                      recipAdd(values);
                    }
                  }}
                  validationSchema={validationSchemaRes}
                >
                  {({ values, errors, setFieldValue, touched }) => (
                    <Form>
                      <for>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Pièce jointe
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="piece_res"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formrec.setFieldValue("piece_res", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField setFieldValue={formrec.setFieldValue} value={formrec.values?.piece_res?.name ? formrec.values?.piece_res?.name : userDocRecep ? "Récépissé du titre de séjour" : ""} endAdornment="download" name="file" placeholder="Télécharger..." />
                              </Box>
                              <Box onClick={() => (userDocRecep.url_file ? window.open(userDocRecep.url_file, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formrec.errors.piece_res && formrec.touched.piece_res ? (
                                <Typography color="red" fontSize="12px">
                                  {formrec.errors.piece_res}
                                </Typography>
                              ) : null}
                            </Box>
                            <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                          </Box>
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Lieu de naissance
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <MyTextField setFieldValue={formrec.setFieldValue} value={formrec.values.lieu_de_naissance} name="lieu_de_naissance" placeholder="Lieu de naissance..." />
                            {formrec.errors.lieu_de_naissance && formrec.touched.lieu_de_naissance ? (
                              <Typography color="red" fontSize="12px">
                                {formrec.errors.lieu_de_naissance}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            {progressRec !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progressRec}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </for>
                    </Form>
                  )}
                </Formik>
              </>
            )}
            <Divider sx={{ margin: "10px 0px" }} />
            <Box>
              <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Relevé d'identité bancaire</Typography>
            </Box>
            <Formik
              initialValues={initialValuesRib}
              onSubmit={(values) => {
                patchRib(values);
              }}
              validationSchema={validationSchemaRIb}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <form>
                    <Box display="flex" flexDirection="column" gap="20px">
                      <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                        <Box width="100%">
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              RIB
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <Box width="100%">
                            <MyTextField setFieldValue={formRib.setFieldValue} value={formRib.values?.rib} name="rib" placeholder="RIB..." />
                            {formRib?.errors.rib && formRib?.rib?.touched ? (
                              <Typography color="red" fontSize="12px">
                                {formRib?.errors.rib}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                      </Box>
                    </Box>
                  </form>
                </Form>
              )}
            </Formik>
            <Divider sx={{ margin: "10px 0px" }} />
            <Formik
              initialValues={initialValuesSecSocial}
              onSubmit={(values) => {
                if (userDocSecSocial !== null) {
                  secSocialPatch(values, userDocSecSocial?.id);
                } else {
                  secSocialAdd(values);
                }
              }}
              validationSchema={validationSchemaSecuSocial}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Sécurité sociale</Typography>
                      <MySwitch isOn={checkedSecSocial} onChange={() => setCheckedSecSocial(!checkedSecSocial)} />
                    </Box>
                    {checkedSecSocial && (
                      <>
                        <form>
                          <Box>
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Type
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <FormControl>
                              <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={typeSoc} onChange={(e) => handleChangeSoc(e)}>
                                <FormControlLabel value="vital_card" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Carte vitale" />
                                <FormControlLabel value="social_security_certificate" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Attestation de sécurité sociale" />
                              </RadioGroup>
                            </FormControl>
                          </Box>

                          <Box display="flex" gap="16px" sx={{ flexDirection: "column" }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Numéro de sécurité sociale
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box>
                                <MyTextField setFieldValue={formSecSocial.setFieldValue} value={formSecSocial.values?.num_sec_social} name="num_sec_social" placeholder="Numéro de sécurité sociale..." />
                                {formSecSocial.errors.num_sec_social && formSecSocial.touched.num_sec_social ? (
                                  <Typography color="red" fontSize="12px">
                                    {formSecSocial.errors.num_sec_social}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Pièce jointe
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  name="justificatif_sec_social"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formSecSocial.setFieldValue("justificatif_sec_social", e.target.files[0]);
                                    setFile(doc?.name);
                                  }}
                                />
                                <MyTextField
                                  setFieldValue={setFieldValue}
                                  value={formSecSocial.values?.justificatif_sec_social?.name ? formSecSocial.values?.justificatif_sec_social?.name : userDocSecSocial && typeSoc === "social_security_certificate" ? "Pièce jointe" : userDocVitalCard && typeSoc === "vital_card" ? "Pièce jointe" : ""}
                                  endAdornment="download"
                                  name="file"
                                  placeholder="Télécharger..."
                                />
                              </Box>
                              <Box
                                onClick={() => {
                                  if (userDocSecSocial) {
                                    window.open(userDocSecSocial?.url_file, "_blank");
                                  } else {
                                    if (userDocVitalCard) {
                                      window.open(userDocVitalCard?.url_file, "_blank");
                                    }
                                  }
                                }}
                                marginTop="2px"
                                alignItems="center"
                                display="flex"
                                gap="7px"
                              >
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                              {formSecSocial.errors.justificatif_sec_social && formSecSocial.touched.justificatif_sec_social ? (
                                <Typography color="red" fontSize="12px">
                                  {formSecSocial.errors.justificatif_sec_social}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            {progresssecS !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progresssecS}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </form>
                      </>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
            <Divider sx={{ margin: "10px 0px" }} />
            <Box>
              <Box display="flex" alignItems="center" gap="10px">
                <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Justificatif de domicile</Typography>
                <MySwitch isOn={checkedJustifDom} onChange={() => setCheckedJustifDom(!checkedJustifDom)} />
              </Box>
              <Typography sx={{ fontSize: "12px", fontWeight: "400", lineHeight: "160%", color: "#666D83" }}>Insérer un justificatif à votre nom et prénom qui date de moins de 1 an</Typography>
            </Box>
            {checkedJustifDom && (
              <Formik
                initialValues={initialValuesJustifDom}
                onSubmit={(values) => {
                  if (proofOfAddress !== null) {
                    domPatch(values, proofOfAddress?.id);
                  } else {
                    domAdd(values);
                  }
                }}
                validationSchema={validationSchemaJustificatifDomicile}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <form>
                      <Box display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                          <Box width="100%">
                            <Box marginBottom="6px">
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                Pièce jointe
                              </Typography>
                              <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                *
                              </Typography>
                            </Box>
                            <Box width="100%" position="relative">
                              <input
                                style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                type="file"
                                name="justificatif_domicile"
                                onChange={(e) => {
                                  const doc = e.target.files[0];
                                  formJustifDom.setFieldValue("justificatif_domicile", e.target.files[0]);
                                  setFile(doc?.name);
                                }}
                              />
                              <MyTextField
                                setFieldValue={formJustifDom.setFieldValue}
                                value={formJustifDom.values?.justificatif_domicile?.name ? formJustifDom.values?.justificatif_domicile?.name : proofOfAddress ? "Pièce jointe" : ""}
                                endAdornment="download"
                                name="file"
                                placeholder="Télécharger..."
                              />
                            </Box>
                            <Box onClick={() => (proofOfAddress?.url_file ? window.open(proofOfAddress?.url_file, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                              <Typography variant="text_clickable">Consulter le fichier</Typography>
                              <img src={checkLinkicon} alt="link-icon" />
                            </Box>
                            {formJustifDom.errors.justificatif_domicile && formJustifDom.touched.justificatif_domicile ? (
                              <Typography color="red" fontSize="12px">
                                {formJustifDom.errors.justificatif_domicile}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                        </Box>
                        <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                          {progresssDom !== 0 && (
                            <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                              <CircularProgress />
                              <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                {progresssDom}%
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </form>
                  </Form>
                )}
              </Formik>
            )}
            <Divider sx={{ margin: "10px 0px" }} />
            <Box display="flex" alignItems="center" gap="10px">
              <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Mutuelle</Typography>
              <MySwitch isOn={checkedMutuelle} onChange={() => setCheckedMutuelle(!checkedMutuelle)} />
            </Box>
            {checkedMutuelle && (
              <Formik
                initialValues={initialValuesMutuelle}
                onSubmit={(values) => {
                  //console.log(userDocMut, "userdocmut");
                  if (userDocMut?.new) {
                    MutAdd(values);
                  } else {
                    MutPatch(values, userDocMut?.id);
                  }
                }}
                validationSchema={validationSchemaMutuelle}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <Box display="flex" flexDirection="column" gap="20px">
                      <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                        <Box width="100%">
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              Nom de l’organisme
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <Box>
                            <MyTextField setFieldValue={formMutuelle.setFieldValue} value={formMutuelle.values?.mut_nom_org} name="mut_nom_org" placeholder="Nom de l’organisme..." error={errors.password && touched.password ? true : false} />
                            {formMutuelle.errors.mut_nom_org && formMutuelle.touched.mut_nom_org ? (
                              <Typography color="red" fontSize="12px">
                                {formMutuelle.errors.mut_nom_org}
                              </Typography>
                            ) : null}
                          </Box>
                        </Box>
                        <Box width="100%">
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              Date de validité
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <Field value={formMutuelle.values?.mut_date_val} setFieldValue={formMutuelle.setFieldValue} name="mut_date_val" placeholder="Date..." as={MyDPFullDate}></Field>
                          {formMutuelle.errors.mut_date_val && formMutuelle.touched.mut_date_val ? (
                            <Typography color="red" fontSize="12px">
                              {formMutuelle.errors.mut_date_val}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                      <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                        {progresssMut !== 0 && (
                          <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                            <CircularProgress />
                            <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                              {progresssMut}%
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            )}
            <Divider sx={{ margin: "10px 0px" }} />
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{ fontSize: "20px", lineHeight: "160%", fontWeight: "700" }}>Permis</Typography>
              <Button onClick={() => setOpenPermit(true)} variant="mj_secondary_md" sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <img src={plusIcon} alt="add-icon" />
                Ajouter
              </Button>
            </Box>
            {openPermit && (
              <Formik
                innerRef={permisRef}
                initialValues={initialValuesPermis}
                onSubmit={(values) => {
                  if (permisID === "first") {
                    PermisAdd(values);
                  } else {
                    PermisPatch(values, permisID);
                  }
                }}
                validationSchema={validationSchemaPermis}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    <form>
                      <Box display="flex" flexDirection="column" gap="20px">
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Catégorie
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>

                              <Box>
                                <FormControl sx={{ height: "42px" }} fullWidth>
                                  <InputLabel id="demo-simple-select-label">Cat</InputLabel>
                                  <Select sx={{ height: "42px", borderColor: "#DEDFE4" }} name="cat_permis" labelId="demo-simple-select-label" id="demo-simple-select" value={formPerm.values?.cat_permis} label="Cat" onChange={(e) => formPerm.setFieldValue("cat_permis", e.target.value)}>
                                    <MenuItem value="A1">A1</MenuItem>
                                    <MenuItem value="A2">A2</MenuItem>
                                    <MenuItem value="B1">B1</MenuItem>
                                    <MenuItem value="C1">C1</MenuItem>
                                    <MenuItem value="D1">D1</MenuItem>
                                    <MenuItem value="A">A</MenuItem>
                                    <MenuItem value="B">B</MenuItem>
                                    <MenuItem value="C">C</MenuItem>
                                    <MenuItem value="D">D</MenuItem>
                                    <MenuItem value="BE">BE</MenuItem>
                                    <MenuItem value="C1E">C1E</MenuItem>
                                    <MenuItem value="DE">DE</MenuItem>
                                    <MenuItem value="AM">AM</MenuItem>
                                    <MenuItem value="CE">CE</MenuItem>
                                  </Select>
                                </FormControl>
                                {formPerm.errors.cat_permis && formPerm.touched.cat_permis ? (
                                  <Typography color="red" fontSize="12px">
                                    {formPerm.errors.cat_permis}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Date d’obtention
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Field value={formPerm.values.date_em} setFieldValue={formPerm.setFieldValue} maxDate={new Date()} name="date_em" placeholder="Date..." as={MyDPFullDate}></Field>
                              {formPerm.errors.date_em && formPerm.touched.date_em ? (
                                <Typography color="red" fontSize="12px">
                                  {formPerm.errors.date_em}
                                </Typography>
                              ) : null}
                            </Box>
                          </Box>
                          <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                            <Box width="100%">
                              <Box marginBottom="6px">
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                  Pièce jointe
                                </Typography>
                                <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                  *
                                </Typography>
                              </Box>
                              <Box width="100%" position="relative">
                                <input
                                  style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                  type="file"
                                  id="input-permis"
                                  name="justif"
                                  onChange={(e) => {
                                    const doc = e.target.files[0];
                                    formPerm.setFieldValue("justif", e.target.files[0]);
                                    setFilePermis(doc?.name);
                                  }}
                                />
                                <MyTextField setFieldValue={formPerm.setFieldValue} value={formPerm?.values.justif?.name} endAdornment="download" name="justif" placeholder="Télécharger..." />
                                {formPerm.errors.justif && formPerm.touched.justif ? (
                                  <Typography color="red" fontSize="12px">
                                    {formPerm.errors.justif}
                                  </Typography>
                                ) : null}
                              </Box>
                              <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                <Typography variant="text_clickable">Consulter le fichier</Typography>
                                <img src={checkLinkicon} alt="link-icon" />
                              </Box>
                            </Box>
                            <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                          </Box>
                          <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                            <Button
                              disabled={progresssPerm !== 0 ? true : false}
                              onClick={() => {
                                setOpenPermit(false);
                                formPerm.setFieldValue("date_em", null);
                                formPerm.setFieldValue("cat_permis", "B");
                                formPerm.setFieldValue("justif", "");
                                formPerm.setFieldError("date_em", "");
                                formPerm.setFieldError("cat_permis", "");
                                formPerm.setFieldError("justif", "");
                              }}
                              variant="mj_secondary_md"
                            >
                              Annuler
                            </Button>
                            <Button onClick={formPerm.handleSubmit} variant="mj_primary_md">
                              Enregistrer
                            </Button>
                            {progresssPerm !== 0 && (
                              <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                <CircularProgress />
                                <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                  {progresssPerm}%
                                </Typography>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </form>
                  </Form>
                )}
              </Formik>
            )}
            <Box display="flex" flexDirection="column" flexWrap="wrap" gap="10px">
              {newPermis?.map((perm, index) => (
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" alignItems="center" gap="2px">
                    <Typography sx={{ cursor: "pointer" }}>#{index + 1}</Typography>
                    <ButtonSquare onClick={() => handleDelitePerm(perm.id)} action="delete" />
                  </Box>
                  <Formik
                    innerRef={permisRef}
                    initialValues={initialValuesPermis}
                    onSubmit={(values) => {
                      if (permisID === "first") {
                        PermisAdd(values);
                      } else {
                        PermisPatch(values, permisID);
                      }
                    }}
                    validationSchema={validationSchemaPermis}
                  >
                    {({ values, errors, setFieldValue, touched }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" flexDirection="column" gap="20px">
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Catégorie
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>

                                <Box>
                                  <FormControl sx={{ height: "42px" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cat</InputLabel>
                                    <Select
                                      sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                      name="cat_permis"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={newPermis[index].category}
                                      label="Cat"
                                      onChange={(e) => {
                                        const updatedObject = { ...newPermis[index], category: e.target.value };
                                        const updatedList = [...newPermis.slice(0, index), updatedObject, ...newPermis.slice(index + 1)];
                                        setNewPermis(updatedList);
                                      }}
                                    >
                                      <MenuItem value="A1">A1</MenuItem>
                                      <MenuItem value="A1">A2</MenuItem>
                                      <MenuItem value="B1">B1</MenuItem>
                                      <MenuItem value="C1">C1</MenuItem>
                                      <MenuItem value="D1">D1</MenuItem>
                                      <MenuItem value="A">A</MenuItem>
                                      <MenuItem value="B">B</MenuItem>
                                      <MenuItem value="C">C</MenuItem>
                                      <MenuItem value="D">D</MenuItem>
                                      <MenuItem value="BE">BE</MenuItem>
                                      <MenuItem value="C1E">C1E</MenuItem>
                                      <MenuItem value="DE">DE</MenuItem>
                                      <MenuItem value="AM">AM</MenuItem>
                                      <MenuItem value="CE">CE</MenuItem>
                                    </Select>
                                  </FormControl>
                                  {errors.cat_permis && touched.cat_permis ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.cat_permis}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Date d’obtention
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                                    name="date_em"
                                    value={newPermis[index].obtained_date}
                                    maxDate={new Date()}
                                    onChange={(newValue) => {
                                      const updatedObject = { ...newPermis[index], obtained_date: moment(newValue).format("yyyy-MM-DD") };
                                      const updatedList = [...newPermis.slice(0, index), updatedObject, ...newPermis.slice(index + 1)];
                                      setNewPermis(updatedList);
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          fullWidth
                                          type="date"
                                          autoComplete="off"
                                          {...params}
                                          error={false}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "jj/mm/aaaa",
                                          }}
                                        />
                                      );
                                    }}
                                  ></DesktopDatePicker>
                                </LocalizationProvider>{" "}
                                {errors.date_em && touched.date_em ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.date_em}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Pièce jointe
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box width="100%" position="relative">
                                  <input
                                    style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                    type="file"
                                    id="input-permis"
                                    name="justif"
                                    onChange={(e) => {
                                      const updatedObject = { ...newPermis[index], file: e.target.files[0] };
                                      const updatedList = [...newPermis.slice(0, index), updatedObject, ...newPermis.slice(index + 1)];
                                      setNewPermis(updatedList);
                                    }}
                                  />
                                  <MyTextField setFieldValue={setFieldValue} value={newPermis[index]?.file?.name ? newPermis[index]?.file?.name : "Pièce jointe"} endAdornment="download" name="justif" placeholder="Télécharger..." />
                                  {errors.justif && touched.justif ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.justif}
                                    </Typography>
                                  ) : null}
                                </Box>
                                <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                  <Typography variant="text_clickable">Consulter le fichier</Typography>
                                  <img src={checkLinkicon} alt="link-icon" />
                                </Box>
                              </Box>
                              <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                            </Box>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              ))}
            </Box>
            <Box display="flex" flexDirection="column" flexWrap="wrap" gap="10px">
              {listePermisToCompare?.map((perm, index) => (
                <Box display="flex" flexDirection="column" gap="24px">
                  <Box display="flex" alignItems="center" gap="2px">
                    <Typography sx={{ cursor: "pointer" }}>#{index + 1}</Typography>
                    <ButtonSquare onClick={() => handleDelitePerm(perm.id)} action="delete" />
                  </Box>
                  <Formik
                    innerRef={permisRef}
                    initialValues={initialValuesPermis}
                    onSubmit={(values) => {
                      if (permisID === "first") {
                        PermisAdd(values);
                      } else {
                        PermisPatch(values, permisID);
                      }
                    }}
                    validationSchema={validationSchemaPermis}
                  >
                    {({ values, errors, setFieldValue, touched }) => (
                      <Form>
                        <Box display="flex" flexDirection="column" gap="20px">
                          <Box display="flex" flexDirection="column" gap="20px">
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Catégorie
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>

                                <Box>
                                  <FormControl sx={{ height: "42px" }} fullWidth>
                                    <InputLabel id="demo-simple-select-label">Cat</InputLabel>
                                    <Select
                                      sx={{ height: "42px", borderColor: "#DEDFE4" }}
                                      name="cat_permis"
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={listePermisToCompare[index].category}
                                      label="Cat"
                                      onChange={(e) => {
                                        const updatedObject = { ...listePermisToCompare[index], category: e.target.value };
                                        const updatedList = [...listePermisToCompare.slice(0, index), updatedObject, ...listePermisToCompare.slice(index + 1)];
                                        setListePrmisToCompare(updatedList);
                                      }}
                                    >
                                      <MenuItem value="A1">A1</MenuItem>
                                      <MenuItem value="A1">A2</MenuItem>
                                      <MenuItem value="B1">B1</MenuItem>
                                      <MenuItem value="C1">C1</MenuItem>
                                      <MenuItem value="D1">D1</MenuItem>
                                      <MenuItem value="A">A</MenuItem>
                                      <MenuItem value="B">B</MenuItem>
                                      <MenuItem value="C">C</MenuItem>
                                      <MenuItem value="D">D</MenuItem>
                                      <MenuItem value="BE">BE</MenuItem>
                                      <MenuItem value="C1E">C1E</MenuItem>
                                      <MenuItem value="DE">DE</MenuItem>
                                      <MenuItem value="AM">AM</MenuItem>
                                      <MenuItem value="CE">CE</MenuItem>
                                    </Select>
                                  </FormControl>
                                  {errors.cat_permis && touched.cat_permis ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.cat_permis}
                                    </Typography>
                                  ) : null}
                                </Box>
                              </Box>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Date d’obtention
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    components={{ OpenPickerIcon: CalendarMonthIcon }}
                                    name="date_em"
                                    value={listePermisToCompare[index].obtained_date}
                                    maxDate={new Date()}
                                    onChange={(newValue) => {
                                      const updatedObject = { ...listePermisToCompare[index], obtained_date: moment(newValue).format("yyyy-MM-DD") };
                                      const updatedList = [...listePermisToCompare.slice(0, index), updatedObject, ...listePermisToCompare.slice(index + 1)];
                                      setListePrmisToCompare(updatedList);
                                    }}
                                    renderInput={(params) => {
                                      return (
                                        <TextField
                                          fullWidth
                                          type="date"
                                          autoComplete="off"
                                          {...params}
                                          error={false}
                                          inputProps={{
                                            ...params.inputProps,
                                            placeholder: "jj/mm/aaaa",
                                          }}
                                        />
                                      );
                                    }}
                                  ></DesktopDatePicker>
                                </LocalizationProvider>{" "}
                                {errors.date_em && touched.date_em ? (
                                  <Typography color="red" fontSize="12px">
                                    {errors.date_em}
                                  </Typography>
                                ) : null}
                              </Box>
                            </Box>
                            <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
                              <Box width="100%">
                                <Box marginBottom="6px">
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                                    Pièce jointe
                                  </Typography>
                                  <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                                    *
                                  </Typography>
                                </Box>
                                <Box width="100%" position="relative">
                                  <input
                                    style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                    type="file"
                                    id="input-permis"
                                    name="justif"
                                    onChange={(e) => {
                                      const updatedObject = { ...listePermisToCompare[index], file: e.target.files[0] };
                                      const updatedList = [...listePermisToCompare.slice(0, index), updatedObject, ...listePermisToCompare.slice(index + 1)];
                                      setListePrmisToCompare(updatedList);
                                    }}
                                  />
                                  <MyTextField setFieldValue={setFieldValue} value={listePermisToCompare[index]?.file?.name ? listePermisToCompare[index]?.file?.name : "Pièce jointe"} endAdornment="download" name="justif" placeholder="Télécharger..." />
                                  {errors.justif && touched.justif ? (
                                    <Typography color="red" fontSize="12px">
                                      {errors.justif}
                                    </Typography>
                                  ) : null}
                                </Box>
                                <Box marginTop="2px" alignItems="center" display="flex" gap="7px">
                                  <Typography variant="text_clickable">Consulter le fichier</Typography>
                                  <img src={checkLinkicon} alt="link-icon" />
                                </Box>
                              </Box>
                              <Box width="100%" sx={{ display: { xs: "none", lg: "inherit", md: "inherit", sm: "inherit" } }}></Box>
                            </Box>
                            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                              {progresssPerm !== 0 && (
                                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                                  <CircularProgress />
                                  <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                                    {progresssPerm}%
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              ))}
              <Box>
                <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                  <Button disabled={progresssPerm !== 0 ? true : false} onClick={handlePrevious} variant="mj_secondary_md">
                    Précédent
                  </Button>
                  <Button disabled={progresssPerm !== 0 ? true : false} onClick={executeSave} variant="mj_primary_md">
                    Enregistrer
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {loading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default OnBoardinginfosAdmin;
