import React, { useState } from "react";
import BoxContainer from "../../../ui/BoxContainer";
import ButtonSquare from "../../../ui/ButtonSquare";
import { Box, Button, Divider, Modal, Typography, InputAdornment, TextField, Autocomplete } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import { modalStyle } from "../../../../theme/ModalStyle";
import { Formik, Form } from "formik";
import MyTextField from "../../../ui/MyTextField";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";

import { useRef } from "react";
import * as Yup from "yup";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

import { Urls } from "../../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
function Metier({ updateBord, setUpdateBoard }) {
  const [open, setOpen] = useState(false);
  const formikRef = useRef();
  const [change, setChange] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [disabledMetier, setDisabledMetier] = useState(false);
  const [metierID, setMetierID] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  //function to handle when click on the modify icon
  const handleModifyClick = (met) => {
    const metier = {
      id: met.id,
      name: met.name,
    };
    setInitialValues({
      titre: metier,
      anneeExp: met.experience_years,
    });
    setOpen(true);
    setDisabledMetier(true);
    setMetierID(met.id);
  };
  //function to handle when click on the add icon
  const handleAddClick = () => {
    setInitialValues({
      anneeExp: "",
    });
    setOpen(true);
    setDisabledMetier(false);
  };
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
    anneeExp: Yup.number().typeError("Valeur invalide").min(0, "Valeur invalide").required("Ce champ est obligatoire"),
  });
  //validation schema fot the update form
  const validationSchemaUpdate = Yup.object().shape({
    anneeExp: Yup.number().typeError("Valeur invalide").min(0, "Valeur invalide").required("Ce champ est obligatoire"),
  });
  const [initialValues, setInitialValues] = useState({
    titre: "",
    anneeExp: "",
  });
  const [metier, setMetier] = useState([]);
  //ajouter un nouveau metier
  const metierAdd = (values) => {
    //console.log(values);
    Api.post(
      Urls?.ADD_USER_METIER + user?.user?.id + "/metiers",
      {
        metier_id: values?.titre,
        experience_years: values.anneeExp,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setUpdateBoard(!updateBord);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //modifier un metier
  const metierUpdate = (values) => {
    //console.log(values);
    Api.patch(
      Urls?.ADD_USER_METIER + user?.user?.id + "/metiers/" + metierID,
      {
        experience_years: values.anneeExp,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpen(false);
        setUpdateBoard(!updateBord);
        setMetierID();
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  //search metier
  const [metierListe, setMetierListe] = useState([]);
  const searchMetier = (value) => {
    Api.get(Urls.GET_METIER_AUTO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        //console.log(res, "search resposne");
        setMetierListe(res?.data?.metiers);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //Fetch user metier
  const [data, setData] = useState();
  useEffect(() => {
    Api.get(Urls.GET_USER_METIER + user?.user?.id + "/metiers", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 3,
      },
    })
      .then((res) => {
        setMetier(res?.data?.metiers);
        //console.log(res);
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  //handle pagination
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 3,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setMetier(metier.concat(res?.data?.metiers));
        setData(res?.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };
  //supprimer un metier
  const deleteMetier = () => {
    Api.delete(Urls.DELETE_USER_METIER + user?.user?.id + "/metiers/" + metierID, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setMetierID();
        setOpenDelete(false);
        setUpdateBoard(!updateBord);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box position="relative">
      <BoxContainer display="flex" flexDirection="column">
        <ToastContainer />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="text_xxl" fontWeight="700">
            Métier
          </Typography>
          <Box>
            <ButtonSquare onClick={handleAddClick} action="add" />
          </Box>
        </Box>
        <Divider sx={{ margin: "15px 0px" }} />
        <Box display="flex" flexDirection="column" gap="20px">
          {metier.map((met) => (
            <Box key={met.id} display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                <Typography fontWeight="600" fontSize="14px" lineHeight="160%">
                  {met.name}
                </Typography>
                <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#666D83">
                  {met.experience_years} Ans
                </Typography>
              </Box>
              <Box display="flex">
                <ButtonSquare onClick={() => handleModifyClick(met)} action="modify" />
                <ButtonSquare
                  onClick={() => {
                    setOpenDelete(true);
                    setMetierID(met.id);
                  }}
                  action="delete"
                />
              </Box>
            </Box>
          ))}
        </Box>
        {data?.links?.next && (
          <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
            <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
          </Box>
        )}
      </BoxContainer>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Métier
              </Typography>
              <Box display="flex" alignItems="center" gap="10px">
                <ButtonSquare onClick={() => setOpen(false)} action="close" />
              </Box>
            </Box>
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (metierID) {
                  metierUpdate(values);
                } else {
                  metierAdd(values);
                }
              }}
              validationSchema={metierID ? validationSchemaUpdate : validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box>
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Titre
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          fullWidth
                          disabled={disabledMetier}
                          id="combo-box-demo"
                          value={values.titre}
                          onChange={(e, value) => {
                            //console.log(value);
                            if (value) {
                              setFieldValue("titre", value?.id);
                            }
                          }}
                          options={metierListe}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              {...params}
                              variant="outlined"
                              value={values?.titre}
                              onChange={(e) => {
                                if (e.target.value.length > 3) {
                                  searchMetier(e.target.value);
                                } else {
                                  setMetierListe([]);
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Métier..."
                            />
                          )}
                        />
                        <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px"></Box>
                      </Box>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Années d'expérience
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <MyTextField setFieldValue={setFieldValue} value={values.anneeExp} name="anneeExp" placeholder="Années d'expérience..." />
                        {errors.anneeExp && touched.anneeExp ? (
                          <Typography color="red" fontSize="12px">
                            {errors.anneeExp}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpen(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openDelete} onClose={() => setOpenDelete(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Supprimer un métier
              </Typography>
              <ButtonSquare onClick={() => setOpenDelete(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir supprimer ce métier</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenDelete(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={deleteMetier} variant="mj_danger_md">
                  Supprimer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Metier;
