import React from "react";

import { modalStyle } from "../../../../theme/ModalStyle";
import { Box, Button, Typography, SvgIcon, Modal, CircularProgress, Menu, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";

import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";

import MyChip from "../../../../components/ui/MyChip";
import ButtonSquare from "../../../../components/ui/ButtonSquare";

import OfferDetailsContent from "./OfferDetailsContent";
import ProfileProp from "../ProfileProp";
import Embaucher from "../Embaucher";
import ContratsM from "../ContratsM";
import DeclarerHeures from "../DeclarerHeures";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { toast } from "react-toastify";

const chipSelector = (status) => {
  switch (status) {
    case 1:
      return { variant: "active_offer", label: "Active" };
    case 0:
      return { variant: "ended_offer", label: "Terminée" };
    default:
      return {};
  }
};

function OfferDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [openActive, setOpenActive] = useState(false);
  const [openCloturer, setOpenCloturer] = useState(false);

  const jobTitle = location?.state?.title;
  const postedSince = location?.state?.postedSince;
  const status = location?.state?.status;
  const [applicationOpen, setApplicationOpen] = useState();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [selectedTab, setSelectedTab] = useState(1);
  const tabs = [
    {
      id: 1,
      name: "Détails de l’offre",
      icon: "📋",
    },
    {
      id: 2,
      name: "Profils proposés",
      icon: "📨",
    },
    {
      id: 3,
      name: "Embauchés",
      icon: "🤝",
    },
    {
      id: 4,
      name: "Contrats",
      icon: "📝",
    },
    {
      id: 5,
      name: "Relevé d’heure",
      icon: "⏰",
    },
  ];
  const [detailOffre, setDetailOffre] = useState({});
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(true);
  // get detail offer by ID AND PEFECT CANDIDATURES
  useEffect(() => {
    Api.get(`${Urls.GET_DETAILS_OFFRE}${id}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "data from el gharbi");
        setDetailOffre(res?.data?.offer);
        setApplicationOpen(res?.data?.offer?.is_application_open);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [change]);

  const contentSelector = (contentId = 1) => {
    switch (contentId) {
      case 1:
        return <OfferDetailsContent id={id} />;
      case 2:
        return <ProfileProp id={id} />;
      case 3:
        return <Embaucher id={id} />;
      case 4:
        return <ContratsM id={id} />;
      case 5:
        return <DeclarerHeures id={id} />;
      default:
        return <OfferDetailsContent id={id} />;
    }
  };
  const [openfermer, setOpenFermer] = useState(false);
  const [openouvrir, setOpenOuvrir] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  //fermer le recrutement
  const FermerRecrutement = (etat) => {
    Api.patch(
      Urls.SUSPEND_OFFER + id + "/is_application_open",
      {
        is_application_open: etat,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenFermer(false);
        setOpenOuvrir(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.resposne?.data?.message);
      });
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const navigateRepOffre = () => {
    navigate("/entreprise/offres/republier-offre", { state: { id: id } });
  };
  //accept offer
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIVATE_DEACTIVATE_DEMANDE + id + "/activate-or-deactivate",
      {
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res.data);
        setOpenCloturer(false);
        toast.success(res?.data?.message);
        setOpenActive(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* header */}
      <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", gap: "16px" }}>
        {/* left*/}
        <Box display="flex" flexDirection="column" gap="6px">
          <Box>
            <Typography variant="text_xl" fontWeight="700">
              {jobTitle} #{id}
            </Typography>
          </Box>
          <Box display="flex" flexDirection="row" gap="10px">
            <MyChip variant={chipSelector(status).variant} label={chipSelector(status).label} />
            <Typography variant="text_normal" color="#4A526C">
              {postedSince}
            </Typography>
          </Box>
        </Box>

        {/* right*/}
        <Box display="flex" flexDirection="row" gap="10px">
          {loading ? (
            <Box>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {applicationOpen ? (
                <Button onClick={() => setOpenFermer(true)} variant="mj_red_md" sx={{ height: "42px", display: "flex", gap: "6px" }}>
                  Fermer le recrutement
                </Button>
              ) : (
                <Button onClick={() => setOpenOuvrir(true)} variant="mj_green_md" sx={{ height: "42px", display: "flex", gap: "6px" }}>
                  ouvrir le recrutement
                </Button>
              )}
            </Box>
          )}
          <Box>
            {detailOffre?.is_active === 1 && (
              <Button onClick={() => setOpenCloturer(true)} variant="mj_secondary_md" sx={{ height: "42px" }}>
                Clôturer la demande
              </Button>
            )}
            {detailOffre?.is_active === 0 && (
              <Button onClick={() => setOpenActive(true)} variant="mj_secondary_md" sx={{ height: "42px" }}>
                Activer la demande
              </Button>
            )}
          </Box>

          <ButtonSquare onClick={handleOpenUserMenu} action="list"></ButtonSquare>
          <Menu
            sx={{ mt: "50px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }} onClick={() => navigateRepOffre()}>
              <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                Réutiliser la demande
              </Typography>
            </MenuItem>
            <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
              <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400", color: "lightgray" }}>
                Supprimer la demande
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>

      {/* tabs */}
      <Box display="flex" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px">
        {tabs.map((tab, index) => (
          <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
            <Typography fontSize="20px" mr>
              {tab.icon}
            </Typography>
            {tab.name}
          </Button>
        ))}
      </Box>

      {/* content */}
      {contentSelector(selectedTab)}
      <Modal
        open={openfermer}
        onClose={() => {
          setOpenFermer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Fermer le recrutement
              </Typography>
              <ButtonSquare onClick={() => setOpenFermer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir fermer le recrutement</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenFermer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => FermerRecrutement(false)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openouvrir}
        onClose={() => {
          setOpenOuvrir(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Ouvrir le recrutement
              </Typography>
              <ButtonSquare onClick={() => setOpenOuvrir(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir ouvrir le recrutement</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenOuvrir(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => FermerRecrutement(true)} variant="mj_green_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openCloturer}
        onClose={() => {
          setOpenCloturer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Clotuer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenCloturer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir cloturer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenCloturer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(0)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openActive}
        onClose={() => {
          setOpenActive(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Activer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenActive(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir activer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenActive(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(1)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default OfferDetails;
