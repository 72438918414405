import React from "react";
import BoxContainer from "./BoxContainer";
import iconPoint from "../../imgs/imgV2/point.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import { Avatar, Box, Button, Modal, Rating, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import iconContrat from "../../imgs/imgV2/contratBlueIcon.svg";
import endedIcon from "../../imgs/imgV2/ended.svg";
import iconComing from "../../imgs/imgV2/calendarIcon.svg";
import logoEntreprise from "../../imgs/imgV2/entrepriseLogo.png";
import MyChip from "./MyChip";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useState } from "react";
import { modalStyle, modalStyle2 } from "../../theme/ModalStyle";
import ButtonSquare from "./ButtonSquare";
import { colors } from "../../theme/Colors";
import { Field, Form, Formik } from "formik";
import MyTextField from "./MyTextField";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import DetailsProfileInterim from "../../pages/entreprise/content/DetailsProfileInterim";
function EmbaucherComponent({ idOffer, userF, setChange, change }) {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [openNoter, setOpenNoter] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [openProfile, setOpenProfile] = useState(false);
  const rateInterim = (values) => {
    Api.post(
      Urls.RATE_INTERIM,
      {
        interim_id: userF?.id,
        rating_scale: ratingValue,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpenNoter(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.resposne?.data?.message);
      });
  };
  //console.log(openProfile, "the state of open profile");
  return (
    <Box sx={{ ":hover": { filter: "brightness(0.95)", background: "white" } }}>
      <BoxContainer sx={{ cursor: "pointer" }}>
        <ToastContainer />
        <Box onClick={() => setOpenProfile(true)} sx={{ cursor: "pointer" }}>
          <Box display="flex" alignItems="flex-start" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="40px">
              {userF?.url_avatar ? (
                <img src={userF?.url_avatar} alt="userF-pic" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
              ) : (
                <Avatar variant="rounded" sx={{ borderRadius: "50%", height: "100px", width: "100px" }}>
                  <PersonIcon sx={{ fontSize: 80 }} />
                </Avatar>
              )}
              <Box>
                {userF?.matching_level !== "weak" && <MyChip variant={userF?.matching_level === "perfect" ? "matching_parfait" : "matching_satisfaisant"} label={userF?.matching_level === "perfect" ? "🥇 Matching parfait" : "👌 Matching satisfaisant"} />}
                <Typography sx={{ lineHeight: "28.8px", fontSize: "18px", fontWeight: "600", cursor: "pointer" }}>
                  {userF?.first_name} {userF?.last_name}
                </Typography>
                {userF?.metier_name && <Typography sx={{ lineHeight: "22.4px", fontSize: "14px", fontWeight: "500" }}>{userF?.metier_name}</Typography>}
              </Box>
            </Box>
            <Box display="flex" alignItems="center" gap="10px">
              {!userF?.rating_scale && (
                <Button onClick={() => setOpenNoter(true)} variant="mj_secondary_md">
                  Noter
                </Button>
              )}

              {userF?.contracts_status === "ENDED" && <MyChip icon={endedIcon} variant="ended_offer" label="terminer" />}
              {userF?.contracts_status === "EXIST_UPCOMING_CONTRACT" && <MyChip icon={iconContrat} variant="skills" label="Contrat à venir" />}
              {userF?.contracts_status === "EXIST_CURRENT_CONTRACT" && <MyChip icon={iconComing} variant="contrat_en_cours" label="Contrat en cours" />}
            </Box>
          </Box>
          <Box display="flex" gap="40px">
            <Box width="100px"></Box>
            <Box marginBottom="20px" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "row" }, alignItems: { sm: "inherit", xs: "inherit", md: "center", lg: "center" } }} gap="8px">
              {userF?.rating_scale && <Rating sx={{ color: colors.main }} name="half-rating-read" defaultValue={userF?.rating_scale} readOnly />}
              {(userF?.metier_experience_years || userF?.hours_worked) && (
                <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                  <img src={iconPoint} alt="icon-point" />
                </Box>
              )}
              <Box>{userF?.metier_experience_years && <Typography>🛠 {userF?.metier_experience_years} ans d’expérience</Typography>}</Box>
              {userF?.metier_experience_years && (
                <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                  <img src={iconPoint} alt="icon-point" />
                </Box>
              )}
              <Box>
                <Typography>⏰️ {userF?.hours_worked ? userF?.hours_worked : 0} heures travaillées avec vous</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={openNoter} onClose={() => setOpenNoter(false)}>
        <Box sx={modalStyle}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                Noter la mission
              </Typography>
              <ButtonSquare action="close" onClick={() => setOpenNoter(false)} />
            </Box>
            <Box marginBottom="20px" display="flex" alignItems="center" gap="20px">
              {userF?.url_avatar ? (
                <img src={userF?.url_avatar} alt="userF-pic" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
              ) : (
                <Avatar variant="rounded" sx={{ borderRadius: "50%", height: "80px", width: "80px" }}>
                  <PersonIcon sx={{ fontSize: 80 }} />
                </Avatar>
              )}
              <Box>
                {userF?.matching_level !== "weak" && <MyChip variant={userF?.matching_level === "perfect" ? "matching_parfait" : "matching_satisfaisant"} label={userF?.matching_level === "perfect" ? "🥇 Matching parfait" : "👌 Matching satisfaisant"} />}
                <Typography sx={{ lineHeight: "28.8px", fontSize: "18px", fontWeight: "600", cursor: "pointer" }}>
                  {userF?.first_name} {userF?.last_name}
                </Typography>
                {userF?.metier_name && <Typography sx={{ lineHeight: "22.4px", fontSize: "14px", fontWeight: "500" }}>{userF?.metier_name}</Typography>}
              </Box>
            </Box>
            <Box>
              <Formik
                initialValues={{
                  comments: "",
                }}
                onSubmit={(values) => {
                  rateInterim(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    {" "}
                    <Box marginBottom="20px">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Sélectionner une note
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline" color={colors.main}>
                          *
                        </Typography>
                      </Box>
                      <Rating
                        precision={1}
                        name="simple-controlled"
                        sx={{ color: colors.main }}
                        value={ratingValue}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                      />
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenNoter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={ratingValue !== 0 ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={openProfile} onClose={() => setOpenProfile(false)}>
        <Box sx={modalStyle2}>
          <Box padding="24px">
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenProfile(false)} action="close" />
                <Typography variant="text_xxl" fontWeight="700">
                  Profile #{userF?.id}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap="10px">
                {!userF?.rating_scale && (
                  <Button onClick={() => setOpenNoter(true)} variant="mj_secondary_md">
                    Noter
                  </Button>
                )}
                {userF?.contracts_status === "ENDED" && <MyChip icon={endedIcon} variant="ended_offer" label="terminer" />}
                {userF?.contracts_status === "EXIST_FUTURE_CONTRACT" && <MyChip icon={iconContrat} variant="skills" label="Contrat à venir" />}
                {userF?.contracts_status === "EXIST_ONGOING_CONTRACT" && <MyChip icon={iconComing} variant="contrat_en_cours" label="Contrat en cours" />}
              </Box>
            </Box>
            <DetailsProfileInterim type="embauched" idOffer={idOffer} id={userF?.id} />
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default EmbaucherComponent;
