import React from "react";
import { colors } from "../../../../theme/Colors";
import { Autocomplete, Divider, Box, InputAdornment, Button, Typography, IconButton, Modal, TextField, InputLabel, Grid, MenuItem, FormControlLabel, formLabelClasses, Chip, Checkbox } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { debounce } from "lodash";

import styled from "@emotion/styled";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import FormStepper from "../../../../components/ui/FormStepper";
import FormStep from "../../../../components/ui/FormStep";

import BoxContainerModal from "../../../../components/ui/BoxContainerModal";
import { modalStyle } from "../../../../theme/ModalStyle";
import { ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { Icon } from "@mui/material";
import Trash from "../../../../imgs/trash.svg";
import { useNavigate } from "react-router-dom";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import MyChip from "../../../../components/ui/MyChip";
import OmjDatePicker from "../../../../components/ui/OmjDatePicker";
import AddIcon from "../../../../imgs/imgV2/plusIcon.svg";
import MySwitch from "../../../../components/ui/MySwitch/MySwitch";
import TimeInput from "../../../../components/ui/TimeInput";
import RightArrowTime from "../../../../imgs/imgV2/RightArrowTime.svg";
import RightArrowTimeOrange from "../../../../imgs/imgV2/RightArrowTimeOrange.svg";
import XIconTime from "../../../../imgs/imgV2/XIconTime.svg";
import moment from "moment/moment";
import BoxContainer from "../../../../components/ui/BoxContainer";
import { toast, ToastContainer } from "react-toastify";
import MapComponent from "../../../../components/mapComponent/MapComponent";
import { useMemo } from "react";
import axios from "axios";

const BlackContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#000B30", padding: "24px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const GrayContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#F5F5F7", padding: "4px 4px 1px 4px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const ItemContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "9px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

const ItemContainerAgenda = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "18px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

function formatDate(date) {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") + " " + [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":");
}

const TrashIcon = () => (
  <Icon sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <img src={Trash} height={16} width={16} />
  </Icon>
);

const Label = styled(InputLabel)({
  color: `${colors.textDark}`,
  fontSize: "0.875rem",
  marginBottom: "0.5rem",
});

const MyTextField = styled(TextField)({
  width: "100%",
});

const ErrorText = styled(Typography)({
  color: `${colors.mj_error}`,
  fontSize: "0.875rem",
});

function formatDateFront(date) {
  moment.locale("fr");
  return moment(date)
    .format("DD MMMM YYYY")
    .replace(/\b\w/g, (match) => match.toUpperCase());
}

function isEqualDate(date1, date2) {
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

function CreerOffres() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const enterprise_id = user?.user?.enterprise?.id;

  const navigate = useNavigate();

  //time
  const [time, setTime] = useState("");

  const handleTimeChange = (newTime) => {
    setTime(newTime);
  };

  const stepOneRef = useRef();

  //states
  const [secteurs, setSecteurs] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [villes, setVilles] = useState([]);
  const [metiers, setMetiers] = useState([]);
  const [diplomes, setDiplomes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [equipmentsProvided, setEquipmentsProvided] = useState([]);
  const [equipmentsToProvide, setEquipmentsToProvide] = useState([]);
  const [formikValues, setFormikValues] = useState(""); //all form values
  const [equipements, setEquipements] = useState([]);
  const [checkedExperienceSouhaite, setCheckedExperienceSouhaite] = useState(false);
  const [checkedDiplomeObligatoire, setCheckedDiplomeObligatoire] = useState(false);
  const [checkedDiplomeSouhaite, setCheckedDiplomeSouhaite] = useState(false);
  const [panierIsntance, setPanierInstance] = useState({ name: null, value: null });
  const [isAddingPanier, setIsAddingPanier] = useState(false);
  const [errorPanier, setErrorPanier] = useState("");
  const [adresse, setAdresse] = useState("");
  const [adresses, setAdresses] = useState([]);
  const [selectedAdresse, setselectedAdresse] = useState({
    id: "",
    display_name: "",
  });
  const [openAd, setOpenAd] = useState(false);

  const [position, setPosition] = useState([48.864716, 2.349014]);
  const [zoom_level, setZoom_level] = useState(15);

  //switchs
  const [checkedLundi, setCheckedLundi] = useState(false);
  const [hasShiftLundi, setHasShiftLundi] = useState(false);

  const [checkedMardi, setCheckedMardi] = useState(false);
  const [hasShiftMardi, setHasShiftMardi] = useState(false);

  const [checkedMercredi, setCheckedMercredi] = useState(false);
  const [hasShiftMercredi, setHasShiftMercredi] = useState(false);

  const [checkedJeudi, setCheckedJeudi] = useState(false);
  const [hasShiftJeudi, setHasShiftJeudi] = useState(false);

  const [checkedVendredi, setCheckedVendredi] = useState(false);
  const [hasShiftVendredi, setHasShiftVendredi] = useState(false);

  const [checkedSamedi, setCheckedSamedi] = useState(false);
  const [hasShiftSamedi, setHasShiftSamedi] = useState(false);

  const [checkedDimanche, setCheckedDimanche] = useState(false);
  const [hasShiftDimanche, setHasShiftDimanche] = useState(false);

  //agenda mapper
  const agendaMapper = [

    {
      id: 3,
      name_en: "monday",
      name: "lundi",
      checked: checkedLundi,
      setChecked: setCheckedLundi,
      hasShift: hasShiftLundi,
      setHasShift: setHasShiftLundi,
    },
    {
      id: 4,
      name_en: "tuesday",
      name: "mardi",
      checked: checkedMardi,
      setChecked: setCheckedMardi,
      hasShift: hasShiftMardi,
      setHasShift: setHasShiftMardi,
    },
    {
      id: 5,
      name_en: "wednesday",
      name: "mercredi",
      checked: checkedMercredi,
      setChecked: setCheckedMercredi,
      hasShift: hasShiftMercredi,
      setHasShift: setHasShiftMercredi,
    },
    {
      id: 6,
      name_en: "thursday",
      name: "jeudi",
      checked: checkedJeudi,
      setChecked: setCheckedJeudi,
      hasShift: hasShiftJeudi,
      setHasShift: setHasShiftJeudi,
    },
    {
      id: 7,
      name_en: "friday",
      name: "vendredi",
      checked: checkedVendredi,
      setChecked: setCheckedVendredi,
      hasShift: hasShiftVendredi,
      setHasShift: setHasShiftVendredi,
    },
    {
      id: 1,
      name_en: "saturday",
      name: "samedi",
      checked: checkedSamedi,
      setChecked: setCheckedSamedi,
      hasShift: hasShiftSamedi,
      setHasShift: setHasShiftSamedi,
    },
    {
      id: 2,
      name_en: "sunday",
      name: "dimanche",
      checked: checkedDimanche,
      setChecked: setCheckedDimanche,
      hasShift: hasShiftDimanche,
      setHasShift: setHasShiftDimanche,
    },
  ];

  //metiers
  const [selectedMetier, setSelectedMetier] = useState(null);
  const [searchedMetier, setSearchedMetier] = useState(null);
  //skills
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedSkillsIDS, setSelectedSkillsIDS] = useState([]);
  const [searchedSkills, setSearchedSkills] = useState(null);
  const [selectedSkillValue, setSelectedSkillValue] = useState(null);

  //diplomes
  const [selectedDiplomes, setSelectedDiplomes] = useState([]);
  const [selectedDiplomesIDS, setSelectedDiplomesIDS] = useState([]);
  const [searchedDiplomes, setSearchedDiplomes] = useState(null);
  const [selectedDiplomeValue, setSelectedDiplomeValue] = useState(null);

  //certifications
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [selectedCertificationsIDS, setSelectedCertificationsIDS] = useState([]);
  const [searchedCertifications, setSearchedCertifications] = useState(null);
  const [selectedCertificationValue, setSelectedCertificationValue] = useState(null);

  //equipements à fournir
  const [selectedEquipmentsToProvide, setSelectedEquipmentsToProvide] = useState([]);
  const [selectedEquipmentsToProvideIDS, setSelectedEquipmentsToProvideIDS] = useState([]);
  const [searchedEquipmentsToProvide, setSearchedEquipmentsToProvide] = useState(null);
  const [selectedEquipmentToProvideValue, setSelectedEquipmentToProvideValue] = useState(null);

  //equipements fournis
  const [selectedEquipmentsProvided, setSelectedEquipmentsProvided] = useState([]);
  const [selectedEquipmentsProvidedIDS, setSelectedEquipmentsProvidedIDS] = useState([]);
  const [searchedEquipmentsProvided, setSearchedEquipmentsProvided] = useState(null);
  const [selectedEquipmentProvidedValue, setSelectedEquipmentProvidedValue] = useState(null);

  const handleChangeSwitchExperience = (event) => {
    setCheckedExperienceSouhaite(event.target.checked);
  };
  const handleChangeSwitchDiplomeSouhaite = (event, form) => {
    setCheckedDiplomeSouhaite(event.target.checked);
    form?.setFieldValue("isDiplomeSouhaite", checkedDiplomeSouhaite);
  };
  const handleChangeSwitchDiplomeObligatoire = (event) => {
    setCheckedDiplomeObligatoire(event.target.checked);
  };

  const [paniersLocal, setPaniersLocal] = useState([]);
  const [openAddPanier, setOpenAddPanier] = useState();

  const handleDonePanier = (form) => {
    const name = form.values.nomPanier;
    const value = form.values.montantPanier;
    //console.log("Name", name);
    //console.log("value", value);

    if (form.values.nomPanier === "") {
      setErrorPanier("Le nom du panier est obligatoire");
    }

    if (form.values.montantPanier === "") {
      setErrorPanier("Le montant est obligatoire");
    }

    if (form.values.panier.find((item) => item.name === name)) {
      //console.log("TRUE PANIER EXISTS");
      setErrorPanier("Le nom du panier doit être unique");
    } else {
      //console.log("FALSE PANIER DOESNT EXISTS NO DUPLICATE");
      form.values.panier.push({ name: name, value: value });
      setPaniersLocal((oldArray) => [...oldArray, { name, value }]);
      setIsAddingPanier(false);
      setErrorPanier("");
    }
  };

  const deletePanier = (form, panier) => {
    //console.log("FORM DELETE PANIER", form);
    //console.log("PANIER DELETE PANIER NAME", panier.name);
    //console.log("SETFORMIKVALUES DELETE PANIER", setFormikValues);
    //console.log("FORMIK VALUES", formikValues.panier);

    form?.setFieldValue(
      "panier",
      form?.values?.panier?.filter((pform) => pform.name !== panier.name)
    );
    setFormikValues(formikValues.panier.filter((fp) => fp.name !== panier.name));
  };

  //get Metiers SEARCH
  useEffect(() => {
    //console.log("GETTIN METIERS SEARCH ///////////:::::::::::");
    if (searchedMetier) {
      //console.log("GETTIN METIERS PAR ID ///////////:::::::::::");
      //console.log("searched metier ///////////////", searchedMetier);
      Api.get(Urls.GET_METIERS_SEARCH + `?search=${searchedMetier}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH METIER ::::::::::::::", response);
          setMetiers(response?.data?.metiers);
        }
      });
    }
  }, [searchedMetier]);

  //get competences
  useEffect(() => {
    //console.log("GETTIN compétences SEARCH ///////////:::::::::::");
    if (searchedSkills) {
      //console.log("search skill ///////////////", searchedSkills);
      Api.get(Urls.GET_SKILLS + `?search=${searchedSkills}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH Compétences ::::::::::::::", response);
          setSkills(response?.data?.skills);
        }
      });
    }
  }, [searchedSkills]);

  //get diplomes
  useEffect(() => {
    //console.log("GETTIN diplomes SEARCH ///////////:::::::::::");
    if (searchedDiplomes) {
      //console.log("search Diplomes ///////////////", searchedDiplomes);
      Api.get(Urls.SEARCH_SCHOOLING + `?search=${searchedDiplomes}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH diplomes ::::::::::::::", response);
          setDiplomes(response?.data?.school_document_types);
        }
      });
    }
  }, [searchedDiplomes]);

  //get certifications
  useEffect(() => {
    //console.log("GETTIN certifications SEARCH ///////////:::::::::::");
    if (searchedCertifications) {
      //console.log("search certifications ///////////////", searchedCertifications);
      Api.get(Urls.GET_CERTIFICATIONS_SEARCH + `?search=${searchedCertifications}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH certifications ::::::::::::::", response);
          setCertifications(response?.data?.certificate_document_types);
        }
      });
    }
  }, [searchedCertifications]);

  //get equipments to provide
  useEffect(() => {
    //console.log("GETTIN certifications SEARCH ///////////:::::::::::");
    if (searchedEquipmentsToProvide) {
      //console.log("search EquipmentsToProvide ///////////////", searchedEquipmentsToProvide);
      Api.get(Urls.GET_EQUIPMENTS_SEARCH + `?search=${searchedEquipmentsToProvide}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH Equipments To Provide ::::::::::::::", response);
          setEquipmentsToProvide(response?.data?.equipment);
        }
      });
    }
  }, [searchedEquipmentsToProvide]);

  //get equipments provided
  useEffect(() => {
    //console.log("GETTIN equipments provided SEARCH ///////////:::::::::::");
    if (searchedEquipmentsProvided) {
      //console.log("search EquipmentsProvided ///////////////", searchedEquipmentsProvided);
      Api.get(Urls.GET_EQUIPMENTS_SEARCH + `?search=${searchedEquipmentsProvided}`, {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }).then((response) => {
        if (response?.status === 200) {
          //console.log("RESPONSE SEARCH EquipmentsProvided ::::::::::::::", response);
          setEquipmentsProvided(response?.data?.equipment);
        }
      });
    }
  }, [searchedEquipmentsProvided]);

  const [openModifyStep1, setOpenModifyStep1] = useState(false);
  const [openModifyStep2, setOpenModifyStep2] = useState(false);
  const [openModifyStep3, setOpenModifyStep3] = useState(false);

  //initial values
  const initialValues = {
    //step1
    intitulePoste: "",
    // secteurActivite: "",
    metier: "",
    metier_name: "",
    lieu: "",
    latitude: 48.864716,
    longitude: 2.349014,
    // departement: "",
    // ville: "",

    //step2
    experienceSouhaite: "",
    certifications: [],
    certifications_names: [],
    equipementFournis: [],
    equipementFournis_names: [],
    equipementAFournir: [],
    equipementAFournir_names: [],
    description: "",
    diplomes: [],
    diplomes_names: [],
    skills: [],
    skills_names: [],

    //step3
    start_date_time: "",
    end_date_time: "",
    panier: [],
    tauxDeBase: "",
    tauxWeekend: "",
    tauxNuit: "",
    tauxIntemperie: "",

    start_time_lundi: "",
    end_time_lundi: "",
    start_time_lundi_2: "",
    end_time_lundi_2: "",

    start_time_mardi: "",
    end_time_mardi: "",
    start_time_mardi_2: "",
    end_time_mardi_2: "",

    start_time_mercredi: "",
    end_time_mercredi: "",
    start_time_mercredi_2: "",
    end_time_mercredi_2: "",

    start_time_jeudi: "",
    end_time_jeudi: "",
    start_time_jeudi_2: "",
    end_time_jeudi_2: "",

    start_time_vendredi: "",
    end_time_vendredi: "",
    start_time_vendredi_2: "",
    end_time_vendredi_2: "",

    start_time_samedi: "",
    end_time_samedi: "",
    start_time_samedi_2: "",
    end_time_samedi_2: "",

    start_time_dimanche: "",
    end_time_dimanche: "",
    start_time_dimanche_2: "",
    end_time_dimanche_2: "",

    //ensure time stays in the form when pressing next/previous
    is_samedi: checkedSamedi,
    is_dimanche: checkedDimanche,
    is_lundi: checkedLundi,
    is_mardi: checkedMardi,
    is_mercredi: checkedMercredi,
    is_jeudi: checkedJeudi,
    is_vendredi: checkedVendredi,

    //helperfield
    isPanier: false,
    nomPanier: "",
    montantPanier: "",
  };

  // validation method for start_time end_time
  const isStartTimeBeforeEndTime = (start, end) => {
    // skip validation if fields are empty
    if (!start || !end) return true;

    const startTime = start.split(":");
    const endTime = end.split(":");

    const startHour = parseInt(startTime[0]);
    const startMinute = parseInt(startTime[1]);

    const endHour = parseInt(endTime[0]);
    const endMinute = parseInt(endTime[1]);

    if (startHour < endHour) return true;
    if (startHour === endHour && startMinute < endMinute) return true;

    return false;
  };

  //validation schemas
  Yup.addMethod(Yup.array, "unique", function (message) {
    return this.test("unique", message, function (list) {
      const mapper = (x) => x.name;
      const set = [...new Set(list.map(mapper))];
      const isUnique = list.length === set.length;
      if (isUnique) {
        return true;
      }

      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
      return this.createError({
        path: `[${idx}].name`,
        message: message,
      });
    });
  });

  const ValidationSchemaModify = Yup.object({
    intitulePoste: Yup.string().required("Ce champ est obligatoire").max(100, "Ce champ doit pas dépasser 100 caractère"),
    metier: Yup.string().required("Ce champ est obligatoire"),
    lieu: Yup.string().required("Ce champ est obligatoire"),
    latitude: Yup.string(),
    longitude: Yup.string(),

    //helpers revisions
    metier_name: Yup.string(),

    experienceSouhaite: Yup.number().typeError("Ce champ doit être numérique").min(0, "Expérience doit être valide"), //.required("Veuillez saisir le nombre d'année d'experience"),
    certifications: Yup.array(), //.required("Ce champ est obligatoire"),
    equipementFournis: Yup.array(),
    equipementAFournir: Yup.array(),
    description: Yup.string().required("Ce champ est obligatoire").max(256, "Ce champ ne doit pas dépasser 256 caractères"),
    diplomes: Yup.array(),
    skills: Yup.array(),

    tauxDeBase: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxWeekend: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxNuit: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxIntemperie: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),

    panier: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Le nom du panier est obligatoire"),
        })
      )
      .unique("Le nom du panier doit être unique"),

    isPanier: Yup.boolean(),

    montantPanier: Yup.number().typeError("Ce champ doit être numérique").positive("le montant doit être supérieure à 0"), //.required("Le montant du panier est obligatoire"),

    nomPanier: Yup.string(), //.required("Le nom du panier est obligatoire"),

    start_date_time: Yup.date("La date doit être valide").required("Ce champ est obligatoire"),
    end_date_time: Yup.date("La date doit être valide")
      .when("start_date_time", (start_date_time, schema) => start_date_time && schema.min(start_date_time, "La date de fin doit être supérieur à la date du début"))
      .nullable(true)
      .required("Ce champ est obligatoire"),

    // checked
    is_samedi: Yup.boolean(),
    is_dimanche: Yup.boolean(),
    is_lundi: Yup.boolean(),
    is_mardi: Yup.boolean(),
    is_mercredi: Yup.boolean(),
    is_jeudi: Yup.boolean(),
    is_vendredi: Yup.boolean(),

    //validate heure début heure de fin matiné
    start_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi);
    }),

    start_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche);
    }),

    start_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi);
    }),

    start_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi);
    }),

    start_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi);
    }),

    start_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi);
    }),

    start_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi);
    }),

    //validate heure début heure de fin soir

    start_time_samedi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi_2);
    }),
    end_time_samedi_2: Yup.string(),

    start_time_dimanche_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche_2);
    }),
    end_time_dimanche_2: Yup.string(),

    start_time_lundi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi_2);
    }),
    end_time_lundi_2: Yup.string(),

    start_time_mardi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi_2);
    }),
    end_time_mardi_2: Yup.string(),

    start_time_mercredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi_2);
    }),
    end_time_mercredi_2: Yup.string(),

    start_time_jeudi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi_2);
    }),
    end_time_jeudi_2: Yup.string(),

    start_time_vendredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi_2);
    }),
    end_time_vendredi_2: Yup.string(),

    //validate in between
    end_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_samedi_2);
    }),
    end_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_dimanche_2);
    }),
    end_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_lundi_2);
    }),
    end_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mardi_2);
    }),
    end_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mercredi_2);
    }),
    end_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_jeudi_2);
    }),
    end_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_vendredi_2);
    }),
  });

  const validationSchema1 = Yup.object({
    intitulePoste: Yup.string().required("Ce champ est obligatoire").max(100, "Ce champ doit pas dépasser 100 caractère"),
    metier: Yup.string().required("Ce champ est obligatoire"),
    lieu: Yup.string().required("Ce champ est obligatoire"),
    latitude: Yup.string(),
    longitude: Yup.string(),

    //helpers revisions
    metier_name: Yup.string(),
  });

  const validationSchema2 = Yup.object({
    experienceSouhaite: Yup.number().typeError("Ce champ doit être numérique").positive("Expérience doit être supérieure à 0").required("Ce champ est obligatoire"), //.required("Veuillez saisir le nombre d'année d'experience"),
    certifications: Yup.array(), //.required("Ce champ est obligatoire"),
    equipementFournis: Yup.array(),
    equipementAFournir: Yup.array(),
    description: Yup.string().required("Ce champ est obligatoire").max(2000, "Ce champ ne doit pas dépasser 2000 caractères"),
    diplomes: Yup.array(),
    skills: Yup.array(),
  });

  const validationSchema3 = Yup.object({
    tauxDeBase: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxWeekend: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxNuit: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    tauxIntemperie: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),
    panier: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Le nom du panier est obligatoire"),
        })
      )
      .unique("Le nom du panier doit être unique"),

    isPanier: Yup.boolean(),

    montantPanier: Yup.number().typeError("Ce champ doit être numérique").positive("le montant doit être supérieure à 0"), //.required("Le montant du panier est obligatoire"),
    nomPanier: Yup.string(), //.required("Le nom du panier est obligatoire"),

    start_date_time: Yup.date("La date doit être valide").required("Ce champ est obligatoire"),
    end_date_time: Yup.date("La date doit être valide")
      .when("start_date_time", (start_date_time, schema) => start_date_time && schema.min(start_date_time, "La date de fin doit être supérieur à la date du début"))
      .nullable(true)
      .required("Ce champ est obligatoire"),

    // checked
    is_samedi: Yup.boolean(),
    is_dimanche: Yup.boolean(),
    is_lundi: Yup.boolean(),
    is_mardi: Yup.boolean(),
    is_mercredi: Yup.boolean(),
    is_jeudi: Yup.boolean(),
    is_vendredi: Yup.boolean(),

    //validate heure début heure de fin matiné
    start_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi);
    }),

    start_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche);
    }),

    start_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi);
    }),

    start_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi);
    }),

    start_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi);
    }),

    start_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi);
    }),

    start_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi);
    }),

    //validate heure début heure de fin soir

    start_time_samedi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi_2);
    }),
    end_time_samedi_2: Yup.string(),

    start_time_dimanche_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche_2);
    }),
    end_time_dimanche_2: Yup.string(),

    start_time_lundi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi_2);
    }),
    end_time_lundi_2: Yup.string(),

    start_time_mardi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi_2);
    }),
    end_time_mardi_2: Yup.string(),

    start_time_mercredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi_2);
    }),
    end_time_mercredi_2: Yup.string(),

    start_time_jeudi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi_2);
    }),
    end_time_jeudi_2: Yup.string(),

    start_time_vendredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi_2);
    }),
    end_time_vendredi_2: Yup.string(),

    //validate in between
    end_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_samedi_2);
    }),
    end_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_dimanche_2);
    }),
    end_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_lundi_2);
    }),
    end_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mardi_2);
    }),
    end_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mercredi_2);
    }),
    end_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_jeudi_2);
    }),
    end_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_vendredi_2);
    }),
  });

  const weeklyPlannerMaker = (values) => {
    let weeklyPlanner = [];

    agendaMapper.map((day) => {
      if (values[`start_time_${day.name}`] && values[`end_time_${day.name}`]) {
        weeklyPlanner.push({
          day_name: day.name_en,
          start_time: values[`start_time_${day.name}`],
          end_time: values[`end_time_${day.name}`],
        });
        if (values[`start_time_${day.name}_2`] && values[`end_time_${day.name}_2`]) {
          weeklyPlanner.push({
            day_name: day.name_en,
            start_time: values[`start_time_${day.name}_2`],
            end_time: values[`end_time_${day.name}_2`],
          });
        }
      }
    });

    //console.log("my weekly planner before sending is :::::::::::::::::", weeklyPlanner);
    return weeklyPlanner;
  };

  const onSubmitCreateOffer = (values) => {
    //console.log("ACCESTOKEN create offre", `${access_token}`);
    //console.log("Values create offre", values);
    //console.log("my days ", weeklyPlannerMaker(values));
    Api.post(
      Urls.CREATE_OFFRE,
      {
        enterprise_id: enterprise_id,
        //step1
        job_title: values.intitulePoste,
        metier_id: values.metier,
        location_address: values.lieu,
        location_latitude: values.latitude,
        location_longitude: values.longitude,

        //step2
        experience_years_wanted: values.experienceSouhaite,
        certificate_document_type_ids: values.certifications,
        equipment_provided_ids: values.equipementFournis,
        equipment_to_be_provided_ids: values.equipementAFournir,
        tasks_to_do: values.description,
        skill_ids: values.skills,
        school_document_type_ids: values.diplomes,

        //step3
        start_date: values.start_date_time,
        end_date: values.end_date_time,

        // les taux
        basic_hourly_rate: values.tauxDeBase,
        weekend_hourly_rate: values.tauxWeekend,
        night_hourly_rate: values.tauxNuit,
        severe_weather_hourly_rate: values.tauxIntemperie,
        //panier
        baskets: values.panier,
        weekly_planner: weeklyPlannerMaker(values),
      },
      {
        headers: {
          "Content-type": "application/json",
          accept: "application/json",
          authorization: `Bearer ${access_token}`,
        },
      }
    )
      .then((response) => {
        //console.log("Create offre", response?.data);
        if (response?.status === 201) {
          //console.log("RESPONES GHERBI", response);
          navigate("/entreprise/offres/offre-cree", { state: { offer: response?.data?.offer } });
        }
      })
      .catch((error) => {
        //console.log(error);
        // toast.error(error.response.data.message);
        navigate("/entreprise/offres/erreur-creation");
      });
  };

  const [stepNumberToModify, setStepNumberToModify] = useState();
  const searchPlaces = (adresse) => {
    if (adresse) {
      axios
        .get("https://nominatim.openstreetmap.org/search", {
          params: {
            q: adresse,
            format: "json",
            polygon_kml: 0,
            addressdetails: 1,
            limit: 100,
            countrycodes: "fr",
            "accept-language": "fr",
          },
        })
        .then((res) => {
          console.log(res);
          setAdresses(res?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const debouncedSearch = React.useMemo(() => {
    return debounce((adresse) => {
      searchPlaces(adresse);
    }, 500);
  }, []);

  useEffect(() => {
    debouncedSearch(adresse);
    console.log(adresse);
  }, [adresse]);
  // return user?.data?.user?.status === "valid" ? (
  return (
    <Box display="flex" direction="row" justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
      <ToastContainer />
      {/* CONTENT*/}
      <Box padding="68px 0px">
        <div>
          <FormStepper
            formikValues={formikValues}
            setFormikValues={setFormikValues}
            initialValues={initialValues}
            onSubmit={onSubmitCreateOffer}
            //handle modals
            stepNumberToModify={stepNumberToModify}
            openModifyStep1={openModifyStep1}
            setOpenModifyStep1={setOpenModifyStep1}
            openModifyStep2={openModifyStep2}
            setOpenModifyStep2={setOpenModifyStep2}
            openModifyStep3={openModifyStep3}
            setOpenModifyStep3={setOpenModifyStep3}
          >
            <FormStep onSubmit={() => console.log("submited")} stepSubtitle="Renseignez les détails essentiels de l’offre" stepName="Informations de base" validationSchema={validationSchema1}>
              {/*  ================================================================ intitulé poste  ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="intitulePoste" name="intitulePoste">
                    Intitulé du poste
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="intitulePoste" name="intitulePoste" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="intitulePoste" type="input" placeholder="Saisir l’intitulé du poste..." id="intitulePoste" as={MyTextField} autoComplete="off" />
                <ErrorMessage component={FormikErrorText} name="intitulePoste" />
              </Box>

              {/* ================================================================ select metier  ================================================================*/}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="metier" name="metier">
                    Métier
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="metier" name="metier" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="metier">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={selectedMetier}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value) {
                          form.setFieldValue("metier", value.id);
                          form.setFieldValue("metier_name", value.name); // helper field revision
                          setSelectedMetier(value);
                          //console.log("selected métier is ::", value);
                        } else {
                          setSelectedMetier(null);
                          form.setFieldValue("metier", "");
                          form.setFieldValue("metier_name", value.name); // helper field revision
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedMetier(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                        if (event?.target?.value === "") {
                          form.setFieldValue("metier", "");
                        }
                      }}
                      options={metiers}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedMetier}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher un métier..."
                        />
                      )}
                    />
                  )}
                </Field>
                <ErrorMessage component={FormikErrorText} name="metier" />
              </Box>

              {/* ================================================================ lieu  ================================================================*/}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                    Lieu
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="lieu">
                  {({ field, form }) => (
                    <>
                      <TextField
                        sx={{ width: "100%" }}
                        value={adresse}
                        type="text"
                        onClick={() => {
                          setOpenAd(true);
                        }}
                        fullWidth
                        onChange={(e) => {
                          setAdresse(e.target.value);
                        }}
                        variant="outlined"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={RechercheIcon} alt="end" />
                            </InputAdornment>
                          ),
                          endAdornment: null,
                        }}
                        placeholder="Adresse..."
                      />

                      {openAd && adresse && (
                        <Box boxShadow="0px 1px 5px 0px black" marginTop="2px" width="100%" maxHeight="300px" sx={{ overflowY: "auto" }}>
                          {adresses?.map((ad) => (
                            <Box
                              onClick={() => {
                                setAdresse(ad?.display_name);
                                form.setFieldValue("lieu", ad?.display_name);
                                form.setFieldValue("latitude", ad?.lat);
                                form.setFieldValue("longitude", ad?.lon);
                                setselectedAdresse(ad);
                                setPosition([ad?.lat, ad?.lon]);
                                setOpenAd(false);
                              }}
                              padding="5px"
                              sx={{ ":hover": { filter: "brightness(0.95)", backgroundColor: "white" }, cursor: "pointer" }}
                            >
                              <Typography key={ad?.id}>{ad.display_name}</Typography>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </>
                  )}
                </Field>
                <ErrorMessage component={FormikErrorText} name="lieu" />
              </Box>

              <Box sx={{ overflow: "hidden" }}>
                <MapComponent position={position} is_radius={false} zoom_level={zoom_level} setZoom_level={setZoom_level} selectedAdresse={selectedAdresse} />
              </Box>
            </FormStep>

            {/* Etape 2 */}
            <FormStep stepSubtitle="Décrivez les compétences et les exigences requises pour le poste" stepName="Compétences et prérequis" onSubmit={() => console.log("submited")} validationSchema={validationSchema2}>
              {/* ================================================================ experience souhaité ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite">
                    Expérience souhaitée
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="experienceSouhaite" type="input" placeholder="Saisir l’intitulé du poste..." id="experienceSouhaite" autoComplete="off">
                  {({ field }) => (
                    <TextField
                      {...field}
                      sx={{ width: "100px" }}
                      placeholder="0"
                      autoComplete="Off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ paddingRight: "10px" }}>
                            <Typography variant="text_subtitle">ans</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage component={FormikErrorText} name="experienceSouhaite" />
              </Box>

              {/* ================================================================ certifications obligatoires ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="certifications" name="certifications">
                    Certifications / Habilitations obligatoires
                  </Typography>
                </Box>

                <Field name="certifications">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedCertifications.find((item) => item.id === value.id)) {
                          setSelectedCertificationValue(value);
                          setSelectedCertifications(selectedCertifications.concat([value]));
                          setSelectedCertificationsIDS(selectedCertificationsIDS.concat([value.id]));
                          form.setFieldValue("certifications", selectedCertificationsIDS.concat([value.id]));
                          form.setFieldValue("certifications", selectedCertificationsIDS.concat([value.id]));
                          //console.log("selected certifications are : ", selectedCertifications);
                          //console.log("selected certificationsIDS are : ", selectedCertificationsIDS);
                        } else {
                          setSelectedCertificationValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedCertifications(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                      }}
                      options={certifications}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedCertifications}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher des certifications..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="certifications">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedCertificationsIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedCertifications?.map((item) => (
                        <MyChip
                          key={item.id}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedCertifications(selectedCertifications.filter((sel) => sel.id !== item.id));
                            setSelectedCertificationsIDS(selectedCertificationsIDS.filter((sel) => sel !== item.id));
                            form.setFieldValue(
                              "certifications",
                              selectedCertificationsIDS.filter((sel) => sel !== item.id)
                            );
                            //console.log("selected certifications after deleting the selected value", selectedCertifications);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>

              {/* ================================================================ search diplomes ======================================================================= */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="diplomes" name="diplomes">
                    Diplômes requis
                  </Typography>
                </Box>

                <Field name="diplomes">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedDiplomes.find((item) => item.id === value.id)) {
                          setSelectedDiplomeValue(value);
                          setSelectedDiplomes(selectedDiplomes.concat([value]));
                          setSelectedDiplomesIDS(selectedDiplomesIDS.concat([value.id]));
                          form.setFieldValue("diplomes", selectedDiplomesIDS.concat([value.id]));
                        } else {
                          setSelectedDiplomeValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedDiplomes(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                      }}
                      options={diplomes}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedDiplomes}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher des skills..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="diplomes">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedDiplomesIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedDiplomes?.map((item) => (
                        <MyChip
                          key={item.id}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedDiplomes(selectedDiplomes.filter((sel) => sel.id !== item.id));
                            setSelectedDiplomesIDS(selectedDiplomesIDS.filter((sel) => sel !== item.id));
                            form.setFieldValue(
                              "diplomes",
                              selectedDiplomesIDS.filter((sel) => sel !== item.id)
                            );
                            //console.log("selected diplomes after deleting the selected value", selectedDiplomes);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>

              {/* ================================================================ EQUIPEMENTS fournis ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="certifications" name="certifications">
                    Equipements fournis
                  </Typography>
                </Box>

                <Field name="equipementFournis">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedEquipmentsProvided.find((item) => item.id === value.id) && !selectedEquipmentsToProvide.find((item) => item.id === value.id)) {
                          setSelectedEquipmentProvidedValue(value);
                          setSelectedEquipmentsProvided(selectedEquipmentsProvided.concat([value]));
                          setSelectedEquipmentsProvidedIDS(selectedEquipmentsProvidedIDS.concat([value.id]));
                          form.setFieldValue("equipementFournis", selectedEquipmentsProvidedIDS.concat([value.id]));
                          //console.log("selected Equipments provided are : ", selectedEquipmentsProvided);
                          //console.log("selected Equipments Provided IDS are : ", selectedEquipmentsProvidedIDS);
                        } else {
                          setSelectedEquipmentProvidedValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedEquipmentsProvided(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                      }}
                      options={equipmentsProvided}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedEquipmentProvidedValue}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher des équipements..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="equipementFournis">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedEquipmentsProvidedIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedEquipmentsProvided?.map((item) => (
                        <MyChip
                          key={item.id}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedEquipmentsProvided(selectedEquipmentsProvided.filter((sel) => sel.id !== item.id));
                            setSelectedEquipmentsProvidedIDS(selectedEquipmentsProvidedIDS.filter((sel) => sel !== item.id));
                            form.setFieldValue(
                              "equipementFournis",
                              selectedEquipmentsProvidedIDS.filter((sel) => sel !== item.id)
                            );
                            //console.log("selected equipementFournis after deleting the selected value", selectedEquipmentsProvided);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>
              {/* ================================================================ EQUIPEMENTS A FOURNIR ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="equipementAFournir" name="equipementAFournir">
                    Equipements à fournir
                  </Typography>
                </Box>

                <Field name="equipementAFournir">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedEquipmentsToProvide.find((item) => item.id === value.id) && !selectedEquipmentsProvided.find((item) => item.id === value.id)) {
                          setSelectedEquipmentToProvideValue(value);
                          setSelectedEquipmentsToProvide(selectedEquipmentsToProvide.concat([value]));
                          setSelectedEquipmentsToProvideIDS(selectedEquipmentsToProvideIDS.concat([value.id]));
                          form.setFieldValue("equipementAFournir", selectedEquipmentsToProvideIDS.concat([value.id]));
                          //console.log("selected Equipments ToProvide are : ", selectedEquipmentsToProvide);
                          //console.log("selected Equipments ToProvide IDS are : ", selectedEquipmentsToProvideIDS);
                        } else {
                          setSelectedEquipmentToProvideValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedEquipmentsToProvide(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                      }}
                      options={equipmentsToProvide}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedEquipmentToProvideValue}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher des équipements..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="equipementAFournir">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedEquipmentsToProvideIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedEquipmentsToProvide?.map((item) => (
                        <MyChip
                          key={item.id}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedEquipmentsToProvide(selectedEquipmentsToProvide.filter((sel) => sel.id !== item.id));
                            setSelectedEquipmentsToProvideIDS(selectedEquipmentsToProvideIDS.filter((sel) => sel !== item.id));
                            form.setFieldValue(
                              "equipementAFournir",
                              selectedEquipmentsToProvideIDS.filter((sel) => sel !== item.id)
                            );
                            //console.log("selected equipementAFournir after deleting the selected value", selectedEquipmentsToProvide);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>

              {/* ================================================================ Taches à réaliser ======================================================================= */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                    Tâches à réaliser
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="description" type="input" placeholder="Décrire les tâches à réaliser..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                <ErrorMessage component={FormikErrorText} name="description" />
              </Box>

              {/* ================================================================ search skills ================================================================ */}
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="experienceSouhaite" name="experienceSouhaite">
                    Compétences
                  </Typography>
                </Box>

                <Field name="skills">
                  {({ field, form }) => (
                    <Autocomplete
                      {...field}
                      fullWidth
                      value={null}
                      blurOnSelect={true}
                      noOptionsText={"Aucune option"}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(event, value) => {
                        if (value && !selectedSkills.find((item) => item.id === value.id)) {
                          setSelectedSkillValue(value);
                          setSelectedSkills(selectedSkills.concat([value]));
                          setSelectedSkillsIDS(selectedSkillsIDS.concat([value.id]));
                          form.setFieldValue("skills", selectedSkillsIDS.concat([value.id]));
                        } else {
                          setSelectedSkillValue(null);
                        }
                      }}
                      onInputChange={(event, value) => {
                        setSearchedSkills(event?.target?.value);
                        //console.log("searching for :", event?.target?.value);
                        //console.log(value);
                      }}
                      options={skills}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MyTextField
                          fullWidth
                          value={selectedSkills}
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <img src={RechercheIcon} alt="end" />
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Rechercher des skills..."
                        />
                      )}
                    />
                  )}
                </Field>
                <Field name="skills">
                  {({ field, form }) => (
                    <Box flexWrap="wrap" display={selectedSkillsIDS.length === 0 ? "none" : "flex"} gap="10px" sx={{ paddingTop: "10px" }}>
                      {selectedSkills?.map((item) => (
                        <MyChip
                          key={item.id}
                          variant="skill"
                          label={item.name}
                          onDelete={() => {
                            setSelectedSkills(selectedSkills.filter((sel) => sel.id !== item.id));
                            setSelectedSkillsIDS(selectedSkillsIDS.filter((sel) => sel !== item.id));
                            form.setFieldValue(
                              "skills",
                              selectedSkillsIDS.filter((sel) => sel !== item.id)
                            );
                            //console.log("selected skills after deleting the selected value", selectedSkills);
                          }}
                        />
                      ))}
                    </Box>
                  )}
                </Field>
              </Box>
            </FormStep>

            {/* Etape 3*/}
            <FormStep onSubmit={() => console.log("submited")} stepSubtitle="Précisez les informations importantes liées à la demande" stepName="Détails de la demande" validationSchema={validationSchema3}>
              {/* ================================================================Date début Date fin================================================================ */}

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time">
                    Date de début
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>

                <Field width="100%" name="start_date_time" color={colors.main} type="input" id="start_date_time" component={OmjDatePicker} hasAsap={true} autoComplete="off" placeholder="saisr la date de début" />
                <ErrorMessage component={FormikErrorText} name="start_date_time" />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time">
                    Date de fin
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field name="end_date_time" color={colors.main} type="input" id="end_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                <ErrorMessage component={FormikErrorText} name="end_date_time" />
              </Box>
              {/* ================================================================Taux horraire ================================================================*/}

              <Divider sx={{ marginBottom: "32px" }} />

              <Box sx={{ marginBottom: "16px" }}>
                <Typography variant="text_lg" fontWeight="700">
                  Agenda
                </Typography>
              </Box>

              <Field>
                {({ form }) => (
                  <Box className="Agenda" sx={{ marginBottom: "32px" }}>
                    <GrayContainer>
                      {agendaMapper.map((day) => (
                        <ItemContainerAgenda display="flex" flexDirection="column">
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
                              <Box sx={{ paddingTop: "2px" }}>
                                <MySwitch
                                  isOn={form.values[`is_${day.name}`]}
                                  onChange={() => {
                                    day.setChecked(!day.checked);
                                    form.setFieldValue(`is_${day.name}`, !day.checked);
                                    form.setFieldValue(`start_time_${day.name}`, "");
                                    form.setFieldValue(`end_time_${day.name}`, "");
                                    form.setFieldValue(`start_time_${day.name}_2`, "");
                                    form.setFieldValue(`end_time_${day.name}_2`, "");
                                    if (form.values[`is_${day.name}`] === true) {
                                      form.setFieldValue(`start_time_${day.name}`, "");
                                      form.setFieldValue(`end_time_${day.name}`, "");
                                    }
                                  }}
                                />
                              </Box>
                              <Box>
                                <Typography variant="text_normal">{day.name}</Typography>
                              </Box>
                            </Box>
                            {form.values[`is_${day.name}`] && (
                              <Box display="flex" flexDirection="column" gap="10px">
                                {/* Crénau matiné */}
                                <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                  <Box width="40px" />
                                  <Field name={`start_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                  <Box width="15px">
                                    <img src={RightArrowTime} width="15px" alt="" />
                                  </Box>
                                  <Field name={`end_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                </Box>
                                {/* ajouter */}
                                {day.hasShift === false ? (
                                  <Box display="flex" flexDirection="row-reverse">
                                    <Button variant="mj_secondary_md" sx={{ width: "146px" }} onClick={() => day.setHasShift(true)}>
                                      Ajouter
                                    </Button>
                                  </Box>
                                ) : (
                                  ""
                                )}
                                {/* Crénau soir */}
                                {day.hasShift && (
                                  <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
                                    <ButtonSquare
                                      sx={{ height: "32px" }}
                                      action="close"
                                      onClick={() => {
                                        day.setHasShift(false);
                                        form.setFieldValue(`start_time_${day.name}_2`, "");
                                        form.setFieldValue(`end_time_${day.name}_2`, "");
                                      }}
                                    />
                                    <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                      <Field name={`start_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                      <Box width="15px">
                                        <img src={RightArrowTime} width="15px" alt="" />
                                      </Box>
                                      <Field name={`end_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                    </Box>
                                  </Box>
                                )}
                              </Box>
                            )}
                          </Box>
                          {form.errors[`start_time_${day.name}`] && (
                            <Box sx={{ paddingTop: "16px" }}>
                              <ErrorMessage name={`start_time_${day.name}`} component={FormikErrorText} />
                            </Box>
                          )}
                          {form.errors[`start_time_${day.name}_2`] && (
                            <Box sx={{ paddingTop: "16px" }}>
                              <ErrorMessage name={`start_time_${day.name}_2`} component={FormikErrorText} />
                            </Box>
                          )}
                          {form.errors[`end_time_${day.name}`] && (
                            <Box sx={{ paddingTop: "16px" }}>
                              <ErrorMessage name={`end_time_${day.name}`} component={FormikErrorText} />
                            </Box>
                          )}
                        </ItemContainerAgenda>
                      ))}
                    </GrayContainer>
                  </Box>
                )}
              </Field>

              {/* ================================================================Taux horraire ================================================================*/}

              <Divider sx={{ marginBottom: "32px" }} />

              <Box sx={{ marginBottom: "16px" }}>
                <Typography variant="text_lg" fontWeight="700">
                  Taux horaire
                </Typography>
              </Box>

              <Grid container spacing={0} sx={{ marginBottom: "32px" }}>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                    <Box sx={{ marginBottom: "6px" }}>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase">
                        De base
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase" style={{ color: colors.main }}>
                        *
                      </Typography>
                    </Box>
                    <Field name="tauxDeBase" type="input" placeholder="0" id="tauxDeBase" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={FormikErrorText} name="tauxDeBase" />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                    <Box sx={{ marginBottom: "6px" }}>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend">
                        Weekend
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend" style={{ color: colors.main }}>
                        *
                      </Typography>
                    </Box>
                    <Field name="tauxWeekend" type="input" placeholder="0" id="tauxWeekend" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={FormikErrorText} name="tauxWeekend" />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                    <Box sx={{ marginBottom: "6px" }}>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit">
                        Nuit
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit" style={{ color: colors.main }}>
                        *
                      </Typography>
                    </Box>
                    <Field name="tauxNuit" type="input" placeholder="0" id="tauxNuit" as={MyTextField} autoComplete="off" />
                    <ErrorMessage component={FormikErrorText} name="tauxNuit" />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ marginBottom: "6px" }}>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                        Heures intempéries
                      </Typography>
                      <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie" style={{ color: colors.main }}>
                        *
                      </Typography>
                    </Box>
                    <Box sx={{ width: "80px" }}>
                      <Field name="tauxIntemperie" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                    </Box>
                    <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                  </Box>
                </Grid>
              </Grid>

              {/* ================================================================Panier ================================================================*/}
              {/* <pre>{JSON.stringify(formikValues, null, 2)}</pre> */}
              <Divider sx={{ marginBottom: "32px" }} />

              <Box sx={{ marginBottom: "16px" }}>
                <Typography variant="text_lg" fontWeight="700">
                  Panier par jour
                </Typography>
              </Box>

              {/* <Box sx={{ margin: "0.5rem 0.5rem" }}>
                <ErrorMessage component={ErrorText} name="nomPanier" />
                <ErrorMessage component={ErrorText} name="montantPanier" />
              </Box> */}

              {/* <Field>
                {({ form }) => (
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      sx={{ padding: "0.5rem", bgcolor: `${colors.paperLightOrange}`, "&:hover": { bgcolor: `${colors.paperLightOrangeHover}` } }}
                      onClick={() => {
                        setIsAddingPanier(true);
                        form?.setFieldValue("isPanier", !isAddingPanier);
                      }}
                    >
                      <AddRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>
                  </Box>
                )}
              </Field> */}

              {/* modal add panier */}

              {/* Modal add panier */}
              <Modal open={openAddPanier} onClose={() => setOpenAddPanier(false)} unmountOnExit>
                <Box sx={modalStyle}>
                  <BoxContainerModal>
                    <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                      <Typography variant="text_xxl" fontWeight="700">
                        Ajouter un panier
                      </Typography>
                      <ButtonSquare onClick={() => setOpenAddPanier(false)} action="close" />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="nomPanier" name="nomPanier">
                          Titre
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="nomPanier" name="nomPanier" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="nomPanier" type="input" placeholder="Saisir le nom du panier" id="nomPanier" as={MyTextField} autoComplete="off" />
                      </Box>
                      <ErrorMessage component={FormikErrorText} name="nomPanier" />
                      <FormikErrorText>{errorPanier}</FormikErrorText>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="montantPanier" name="montantPanier">
                          Montant
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="montantPanier" name="montantPanier" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box sx={{ width: "80px" }}>
                        <Field name="montantPanier" type="input" placeholder="0" id="montantPanier" as={MyTextField} autoComplete="off" />
                      </Box>
                      <ErrorMessage component={FormikErrorText} name="montantPanier" />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                      <Field>
                        {({ form }) => (
                          <Box display="flex" flexDirection="row" justifyContent="center" gap="12px">
                            <Button
                              variant="mj_secondary_md"
                              onClick={() => {
                                setOpenAddPanier(false);
                                form?.setFieldValue("nomPanier", "");
                                form?.setFieldValue("montantPanier", "");
                              }}
                            >
                              Annuler
                            </Button>
                            <Button
                              variant="mj_primary_md"
                              onClick={() => {
                                const name = form.values.nomPanier;
                                const value = form.values.montantPanier;
                                //console.log("Name", name);
                                //console.log("value", value);

                                // if (form.values.nomPanier === "") {
                                //   setErrorPanier("Le nom du panier est obligatoire");
                                // }
                                // if (form.values.montantPanier === "") {
                                //   setErrorPanier("Le montant est obligatoire");
                                // }

                                if (form.values.panier.find((item) => item.name === name)) {
                                  //console.log("TRUE PANIER EXISTS");
                                  setErrorPanier("Le nom du panier doit être unique");
                                } else {
                                  if (form.errors.nomPanier || form.errors.montantPanier) {
                                    return;
                                  } else {
                                    //console.log("FALSE PANIER DOESNT EXISTS NO DUPLICATE");
                                    form.values.panier.push({ name: name, value: value });
                                    setPaniersLocal((oldArray) => [...oldArray, { name, value }]);
                                    setOpenAddPanier(false);
                                    form?.setFieldValue("nomPanier", "");
                                    form?.setFieldValue("montantPanier", "");
                                    setErrorPanier("");
                                    form.errors.nomPanier = "";
                                    form.errors.montantPanier = "";
                                  }
                                }
                              }}
                            >
                              Enregistrer
                            </Button>
                          </Box>
                        )}
                      </Field>
                    </Box>
                  </BoxContainerModal>
                </Box>
              </Modal>

              <Box className="panier" sx={{ marginBottom: "32px" }}>
                <GrayContainer>
                  <Field>
                    {({ form }) =>
                      formikValues?.panier?.map((p) => {
                        return (
                          <ItemContainer key={p?.name} sx={{ margin: "1rem 0.5rem" }} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="text_normal">{p.name}</Typography>

                            <Box display="flex" gap="16px" alignItems="center">
                              <Typography variant="text_normal" fontWeight="700">
                                {p.value} €
                              </Typography>
                              <Box>
                                <ButtonSquare action="modify" />
                                <ButtonSquare action="delete" onClick={() => deletePanier(form, p)} />
                              </Box>
                            </Box>
                          </ItemContainer>
                        );
                      })
                    }
                  </Field>
                  <Button onClick={() => setOpenAddPanier(true)} fullWidth sx={{ marginBottom: "4px", padding: "14px 14px", color: "#000B30", "&:hover": { backgroundColor: "transparent" }, textTransform: "none" }}>
                    <Box mr>
                      <img src={AddIcon} width="12px" alt="add-icon" />
                    </Box>
                    Ajouter
                  </Button>
                </GrayContainer>
              </Box>
            </FormStep>

            {/* Etape 4  */}
            <FormStep onSubmit={() => console.log("submited")} validationSchema={ValidationSchemaModify}>
              {/* info de base STEP 1*/}

              <Field>
                {({ form }) => (
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
                          Informations de base
                        </Typography>
                        <ButtonSquare
                          action="modify"
                          onClick={() => {
                            setStepNumberToModify(0);
                            setOpenModifyStep1(true);
                          }}
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Intitulé du poste
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="text_normal">{form.values.intitulePoste}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Métier
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="text_normal">{form.values.metier_name}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Lieu
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="text_normal">{form.values.lieu}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ margin: "32px 0px" }} />

                    {/* Compétences et prérequis STEP 2*/}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
                          Compétences et prérequis
                        </Typography>
                        <ButtonSquare
                          action="modify"
                          onClick={() => {
                            setStepNumberToModify(1);
                            setOpenModifyStep2(true);
                          }}
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Expérience souhaité
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="text_normal">{form.values.experienceSouhaite} ans</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Certifications / Habilitations obligatoires
                          </Typography>
                        </Box>
                        <Box flexWrap="wrap" display={selectedCertifications?.length === 0 ? "none" : "flex"} gap="10px" flexDirection="row">
                          {selectedCertifications?.map((certification) => (
                            <Box>
                              <MyChip variant="skill" label={certification.name} />
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Diplômes requis
                          </Typography>
                        </Box>
                        <Box flexWrap="wrap" display={selectedDiplomes.length === 0 ? "none" : "flex"} gap="10px" flexDirection="row">
                          {selectedDiplomes.map((skill) => (
                            <Box>
                              <MyChip variant="skill" label={skill.name} />
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Equipement fournis
                          </Typography>
                        </Box>
                        <Box flexWrap="wrap" display={selectedEquipmentsProvided?.length === 0 ? "none" : "flex"} gap="10px" flexDirection="row">
                          {selectedEquipmentsProvided?.map((equipment) => (
                            <Box>
                              <MyChip variant="skill" label={equipment.name} />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Equipements à fournir
                          </Typography>
                        </Box>
                        <Box flexWrap="wrap" display={selectedEquipmentsToProvide.length === 0 ? "none" : "flex"} gap="10px" flexDirection="row">
                          {selectedEquipmentsToProvide.map((equipment) => (
                            <Box>
                              <MyChip variant="skill" label={equipment.name} />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Tâches à réaliser
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="text_normal">{form.values.description}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Compétences
                          </Typography>
                        </Box>
                        <Box flexWrap="wrap" display={selectedSkills.length === 0 ? "none" : "flex"} gap="10px" flexDirection="row">
                          {selectedSkills.map((skill) => (
                            <Box>
                              <MyChip variant="skill" label={skill.name} />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                    <Divider sx={{ margin: "32px 0px" }} />

                    {/* Détail de l'offre STEP 3*/}
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", marginBottom: "20px" }}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
                          Détails de la demande
                        </Typography>
                        <ButtonSquare
                          action="modify"
                          onClick={() => {
                            setStepNumberToModify(2);
                            setOpenModifyStep3(true);
                          }}
                        />
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <Box>
                          <Typography variant="text_normal" fontWeight="600">
                            Dates
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                          {/* {//console.log("date created", moment(new Date()).format("DD MM YYYY"))}
                          {//console.log("date to compare with", moment(form.values.start_date_time).format("DD MM YYYY"))} */}
                          <Typography variant="text_normal">{isEqualDate(new Date(), moment(form.values.start_date_time).toDate()) ? "Dès que possible" : formatDateFront(form.values.start_date_time)}</Typography>
                          <img src={RightArrowTime} width="15px" alt="" />
                          <Typography variant="text_normal">{formatDateFront(form.values.end_date_time)}</Typography>
                        </Box>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="2px">
                        <BlackContainer>
                          <Box sx={{ marginBottom: "21px" }}>
                            <Typography variant="text_normal" fontSize="20px" fontWeight="700" color="white" lineHeight="32px">
                              🗓️ Agenda
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" justifyContent="space-between" gap="16px">
                            {agendaMapper.map((day, index) => (
                              <>
                                <Box key={day.index} display="flex" flexDirection="row" justifyContent="space-between">
                                  <Typography variant="text_normal" color={!form.values[`start_time_${day.name}`] && !form.values[`start_time_${day.name}_2`] ? "#4A526C" : "white"}>
                                    {day.name.charAt(0).toUpperCase() + day.name.slice(1)}
                                  </Typography>

                                  <Box width="250px" display="flex" flexDirection="row" justifyContent="space-between">
                                    {form.values[`start_time_${day.name}`] ? (
                                      <Box width="100px" display="flex" flexDirection="row" gap="5px">
                                        <Typography variant="text_xs" fontWeight="400" color="white">
                                          {form.values[`start_time_${day.name}`]}
                                        </Typography>
                                        <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                                        <Typography variant="text_xs" fontWeight="400" color="white">
                                          {form.values[`end_time_${day.name}`]}
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box width="100px" display="flex" justifyContent="center">
                                        <img src={XIconTime} height="20px" alt="" />
                                      </Box>
                                    )}
                                    {form.values[`start_time_${day.name}_2`] ? (
                                      <Box width="100px" display="flex" flexDirection="row" gap="5px">
                                        <Typography variant="text_xs" fontWeight="400" color="white">
                                          {form.values[`start_time_${day.name}_2`]}
                                        </Typography>
                                        <img src={RightArrowTimeOrange} width="12.5px" alt="" />
                                        <Typography variant="text_xs" fontWeight="400" color="white">
                                          {form.values[`end_time_${day.name}_2`]}
                                        </Typography>
                                      </Box>
                                    ) : (
                                      <Box width="100px" display="flex" justifyContent="center">
                                        <img src={XIconTime} height="20px" alt="" />
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                                {index !== agendaMapper.length - 1 && <Divider sx={{ backgroundColor: "#1C2647" }} />}
                              </>
                            ))}
                          </Box>
                        </BlackContainer>
                      </Box>{" "}
                      {/* Taux horaire */}
                      <BoxContainer>
                        <Box marginBottom="21px">
                          <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                            💸 Taux horaire
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="10px">
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="text_normal" color="#4A526C">
                              Brut
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600">
                              {form.values.tauxDeBase}€
                            </Typography>
                          </Box>
                          <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="text_normal" color="#4A526C">
                              Weekend
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600">
                              {form.values.tauxWeekend}€
                            </Typography>
                          </Box>
                          <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="text_normal" color="#4A526C">
                              Nuit
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600">
                              {form.values.tauxNuit}€
                            </Typography>
                          </Box>
                          <Divider sx={{ backgroundColor: "#EBEBEE" }} />
                          <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="text_normal" color="#4A526C">
                              Heures intempéries
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600">
                              {form.values.tauxIntemperie}€
                            </Typography>
                          </Box>
                        </Box>
                      </BoxContainer>
                      {/* panier */}
                      <BoxContainer>
                        <Box marginBottom="21px">
                          <Typography variant="text_normal" fontSize="20px" fontWeight="700" lineHeight="32px">
                            🛒 Panier par jour
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" gap="10px">
                          {form.values.panier.map((itemPanier, index) => (
                            <>
                              <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Typography variant="text_normal" color="#4A526C">
                                  {itemPanier.name}
                                </Typography>
                                <Typography variant="text_normal" fontWeight="600">
                                  {itemPanier.value}€
                                </Typography>
                              </Box>
                              {index !== form.values.panier.length - 1 && <Divider sx={{ backgroundColor: "#EBEBEE" }} />}
                            </>
                          ))}
                        </Box>
                      </BoxContainer>
                    </Box>
                    {/*  */}
                  </Box>
                )}
              </Field>
              {/* step 5 modification step1 */}
            </FormStep>
          </FormStepper>
        </div>
      </Box>
    </Box>
    // ) : user?.data?.user?.status === "pending" ? (
    //   <Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
    //     <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           height: "50vh",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //         severity="error"
    //       >
    //         <Typography sx={{ marginBottom: "0.5em" }}>Veuillez patienter. Votre compte est en cours de validation</Typography>
    //         <Typography sx={{ marginBottom: "1.5rem" }}>par l'équipe Malinjob.</Typography>
    //       </Box>
    //     </Box>
    //     <MesOffresRightBar />
    //   </Box>
    // ) : (
    //   <Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
    //     <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexDirection: "column",
    //           height: "50vh",
    //           alignItems: "center",
    //           justifyContent: "center",
    //         }}
    //       >
    //         <Typography sx={{ marginBottom: "1.5rem" }}>Veuillez valider votre compte pour pouvoir créer des offres</Typography>
    //         <Button onClick={() => navigate("/entreprise/compte")} variant="mj_primary_md">
    //           Valider mon compte
    //         </Button>
    //       </Box>
    //     </Box>
    //   </Box>
    // );
  );
}

export default CreerOffres;
