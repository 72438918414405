import React from "react";
import { useState, useEffect } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../../../theme/Colors";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import * as Yup from "yup";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";

function OBInfosEntreprise({ setCurrentStep }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  //validation for fields
  const validationSchema = Yup.object({
    contact_email: Yup.string().email("Le format de l'email est invalide").required("Ce champs est obligatoire"),
    billing_email: Yup.string().email("Le format de l'email est invalide").required("Ce champs est obligatoire"),
  });

  const handleNext = () => {
    setCurrentStep(2);
  };

  const [initialValues, setInitialValues] = useState({
    contact_email: "",
    billing_email: "",
  });

  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "GETTING EMAIL FAC + CONTACT");
        setInitialValues({
          contact_email: res?.data?.user?.enterprise?.contact_email ? res?.data?.user?.enterprise?.contact_email : "",
          billing_email: res?.data?.user?.enterprise?.billing_email ? res?.data?.user?.enterprise?.billing_email : "",
        });
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);

  const onSubmitInfosEntreprise = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + user?.user?.enterprise?.id,
      {
        contact_email: values.contact_email,
        billing_email: values.billing_email,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log("UPDATED EMAIL CONTACT &&&&&&&&&&&&&&&&&&& FAC");
        handleNext();
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitInfosEntreprise}>
        {({ values, error }) => (
          <Form>
            <Box sx={{ marginBottom: "32px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="contact_email">
                    Email de contact
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="contact_email" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="contact_email" type="email" placeholder="votre@email.com" id="email" as={TextField}></Field>
                <ErrorMessage name="contact_email" component={FormikErrorText} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="billing_email">
                    Email de facturation
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="billing_email" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="billing_email" type="email" placeholder="votre@email.com" id="email" as={TextField}></Field>
                <ErrorMessage name="billing_email" component={FormikErrorText} />
              </Box>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} type="submit" variant="mj_green_md">
                  Enregistrer
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} variant="mj_primary_md" onClick={() => handleNext()}>
                  Suivant
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default OBInfosEntreprise;
