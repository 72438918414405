export const Urls = {
  //auth urls V1
  // LOGIN_URL: "/login",
  // SIGNUP_URL: "/register",
  // OPT_FORGOT_PASSWORD: "/reset-password/send-otp",
  // OTP_VERIFY_FORGOT_PASSWORD: "/reset-password/verify-otp",
  // RESET_PASSWORD: "/reset-password",
  // SEND_VALIDATION_OTP: "/validate-email/send-otp",
  // EMAIL_VALIDATION: "/validate-email",
  // LOGOUT: "/logout",

  //auth urls V2
  LOGIN_URL: "/auth/login",
  //SIGNUP_URL: "/register",
  SIGNUP_URL_ENTREPRISE: "/auth/register/enterprise",
  SIGNUP_URL_INTERIM: "/auth/register/interim",
  OTP_FORGOT_PASSWORD: "/auth/reset-password/send-otp",
  OTP_VERIFY_FORGOT_PASSWORD: "/auth/reset-password/check-otp",
  RESET_PASSWORD: "/auth/reset-password",
  SEND_VALIDATION_OTP: "/auth/verify-user-email/send-otp",
  EMAIL_VALIDATION: "/auth/verify-user-email",
  LOGOUT: "/auth/logout",
  //modify profile v2
  MODIFY_INTERIMAIRE: "/users/",
  MODIFY_PROFILE_PIc: "/users/",
  //get user document by tag v2
  GET_USER_DOCUMENT: "/users/",
  //post use documnets v2
  POST_USER_DOCUMENTS: "/users/",
  //patch profile utilisateur v2
  PATCH_PROFILE_UTILISATEUR: "/profile/",
  //get user candidatures
  GET_LISTE_CANDIDATURES: "/users/",
  //patch use Doccuments v2
  PATCH_USER_DOCUMENTS: "/userDocuments/",
  //delete user documents v2
  DELETE_USER_DOCUMENTS: "/schoolDocuments/",
  //search certificate v2
  SEARCH_CERTIFICATE: "/certificateDocumentTypes",
  //search scolarite v2
  SEARCH_SCHOOLING: "/schoolDocumentTypes",
  //add experience v2
  ADD_EXPERIENCEs: "/users/",
  //liste des experience v2
  LISTE_EXPERIENCE: "/users/",
  //get hqrd skills v2
  GET_HARD_SKILLS: "/hardSkills",
  //get SOFT skills v2
  GET_SOFT_SKILLS: "/softSkills",
  //get soft skills v2
  GET_SKILLS: "/skills",
  //get user hardSkills v2
  GET_USER_HARD_SKILLS: "/users/",
  //put hard skills v2
  PUT_HARD_SKILLS: "/users/",
  //put soft skills v2
  PUT_SKILLS: "/users/",
  //get user softSkills v2
  GET_USER_SKILLS: "/users/",
  // update experience v2
  UPDATE_EXPERIENCE: "/experiences/",
  //delete experience v2
  DELETE_EXPERIENCE: "/experiences/",
  //add user metier v2
  ADD_USER_METIER: "/users/",
  //get metier autocomplete V2
  GET_METIER_AUTO: "/metiers",
  //get user metier v2
  GET_USER_METIER: "/users/",
  //update user metier v2
  UPDATE_USER_METIER: "/users/",
  //delete user metier v2
  DELETE_USER_METIER: "/users/",
  //ajouter un certificat v2
  POST_CERTIFICTAE: "/certificateDocuments",
  //update certificate v2
  UPDATE_CERTIFICATE: "/certificateDocuments/",
  //delete certificate v2
  DELETE_CERTFIIFCATE: "/certificateDocuments/",
  //get user certificates v2
  GET_CERTIFICATE: "/certificateDocuments",
  //create scolariter v2
  POST_SCOLARITE: "/schoolDocuments",
  //UPDATE SCOLARITE v2
  UPDATE_SCOLARITER: "/schoolDocuments/",
  //delete scolariter v2
  DELETE_SCOLARITE: "/schoolDocuments/",
  //GET SCOLARITER v2
  GET_SCOLARITE: "/schoolDocuments",
  //ajouter carte didentite v2
  ADD_IDENTITY_CARD: "/nationalIdentityCards",
  //update carte identite v2
  UPDATE_IDENTITY_CARD: "/nationalIdentityCards/",
  //ajouter passport v2
  ADD_PASSPORT: "/passports",
  //update passport v2
  UPDATE_PASSPORT: "/passports/",
  //ajouter titre de sejour
  ADD_TITRE_SEJOUR: "/residencePermits",
  //update titre de sejour v2
  UPDATE_TITRE_SEJOUR: "/residencePermits/",
  //ajouter res titre de sejour V2
  ADD_RES: "/residencePermitReceipts",
  //update res v2
  UPDATE_RES: "/residencePermitReceipts/",
  //ajouter carte vital
  ADD_VITAL_CARD: "/vitalCards",
  //UPDATE carte vital
  UPDATE_VITAL_CARD: "/vitalCards/",
  //ADD securiter social v2
  ADD_SECURITE_SOCIAL: "/socialSecurityCertificates",
  //update sec social v2
  UPDATE_SEC_SOCIAL: "/socialSecurityCertificates/",
  //add justif dom v2
  ADD_JUSTIF_DOM: "/addressProofs",
  //UPDATE JUSTIF DOM V2
  UPDATE_JUSTIF_DOM: "/addressProofs/",
  //ADD MUTUELLE v2
  ADD_MUTUELLE: "/mutuals",
  //UPDATE MUTUELLE v2
  UPDATE_MUTUELLE: "/mutuals/",
  //ADD MED VISITE v2
  ADD_MEDICAL_VISIT: "/medicalVisits",
  //UPDATE MEDICAL VISIT v2
  UPDATE_MEDICAL_VISIT: "/medicalVisits/",
  //get user V2
  GET_USER_REC: "/auth-user-resource",
  //ajouter permis
  ADD_PERMIS: "/drivingLicenses",
  //update permis
  UPDATE_PERMIS: "/drivingLicenses/",
  //delete Permis v2
  DELETE_PERMIS: "/drivingLicenses/",
  //modifier mot de passe v2
  UPDATE_PASSWORD_USER: "/users/",
  //update profile entreprise v2
  UPDATE_PROFILE_ENTREPRISE: "/enterprises/",
  //update lgoo entreprise v2
  UPDATE_LOGO_ENTREPRISE: "/enterprises/",
  //get forme juridique v2
  GET_FORME_JURIDIQUE: "/forme-juridiques",
  //sign mandat sepa v2
  SIGN_MADNDAT_SEPA: "/enterprises/",
  //get list offer v2
  GET_OFFRE_LIST: "/offers",
  //get details offre v2
  GET_DETAILS_OFFRE: "/offers/",
  //chsoisir methode payement
  METHODE_PAYEMENT: "/enterprises/",
  //postuler a une offre
  POSTULER_OFFRE_ID: "/users/",
  //update candidatures v2
  UPDATE_CANDIDATURE: "/applications/",
  //get motif de refus v2
  GET_MOTIF_REFUS: "/interimRefusalReasons",
  //get detail contrat
  GET_DETAIL_CONTRAT: "/contracts/",
  //get missions v2
  GET_LIST_MISSION: "/missions",
  //show mission v2
  SHOW_MISSION: "/missions/",
  //get user documents v2
  //get entreprise by id v2
  GET_ENTREPRISE: "/enterprises/",
  //get contrats v2
  GET_CONTRATS: "/contracts",
  //get profile proposés v2
  PROFILE_PROPOS: "/offers/",
  //get user notifs
  GET_USER_NOTIFS: "/users/",
  //suspendre l'offre v2
  SUSPEND_OFFER: "/offers/",
  //get facteur de penibilite V2
  GET_PENIBILITE_FACTORS: "/arduousnessFactors",
  //GET risques pro v2
  GET_RISQUES_PRO: "/professionalRisks",
  //get raison de recrutement v2
  GET_RAISON_RECUTEMENT: "/recruitmentReasons",
  //GET RAISON DE REFUS v2
  GET_REFUSAL_REASONS_ENTREPRISE: "/enterpriseRefusalReasons",
  //get lestes des embauchers v2
  GET_EMBAUCHER: "/offers/",
  // get profile utilisateur v2
  GET_PROFILE_IMTERIM: "/applications/",
  //rate a mission v2
  RATE_INTERIM: "/rate-interim",
  //get details profile embaucher v2
  GET_DETAIL_INTERIM_EMBAUCHER: "/offers/",
  //get la liste des problems v2
  GET_LISTE_PROBS: "/enterpriseProblemReasons",
  //declqrer un probléme v2
  DECLACRE_PROB: "/contracts/",
  // suspendre un contrat v2
  SUSPEND_CONTRAT: "/contracts/",
  //avenant de prolongation v2
  PROLONGATION_VALUE_AVENANT: "/contracts/",
  //get interims for filter v2
  GET_LIST_INTERIMS_ENT: "/enterprises/",
  // modify profile v2
  MODIFY_PROFILE: "/profiles/",
  //get declared hours,
  DECLARED_HOURS: "/contracts/",
  //declarer les heures v2
  DECLARE_HOURS: "/contracts/",
  //get fiche de paie v2
  GET_FICHE_DE_PAIE: "/payslips/",
  //GET ENTREPRISE FACTUR v2
  GET_ENTREPRISE_FACTURE: "/invoices",
  //Signer contrat avec id contrat v2
  SIGN_CONTRAT_ID: "/contracts/",
  //detail contrat declarer les heures v2
  DETAIL_DECLARE_HOURS: "/contracts/",
  // get liste interims by entreprise v2
  GET_LISTE_INTERIMS_BY_ENTREPRISE: "/enterprises/",
  // get contrats to sign v2
  GET_CONTRAT_SIGN: "/users/",
  // get statistics v2
  GET_STATISTICS: "/users/",
  // contrat to declare hours v2
  DECLARE_HOURS_CONTRACTS: "/users/",
  //today work interim v2
  TODAY_WORK_INTERIM: "/users/",
  //send a message V2
  SEND_MESSAGE_POST: "/messages",
  // get messages v2
  GET_MESSAGES: "/messages",
  // make a new proposition
  MAKE_PROPOSITION: "/enterprises/",
  //activer ou cloturer une demande
  ACTIVATE_DEACTIVATE_DEMANDE: "/offers/",
  //get forme juridique
  //get entretiens
  LISTE_ENTRETIENS: "/interviews",
  //profile urls
  CHANGE_PHOTO: "/change-logo",
  PUBLIC_IMG: "/storage/entreprises/logos/70_1662300916_fOaI4tFNgBY1CwE7zKFSmIzf9zJnftrscIhcGD10.jpg",
  UPDATE_USER: "/update/user",
  UPLOAD_CNI_POLEEMPLOI: "/upload-user-file",
  CHANGE_PASSWORD: "/change-password",
  FORME_JURIDIQUE: "/forme-juridiques",
  GET_CNI_POLE_EMPLOI: "/get/user-document",
  DELETE_CNI_POLE_EMPLOI: "/users/document",

  //Get user
  GET_USER: "/get/user",

  //CV
  GET_METIERS: "/get/metiers",
  GET_METIER_USER: "/get/metier-candidat",
  UPDATE_METIER: "/update/metier-candidat",

  GET_EXPERIENCE_MONTHS_NUMBER: "/experiences-months",

  GET_EXPERIENCES: "/experiences",
  DELETE_EXPERIENCE: "/experiences/",
  PATCH_EXPERIENCE: "/experiences/",
  ADD_EXPERIENCE: "/experiences",

  GET_FORMATIONS: "/formations",
  DELETE_FORMATION: "/formations/",
  PATCH_FORMATION: "/formations/",
  ADD_FORMATION: "/formations",

  //Create offer
  GET_METIERS_SEARCH: "/metiers",
  GET_HARDSKILLS_SEARCH: "/hardSkills",
  GET_SOFTSKILLS_SEARCH: "/softSkills",
  GET_CERTIFICATIONS_SEARCH: "/certificateDocumentTypes",
  GET_EQUIPMENTS_SEARCH: "/equipment",
  // GET_SECTEUR_ACTIVITES: "/get/secteur-activites",
  // GET_METIER_SECTEUR_ACTIVITES_ID: "/get/metiers/secteur-activite-",
  // GET_MOTIFS: "/get/motifs",
  // GET_RISQUES: "/get/risque-professionnelles",
  // GET_EQUIPEMENTS: "/get/equipement-obligatoires",
  // GET_DIPLOMES_METIER_ID: "/get/documents/metier-",

  //test contrat
  CONTRAT: "/entreprise/candidatures/140/contrat",

  //agenda
  GET_MON_AGENDA: "/renard/my-agenda?date=",

  //Villes souhaités
  GET_VILLES_SOUHAITES: "/renard/villes-souhaite",
  ADD_VILLES_SOUHAITES: "/renard/villes-souhaite",
  DELETE_VILLES_SOUHAITES: "/renard/villes-souhaite",

  //get départements
  GET_DEPARTEMENTS: "/get/location/departements",
  GET_VILLE_BY_DEPARTEMENT: "/get/location/villes/departement-",
  //DELETE USER
  DELETE_USER: "/users/",
  //Mes offres + mission entreprise + mission renard
  GET_MES_OFFRES: "get/mes-offres",
  GET_OFFER_BY_ID: "get/mon-offre",
  GET_OFFER_DETAIL_BY_ID: "/offres/",
  DELETE_OFFER_BY_ID: "delete/offre",
  GET_PERFECT_MATCHES: "/entreprise/offres/",
  GET_CANDIDATURE_BY_ID: "/entreprise/candidatures/",
  GET_LISTE_MISSION_ENTREPRISE: "/entreprises/",
  GET_INTERIM_MISSION_ENTREPRISE: "/missions/",
  GET_RENARD_PROFILE: "/renards/",
  GET_LISTE_CONTRAT_BY_MISSION_BY_RENARD: "/missions/",
  GET_LISTE_CONTRAT_BY_FILTER: "/contrats",
  GET_MOTIF_PROBLEMS: "/problem-motifs",
  DELETE_PROBLEM_BY_ID: "/problems/",
  GET_HISTORIQUE_INTERIMAIRES: "/entreprises/",
  GET_LISTE_MISSION_RENARDS_entreprise: "/entreprises/",
  GET_LISTE_MISSION_RENARD: "/renards/",
  GET_DETAIL_ENTREPRISE_BY_MISSION_ID: "/missions/",
  GET_DETAIL_CONTRAT_RENARD: "/contrats/",
  POST_SIGNER_CONTRAT_RENARD: "/contrats/",
  GET_LAST_CONTRAT_SIGNED: "/missions/",
  PROLONGER_MISSION: "/missions/",
  //POSTULER A UNE OFFRE
  POSTULER_OFFRE: "/renard/offres/",
  //MES CANDIDATURES
  GET_MES_CANDIDATURES_RENARD: "/renard/candidatures?",
  GET_CANDIDATURE_BY_ID_RENARD: "/renard/candidatures/",
  GET_POSSIBLE_STATUS_CANDIDATURE: "/renard/candidatures/",

  //search offres
  SEARCH_OFFRES: "/search/offres",

  //search offres
  GET_MATCHED_OFFERS: "/renard/offres/matched",

  //create offre
  CREATE_OFFRE: "/offers",

  //update offre
  UPDATE_OFFRE: "/offers",

  //validations
  //LISTE DES ACOMPTE PAR FILTRE
  GET_LISTE_ACOMPTE: "/users/",
  //DEMANDER UN ACOMPTE
  POST_DEMANDER_UN_ACOMPTE: "/users/",
  //ANNULER UNE DEMANDE DACOMPTE
  DELETE_DEMANDE_ACOMPTE: "/users/",
  //LISTE FICHE DE PAIE
  GET_LISTE_FICHE_DE_PAIE: "/users/",
  //DETAILS FICHE DE PAIE
  GET_DETAILS_FICHE_DE_PAIE: "/users/",
  //Liste des factures entreprise
  GET_LISTE_DES_FACTURES: "/entreprises/",
  //Details de la facture
  GET_DETAILS_FACTURE: "/entreprises/",
  //lien de telechargement du mandat sepa
  // not used : GET_DOWNLOAD_LINK_MANDAT_SEPA: "/entreprises/",
  //signer le mandat sepa
  SIGNER_MANDAT_SEPA: "/entreprises/",
  //Obtenir mon compte utilisateur
  GET_USER_INFOS: "/get/user",
  //Liste contrats a signer candidat ,
  GET_CONTRAT_A_SIGNER: "/users/",
  //liste contrat home entreprise declarer heures
  GET_CONTRAT_DECLARER_HEURES_HOME: "/entreprises/",
  //messagerie
  GET_USER_MESSAGES: "/users/",
  // messagerie candidat
  SEND_MESSAGE: "/users/",
};
