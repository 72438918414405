import React, { useState, useRef } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Debug } from "./Debug";
import { Box, Button, Typography, Modal } from "@mui/material";
import { colors } from "../../theme/Colors";
import BoxContainerModal from "./BoxContainerModal";
import { modalStyle } from "../../theme/ModalStyle";
import ButtonSquare from "./ButtonSquare";
import { toast, ToastContainer } from "react-toastify";

const FormStepper = ({ children, initialValues, onSubmit, setFieldValue, ...props }) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  // console.log("step number to modify ::::::::::::::::::::::::::::::::: ", props.stepNumberToModify);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      bag.setTouched({});
      await step.props.onSubmit(values, bag);
    }

    if (isLastStep) {
      return onSubmit(props.formikValues, bag);
    } else {
      bag.setTouched({});
      next(values);
    }
  };

  const modalSelector = [
    {
      open: props.openModifyStep1,
      setOpen: props.setOpenModifyStep1,
    },
    {
      open: props.openModifyStep2,
      setOpen: props.setOpenModifyStep2,
    },
    {
      open: props.openModifyStep3,
      setOpen: props.setOpenModifyStep3,
    },
  ];

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  // const formRef = useRef();
  // console.log(formRef);
  // props.setFormikValues(formRef.current.values);

  return (
    <Formik initialValues={snapshot} onSubmit={handleSubmit} validationSchema={step.props.validationSchema}>
      {(formik, values) => (
        <>
          <Form>
            <ToastContainer />
            <Box sx={{ maxWidth: "400px" }}>
              {props.setFormikValues(formik.values)}
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: "40px",
                    justifyContent: "center",
                    width: "100%",
                    gap: "8px",
                  }}
                  activeStep={stepNumber}
                >
                  {steps.map((currentStep, index) => {
                    return (
                      <Box key={index}>
                        <Box
                          flex={1}
                          sx={{
                            borderRadius: "4px",
                            height: "4px",
                            bgcolor: index <= stepNumber ? colors.main : "#EBEBEE",
                          }}
                          width="94px"
                        />
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              {steps[stepNumber].props.stepName && steps[stepNumber].props.stepSubtitle && (
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "32px", textAlign: "center" }}>
                  <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
                    {steps[stepNumber].props.stepName}
                  </Typography>
                  <Typography variant="text_normal" color={colors.text_subtitle}>
                    {steps[stepNumber].props.stepSubtitle}
                  </Typography>
                </Box>
              )}
              {step}
              <Box sx={{ display: "flex", flexDirection: "row-reverse", gap: "1rem" }}>
                <Box>
                  <Button sx={{ marginTop: "1rem", width: "118px" }} variant="mj_primary_md" disabled={formik.isSubmitting} type="submit">
                    {isLastStep ? "Créer l'offre" : "Suivant"}
                  </Button>
                </Box>
                {stepNumber > 0 && (
                  <Box>
                    <Button sx={{ marginTop: "1rem", width: "118px" }} variant="mj_secondary_md" onClick={() => previous(formik.values)}>
                      Précédent
                    </Button>
                  </Box>
                )}
              </Box>

              {(props?.stepNumberToModify || props?.stepNumberToModify === 0) && (
                <Modal
                  open={modalSelector[props?.stepNumberToModify].open}
                  onClose={() => {
                    formik.setValues(snapshot);
                    modalSelector[props?.stepNumberToModify].setOpen(false);
                  }}
                >
                  <Box sx={modalStyle} maxWidth="700px">
                    <BoxContainerModal>
                      <Box sx={{ marginBottom: "40px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="text_xxl" fontWeight="700">
                          Informations de base
                        </Typography>
                        <ButtonSquare
                          onClick={() => {
                            formik.setValues(snapshot);
                            modalSelector[props?.stepNumberToModify].setOpen(false);
                          }}
                          action="close"
                        />
                      </Box>

                      {steps[props?.stepNumberToModify]}
                      <Box sx={{ display: "flex", flexDirection: "row-reverse", gap: "10px" }}>
                        <Box>
                          <Button
                            sx={{ marginTop: "1rem", width: "118px" }}
                            variant="mj_primary_md"
                            disabled={formik.isSubmitting}
                            type="submit"
                            onClick={() => {
                              if (isEmptyObject(formik.errors)) {
                                setSnapshot(formik.values);
                                modalSelector[props?.stepNumberToModify].setOpen(false);
                              } else {
                                Object.values(formik.errors).map((error) => {
                                  toast.error(error);
                                });
                              }
                            }}
                          >
                            Enregistrer
                          </Button>
                        </Box>
                        <Box>
                          <Button
                            sx={{ marginTop: "1rem", width: "118px" }}
                            variant="mj_secondary_md"
                            disabled={formik.isSubmitting}
                            onClick={() => {
                              formik.setValues(snapshot);
                              modalSelector[props?.stepNumberToModify].setOpen(false);
                            }}
                          >
                            Annuler
                          </Button>
                        </Box>
                      </Box>
                      {/* <Debug /> */}
                    </BoxContainerModal>
                  </Box>
                </Modal>
              )}
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default FormStepper;
