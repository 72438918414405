import React, { useState, useEffect } from "react";
import BoxContainer from "../../../ui/BoxContainer";
import ButtonSquare from "../../../ui/ButtonSquare";
import { Box, Button, Divider, Modal, Typography, InputAdornment, TextField, Autocomplete, Popper, CircularProgress } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import { modalStyle } from "../../../../theme/ModalStyle";
import { Formik, Form, Field } from "formik";
import MyTextField from "../../../ui/MyTextField";
import RechercheIcon from "../../../../imgs/imgV2/RechercheIcon.svg";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import MyDP from "../../../ui/MyDP";
import checkLinkicon from "../../../../imgs/imgV2/checkLinkIcon.svg";
import { useRef } from "react";
import * as Yup from "yup";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";

function Certification({ updateBord, setUpdateBoard }) {
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [file, setFile] = useState();
  const [certification, setCertification] = useState([]);
  const [listeCertification, setListeCertification] = useState([]);
  const { user } = useAuthContext();
  const [change, setChange] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const access_token = user?.access_token;
  const formCert = useRef();
  const [idCert, setIDCert] = useState();
  //function to handle when click on the modify icon
  const handleModifyClick = (cert) => {
    //console.log(cert?.document_type?.slug);
    setInitialValues({
      titreObj: cert?.certificate_document_type,
      titre: cert?.certificate_document_type?.id,
      date: cert?.expiration_date,
      file: "",
      link_file: cert?.url_file,
    });
    SearchCertif(cert?.certificate_document_type?.name);
    setFile("Piéce jointe");
    setOpen(true);
  };
  //function to handle when click on the add icon
  const handleAddClick = () => {
    setInitialValues({
      titre: "",
      date: "",
      file: "",
      link_file: "",
    });
    setFile("");
    setOpen(true);
  };
  const FILE_SIZE = 5000 * 1024;
  //validation schema fot the form
  const validationSchema = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
    date: Yup.date().required("Ce champ est obligatoire"),
    file: Yup.mixed()
      .required("Veuillez selectionner un fichier")
      .test("fileSize", "Fichier volumineux", (value) => value && value.size <= FILE_SIZE),
  });
  const validationSchemaUpdate = Yup.object().shape({
    titre: Yup.string().required("Ce champ est obligatoire"),
    date: Yup.date().required("Ce champ est obligatoire"),
  });

  const [initialValues, setInitialValues] = useState({
    titre: "",
    titreObj: "",
    date: "",
    file: "",
    link_file: "",
  });

  //get user document
  useEffect(() => {
    Api.get(Urls.GET_CERTIFICATE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 4,
      },
    })
      .then((res) => {
        //console.log(res, "certificate");
        setListeCertification(res?.data?.certificate_documents);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, [change]);
  //rechercher des certificat pour le autocomplete
  const SearchCertif = (value) => {
    Api.get(Urls.SEARCH_CERTIFICATE, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: value,
      },
    })
      .then((res) => {
        //console.log(res);
        setCertification(res?.data?.certificate_document_types);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  //create certificate
  const [progressCertificate, setProgressCertificate] = useState(0);
  var configscertificate = {
    headers: {
      "Content-type": "multipart/form-data",
      authorization: `Bearer ${access_token}`,
    },
    onUploadProgress: function (progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      setProgressCertificate(percentCompleted);
    },
  };
  const createCertificate = (values) => {
    //console.log(values.titre);
    Api.post(
      Urls.POST_CERTIFICTAE,
      {
        user_id: user?.user?.id,
        certificate_document_type_id: values.titre,
        expiration_date: values.date,
        file: values.file,
      },
      configscertificate
    )
      .then((res) => {
        //console.log(res);
        setProgressCertificate(0);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        setProgressCertificate(0);
        setUpdateBoard(!updateBord);
        toast.error(err.response.data.message);
      });
  };
  const ModifyCertificate = (values, id) => {
    //console.log(values.titre);
    Api.post(
      Urls?.UPDATE_CERTIFICATE + id,
      {
        certificate_document_type_id: values.titre,
        expiration_date: values.date,
        ...(values.file !== "" && { file: values.file }),
      },
      configscertificate
    )
      .then((res) => {
        //console.log(res);
        setProgressCertificate(0);
        toast.success(res?.data?.message);
        setIDCert();
        setChange(!change);
        setOpen(false);
      })
      .catch((err) => {
        //console.log(err);
        setProgressCertificate(0);
        setIDCert();
        toast.error(err.response.data.message);
      });
  };
  //delete certification
  const deleteCertification = () => {
    Api.delete(Urls.DELETE_CERTFIIFCATE + idCert, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setIDCert();
        setOpenDelete(false);
        setUpdateBoard(!updateBord);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 4,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setListeCertification(listeCertification.concat(res?.data?.certificate_documents));
        setData(res.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };
  return (
    <Box position="relative">
      <ToastContainer />
      {loading === false ? (
        <BoxContainer display="flex" flexDirection="column">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="text_xxl" fontWeight="700">
              🏆 Certifications
            </Typography>
            <ButtonSquare onClick={handleAddClick} action="add" />
          </Box>
          <Divider sx={{ margin: "15px 0px" }} />
          <Box display="flex" flexDirection="column" gap="20px">
            {listeCertification.map((cerf) => (
              <Box key={cerf.id} display="flex" alignItems="center" justifyContent="space-between">
                <Box>
                  <Typography fontWeight="600" fontSize="14px" lineHeight="160%">
                    {cerf?.certificate_document_type?.name}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="400" lineHeight="160%" color="#666D83">
                    {moment(cerf.expiration_date).format("MMMM YYYY")}
                  </Typography>
                </Box>
                <Box display="flex">
                  <ButtonSquare
                    onClick={() => {
                      handleModifyClick(cerf);
                      setIDCert(cerf.id);
                    }}
                    action="modify"
                  />
                  <ButtonSquare onClick={() => window.open(cerf.url_file, "_blank")} action="download-blue" />
                  <ButtonSquare
                    onClick={() => {
                      setOpenDelete(true);
                      setIDCert(cerf.id);
                    }}
                    action="delete"
                  />
                </Box>
              </Box>
            ))}
          </Box>
          {data?.links?.next && (
            <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
              <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
            </Box>
          )}
        </BoxContainer>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Certification
              </Typography>
              <ButtonSquare onClick={() => setOpen(false)} action="close" />
            </Box>
            <Formik
              innerRef={formCert}
              initialValues={initialValues}
              onSubmit={(values) => {
                if (!idCert) {
                  createCertificate(values);
                } else {
                  ModifyCertificate(values, idCert);
                }
              }}
              validationSchema={idCert ? validationSchemaUpdate : validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box gap="20px" display="flex" flexDirection="column">
                    <Box>
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Titre
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          ListboxProps={{ style: { maxHeight: "180px" } }}
                          fullWidth
                          id="combo-box-demo"
                          value={values?.titreObj}
                          onChange={(e, value) => {
                            if (value) {
                              setFieldValue("titre", value?.id);
                            }
                          }}
                          options={certification}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={values.titre}
                              onChange={(e) => {
                                if (e.target.value) {
                                  if (e.target.value?.length > 0) {
                                    SearchCertif(e?.target?.value);
                                  } else {
                                    setCertification([]);
                                  }
                                }
                              }}
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={RechercheIcon} alt="end" />
                                  </InputAdornment>
                                ),
                                endAdornment: null,
                              }}
                              placeholder="Certification..."
                            />
                          )}
                        />
                      </Box>
                      {errors.titre && touched.titre ? (
                        <Typography color="red" fontSize="12px">
                          {errors.titre}
                        </Typography>
                      ) : null}
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Date d’expiration
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field value={values.date} setFieldValue={setFieldValue} name="date" placeholder="Date..." minDate={new Date()} as={MyDP}></Field>
                        {errors.date && touched.date ? (
                          <Typography color="red" fontSize="12px">
                            {errors.date}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Pièce jointe
                          </Typography>
                        </Box>
                        <Box width="100%" position="relative">
                          <input
                            style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                            type="file"
                            name="file"
                            onChange={(e) => {
                              const doc = e.target.files[0];
                              setFieldValue("file", e.target.files[0]);
                              setFile(doc?.name);
                            }}
                          />
                          <MyTextField setFieldValue={setFieldValue} value={file} endAdornment="download" name="file" placeholder="Télécharger..." />
                        </Box>
                        <Box onClick={() => (values.link_file ? window.open(values.link_file, "_blank") : null)} marginTop="2px" alignItems="center" display="flex" gap="7px">
                          <Typography variant="text_clickable">Consulter le fichier</Typography>
                          <img src={checkLinkicon} alt="link-icon" />
                        </Box>
                        {errors.file && touched.file ? (
                          <Typography color="red" fontSize="12px">
                            {errors.file}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button
                        disabled={progressCertificate !== 0 ? true : false}
                        onClick={() => {
                          setOpen(false);
                          setCertification([]);
                        }}
                        variant="mj_secondary_md"
                      >
                        Annuler
                      </Button>
                      <Button disabled={progressCertificate !== 0 ? true : false} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>{" "}
                      {progressCertificate !== 0 && (
                        <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                          <CircularProgress />
                          <Typography fontSize="14px" fontWeight="600" color={colors.main}>
                            {progressCertificate}%
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
          setCertification([]);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Supprimer une certification
              </Typography>
              <ButtonSquare onClick={() => setOpenDelete(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir supprimer cette certification</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenDelete(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={deleteCertification} variant="mj_danger_md">
                  Supprimer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default Certification;
