import React, { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";

import { colors } from "../../theme/Colors";

import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { RadioGroup } from "@mui/material";
import RadioComponent from "../../components/ui/RadioComponent";
import arrow from "../../imgs/imgV2/arrowOrange.svg";
import logo from "../../imgs/imgV2/OhMyJob.svg";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";

function Signup() {
  const [checked, setChecked] = useState("candidat");
  const navigate = useNavigate();

  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>
          {/* SELECT CANDIDAT ENTREPRISE */}
          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                  Créer votre compte
                </Typography>
                <Typography variant="text_normal" color={colors.text_subtitle}>
                  Êtes-vous un intérimaire ou une entreprise ?
                </Typography>
              </Box>

              <Box marginBottom="32px">
                <Box sx={{ margin: "10px 0px" }}>
                  <RadioGroup sx={{ display: "flex", flexDirection: "column", gap: "10px" }} aria-labelledby="demo-radio-buttons-group-label" value="candidat" onChange={(e) => setChecked(e.target.value)} name="radio-buttons-group">
                    <RadioComponent
                      onClick={() => {
                        setChecked("candidat");
                      }}
                      val="candidat"
                      check={checked}
                      icon="candidat"
                      title="Je suis un intérimaire"
                      subtitle="Je recherche du travail."
                    />
                    <RadioComponent onClick={() => setChecked("entreprise")} val="entreprise" check={checked} icon="entreprise" title="Nous sommes une entreprise" subtitle="On recherche des talents à recruter." />
                  </RadioGroup>
                </Box>
              </Box>

              <Button disabled={checked === null ? true : false} fullWidth variant="mj_primary_md" onClick={() => navigate(`/signup-${checked}`)}>
                Suivant
              </Button>
            </Box>
            <Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="text_normal" color={colors.text_subtitle} fontWeight="500" mr>
                  Vous avez déjà un compte ?
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <Typography variant="text_normal" color={colors.main} fontWeight="500" sx={{ marginRight: "6px" }}>
                      {" "}
                    </Typography>
                    <Typography variant="text_normal" color={colors.main} fontWeight="500" sx={{ marginRight: "6px" }}>
                      Se connecter
                    </Typography>
                    <img src={arrow} alt="arrow" />
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* END SELECT CANDIDAT ENTREPRISE */}
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  );
}

// const Page = styled.div`
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: ${colors.main};

//   .formContainer {
//     border-radius: 24px;
//     align-items: center;
//     padding: 1.5rem 3rem;
//     background: white;
//     width: 550px;
//     min-height: 600px;
//   }

//   .field {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 1rem;
//   }
//   .submit {
//     margin-bottom: 1rem;
//   }

//   label {
//     margin-bottom: 0.5rem;
//     //color: ${colors.tFieldGray};
//   }
// `;

export default Signup;
