import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import @import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    .errorMessage{
    color: #ff0000;
}
}

body{
    font-size: 0.875rem;
    font-family: "Sora", "Tahoma", sans-serif;       
}

`;

export default GlobalStyle;
