import { Box, Typography, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";

function InterimaireEnPosteSection() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const id = user?.user?.enterprise?.id;
  const [interims, setInterims] = useState([]);

  useEffect(() => {
    Api.get(Urls.GET_LISTE_INTERIMS_BY_ENTREPRISE + id + "/interims", {
      headers: { "content-type": "appication/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 7,
      },
    })
      .then((res) => {
        setInterims(res?.data?.users);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box sx={{ border: "1px solid #DEDFE4", borderRadius: "6px" }}>
      {/* header */}
      <ToastContainer />
      <Box padding="24px" display="flex" flexDirection="row" gap="14px">
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          👨‍💼 Intérimaires en poste
        </Typography>
      </Box>

      {/* interimaires */}
      <Box display="flex" flexDirection="column" padding="6px 0px">
        {interims?.map((item, index) => (
          <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" padding="10px 20px">
            {/* Intérimaire avatar */}
            <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
              <Box display="flex" alignItems="center">
                <Avatar sx={{ width: 50, height: 50 }} src={item.url_avatar} />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="text_md" fontWeight="600">
                  {item.first_name} {item.last_name}
                </Typography>
                <Typography variant="text_sm" fontWeight="400">
                  {item.contract.job_title}
                </Typography>
                <Typography variant="text_md" fontWeight="500">
                  💸️{item.contract.total_amount}€ ・ ⏰️{item.contract.total_hours} heures
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default InterimaireEnPosteSection;
