import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";

export const useSignupCandidat = (email, password) => {
  const navigate = useNavigate();

  const [errorSignupCandidat, setErrorCandidat] = useState(null);
  const [isLoadingCandidat, setisLoadingCandidat] = useState(null);
  const [successSignupCandidat, setSuccessSignupCandidat] = useState(null);

  const signupCandidat = async (email, password, first_name, last_name, phone_number) => {
    setisLoadingCandidat(true);
    setErrorCandidat(null);
    setSuccessSignupCandidat(null);

    const response = await Api.post(
      Urls.SIGNUP_URL_INTERIM,
      { email, password, first_name, last_name, phone_number },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          setSuccessSignupCandidat(response.statusText);
          // save the user to local storage
          //localStorage.setItem("user", JSON.stringify(response.data));

          // update the auth context
          //dispatch({ type: "LOGIN", payload: response.data });

          // update loading state
          setisLoadingCandidat(false);
          navigate("/validate-email", { state: email });
        }
      })
      .catch((error) => {
        setErrorCandidat(error.response.data.message);
        setisLoadingCandidat(false);
      });
  };

  return {
    signupCandidat,
    isLoadingCandidat,
    errorSignupCandidat,
    successSignupCandidat,
  };
};
