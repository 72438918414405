import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ThreeDIcon from "../../../../components/ui/ThreeDIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { colors } from "../../../../theme/Colors";
import { useEffect } from "react";
import moment from "moment";
import { useState } from "react";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";

const IconWithText = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const offers = location?.state?.offer;
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");

    if (minutes === 1) {
      return "Postée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Postée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Postée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Postée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Postée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Postée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Postée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Postée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Postée il y a 1 mois";
    } else {
      return `Postée il y a ${mois} mois`;
    }
  }
  useEffect(() => {
    if (!offers) {
      navigate("/entreprise/home");
    }
  });
  // get detail offer by ID AND PEFECT CANDIDATURES
  const [offer, setDetailOffre] = useState();
  useEffect(() => {
    Api.get(`${Urls.GET_DETAILS_OFFRE}${offers?.id}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "data from el gharbi");
        setDetailOffre(res?.data?.offer);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginBottom: "32px" }}>
        <ThreeDIcon name="check" />
      </Box>

      <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
        <Typography variant="text_xl" fontSize="25px" fontWeight="700">
          Votre demande a été publiée avec succès
        </Typography>
        <Typography variant="text_normal" color={colors.text_subtitle}>
          Votre candidat idéal vous attend
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "32px" }}>
        <Typography variant="text_normal">Votre offre d'emploi a été publiée avec succès. Merci d’utiliser nos services. Nous espérons que vous trouverez rapidement le candidat idéal pour votre entreprise. Si vous avez des questions ou des préoccupations, n'hésitez pas à nous contacter.</Typography>
      </Box>

      <Box>
        <Button fullWidth variant="mj_primary_md" onClick={() => navigate("/entreprise/offres/" + offers.id, { state: { title: offer.job_title, status: offer.is_active, postedSince: afficherDifferenceDate(offer.created_at) } })}>
          Voir l'annonce
        </Button>
      </Box>
    </>
  );
};

function SuccessCreateOffer() {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
        <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "68px 16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box width="100%">
            <IconWithText />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default SuccessCreateOffer;
