import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useAuthContext } from "./useAuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useVerifyPasswordOtp = (email, otp) => {
  const [errorVerifyPasswordOtp, setErrorVerifyPasswordOtp] = useState(null);
  const [successVerifyPasswordOtp, setSuccessVerifyPasswordOtp] = useState(null);
  const [isLoadingVerifyPasswordOtp, setIsLoadingVerifyPasswordOtp] = useState(null);

  //invoke useNavigate
  const navigate = useNavigate();

  //invoke authcontext
  const { dispatch } = useAuthContext();

  const verifyPasswordOtp = async (email, otp) => {
    setIsLoadingVerifyPasswordOtp(true);
    setErrorVerifyPasswordOtp(null);
    setSuccessVerifyPasswordOtp(null);

    const response = await Api.post(
      Urls.OTP_VERIFY_FORGOT_PASSWORD,
      { email, otp },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        //save to local storage
        localStorage.setItem("user", JSON.stringify(response.data));

        // update the auth context
        dispatch({ type: "LOGIN", payload: response.data });

        // update loading state if we have a response
        setIsLoadingVerifyPasswordOtp(false);
        setSuccessVerifyPasswordOtp(response.data.message);
        toast.success(response.data.message);

        navigate("/password-change", { state: { email, otp } });
      })
      .catch((error) => {
        setIsLoadingVerifyPasswordOtp(false);
        setErrorVerifyPasswordOtp(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return {
    verifyPasswordOtp,
    isLoadingVerifyPasswordOtp,
    errorVerifyPasswordOtp,
    successVerifyPasswordOtp,
  };
};
