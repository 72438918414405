import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/Colors";
import { Container } from "@mui/material";
import Button from "@mui/material/Button";
import ThreeDIcon from "../../components/ui/ThreeDIcon";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";
import { useNavigate } from "react-router-dom";
import logo from "../../imgs/imgV2/OhMyJob.svg";

const IconWithText = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginBottom: "32px" }}>
        <ThreeDIcon name="check" />
      </Box>

      <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
        <Typography variant="text_xl" fontSize="25px" fontWeight="700">
          Mot de passe modifié avec succès
        </Typography>
        <Typography variant="text_normal" color={colors.text_subtitle}>
          Connectez-vous à votre compte avec votre nouveau mot de passe
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "32px" }}>
        <Typography variant="text_normal">Nous sommes heureux de vous informer que votre mot de passe a été modifié avec succès. Vous pouvez maintenant accéder à votre compte en utilisant votre nouveau mot de passe. </Typography>
      </Box>

      <Box>
        <Button fullWidth variant="mj_primary_md" onClick={() => navigate("/login")}>
          Accéder à la plateforme
        </Button>
      </Box>
    </>
  );
};

function AccessPlatformChangedPassword() {
  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>
          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <IconWithText />
            </Box>
          </Box>
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  );
}

export default AccessPlatformChangedPassword;
