import React from "react";
import BoxContainer from "../../../ui/BoxContainer";
import { Avatar, Box, Typography } from "@mui/material";

import EmailIcon from "../../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../../imgs/imgV2/TelIcon.svg";
import IconSpec from "../../../../imgs/imgV2/spec.svg";
import PersonIcon from "@mui/icons-material/Person";

import { ToastContainer } from "react-toastify";
function InfosRecruteurCN({ entreprise }) {
  //min date disponibility
  const today = new Date();

  // add one day to the date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <Box position="relative">
      <ToastContainer />
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column" gap="20px">
          <Box>
            <Box display="flex" alignItems="center" gap="20px">
              <Avatar sx={{ height: "80px", width: "80px", position: "relative" }}>
                <PersonIcon fontSize="large" />
              </Avatar>
              <Box display="flex" flexDirection="column">
                <Typography fontSize="16px" fontWeight="600" lineHeight="25.6px">
                  {entreprise?.recruiter_first_name ? entreprise?.recruiter_first_name : ""} {entreprise?.recruiter_last_name ? entreprise?.recruiter_last_name : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <img src={IconSpec} alt="spec-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{entreprise?.recruiter_job_position ? entreprise?.recruiter_job_position : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{entreprise?.recruiter_email ? entreprise?.recruiter_email : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{entreprise?.recruiter_phone_number ? entreprise?.recruiter_phone_number : ""}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
    </Box>
  );
}

export default InfosRecruteurCN;
