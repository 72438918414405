import React from "react";
import BoxContainer from "../../../ui/BoxContainer";
import { Box, Divider, Typography } from "@mui/material";
import ButtonSquare from "../../../ui/ButtonSquare";
function Documents({ userF }) {
  return (
    <BoxContainer backgroundColor="#000B30">
      <Box display="flex" flexDirection="column" gap="15px">
        {userF?.work_certificates?.map((certif, index) => (
          <>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "22.4px", color: "white" }}>{certif?.type === "POLE_EMPLOI" ? "Attestation pole emploi" : "Attestation de travail"}</Typography>
              <ButtonSquare onClick={() => window?.open(certif?.url_certificate, "_blank")} action="download-orange" />
            </Box>
            {index < userF?.work_certificates?.length && <Divider />}
          </>
        ))}
      </Box>
    </BoxContainer>
  );
}

export default Documents;
