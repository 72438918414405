import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useChangePassword = (email, password) => {
  const [errorChangePassword, setErrorChangePassword] = useState(null);
  const [successChangePassword, setSuccessChangePassword] = useState(null);
  const [isLoadingChangePassword, setIsLoadingChangePassword] = useState(null);

  const navigate = useNavigate();

  const resetPassword = async (new_password, email, otp) => {
    setIsLoadingChangePassword(true);
    setErrorChangePassword(null);
    setSuccessChangePassword(null);

    const response = await Api.post(
      Urls.RESET_PASSWORD,
      { new_password, email, otp },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // update loading state
          setIsLoadingChangePassword(false);
          setSuccessChangePassword(response.data.message);
          toast.success(response.data.message);

          //wait 2 sec to redirect to login
          setTimeout(() => {
            navigate("/updated-password", { replace: true });
          }, 1200);
        }
      })
      .catch((error) => {
        setIsLoadingChangePassword(false);
        setErrorChangePassword(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return {
    resetPassword,
    isLoadingChangePassword,
    errorChangePassword,
    successChangePassword,
  };
};
