import React from "react";
import Stack from "@mui/material/Stack";
import { colors } from "../../../theme/Colors";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button } from "@mui/material";
import styled from "@emotion/styled";
import "dayjs/locale/fr";

import { useNavigate } from "react-router-dom";

import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MainTitle from "../../../components/ui/MainTitle";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import { toast } from "react-toastify";

function HistoriqueInterimaires() {
  const { user } = useAuthContext();
  const access_token = user?.data?.access_token;
  const idEntreprise = user?.data?.user?.entreprise?.id;
  const [data, setData] = useState({});
  const [interim, setInterim] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  //SOME STYLING
  const StyledTable = styled(Table)({
    borderRadius: "20px",
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: `${colors.gray}`,
      fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      color: `${colors.gray}`,
      fontWeight: "600",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer",
  }));
  //GET HISTORIQUE DES INTERIMAIRES
  useEffect(() => {
    Api.get(`${Urls.GET_HISTORIQUE_INTERIMAIRES}${idEntreprise}/renards`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setData(res.data);
        setInterim(res.data.data);
        //console.log(res.data);
        setLoading(false);
      }
      if (err) {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      }
    });
  }, []);
  //handle pagination
  const handlepagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setData(res.data);
        setInterim(interim.concat(res.data.data));
      }
      if (err) {
        //console.log(err);
      }
    });
  };
  //HANDLE CLICK ROW INTERIM
  const HandleClickRow = (id) => {
    navigate(`/entreprise/historique-interimaires/${id}`);
  };
  return (
    <Box display="flex" direction="row" justifyContent="space-between" sx={{ flexDirection: { xs: "column", sm: "column", md: "row" }, "&>:nth-of-type(2)": { xs: { order: "1" }, md: { order: "2" } }, "&>:nth-of-type(1)": { xs: { order: "2" } } }}>
      {/* CONTENT*/}
      <Box sx={{ margin: { xs: "1rem 0rem 0rem 0rem", md: "1rem" } }} flex={3}>
        <MainTitle icon="c_agenda" style={{ marginBottom: "1.5rem" }} iconWidth="35" size="h4" title="Historique des intérimaires" />
        {loading === false ? (
          <Box>
            <TableContainer sx={{ borderRadius: "15px", border: `1px solid lightgray` }} component={Paper}>
              <StyledTable aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Intérimaire</StyledTableCell>
                    <StyledTableCell align="right">Missions</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {interim?.map((interim) => (
                    <StyledTableRow onClick={() => HandleClickRow(interim.id)} key={interim.id}>
                      <StyledTableCell>
                        {interim.first_name} {interim.last_name}
                      </StyledTableCell>
                      <StyledTableCell align="right">{interim.number_of_missions} Mission</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </StyledTable>
            </TableContainer>
          </Box>
        ) : (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Stack>
        )}
        {data?.links?.next ? (
          <Stack sx={{ marginBottom: "10px", marginTop: "30px" }} direction="row" justifyContent="center" alignItems="center">
            <Button onClick={handlepagination} variant="mj_secondary">
              Charger plus...
            </Button>
          </Stack>
        ) : null}
      </Box>
      {/*RIGHTBAR*/}
      <Box flex={1}></Box>
    </Box>
  );
}

export default HistoriqueInterimaires;
