import React, { useState } from "react";
import BoxContainer from "../../../ui/BoxContainer";
import ButtonSquare from "../../../ui/ButtonSquare";
import { Box, Divider, Typography } from "@mui/material";

import locationIcon from "../../../../imgs/imgV2/LocationIcon.svg";
import EmailIcon from "../../../../imgs/imgV2/EmailIcon.svg";
import TelIcon from "../../../../imgs/imgV2/TelIcon.svg";
import { PDFDownloadLink, Document, Page, Text } from "@react-pdf/renderer";
import Html from "react-pdf-html";

import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";
function PropositionCom() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userFetch, setUSerFetch] = useState();
  const [change, setChange] = useState(false);
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUSerFetch(res?.data);
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, [change]);
  const addDemande = () => {
    Api.post(
      Urls.MAKE_PROPOSITION + user?.user?.enterprise?.id + "/commercialProposalNotification",
      {},
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        //console.log(err);
      });
  };
  return (
    <Box position="relative">
      <ToastContainer />
      <BoxContainer display="flex" flexDirection="column">
        <Box display="flex" marginBottom="20px" alignItems="center" justifyContent="space-between">
          <Typography variant="text_xxl" fontWeight="700">
            Proposition commerciale{" "}
          </Typography>
        </Box>
        {userFetch?.user?.enterprise?.commercial_proposition && <></>}
        {/*<Box marginBottom="20px" display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "22.4px" }}>Télécharger</Typography>
            <PDFDownloadLink
              document={
                <Document>
                  <Page>
                    <Text>Entreprise : {userFetch?.user?.enterprise?.name}</Text>
                    <Text>Coefficient : {userFetch?.user?.enterprise?.commercial_proposition?.value}</Text>
                    <Text>Clause : </Text>
                    <Html>{userFetch?.user?.enterprise?.commercial_proposition?.clause}</Html>
                  </Page>
                </Document>
              }
              fileName="proposition-com.pdf"
            >
              {({ blob, url, loading, error }) => (loading ? "Loading document..." : <ButtonSquare action="download-orange" />)}
            </PDFDownloadLink>
            </Box>*/}

        <Divider sx={{ marginBottom: "20px" }} />
        {!userFetch?.user?.enterprise?.has_commercial_proposal_notification && (
          <Box marginBottom="20px" display="flex" alignItems="center" justifyContent="space-between">
            <Typography sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "22.4px" }}>Faire une nouvelle demande</Typography>
            <ButtonSquare onClick={() => addDemande()} action="add-outlined" />
          </Box>
        )}
        {userFetch?.user?.enterprise?.has_commercial_proposal_notification && <Typography sx={{ marginBottom: "10px", fontWeight: "500", fontSize: "14px", lineHeight: "22.4px" }}>Une demande est en cours de traitement</Typography>}
        <Divider sx={{ marginBottom: "16px" }} />
        <Box display="flex" flexDirection="column" gap="20px">
          <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
            Service commercial de l’agence affiliée
          </Typography>
          <Box display="flex" flexDirection="column" gap="8px">
            <Box display="flex" alignItems="center" gap="4px">
              <img src={locationIcon} alt="location-icon" />
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px" }}>{userFetch?.user?.agency?.address ? userFetch?.user?.agency?.address : ""}</Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="4px">
              <img src={EmailIcon} alt="email-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.agency?.email ? userFetch?.user?.agency?.email : ""}</Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="4px">
              <img src={TelIcon} alt="tel-icon" />
              <Typography variant="text_clickable">{userFetch?.user?.agency?.phone_number ? userFetch?.user?.agency?.phone_number : ""}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
    </Box>
  );
}

export default PropositionCom;
