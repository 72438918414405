import React from "react";

import { Box, Button, Typography, CircularProgress } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import MyChip from "../../../components/ui/MyChip";
import DetailContrat from "./DetailContrat";
import Entreprise from "./Entreprise";
import ContratsM from "./ContratsM";
import ListeContratDeclarerH from "./ListeContratDeclarerH";
import { useAuthContext } from "../../../hooks/useAuthContext";

function Contrats() {
  const location = useLocation();
  const idMission = location.pathname.split("/")[4];
  const id_contrat = location?.state?.contrat_id;
  const idEnt = location?.state?.idEnt;
  const mission = location?.state?.mission;
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!mission || !idEnt) {
      navigate("/renard/mes-missions");
    }
    if (id_contrat) {
      setTabs([
        {
          id: 1,
          name: "Contrat #" + id_contrat,
          icon: "📋",
        },
        {
          id: 2,
          name: "Entreprise",
          icon: "🏢",
        },
        {
          id: 3,
          name: "Contrats",
          icon: "📝",
        },
        {
          id: 4,
          name: "Heures déclarées",
          icon: "⏰",
        },
      ]);
    } else {
      setTabs([
        {
          id: 1,
          name: "Entreprise",
          icon: "🏢",
        },
        {
          id: 2,
          name: "Contrats",
          icon: "📝",
        },
        {
          id: 3,
          name: "Heures déclarées",
          icon: "⏰",
        },
      ]);
    }
    setLoading(false);
  }, []);

  const contentSelector = (contentId = 1) => {
    if (!loading && id_contrat) {
      switch (contentId) {
        case 1:
          return <DetailContrat id={id_contrat} />;
        case 2:
          return <Entreprise id={idEnt} />;
        case 3:
          return <ContratsM id={mission?.id} />;
        case 4:
          return <ListeContratDeclarerH id={mission?.id} />;
        default:
          return <DetailContrat id={id_contrat} />;
      }
    }
    if (!loading && !id_contrat) {
      switch (contentId) {
        case 1:
          return <Entreprise id={idEnt} />;
        case 2:
          return <ContratsM id={mission?.id} />;
        case 3:
          return <ListeContratDeclarerH id={mission?.id} />;
        default:
          return <Entreprise id={idEnt} />;
      }
    }
  };

  return (
    <>
      {!loading && (
        <Box display="flex" flexDirection="column" gap="40px">
          {/* header */}
          <Box display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", gap: "16px" }}>
            {/* left*/}
            <Box display="flex" flexDirection="column" gap="6px">
              <Box>
                <Typography variant="text_xl" fontWeight="700">
                  {mission?.job_title}
                </Typography>
              </Box>
              <Box>
                <MyChip variant={mission?.status === "ACTIVE" ? "active_offer" : "ended_offer"} label={mission?.status === "ACTIVE" ? "Active" : "Terminée"} />
              </Box>
            </Box>

            {/* right*/}
          </Box>

          {/* tabs */}
          <Box display="flex" width="100%" sx={{ backgroundColor: "#FAFAFB", borderRadius: "6px", padding: "2px", flexDirection: { md: "row", xs: "column" } }} gap="6px">
            {tabs.map((tab, index) => (
              <Button disableRipple fullWidth key={index} variant={selectedTab === tab.id ? "mj_primary_choice_active_lg" : "mj_primary_choice_lg"} onClick={() => setSelectedTab(tab.id)}>
                <Typography fontSize="20px" mr>
                  {tab.icon}
                </Typography>
                {tab.name}
              </Button>
            ))}
          </Box>

          {/* content */}
          {contentSelector(selectedTab)}
        </Box>
      )}
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
    </>
  );
}

export default Contrats;
