import { createContext, useReducer, useEffect } from "react";
import { Urls } from "../api/Urls";
import Api from "../api/Api";

export const UserContext = createContext();

export const userReducer = (state, action) => {
  switch (action.type) {
    case "GET_USER":
      return { userData: action.payload };
    default:
      return state;
  }
};

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {
    userData: null,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    const access_token = user?.data?.access_token;
    let userData = null;

    if (access_token) {
      Api.get(Urls.GET_USER, {
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${access_token}`,
        },
      })
        .then((response) => {
          if (response?.data?.status === "success") {
            userData = response?.data?.data?.user;

            //get the full user from ls

            const userLS = JSON.parse(localStorage.getItem("user"));
            userLS.data.user = userData;
            localStorage.setItem("user", JSON.stringify(userLS));

            if (userData) {
              dispatch({ type: "GET_USER", payload: userData });
            }
          }
        })
        .catch((e) => {});
    }
  }, []);

  return <UserContext.Provider value={{ ...state, dispatch }}>{children}</UserContext.Provider>;
};
