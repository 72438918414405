import React, { useState, useRef } from "react";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import { colors } from "../../theme/Colors";
import styled from "styled-components";
import { useLocation, Link } from "react-router-dom";
import Timer from "../../components/ui/Timer";
import { TextField, Container } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect } from "react";
import { useLogin } from "../../hooks/useLogin";
import { useVerifyPasswordOtp } from "../../hooks/useVerifyPasswordOtp";
import { useForgotPassword } from "../../hooks/useForgotPassword";
import { useNavigate } from "react-router-dom";
import { useValidateEmailOtp } from "../../hooks/useValidateEmailOtp";
import { useSendEmailOtp } from "../../hooks/useSendEmailOtp";
import logo from "../../imgs/imgV2/OhMyJob.svg";
import FormikErrorText from "../../components/ui/FormikErrorText";
import Alert from "@mui/material/Alert";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";
import Button from "@mui/material/Button";
import ThreeDIcon from "../../components/ui/ThreeDIcon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const IconWithText = () => {
  return (
    <>
      <Box display="flex" justifyContent="center" sx={{ marginBottom: "32px" }}>
        <ThreeDIcon name="message" />
      </Box>

      <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
        <Typography variant="text_xl" fontSize="25px" fontWeight="700">
          Confirmation de votre e-mail
        </Typography>
        <Typography variant="text_normal" color={colors.text_subtitle}>
          Veuillez confirmer votre e-mail pour vous connecter
        </Typography>
      </Box>

      <Box sx={{ marginBottom: "32px" }}>
        <Typography variant="text_normal">
          Pour accéder à votre compte, confirmez votre adresse e-mail en suivant les instructions envoyées à l'adresse que vous avez fournie. Si vous ne trouvez pas l'e-mail, vérifiez votre dossier spam ou contactez-nous. Nous sommes impatients de vous voir sur notre plateforme!
        </Typography>
      </Box>
    </>
  );
};

function ValidateEmail() {
  const [isWaitingForResendLocal, setIsWaitingForResendLocal] = useState(true);

  setTimeout(() => {
    setIsWaitingForResendLocal(false);
  }, 123000);

  //invoke useLocation to pass email to intial values
  const location = useLocation();

  //initialize error, isLoading, and verifyPasswordOtp function
  const { validateEmailOtp, errorValidateEmailOtp, isLoadingValidateEmailOtp, successValidateEmailOtp } = useValidateEmailOtp();

  //validation for fields
  const validationSchema = Yup.object({
    otp: Yup.string().required("Ce champs est obligatoire"),
  });

  //Form initial values
  const initialValues = {
    email: location.state,
    otp: "",
  };

  //resend initial values
  const initialValuesResend = {
    email: location.state,
  };

  //invoke validateEmailOtp that we get from usevalidateEmailOtp
  const onSubmitValidateEmail = async (values, isLoadingValidateEmailOtp, errorValidateEmailOtp, successValidateEmailOtp) => {
    await validateEmailOtp(values.email, values.otp);
  };

  //invoke Resend OTP
  const { sendEmailOtp, errorSendEmailOtp, isLoadingSendEmailOtp, successSendEmailOtp, isWaitingForResend } = useSendEmailOtp();

  //onClikc resend OTP
  const onSubmitResend = async (values, isLoadingSendEmailOtp, errorSendEmailOtp, successSendEmailOtp) => {
    await sendEmailOtp(values.email);
  };

  return location.state ? (
    <Container maxWidth={"xl"}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>

          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px 16px 64px 16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <IconWithText />

              {/* FORM FOR SENDING THE OTP */}
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitValidateEmail}>
                {({ values, error }) => (
                  <Form>
                    {/* <Typography>
                  Saisissez le code que vous avez reçu à votre adresse
                </Typography>
                <Typography className="userEmail">{values.email}</Typography> */}

                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="otp" name="name">
                          Code de vérification
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="otp" type="input" placeholder="code à 6 chiffres" id="otp" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="otp" />
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center">
                      <Button disabled={isLoadingValidateEmailOtp} className="submit" type="submit" variant="mj_primary_md" fullWidth>
                        Vérifier
                      </Button>
                    </Box>

                    {/* {errorValidateEmailOtp && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorValidateEmailOtp}
                      </Alert>
                    )} */}
                    {/* {successValidateEmailOtp && (
                      <Box>
                        <Box display="flex" alignItems="center" justifyContent="center" marginRight="auto"></Box>
                        <Alert
                          sx={{
                            border: `1px solid  ${colors.mjGreenOutline}`,
                            borderRadius: "12px",
                          }}
                          severity="success"
                        >
                          {successValidateEmailOtp}
                        </Alert>
                      </Box>
                    )} */}
                  </Form>
                )}
              </Formik>

              {/* RESEND OTP */}
              <Formik initialValues={initialValuesResend} onSubmit={onSubmitResend}>
                {({ values, error }) => (
                  <Form>
                    <Box display="flex" width="100%" sx={{ marginBottom: "1rem", marginTop: "10px" }}>
                      <Button fullWidth variant="mj_secondary_md" disabled={isWaitingForResend || isLoadingSendEmailOtp || isWaitingForResendLocal || successSendEmailOtp} disableRipple type="submit">
                        Renvoyer le mail
                      </Button>
                      <ErrorMessage name="email" />
                    </Box>

                    {isWaitingForResendLocal && (
                      <Box mb="1rem" display="flex" justifyContent="center">
                        <Timer />
                      </Box>
                    )}
                    {successSendEmailOtp && (
                      <>
                        <Box mb="1rem" display="flex" justifyContent="center">
                          <Timer />
                        </Box>
                        {/* <Alert
                          sx={{
                            border: `1px solid  ${colors.mjGreenOutline}`,
                            borderRadius: "12px",
                          }}
                          severity="success"
                        >
                          {successSendEmailOtp}
                        </Alert> */}
                      </>
                    )}
                    {/* {errorSendEmailOtp && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorSendEmailOtp}
                      </Alert>
                    )} */}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  ) : (
    "error 404"
  );
}
const Page = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.main};

  .formContainer {
    border-radius: 24px;
    align-items: center;
    padding: 1.5rem 3rem;
    background: white;
    width: 550px;
    height: 800px;
  }

  .field {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .submit {
    margin-bottom: 1rem;
  }

  label {
    margin-bottom: 0.5rem;
  }

  .timer {
    margin-right: 5px;
  }

  .userEmail {
    color: ${colors.main};
    font-weight: 600;
    margin-bottom: 1rem;
  }
`;

export default ValidateEmail;
