import React from "react";
import { useState, useEffect } from "react";
import { colors } from "../../../theme/Colors";
import { Alert, Container, Box, Stack, Button, Typography, Avatar, IconButton, TextField, InputLabel, Select, MenuItem, FormControl, CircularProgress } from "@mui/material";

import ArrowRightIcon from "../../../imgs/imgV2/ArrowRight.svg";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useUserContext } from "../../../hooks/useUserContext";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import IconValide from "../../../imgs/valide.svg";
import MySelect from "../../../components/ui/MySelect";
import defaultProfilePicEntreprise from "../../../imgs/entreprise_default.png";
import InfosEntreprise from "../../../components/fonctionnel/entreprise/MonProfil/InfosEntreprise";
import InfoAdministrativeEntreprise from "../../../components/fonctionnel/entreprise/MonProfil/InfoAdministrativeEntreprise";
import InfosRecruteurC from "../../../components/fonctionnel/entreprise/MonProfil/InfosRecruteurC";
import PropositionCom from "../../../components/fonctionnel/entreprise/MonProfil/PropositionCom";

function ProfileEntreprise() {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [UserFetch, setUserFetch] = useState({});
  const [updateBord, setUpdateBoard] = useState(false);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUserFetch(res?.data?.user?.enterprise);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  }, [updateBord]);
  const colorSelector = () => {
    if (UserFetch?.valid_steps?.step_1 && UserFetch?.valid_steps?.step_2 && UserFetch?.valid_steps?.step_3 && UserFetch?.valid_steps?.step_4) {
      return true;
    } else {
      return false;
    }
  };
  const stepSelector = () => {
    if (!UserFetch?.valid_steps?.step_1) {
      return "Informations de l’entreprise";
    }
    if (!UserFetch?.valid_steps?.step_2) {
      return "Informations administratives";
    }
    if (!UserFetch?.valid_steps?.step_3) {
      return "Informations supplémentaires";
    }
    if (!UserFetch?.valid_steps?.step_4) {
      return "Informations du recruteur";
    }
  };
  const handleClickButton = (value) => {
    if (value === "Informations de l’entreprise") {
      navigate("/entreprise/onboarding", { state: { step: 1 } });
    }
    if (value === "Informations administratives") {
      navigate("/entreprise/onboarding", { state: { step: 2 } });
    }
    if (value === "Informations supplémentaires") {
      navigate("/entreprise/onboarding", { state: { step: 3 } });
    }
    if (value === "Informations du recruteur") {
      navigate("/entreprise/onboarding", { state: { step: 4 } });
    }
  };
  //console.log("updated profile", updateBord);
  const navigateSelectActionOffer = () => {
    navigate("/entreprise/offres/option-create-offer");
  };
  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <Box>
        <Typography fontWeight="700" fontSize="28px" lineHeight="44.8px">
          Gérer votre profil
        </Typography>
      </Box>
      {!loading && UserFetch ? (
        <Box display="flex" flexDirection="column" gap="0px">
          {!colorSelector() && (
            <Box borderRadius="6px 6px 0px 0px" width="100%" bgcolor="#000B30" padding="40px 37px" display="flex" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }} justifyContent="space-between">
              <Typography fontWeight="600" color="white" fontSize="22px" lineHeight="35.2px">
                🚀 Obtenez un accès complet en complétant votre profil.
              </Typography>

              <Button onClick={() => handleClickButton(stepSelector())} sx={{ marginTop: { xs: "20px", md: "0px", lg: "0px", sm: "0px" }, marginLeft: "10px" }} variant="mj_primary_md">
                Suivant: {stepSelector()} <img src={ArrowRightIcon} alt="arrow right icon" style={{ marginLeft: "10px" }} />
              </Button>
            </Box>
          )}
          {colorSelector() && (
            <Box borderRadius="6px 6px 0px 0px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }} width="100%" bgcolor="#EDFCF5" padding="40px 37px" display="flex" justifyContent="space-between">
              <Typography fontWeight="600" fontSize="22px" lineHeight="35.2px">
                ✅ Compte complété
              </Typography>
              <Box display="flex" gap="12px" sx={{ marginTop: { xs: "20px", md: "0px", lg: "0px", sm: "0px" } }} alignItems="center">
                <Box>
                  <Button variant="mj_secondary_md" sx={{ background: "transparent" }}>
                    Votre proposition commerciale
                  </Button>
                </Box>
                <Box>
                  <Button variant="mj_primary_md" onClick={navigateSelectActionOffer}>
                    Publier une demande
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box display="flex">
            <Box borderRight="2px solid white" borderRadius="0px 0px 0px 6px" height="12px" width="25%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_1 ? colors.main : "#F6BC9B"}></Box>
            <Box borderRight="2px solid white" height="12px" width="25%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_2 ? colors.main : "#F6BC9B"}></Box>
            <Box borderRight="2px solid white" height="12px" width="25%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_3 ? colors.main : "#F6BC9B"}></Box>
            <Box borderRadius="0px 0px 6px 0px" height="12px" width="25%" backgroundColor={colorSelector() ? "#49E59F" : UserFetch?.valid_steps?.step_4 ? colors.main : "#F6BC9B"}></Box>
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      <Box display="flex" gap="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex={1}>
          <InfosEntreprise updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
        </Box>
        <Box flex={1}>
          <InfoAdministrativeEntreprise updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
        </Box>
        <Box flex={1}>
          <InfosRecruteurC updateBord={updateBord} setUpdateBoard={setUpdateBoard} />
        </Box>
      </Box>
      <Box display="flex" gap="24px" marginBottom="24px" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }}>
        <Box flex={1}>
          <PropositionCom />
        </Box>
        <Box flex={1}></Box>
        <Box flex={1}></Box>
      </Box>
    </Box>
  );
}

export default ProfileEntreprise;
