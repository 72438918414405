import React, { useEffect, useState } from "react";
import { Box, Button, MenuItem, Modal, Typography } from "@mui/material";
import MyChip from "../../../../components/ui/MyChip";
import lockIcon from "../../../../imgs/imgV2/lockIcon.svg";
import alertIcon from "../../../../imgs/imgV2/alertIcon.svg";
import ButtonSquare from "../../../../components/ui/ButtonSquare";
import moment from "moment";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { modalStyle } from "../../../../theme/ModalStyle";
import BoxContainer from "../../../../components/ui/BoxContainer";

function MissionsSection() {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const access_token = user?.access_token;
  const idUser = user?.user?.id;

  //filter states
  const [openfermer, setOpenFermer] = useState(false);
  const [openouvrir, setOpenOuvrir] = useState(false);
  const [openCloturer, setOpenCloturer] = useState(false);
  const [id, setId] = useState(null);
  const [openActive, setOpenActive] = useState(false);

  const [change, setChange] = useState(false);
  const [openOptions, setOpenOptions] = useState({
    state: false,
    id: null,
  });
  const [missions, setMissions] = useState([]);

  useEffect(() => {
    Api.get(Urls.GET_OFFRE_LIST, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 10,
      },
    })
      .then((response) => {
        setMissions(response?.data?.offers);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  }, [change]);
  function afficherDifferenceDate(date) {
    //console.log("la date que je donne", date);
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    //console.log("la date que je donne", date, minutes, hours, jours, semaines, mois);

    if (minutes === 1) {
      return "Postée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Postée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Postée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Postée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Postée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Postée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Postée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Postée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Postée il y a 1 mois";
    } else {
      return `Postée il y a ${mois} mois`;
    }
  }
  //fermer le recrutement
  const FermerRecrutement = (etat) => {
    Api.patch(
      Urls.SUSPEND_OFFER + id + "/is_application_open",
      {
        is_application_open: etat,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenFermer(false);
        setOpenOuvrir(false);
        setChange(!change);
      })
      .catch((err) => {
        toast.error(err?.resposne?.data?.message);
      });
  };
  const chipSelector = (status) => {
    switch (status) {
      case 1:
        return { variant: "active_offer", label: "Active" };
      case 0:
        return { variant: "ended_offer", label: "Terminée" };
      default:
        return {};
    }
  };
  const activateOffer = (status) => {
    Api.post(
      Urls.ACTIVATE_DEACTIVATE_DEMANDE + id + "/activate-or-deactivate",
      {
        is_active: status,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res.data);
        setOpenCloturer(false);
        toast.success(res?.data?.message);
        setOpenActive(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err.messages);
        toast.error(err?.response?.data.message);
      });
  };
  return (
    <Box>
      <Box sx={{ padding: "24px", display: "flex", flexDirection: { md: "row", xs: "column" }, border: "1px solid #DEDFE4", marginTop: "-1px", justifyContent: { md: "space-between" }, gap: "16px", alignItems: { md: "center" } }} borderRadius="6px 6px 0px 0px">
        <Box>
          <Typography fontSize="20px" fontWeight="700">
            💼 Missions
          </Typography>
        </Box>
        <Box
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
          }}
        >
          <Typography onClick={() => navigate("/entreprise/offres")} color="#0065FF" fontSize="12px" fontWeight="500">
            Voir toutes les missions
          </Typography>
        </Box>
      </Box>
      {missions.map((m, index) => (
        <Box
          sx={{
            ":hover": openOptions?.state === false ? { filter: "brightness(0.95)", background: "white" } : null,
            cursor: "pointer",
            padding: "24px",
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            border: "1px solid #DEDFE4",
            marginTop: "-1px",
            justifyContent: { md: "space-between" },
            gap: "16px",
            alignItems: { md: "center" },
          }}
          borderRadius={index === missions?.length - 1 ? "0px 0px 6px 6px " : "0px 0px 0px 0px"}
        >
          {/* infos left */}
          <Box flex={6} onClick={() => navigate(`/entreprise/offres/${m?.id}`, { state: { application_open: m?.is_application_open, title: m?.job_title, status: m?.is_active, postedSince: afficherDifferenceDate(m.created_at) } })} sx={{ cursor: "pointer" }} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" gap="8px" sx={{ marginBottom: "4px" }} alignItems="center">
              {(m.status || m.status === 0) && <MyChip variant={chipSelector(m.status).variant} label={chipSelector(m.status).label} />}
              {!m.is_application_open && <img src={lockIcon} height="18px" alt="" />}
            </Box>
            <Box>
              <Typography variant="text_md" fontWeight="500">
                {m.job_title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="text_normal" color="#4A526C">
                {afficherDifferenceDate(m.created_at)}
              </Typography>
            </Box>
            <Box>{m.some_hours_are_not_declared}</Box>
            {m.some_hours_are_not_declared && (
              <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                <img src={alertIcon} width="14px" alt="" />
                <Typography variant="text_normal" color="#CC0000" fontWeight="500">
                  Certaines heures ne sont pas déclarées
                </Typography>
              </Box>
            )}
          </Box>

          {/* infos right */}
          <Box flex={4} display="flex" sx={{ flexDirection: { md: "row", xs: "column" }, gap: { md: "20px", xs: "8px" }, alignItems: { md: "center" } }}>
            <Box onClick={() => navigate(`/entreprise/offres/${m?.id}`, { state: { application_open: m?.is_application_open, title: m?.job_title, status: m?.is_active, postedSince: afficherDifferenceDate(m.created_at) } })} sx={{ cursor: "pointer" }} flex={6} display="flex" flexDirection="row">
              <Box displa="flex" flexDirection="column">
                <Box width="120px">
                  <Typography variant="text_normal" display="inline" color="black">
                    {m.nbr_total_profiles}
                    <Typography sx={{ display: `${m.nbr_new_profiles === 0 ? "none" : "inline"}` }} fontWeight="500" fontSize="14px" lineHeight="22.4px" color="#0065FF">
                      ({m.nbr_new_profiles} nouv.)
                    </Typography>
                  </Typography>
                </Box>
                <Box width="120px">
                  <Typography variant="text_normal" color="#666D83">
                    Candidatures
                  </Typography>
                </Box>
              </Box>
              <Box displa="flex" flexDirection="column">
                <Box width="120px">
                  <Typography variant="text_normal" color="black">
                    {m?.nbr_total_hires}
                  </Typography>
                </Box>
                <Box width="120px">
                  <Typography variant="text_normal" color="#666D83">
                    Embauchés
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box flex={2} position="relative" displa="flex" flexDirection="column">
              <ButtonSquare
                action="3points_outlined"
                onClick={() => {
                  if (m.id === openOptions.id) {
                    setOpenOptions({
                      state: false,
                      id: null,
                    });
                  } else {
                    setOpenOptions({
                      state: true,
                      id: m.id,
                    });
                  }
                }}
              />
              {openOptions.state && openOptions.id === m.id && (
                <Box sx={{ position: "absolute" }} backgroundColor="white" borderRadius="6px" zIndex="99999" boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.08)" border="1px solid rgba(222, 223, 228, 1)">
                  {m.is_application_open && (
                    <MenuItem onClick={() => setOpenFermer(true)} sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                      <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                        Fermer les candidatures
                      </Typography>
                    </MenuItem>
                  )}
                  {!m.is_application_open && (
                    <MenuItem onClick={() => setOpenOuvrir(true)} sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                      <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                        ouvrir les candidatures
                      </Typography>
                    </MenuItem>
                  )}

                  {m?.is_active === 1 && (
                    <MenuItem
                      onClick={() => {
                        setId(m.id);
                        setOpenCloturer(true);
                        setOpenOptions({
                          id: null,
                          state: false,
                        });
                      }}
                      sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                    >
                      <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                        Clôturer la mission
                      </Typography>
                    </MenuItem>
                  )}
                  {m?.is_active === 0 && (
                    <MenuItem
                      onClick={() => {
                        setId(m?.id);
                        setOpenActive(true);
                        setOpenOptions({
                          id: null,
                          state: false,
                        });
                      }}
                      sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                    >
                      <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                        Activer la mission
                      </Typography>
                    </MenuItem>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ))}
      <Modal
        open={openfermer}
        onClose={() => {
          setOpenFermer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Fermer le recrutement
              </Typography>
              <ButtonSquare onClick={() => setOpenFermer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir fermer le recrutement</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenFermer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => FermerRecrutement(false)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openouvrir}
        onClose={() => {
          setOpenOuvrir(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Ouvrir le recrutement
              </Typography>
              <ButtonSquare onClick={() => setOpenOuvrir(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir ouvrir le recrutement</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenOuvrir(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => FermerRecrutement(true)} variant="mj_green_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openCloturer}
        onClose={() => {
          setOpenCloturer(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Clotuer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenCloturer(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir cloturer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenCloturer(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(0)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal
        open={openActive}
        onClose={() => {
          setOpenActive(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Activer la demande
              </Typography>
              <ButtonSquare onClick={() => setOpenActive(false)} action="close" />
            </Box>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "600", lineHeight: "160%" }}>Êtes-vous sûr de bien vouloir activer la demande</Typography>
              </Box>
              <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                <Button onClick={() => setOpenActive(false)} variant="mj_secondary_md">
                  Annuler
                </Button>
                <Button onClick={() => activateOffer(1)} variant="mj_danger_md">
                  Confirmer
                </Button>
              </Box>
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default MissionsSection;
