import { Box } from "@mui/material";
import React from "react";
import InfosEntrepriseCN from "../../../components/fonctionnel/candidat/Contrat/InfosEntrepriseCN";
import InfosRecruteurCN from "../../../components/fonctionnel/candidat/Contrat/InfosRecruteurCN";
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useState } from "react";
import Api from "../../../api/Api";
import { useEffect } from "react";
import { Urls } from "../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";

function Entreprise({ id }) {
  const { user } = useAuthContext();
  const [entreprise, setEntreprise] = useState();
  useEffect(() => {
    Api.get(Urls.GET_ENTREPRISE + id, {
      headers: { "content-type": "application/json", authorization: `Bearer ${user?.access_token}` },
    })
      .then((res) => {
        setEntreprise(res?.data?.enterprise);
        //console.log(res, "detail entreprise");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box display="flex" sx={{ flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" } }} gap="24px">
      <ToastContainer />
      <Box flex={2}>
        <InfosEntrepriseCN entreprise={entreprise} />
      </Box>
      <Box flex={2}>
        <InfosRecruteurCN entreprise={entreprise} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }} flex={1}></Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }} flex={1}></Box>
    </Box>
  );
}

export default Entreprise;
