import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useForgotPassword = (email) => {
  const [errorForgotPassword, setErrorForgotPassword] = useState(null);
  const [successForgotPassword, setSuccessForgotPassword] = useState(null);
  const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(null);
  const [isWaitingForResend, setIsWaitingForResend] = useState(null);

  const navigate = useNavigate();

  const forgotPassword = async (email) => {
    setIsWaitingForResend(true);
    setIsLoadingForgotPassword(true);
    setErrorForgotPassword(null);
    setSuccessForgotPassword(null);

    // return false;

    const response = await Api.post(
      Urls.OTP_FORGOT_PASSWORD,
      { email },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // update loading state
          setIsLoadingForgotPassword(false);
          setSuccessForgotPassword(response.data.message);
          toast.success(response.data.message);

          //wait 2 sec to redirect to password-verification
          setTimeout(() => {
            navigate("/password-verification", { state: email });
          }, 1200);

          //wait two minutes to send again
          setTimeout(() => {
            setIsWaitingForResend(false);
          }, 122000);
        }
      })
      .catch((error) => {
        setIsWaitingForResend(false);
        setIsLoadingForgotPassword(false);
        setErrorForgotPassword(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return {
    forgotPassword,
    isLoadingForgotPassword,
    errorForgotPassword,
    successForgotPassword,
    isWaitingForResend,
  };
};
