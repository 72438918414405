import { useState } from "react";
import Api from "../api/Api";
import { Urls } from "../api/Urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useSendEmailOtp = (email) => {
  const [errorSendEmailOtp, setErrorSendEmailOtp] = useState(null);
  const [successSendEmailOtp, setSuccessSendEmailOtp] = useState(null);
  const [isLoadingSendEmailOtp, setIsLoadingSendEmailOtp] = useState(null);
  const [isWaitingForResend, setIsWaitingForResend] = useState(null);

  const sendEmailOtp = async (email) => {
    setIsWaitingForResend(true);
    setIsLoadingSendEmailOtp(true);
    setErrorSendEmailOtp(null);
    setSuccessSendEmailOtp(null);

    const response = await Api.post(
      Urls.SEND_VALIDATION_OTP,
      { email },
      {
        headers: { "Content-type": "application/json" },
      }
    )
      .then((response) => {
        if (response.status === 200) {
          // update loading state
          setIsLoadingSendEmailOtp(false);
          setSuccessSendEmailOtp(response.data.message);
          toast.success(response.data.message);

          //wait two minutes to send again
          setTimeout(() => {
            setIsWaitingForResend(false);
          }, 122000);
        }
      })
      .catch((error) => {
        setIsWaitingForResend(false);
        setIsLoadingSendEmailOtp(false);
        setErrorSendEmailOtp(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };

  return {
    sendEmailOtp,
    isLoadingSendEmailOtp,
    errorSendEmailOtp,
    successSendEmailOtp,
    isWaitingForResend,
  };
};
