import { Box, Typography, Divider, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import arrowOrange from "../../../../imgs/imgV2/arrowOrange.svg";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";

function DayRecap({ userFetch }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [contrat, setContrat] = useState({});
  const [currentMeteo, setCurrentMeteo] = useState({});
  const id = user?.user?.id;
  useEffect(() => {
    Api.get(Urls.TODAY_WORK_INTERIM + id + "/today-work", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setContrat(res?.data?.contract);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, []);
  const meteo = async () => {
    if (userFetch?.profile?.travel_latitude) {
      const options = {
        method: "GET",
        url: "https://weatherapi-com.p.rapidapi.com/current.json",
        params: { lang: "fr", q: `${userFetch?.profile?.travel_latitude},${userFetch?.profile?.travel_longitude}` },
        headers: {
          "X-RapidAPI-Key": process.env.REACT_APP_WEATHER_API_KEY,
          "X-RapidAPI-Host": process.env.REACT_APP_WEATHER_API_HOST,
        },
      };
      try {
        const response = await axios.request(options);
        setCurrentMeteo(response?.data);
      } catch (error) {
        toast.error("METEO : une erreur est survenu");
      }
    }
  };
  useEffect(() => {
    meteo();
  }, [userFetch]);
  return (
    <Box sx={{ border: "1px solid #DEDFE4", borderRadius: "6px" }}>
      {/* header */}
      <ToastContainer />
      <Box padding="24px" display="flex" flexDirection="row" gap="14px">
        <Typography display="flex" flexDirection="row" alignItems="center" fontSize="20px" fontWeight="700">
          🗓️ Votre journée
        </Typography>
      </Box>

      {/* journée */}
      <Box display="flex" flexDirection="column" gap="16px" padding="0px 24px 24px 24px">
        {/* meteo */}
        <Box flexWrap="wrap" display="flex" flexDirection="row" alignItems="center" gap="30px">
          <Box display="flex" flexDirection="row" gap="12px">
            <Box width="32px" height="32px" borderRadius="50%" backgroundColor="#FAFAFB" display="flex" justifyContent="center" alignItems="center" padding="6px">
              {currentMeteo?.current?.condition?.icon && <img style={{ height: "50px", width: "50px" }} src={currentMeteo?.current?.condition?.icon} alt="meteo" />}
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="text_normal" color="#666D83">
                Météo
              </Typography>
              <Typography variant="text_normal" fontWeight="500">
                {currentMeteo?.current?.condition?.text ? currentMeteo?.current?.condition?.text : "--"}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" flexDirection="row" gap="12px">
            <Box width="32px" height="32px" borderRadius="50%" backgroundColor="#FAFAFB" display="flex" justifyContent="center" alignItems="center" padding="6px">
              <Typography fontSize="20px" padding="6px">
                🌡️
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="text_normal" color="#666D83">
                Ressentie
              </Typography>
              <Typography variant="text_normal" fontWeight="500">
                {currentMeteo?.current?.temp_c ? currentMeteo?.current?.temp_c + "°" : "--"}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* divider */}
        {contrat && (
          <Box>
            <Divider />
          </Box>
        )}

        {/* job */}
        {contrat && (
          <>
            {" "}
            <Box display="flex" flexDirection="row" gap="13px">
              <Box>
                <Avatar widht="32px" height="32px" src={contrat?.enterprise?.url_logo} />
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="text_normal" fontWeight="500">
                  {contrat?.job_title}
                </Typography>
                <Typography variant="text_normal" color="#666D83">
                  {contrat?.enterprise?.name}
                </Typography>
                <Box display="flex" flexDirection="row" gap="20px">
                  {contrat?.weekly_planner?.map((week) => (
                    <Box display="flex" flexDirection="row" flexWrap="wrap" gap="4px">
                      <Typography variant="text_normal">{week?.start_time}</Typography>
                      <img src={arrowOrange} alt="" />
                      <Typography variant="text_normal">{week?.end_time}</Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            {/* adress */}
            <Box display="flex" flexDirection="row" gap="12px">
              <Box width="32px" height="32px" borderRadius="50%" backgroundColor="#FAFAFB" display="flex" justifyContent="center" alignItems="center" padding="6px">
                <Typography fontSize="20px" padding="6px">
                  📍
                </Typography>
              </Box>

              <Box display="flex" flexDirection="column">
                <Typography variant="text_normal" color="#666D83">
                  Adresse
                </Typography>
                <Typography variant="text_normal" fontWeight="500">
                  {contrat?.location_address}
                </Typography>
                <Box sx={{ "&:hover": { cursor: "pointer" } }}>
                  <Typography variant="text_normal" fontWeight="500" color="#0065FF">
                    Voir l’itinéraire
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

export default DayRecap;
