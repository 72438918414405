import React from "react";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../../../theme/Colors";
import OBInfosEntreprise from "./OBInfosEntreprise";
import TableauDeBord from "../tableau-de-bord/TableauDeBord";
import OBInfosAdministratives from "./OBInfosAdministratives";
import OBInfosSupplementaires from "./OBInfosSupplementaires";
import OBInfosRecruteur from "./OBInfosRecruteur";
import { useLocation } from "react-router-dom";

function OBSelector() {
  const location = useLocation();
  const step = location?.state?.step;
  const [onBoardingStep, setOnBoardingStep] = useState(step ? step : 1);
  const steps = [
    { stepNumber: 1, title: "Informations de l’entreprise", subtitle: "Identité de l'entreprise" },
    { stepNumber: 2, title: "Informations administratives", subtitle: "Téléchargement de documents" },
    { stepNumber: 3, title: "Informations supplémentaires", subtitle: "Autres renseignements" },
    { stepNumber: 4, title: "Informations du recruteur", subtitle: "Coordonnées du recruteur" },
  ];

  const boardSelector = (step) => {
    switch (step) {
      case 1:
        return <OBInfosEntreprise setCurrentStep={setOnBoardingStep} />;
      case 2:
        return <OBInfosAdministratives setCurrentStep={setOnBoardingStep} />;
      case 3:
        return <OBInfosSupplementaires setCurrentStep={setOnBoardingStep} />;
      case 4:
        return <OBInfosRecruteur setCurrentStep={setOnBoardingStep} />;
      default:
        return <TableauDeBord />;
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={{ maxWidth: "400px", padding: "84px 0px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              marginBottom: "40px",
              justifyContent: "center",
              width: "100%",
              gap: "8px",
            }}
          >
            {steps.map((currentStep) => {
              return (
                <Box key={currentStep.stepNumber}>
                  <Box
                    flex={1}
                    sx={{
                      borderRadius: "4px",
                      height: "4px",
                      bgcolor: currentStep.stepNumber <= onBoardingStep ? colors.main : "#EBEBEE",
                    }}
                    width="94px"
                  />
                </Box>
              );
            })}
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "32px", textAlign: "center" }}>
          <Typography variant="text_normal" fontSize="25px" fontWeight="700" lineHeight="40px">
            {steps[onBoardingStep - 1].title}
          </Typography>
          <Typography variant="text_normal" color={colors.text_subtitle}>
            {steps[onBoardingStep - 1].subtitle}
          </Typography>
        </Box>

        {boardSelector(onBoardingStep)}
      </Box>
    </Box>
  );
}

export default OBSelector;
