import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import MissionsSection from "./MissionsSection";
import EntretienPrevuSection from "./EntretienPrevuSection";
import ReleveHeureSection from "./ReleveHeureSection";
import InterimaireEnPosteSection from "./InterimaireEnPosteSection";
import ContratsSection from "./ContratsSection";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

function TableauDeBord() {
  //invoke user
  const { user } = useAuthContext();
  const access_token = user?.access_token;

  const [userFetch, setUserFetch] = useState({});
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setUserFetch(res?.data?.user);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  }, []);
  return (
    <Box display="flex" flexDirection="column" gap="40px">
      {/* title */}
      <ToastContainer />
      <Box>
        <Typography variant="text_xl" fontWeight="700">
          Bonjour, {userFetch?.enterprise?.recruiter_first_name} 🙂
        </Typography>
      </Box>

      <Box>
        <Grid container spacing={2.5}>
          {/* left section */}
          <Grid item md={8.5} xs={12}>
            {/* MISSION */}
            <Grid xs={12} marginBottom="20px">
              <Box>
                <MissionsSection />
              </Box>
            </Grid>

            {/* Contrats */}
            <Grid xs={12} sx={{ marginBottom: { xs: "0px", md: "20ox" } }}>
              <Box>
                <ContratsSection />
              </Box>
            </Grid>
          </Grid>

          {/* right section  */}

          <Grid item md={3.5} xs={12}>
            {/* ESTIMATION */}
            {/*
            <Grid item xs={12} marginBottom="20px">
              <EstimationSection />
            </Grid>
  */}
            <Grid item xs={12} marginBottom="20px">
              <EntretienPrevuSection />
            </Grid>

            {/* RELEVE DHEURE */}
            <Grid item xs={12} marginBottom="20px">
              <ReleveHeureSection />
            </Grid>

            {/* INTERIMAIRE EN POSTE */}
            <Grid item xs={12} marginBottom="20px">
              <InterimaireEnPosteSection />
            </Grid>
          </Grid>
          <Grid item></Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TableauDeBord;
