import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Stack from "@mui/material/Stack";
import { colors } from "../../../theme/Colors";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, CardContent, Divider, TextField, InputAdornment, Grid, Autocomplete, Modal, Paper, Menu, MenuItem, Rating, Avatar, Select, InputLabel, FormControl, IconButton } from "@mui/material";
import "dayjs/locale/fr";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import candIcon from "../../../imgs/imgV2/iconCandidatures.svg";
import searchIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import propositionIcon from "../../../imgs/imgV2/propIcon.svg";
import { modalStyle, modalStyle2 } from "../../../theme/ModalStyle";
import PersonIcon from "@mui/icons-material/Person";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import CloseIcon from "@mui/icons-material/Close";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import MainTitle from "../../../components/ui/MainTitle";
import { ToastContainer, toast } from "react-toastify";
import CandidatureComponent from "../../../components/ui/CandidatureComponent";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import personIcon from "../../../imgs/imgV2/personImage.png";
import dollarIcon from "../../../imgs/imgV2/dollarIcon.svg";
import plusIconBleu from "../../../imgs/imgV2/plusIconBlue.svg";

import docgrayIcon from "../../../imgs/imgV2/DocGray.svg";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import BoxContainer from "../../../components/ui/BoxContainer";
import arrowRight from "../../../imgs/imgV2/arrowRightBlavk.svg";
import MyTextField from "../../../components/ui/MyTextField";
import MyChip from "../../../components/ui/MyChip";
import { Visibility, VisibilityOff, WidthNormalTwoTone } from "@mui/icons-material";

import CheckedCircle from "../../../components/ui/CheckedCircle";
import moment from "moment";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "../../../components/ui/FormikErrorText";
import OmjDatePicker from "../../../components/ui/OmjDatePicker";
import DetailOffreFinale from "../../candidat/content/DetailOffreFinale";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DEDFE4",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
//SOME STYLING
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function ContratsM({ id }) {
  const [open, setOpen] = useState(false);
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [contrats, setContrats] = useState([]);
  const [data, setData] = useState({});
  const [openNoter, setOpenNoter] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [date, setDate] = useState();
  const userF = {};
  const [change, setChange] = useState(false);
  const [problems, setProblems] = useState([]);
  const [openAccepter, setOpenAccepter] = useState(false);
  const [idContrat, setIdContrat] = useState();
  const [openSuspend, setOpenSuspend] = useState(false);
  const [openProblem, setOpnProblem] = useState(false);
  const [interims, setInterims] = useState([]);
  const [search, setSearch] = useState({
    interim: "",
    contrat: "",
    etat: "",
  });

  const [showPasswordDel, setShowPasswordDel] = useState(false);

  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const [openProlonger, setOpenProlonger] = useState({
    state: false,
    contrat: null,
  });
  const [openModifRem, setOpenModifRem] = useState({
    state: false,
    contrat: null,
  });
  const validationSchema = Yup.object().shape({
    start_date_time: Yup.date("La date doit être valide").typeError("La date doit être valide").required("Ce champ est obligatoire"),
    end_date_time: Yup.date("La date doit être valide").typeError("La date doit être valide").required("Ce champ est obligatoire"),
    tauxDeBase: Yup?.number().typeError("Valeur invalide").positive("Valeur invalide").required("Ce champ est obligatoire"),
  });
  const validationSchema2 = Yup.object().shape({
    start_date_time: Yup.date("La date doit être valide").typeError("La date doit être valide").required("Ce champ est obligatoire"),
    end_date_time: Yup.date("La date doit être valide").typeError("La date doit être valide").required("Ce champ est obligatoire"),
  });
  useEffect(() => {
    Api.get(Urls?.GET_LIST_INTERIMS_ENT + user?.user?.enterprise?.id + "/worked-interims", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInterims(res?.data?.users);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, []);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls.GET_CONTRATS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        filter: "default",
        ...(search?.interim ? { interimId: search?.interim } : null),
        ...(search?.etat ? { status: search?.etat } : null),
        ...(search?.contrat ? { search: search?.contrat } : null),
        offerId: id,
      },
    })
      .then((res) => {
        //console.log(res, "liste des contrats");
        setContrats(res?.data?.contracts);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [search, change]);
  useEffect(() => {
    Api.get(Urls.GET_LISTE_PROBS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "liste des probs");
        setProblems(res?.data?.enterprise_problem_reasons);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setContrats(res.data?.contracts);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setContrats([]);
      });
  };
  const rateInterim = (values) => {
    Api.post(
      Urls.RATE_INTERIM,
      {
        interim_id: idContrat?.user?.id,
        rating_scale: ratingValue,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpenNoter(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const [anchorElUser, setAnchorElUser] = useState(null);
  const ficheDePaie = [1, 2, 3, 4, 5, 6];
  const Suspend = (values) => {
    Api.post(
      Urls.SUSPEND_CONTRAT + idContrat?.id + "/suspend",
      {
        enterprise_problem_reason_id: values?.problem_id,
        detail: values?.comments,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenSuspend(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.resposne?.data?.message);
      });
  };
  const DeclareProblem = (values) => {
    Api.post(
      Urls.DECLACRE_PROB + idContrat?.id + "/problems",
      {
        enterprise_problem_reason_id: values?.problem_id,
        detail: values?.comments,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpnProblem(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.resposne?.data?.message);
      });
  };
  function addOneDay(dateString) {
    var date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    //console.log(date);
    return date;
  }
  const etats = [
    { id: "CURRENT", name: "En cours" },
    { id: "PENDING", name: "En attente" },
    { id: "FINISHED", name: "Terminés" },
    { id: "CANCELED", name: "Annulés" },
    { id: "UPCOMING", name: "À venir" },
    { id: "SUSPENDED", name: "Suspendus" },
  ];
  function afficherDifferenceDate(date) {
    const now = moment();
    const diffe = moment(date);
    const minutes = now.diff(diffe, "minutes");
    const hours = now.diff(diffe, "hours");
    const jours = now.diff(diffe, "days");
    const semaines = now.diff(diffe, "weeks");
    const mois = now.diff(diffe, "months");
    if (minutes === 1) {
      return "Créé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Créé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Créé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Créé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Créé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Créé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Créé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Créé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Créé il y a 1 mois";
    } else {
      return `Créé il y a ${mois} mois`;
    }
  }
  const prolongation = (values) => {
    Api.post(
      Urls.PROLONGATION_VALUE_AVENANT + idContrat?.id + "/avenants",
      {
        contract_type: "PROLONGATION",
        end_date: values.end_date_time,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenProlonger(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const avenantValeur = (values) => {
    Api.post(
      Urls.PROLONGATION_VALUE_AVENANT + idContrat?.id + "/avenants",
      {
        contract_type: "VALUE",
        basic_hourly_rate: values.tauxDeBase,
        start_date: values.start_date_time,
        end_date: values.end_date_time,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setOpenModifRem(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterCandidature = (id, password) => {
    Api.patch(
      Urls.SIGN_CONTRAT_ID + id + "/sign",
      {
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <ToastContainer />
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={6}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher un contrat
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    //console.log(event.target.value);
                    setSearch({
                      ...search,
                      contrat: event.target.value,
                    });
                    if (event.target.value === null) {
                      setSearch({
                        ...search,
                        contrat: "",
                      });
                    }
                  } else {
                    setSearch({
                      ...search,
                      contrat: "",
                    });
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Intérimaire
                </Typography>
              </Box>
              <Box display="flex" gap="5px" alignItems="center">
                <FormControl sx={{ height: "42px" }} fullWidth>
                  <Select
                    sx={{ height: "42px", borderColor: "#DEDFE4" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={search?.interim}
                    onChange={(e) =>
                      setSearch({
                        ...search,
                        interim: e.target.value,
                      })
                    }
                  >
                    {interims?.map((interim) => (
                      <MenuItem key={interim.id} value={interim?.id}>
                        <Box display="flex" alignItems="center" gap="5px">
                          <Box marginTop="5px">{interim?.url_avatar ? <img src={interim?.url_avatar} style={{ height: "32px", width: "32px", borderRadius: "50%" }} alt="proile-pic" /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Box>
                          <Box>
                            {interim?.first_name} {interim?.last_name}
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <Box>
                  <CloseIcon
                    onClick={() =>
                      setSearch({
                        ...search,
                        interim: "",
                      })
                    }
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>

              <Autocomplete
                //options={[offerStates]}
                options={etats}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    setSearch({
                      ...search,
                      etat: value?.id,
                    });
                    //console.log(value);
                  } else {
                    //console.log(null);
                    setSearch({
                      ...search,
                      etat: "",
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!loading ? (
        <TableContainer sx={{ zIndex: 1, borderRadius: "6px", border: `1px solid #DEDFE4` }}>
          <StyledTable aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#DEDFE4" }}>
              <TableRow>
                <StyledTableCell width="20%">Numéro</StyledTableCell>
                <StyledTableCell width="20%">Intérimaire</StyledTableCell>
                <StyledTableCell width="10%" align="center">
                  État
                </StyledTableCell>
                <StyledTableCell width="20%">Dates</StyledTableCell>
                <StyledTableCell width="12.5%" align="center">
                  Signatures
                </StyledTableCell>
                <StyledTableCell width="12.5%" align="center">
                  Montant
                </StyledTableCell>
                <StyledTableCell width="5%" align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody position="relative">
              {contrats?.map((contrat, index) => (
                <StyledTableRow>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="10px">
                      <img src={contrat?.contract_type === "INIT" ? docgrayIcon : contrat?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
                      <Typography>{contrat?.id}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="10px">
                      <Avatar sx={{ height: "32px", width: "32px", position: "relative" }}>{contrat?.user?.url_avatar ? <img src={contrat?.user?.url_avatar} alt="profile-pic" style={{ height: "32px", width: "32px", borderRadius: "50%" }} /> : <PersonIcon sx={{ fontSize: "25px" }} />}</Avatar>{" "}
                      <Typography>
                        {contrat?.user?.first_name} {contrat?.user?.last_name}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="center"
                  >
                    {contrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                    {contrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                    {contrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                    {contrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                    {contrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
                    {contrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                    {contrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="left"
                  >
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>{moment(contrat?.start_date).format("DD MMMM YYYY")}</Typography>
                      <img src={arrowRight} alt="icon-arrow" />
                      <Typography>{moment(contrat?.end_date).format("DD MMMM YYYY")}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {(contrat?.signed_by_enterprise_at || contrat?.status !== "PENDING") && (
                      <Box
                        onClick={() => {
                          setOpen(true);
                          setIdContrat(contrat);
                        }}
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        justifyContent="center"
                      >
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_interim_at ? true : false} height="12px" width="12px" />
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_enterprise_at ? true : false} height="12px" width="12px" />
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_admin_at ? true : false} height="12px" width="12px" />
                      </Box>
                    )}
                    {!contrat?.signed_by_enterprise_at && contrat?.status === "PENDING" && (
                      <Box>
                        <Button
                          variant="mj_primary_md"
                          onClick={() => {
                            window.open("https://auth.coffreo.cc/", "_blank");
                            setIdContrat(contrat);
                          }}
                        >
                          Signer
                        </Button>
                      </Box>
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="center"
                  >
                    <Typography fontWeight="600" color="black">
                      {contrat?.contract_type === "VALUE" ? "-" : contrat?.total_amount + "€"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      onClick={() => {
                        if (anchorElUser === contrat?.id) setAnchorElUser(null);
                        else {
                          setAnchorElUser(contrat?.id);
                          setIdContrat(contrat);
                        }
                      }}
                    >
                      <ButtonSquare key={data?.id} action="3points" />
                    </Box>
                    <Box zIndex="999" right="12%" backgroundColor="white" border="1px solid rgba(222, 223, 228, 1)" boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.08)" borderRadius="6px" position="absolute" display={anchorElUser === contrat?.id ? "inherit" : "none"}>
                      {contrat?.status === "CURRENT" && (
                        <MenuItem
                          onClick={() => {
                            setOpenModifRem({
                              state: true,
                              contrat: contrat,
                            });
                            setAnchorElUser(false);
                          }}
                          sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                        >
                          <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                            Modifier rémunération
                          </Typography>
                        </MenuItem>
                      )}
                      {contrat?.status === "CURRENT" && (
                        <MenuItem
                          onClick={() => {
                            setOpenProlonger({
                              state: true,
                              contrat: contrat,
                            });
                            setAnchorElUser(false);
                          }}
                          sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                        >
                          <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                            Prolonger intérimaire
                          </Typography>
                        </MenuItem>
                      )}

                      <MenuItem
                        onClick={() => {
                          setOpenNoter(true);
                          setAnchorElUser(false);
                        }}
                        sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                      >
                        <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                          Noter la mission
                        </Typography>
                      </MenuItem>
                      {contrat?.url_contract_enterprise && (
                        <MenuItem
                          onClick={() => {
                            if (contrat?.url_contract_enterprise) {
                              window.open(contrat?.url_contract_enterprise, "_blank");
                            }
                          }}
                          sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                        >
                          <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                            Télécharger
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          setOpnProblem(true);
                          setAnchorElUser(null);
                          setIdContrat(contrat);
                        }}
                        sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                      >
                        <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                          Déclarer un problème
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setOpenSuspend(true);
                          setAnchorElUser(null);
                        }}
                        sx={{ height: "42px", width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}
                      >
                        <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                          Demande de suspension
                        </Typography>
                      </MenuItem>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          {" "}
          <CircularProgress />
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box zIndex={2} sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                  <ButtonSquare onClick={() => setOpen(false)} action="close" />
                  <Typography variant="text_xxl" fontWeight="700">
                    Contrat #{idContrat?.id}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" gap="8px">
                  <Button variant="mj_secondary_md">Noter la mission</Button>
                  {idContrat?.url_contract_enterprise && (
                    <Button onClick={() => window.open(idContrat?.url_contract_enterprise, "_blank")} variant="mj_secondary_md">
                      Télécharger
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setOpenSuspend(true);
                      setAnchorElUser(null);
                    }}
                    variant="mj_secondary_md"
                  >
                    Demande de suspension
                  </Button>
                  <Button
                    onClick={() => {
                      setOpnProblem(true);
                      setAnchorElUser(null);
                    }}
                    variant="mj_secondary_md"
                  >
                    Déclarer un problème
                  </Button>
                  {idContrat?.status === "CURRENT" && (
                    <Button
                      onClick={() => {
                        setOpenProlonger({
                          state: true,
                          contrat: idContrat,
                        });
                        setAnchorElUser(false);
                      }}
                      variant="mj_secondary_md"
                    >
                      {" "}
                      Prolonger intérimaire
                    </Button>
                  )}
                  {idContrat?.status === "CURRENT" && (
                    <Button
                      onClick={() => {
                        setOpenModifRem({
                          state: true,
                          contrat: idContrat,
                        });
                        setAnchorElUser(false);
                      }}
                      variant="mj_secondary_md"
                    >
                      Modifier rémunération
                    </Button>
                  )}{" "}
                </Box>
              </Box>
              <Box display="flex" gap="14px" alignItems="center">
                {idContrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                {idContrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                {idContrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                {idContrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                {idContrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
                {idContrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                {idContrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}{" "}
                <Typography fontSize="12px" color="#4A526C" fontWeight="400" lineHeight="160%">
                  {afficherDifferenceDate(date)}
                </Typography>
              </Box>
              <Typography marginTop="10px" fontSize="12px" fontWeight="600" color="red">
                {idContrat?.suspended_at && "Suspendu le : " + moment(idContrat?.suspended_at).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <Box>
              <DetailOffreFinale setDate={setDate} type="contratFinale" id={idContrat?.id} />
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openNoter} onClose={() => setOpenNoter(false)}>
        <Box sx={modalStyle}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                Noter la mission
              </Typography>
              <ButtonSquare action="close" onClick={() => setOpenNoter(false)} />
            </Box>
            <Box marginBottom="20px" display="flex" alignItems="center" gap="20px">
              {idContrat?.user?.url_avatar ? (
                <img src={idContrat?.user?.url_avatar} alt="userF-pic" style={{ height: "100px", width: "100px", borderRadius: "50%" }} />
              ) : (
                <Avatar variant="rounded" sx={{ borderRadius: "50%", height: "80px", width: "80px" }}>
                  <PersonIcon sx={{ fontSize: 80 }} />
                </Avatar>
              )}
              <Box>
                <Typography sx={{ lineHeight: "28.8px", fontSize: "18px", fontWeight: "600", cursor: "pointer" }}>
                  {idContrat?.user?.first_name} {idContrat?.user?.last_name}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Formik
                initialValues={{
                  comments: "",
                }}
                onSubmit={(values) => {
                  rateInterim(values);
                }}
              >
                {({ values, errors, setFieldValue, touched }) => (
                  <Form>
                    {" "}
                    <Box marginBottom="20px">
                      <Box marginBottom="6px">
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                          Sélectionner une note
                        </Typography>
                        <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline" color={colors.main}>
                          *
                        </Typography>
                      </Box>
                      <Rating
                        precision={1}
                        name="simple-controlled"
                        sx={{ color: colors.main }}
                        value={ratingValue}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                      />
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenNoter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={ratingValue !== 0 ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={openProblem} onClose={() => setOpnProblem(false)}>
        <Box sx={modalStyle}>
          <Box padding="24px">
            <Formik
              initialValues={{
                comments: "",
                problem_id: "",
              }}
              onSubmit={(values) => {
                DeclareProblem(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                      Déclarer un problème
                    </Typography>
                    <ButtonSquare action="close" onClick={() => setOpnProblem(false)} />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Box>
                          <Typography sx={{ display: "inline" }} variant="text_normal" fontWeight="600">
                            Motif du problème
                          </Typography>
                          <Typography sx={{ display: "inline" }} variant="text_normal" fontWeight="600" color={colors.main}>
                            *
                          </Typography>
                        </Box>
                      </Box>
                      <Autocomplete
                        //options={[offerStates]}
                        options={problems}
                        getOptionLabel={(e) => e.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value !== null) {
                            //console.log("selecting problm state value", value);
                            //console.log("selecting problm state value", value.id);
                            setFieldValue("problem_id", value?.id);
                            //setSelectedOfferState(value ? value.id : "");
                          } else {
                            //console.log(null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Sélectionner le motif du problème" />}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo">
                          Détailler votre problème
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Field fullWidth name="comments" type="input" placeholder="Veuillez nous donner plus de détails par rapport à votre problème..." id="comments" as={MyTextField} multiline columns={5} rows={4} autoComplete="off" />
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpnProblem(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.comments && values?.problem_id ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openSuspend} onClose={() => setOpenSuspend(false)}>
        <Box sx={modalStyle}>
          <Box padding="24px">
            <Formik
              initialValues={{
                comments: "",
                problem_id: "",
              }}
              onSubmit={(values) => {
                Suspend(values);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                      Demande de suspension
                    </Typography>
                    <ButtonSquare action="close" onClick={() => setOpenSuspend(false)} />
                  </Box>
                  <Box marginBottom="16px">
                    <Typography fontSize="20px" lineHeight="160%" fontWeight="700">
                      Dates
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Box>
                          <Typography sx={{ display: "inline" }} variant="text_normal" fontWeight="600">
                            Motif du problème
                          </Typography>
                          <Typography sx={{ display: "inline" }} variant="text_normal" fontWeight="600" color={colors.main}>
                            *
                          </Typography>
                        </Box>
                      </Box>
                      <Autocomplete
                        //options={[offerStates]}
                        options={problems}
                        getOptionLabel={(e) => e.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, value) => {
                          if (value !== null) {
                            //console.log("selecting problm state value", value);
                            //console.log("selecting problm state value", value.id);
                            setFieldValue("problem_id", value?.id);
                            //setSelectedOfferState(value ? value.id : "");
                          } else {
                            //console.log(null);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Sélectionner le motif du problème" />}
                      />
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo">
                          Détailler votre problème
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tasksToDo" name="tasksToDo" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Field fullWidth name="comments" type="input" placeholder="Veuillez nous donner plus de détails par rapport à votre problème..." id="comments" as={MyTextField} multiline columns={5} rows={4} autoComplete="off" />
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenSuspend(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.comments && values?.problem_id ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openModifRem?.state}
        onClose={() =>
          setOpenModifRem({
            state: false,
            contrat: null,
          })
        }
      >
        <Box sx={modalStyle}>
          <Box padding="24px">
            <Formik
              initialValues={{
                start_date_time: "",
                end_date_time: openModifRem?.contrat?.end_date,
                tauxDeBase: "",
              }}
              onSubmit={(values) => {
                avenantValeur(values);
              }}
              validationSchema={validationSchema}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                      Modifier rémunération
                    </Typography>
                    <ButtonSquare
                      action="close"
                      onClick={() =>
                        setOpenModifRem({
                          state: false,
                          contrat: null,
                        })
                      }
                    />
                  </Box>
                  <Box marginBottom="16px">
                    <Typography fontSize="20px" lineHeight="160%" fontWeight="700">
                      Dates
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" gap="16px" width="100%">
                      <Box width="50%">
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time">
                            Début
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field minDate={addOneDay(new Date())} fullWidth={true} background="lightgray" name="start_date_time" type="input" id="start_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                        <ErrorMessage component={FormikErrorText} name="start_date_time" />
                      </Box>
                      <Box width="50%">
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time">
                            Fin
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field disabled={true} fullWidth={true} name="end_date_time" type="input" id="end_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                        <ErrorMessage component={FormikErrorText} name="end_date_time" />
                      </Box>
                    </Box>
                  </Box>
                  <Divider sx={{ marginTop: "40px", marginBottom: "40px" }} />
                  <Box sx={{ marginBottom: "6px" }}>
                    <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase">
                      Taux horaire brute
                    </Typography>
                    <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase" style={{ color: colors.main }}>
                      *
                    </Typography>
                  </Box>
                  <Box marginBottom="20px">
                    <Box width="100px">
                      <Field name="tauxDeBase" type="input" placeholder="0" id="tauxDeBase" as={MyTextField} endAdornment="euro" autoComplete="off" />
                    </Box>
                    <ErrorMessage component={FormikErrorText} name="tauxDeBase" />
                  </Box>
                  <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                    <Button onClick={() => setOpenModifRem(false)} variant="mj_secondary_md">
                      Annuler
                    </Button>
                    <Button disabled={values?.tauxDeBase && values?.end_date_time && values?.start_date_time ? false : true} type="submit" variant="mj_primary_md">
                      Enregistrer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openProlonger?.state}
        onClose={() =>
          setOpenProlonger({
            state: false,
            contrat: null,
          })
        }
      >
        <Box sx={modalStyle}>
          <Box padding="24px">
            <Formik
              initialValues={{
                end_date_time: "",
                start_date_time: addOneDay(openProlonger?.contrat?.end_date),
              }}
              onSubmit={(values) => {
                prolongation(values);
              }}
              validationSchema={validationSchema2}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="32px" fontWeight="700" lineHeight="51.2px">
                      Prolonger
                    </Typography>
                    <ButtonSquare
                      action="close"
                      onClick={() =>
                        setOpenProlonger({
                          state: false,
                          contrat: null,
                        })
                      }
                    />
                  </Box>
                  <Box marginBottom="16px">
                    <Typography fontSize="20px" lineHeight="160%" fontWeight="700">
                      Dates
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" gap="16px" width="100%">
                      <Box width="50%">
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time">
                            Début
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field disabled={true} fullWidth={true} background="lightgray" name="start_date_time" type="input" id="start_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                        <ErrorMessage component={FormikErrorText} name="start_date_time" />
                      </Box>
                      <Box width="50%">
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time">
                            Fin
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field minDate={addOneDay(addOneDay(openProlonger?.contrat?.end_date))} fullWidth={true} name="end_date_time" type="input" id="end_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                        <ErrorMessage component={FormikErrorText} name="end_date_time" />
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenProlonger(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.end_date_time && values?.start_date_time ? false : true} type="submit" variant="mj_primary_md">
                        Enregistrer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      {contrats.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
      {contrats?.length === 0 && !loading && <Typography textAlign="center">Aucun contrat</Typography>}
      {contrats?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(idContrat?.id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Box>
                      <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                    </Box>
                    <Box>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{idContrat?.id}
                      </Typography>
                    </Box>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ContratsM;
