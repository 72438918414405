import React from "react";
import { Box, Typography, Button, Modal, FormControlLabel, Checkbox } from "@mui/material";
import BoxContainer from "./BoxContainer";
import MyChip from "./MyChip";
import iconPoint from "../../imgs/imgV2/point.svg";
import IconJob from "../../imgs/imgV2/spec.svg";
import arrowRightIcon from "../../imgs/imgV2/arrowRightBlavk.svg";
import moment from "moment";
import { useState } from "react";
import { modalStyle } from "../../theme/ModalStyle";
import ButtonSquare from "./ButtonSquare";
function OffreComponent({ openPostulerOffre, setOpenPostulerOffre, handelClickOffer, handlePostuler, postulerIDS, id, matchingLevel, createdAt, start_date, end_date, hourly_rate, address, offreName, posteName, detail }) {
  const [openPostuler, setOpenPostuler] = useState(false);
  const [checkedPostuler, setCheckedPostuler] = useState(false);
  function afficherDifferenceDate(date) {
    const now = moment();
    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");
    //console.log(createdAt, "dates");
    if (minutes === 1) {
      return "Postée il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Postée il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Postée il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Postée il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Postée il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Postée il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Postée il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Postée il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Postée il y a 1 mois";
    } else {
      return `Postée il y a ${mois} mois`;
    }
  }
  return (
    <Box sx={{ ":hover": { filter: "brightness(0.95)", background: "white" } }} width="100%" position="relative">
      <BoxContainer width="100%">
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box sx={{ cursor: "pointer" }} flex={5} onClick={() => handelClickOffer(id)}>
              {matchingLevel !== "weak" && <MyChip variant="matching_parfait" label="💡 Offre suggérée" />}
              {matchingLevel === "weak" && (
                <Box sx={{ cursor: "pointer" }} onClick={() => handelClickOffer(id)}>
                  <Typography sx={{ cursor: "pointer", fontSize: "18px", fontWeight: "500", lineHeight: "28.8px" }}>{offreName}</Typography>
                </Box>
              )}
            </Box>
            <Box flex={1}>
              {postulerIDS?.includes(id) && <MyChip onClick={() => handelClickOffer(id)} variant="skill" label="Vous avez postulé" />}
              {!postulerIDS?.includes(id) && (
                <Button variant="mj_primary_md" onClick={() => setOpenPostuler(true)}>
                  Postuler
                </Button>
              )}
            </Box>
          </Box>
          <Box sx={{ cursor: "pointer" }} onClick={() => handelClickOffer(id)}>
            {matchingLevel !== "weak" && (
              <Box marginBottom="4px">
                <Typography sx={{ fontSize: "18px", fontWeight: "500", lineHeight: "28.8px", cursor: "pointer" }}>{offreName}</Typography>
              </Box>
            )}
            <Box marginBottom="4px">
              <MyChip sx={{ cursor: "pointer" }} variant="skill" label={posteName} icon={IconJob} />
            </Box>
            <Box marginBottom="20px">
              <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "22.4px", color: "#4A526C" }}>{afficherDifferenceDate(createdAt)}</Typography>
            </Box>
            <Box marginBottom="20px" display="flex" sx={{ flexDirection: { xs: "column", sm: "column", lg: "row", md: "row" }, alignItems: { sm: "inherit", xs: "inherit", md: "center", lg: "center" } }} gap="8px">
              <Box>
                <Typography>💰{hourly_rate}€/h</Typography>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                <img src={iconPoint} alt="icon-point" />
              </Box>
              <Box>
                <Typography>📍{address}</Typography>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" } }}>
                <img src={iconPoint} alt="icon-point" />
              </Box>
              <Box display="flex" gap="8px">
                <Box>
                  <Typography>🗓 {moment(start_date).format("DD MMMM YYYY")}</Typography>
                </Box>
                <Box>
                  <img src={arrowRightIcon} alt="icon-point" />
                </Box>
                <Box>
                  <Typography>{moment(end_date).format("DD MMMM YYYY")}</Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: "400", fontSize: "14px", lineHeight: "22.4px" }}>{detail}</Typography>
            </Box>
          </Box>
        </Box>
      </BoxContainer>
      <Modal
        open={openPostuler}
        onClose={() => {
          setOpenPostuler(false);
        }}
      >
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box display="flex" justifyContent="end" alignItems="end">
              <ButtonSquare onClick={() => setOpenPostuler(false)} action="close" />
            </Box>
            <Typography marginBottom="16px" variant="text_xxl" fontWeight="700">
              Voulez-vous postuler pour ce poste ?
            </Typography>
            <Box display="flex" flexDirection="column" gap="22px" marginBottom="46px">
              <Box marginBottom="10px" display="flex" justifyContent="center">
                <Typography sx={{ fontSize: "14px", fontWeight: "400", lineHeight: "160%", textAlign: "center" }}>Êtes-vous sûr(e) de vouloir postuler pour ce poste ?</Typography>
              </Box>
              <Box>
                <FormControlLabel
                  onChange={(e) => {
                    setCheckedPostuler(e.target.checked);
                  }}
                  control={<Checkbox checked={checkedPostuler} />}
                  label="Je possède les certifications demandées."
                />
              </Box>
            </Box>
            <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
              <Button
                onClick={() => {
                  setOpenPostuler(false);
                }}
                variant="mj_secondary_md"
              >
                Annuler
              </Button>
              <Button
                disabled={!checkedPostuler}
                onClick={() => {
                  handlePostuler(id)
                    .then((res) => {
                      setOpenPostuler(false);
                    })
                    .catch((err) => {
                      setOpenPostuler(true);
                    });
                }}
                variant="mj_primary_md"
              >
                Envoyer
              </Button>{" "}
            </Box>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default OffreComponent;
