import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";
import frLocale from "date-fns/locale/fr";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";

const MyDPFullDate = ({ children, setFieldValue, minDate, maxDate, color, ...props }) => {
  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const [value, setValue] = React.useState();

  return (
    <LocalizationProvider adapterLocale={frLocale} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        disabled={props.disabled}
        components={{ OpenPickerIcon: CalendarMonthIcon }}
        name={props.name}
        value={props.value}
        maxDate={maxDate}
        minDate={minDate}
        onChange={(newValue) => {
          setFieldValue(props.name, moment(newValue).format("yyyy-MM-DD"));
          setValue(newValue);
        }}
        InputProps={{ sx: { "& .MuiSvgIcon-root": { color: `${color}` } } }}
        renderInput={(params) => {
          return (
            <TextField
              fullWidth
              type="date"
              autoComplete="off"
              {...params}
              error={false}
              onKeyDown={onKeyDown}
              inputProps={{
                ...params.inputProps,
                placeholder: "jj/mm/aaaa",
              }}
            />
          );
        }}
      >
        {children}
      </DesktopDatePicker>
    </LocalizationProvider>
  );
};

export default MyDPFullDate;
