import React from "react";
import { colors } from "../../../theme/Colors";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography, CardContent, Divider, TextField, InputAdornment, Grid, Autocomplete, Modal, Paper, IconButton, FormControlLabel, Checkbox, FormControl, FormGroup } from "@mui/material";
import "dayjs/locale/fr";
import leftArrowIcon from "../../../imgs/imgV2/leftArrowBlack.svg";
import arrowRightIcon from "../../../imgs/imgV2/arrowRightBlavk.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import dollarIcon from "../../../imgs/imgV2/dollarIcon.svg";

import { modalStyle, modalStyle2 } from "../../../theme/ModalStyle";
import styled from "@emotion/styled";
import plusIconBleu from "../../../imgs/imgV2/plusIconBlue.svg";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../../hooks/useAuthContext";
import Api from "../../../api/Api";
import { Urls } from "../../../api/Urls";
import RechercheIcon from "../../../imgs/imgV2/RechercheIcon.svg";
import { toast } from "react-toastify";
import ButtonSquare from "../../../components/ui/ButtonSquare";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import docgrayIcon from "../../../imgs/imgV2/DocGray.svg";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import BoxContainer from "../../../components/ui/BoxContainer";
import arrowRight from "../../../imgs/imgV2/arrowRightBlavk.svg";
import MyTextField from "../../../components/ui/MyTextField";
import MyChip from "../../../components/ui/MyChip";
import CheckedCircle from "../../../components/ui/CheckedCircle";
import DetailsListeContrat from "./DetailsListeContrat";
import moment from "moment";
import { Field, Form, Formik } from "formik";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#DEDFE4",
    color: `#0D173A`,
    fontSize: 12,
    fontWeight: 500,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: `#666D83`,
    fontWeight: "500",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  cursor: "pointer",
}));
//SOME STYLING
const StyledTable = styled(Table)({
  borderRadius: "6px",
});
function ContratsM({ id }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [contrats, setContrats] = useState([]);
  const [data, setData] = useState({});
  const [openSigner, setOpenSigner] = useState(false);
  const [idContrat, setIdContrat] = useState();
  const [showPasswordDel, setShowPasswordDel] = useState(false);
  const [date, setDate] = useState();
  const [change, setChange] = useState(false);
  const [search, setSearch] = useState({
    interim: "",
    contrat: "",
    etat: "",
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Api.get(Urls.GET_CONTRATS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        filter: "default",
        ...(search?.etat ? { status: search?.etat } : null),
        ...(search?.contrat ? { search: search?.contrat } : null),
        missionId: id,
      },
    })
      .then((res) => {
        //console.log(res, "liste des contrats");
        setContrats(res?.data?.contracts);
        setData(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  }, [search, change]);
  //show password
  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  //handle Pagination
  const handlePagination = (link) => {
    //console.log("getting data");
    Api.get(`${link}`, {
      headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log("RESULTAT MATCHED offres DATA", res?.data);
        //console.log("RESPONES GHERBI", res);
        setData(res?.data);
        setContrats(res.data?.contracts);
        //console.log(res?.data?.meta?.links, "link pag");
      })
      .catch((err) => {
        //console.log(err);
        setContrats([]);
      });
  };
  const etats = [
    { id: "CURRENT", name: "En cours" },
    { id: "PENDING", name: "En attente" },
    { id: "FINISHED", name: "Terminés" },
    { id: "CANCELED", name: "Annulés" },
    { id: "UPCOMING", name: "À venir" },
    { id: "SUSPENDED", name: "Suspendus" },
    { id: "CREATING", name: "En cours de création" },
  ];
  function afficherDifferenceDate(date) {
    const now = moment();
    const diffe = moment(date);
    //console.log(date);
    const minutes = now.diff(diffe, "minutes");
    const hours = now.diff(diffe, "hours");
    const jours = now.diff(diffe, "days");
    const semaines = now.diff(diffe, "weeks");
    const mois = now.diff(diffe, "months");
    if (minutes === 1) {
      return "Créé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Créé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Créé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Créé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Créé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Créé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Créé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Créé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Créé il y a 1 mois";
    } else {
      return `Créé il y a ${mois} mois`;
    }
  }
  const AccepterCandidature = (id, password) => {
    Api.patch(
      Urls.SIGN_CONTRAT_ID + id + "/sign",
      {
        candidate_status: "SIGNED",
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success("Offre acceptée");
        setOpenSigner(false);
        setChange(!change);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const ficheDePaie = [1, 2, 3, 4, 5, 6];
  const [open, setOpen] = useState(false);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Box sx={{ backgroundColor: "#FAFAFB", padding: "40px", borderRadius: "6px" }}>
        <Box>
          <Grid container spacing="16px">
            <Grid item xs={12} md={9}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  Rechercher un contrat
                </Typography>
              </Box>
              <MyTextField
                fullWidth
                autoComplete="off"
                type="input"
                placeholder="Rechercher"
                onChange={(event) => {
                  if (event.target.value) {
                    //console.log(event.target.value);
                    setSearch({
                      ...search,
                      contrat: event.target.value,
                    });
                    if (event.target.value === null) {
                      setSearch({
                        ...search,
                        contrat: "",
                      });
                    }
                  } else {
                    setSearch({
                      ...search,
                      contrat: "",
                    });
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={RechercheIcon} alt="end" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Box sx={{ marginBottom: "6px" }}>
                <Typography variant="text_normal" fontWeight="600" htmlFor="lieu" name="lieu">
                  État
                </Typography>
              </Box>
              <Autocomplete
                //options={[offerStates]}
                options={etats}
                getOptionLabel={(e) => e.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, value) => {
                  if (value !== null) {
                    setSearch({
                      ...search,
                      etat: value?.id,
                    });
                    //console.log(value);
                  } else {
                    //console.log(null);
                    setSearch({
                      ...search,
                      etat: "",
                    });
                  }
                }}
                renderInput={(params) => <TextField {...params} name="autocomplete" placeholder="Tous" />}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      {!loading ? (
        <TableContainer sx={{ borderRadius: "6px", border: `1px solid #DEDFE4` }}>
          <StyledTable aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#DEDFE4" }}>
              <TableRow>
                <StyledTableCell width="40%">Numéro</StyledTableCell>
                <StyledTableCell width="10%" align="center">
                  État
                </StyledTableCell>
                <StyledTableCell width="20%">Dates</StyledTableCell>
                <StyledTableCell width="12.5%" align="center">
                  Signatures
                </StyledTableCell>
                <StyledTableCell width="12.5%" align="center">
                  Montant
                </StyledTableCell>
                <StyledTableCell width="5%" align="center"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contrats?.map((contrat, index) => (
                <StyledTableRow>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                  >
                    <Box display="flex" alignItems="center" gap="10px">
                      <img src={contrat?.contract_type === "INIT" ? docgrayIcon : contrat?.contract_type === "PROLONGATION" ? plusIconBleu : dollarIcon} alt="doc-icon" />
                      <Typography>{contrat?.id}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="center"
                  >
                    {contrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                    {contrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                    {contrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                    {contrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                    {contrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                    {contrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}

                    {contrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="left"
                  >
                    <Box display="flex" alignItems="center" gap="10px">
                      <Typography>{moment(contrat?.start_date).format("DD MMMM YYYY")}</Typography>
                      <img src={arrowRight} alt="icon-arrow" />
                      <Typography>{moment(contrat?.end_date).format("DD MMMM YYYY")}</Typography>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {(contrat?.signed_by_interim_at || contrat?.status !== "PENDING") && (
                      <Box
                        onClick={() => {
                          setOpen(true);
                          setIdContrat(contrat);
                        }}
                        display="flex"
                        alignItems="center"
                        gap="6px"
                        justifyContent="center"
                      >
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_interim_at ? true : false} height="12px" width="12px" />
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_enterprise_at ? true : false} height="12px" width="12px" />
                        <CheckedCircle bgcolor={contrat?.signed_by_interim_at && contrat?.signed_by_enterprise_at && contrat?.signed_by_admin_at ? null : "#EA5B0C"} checked={contrat?.signed_by_admin_at ? true : false} height="12px" width="12px" />
                      </Box>
                    )}
                    {!contrat?.signed_by_interim_at && contrat?.status === "PENDING" && (
                      <Box>
                        <Button
                          variant="mj_primary_md"
                          onClick={() => {
                            window.open("https://auth.coffreo.cc/", "_blank");
                            setIdContrat(contrat);
                          }}
                        >
                          Signer
                        </Button>
                      </Box>
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setIdContrat(contrat);
                    }}
                    align="center"
                  >
                    <Typography fontWeight="600" color="black">
                      {contrat?.contract_type === "VALUE" ? "-" : contrat?.total_amount + "€"}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <ButtonSquare
                      onClick={() => {
                        if (contrat?.url_contract_interim) window.open(contrat?.url_contract_interim, "_blank");
                      }}
                      action="download-blue"
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
      {contrats.length > 0 && <Divider sx={{ margin: "24px 0px" }} />}
      {contrats?.length === 0 && !loading && <Typography textAlign="center">Aucun contrat</Typography>}
      {contrats?.length > 0 && (
        <Box marginBottom="24px" display="flex" alignItems="center" gap="20px" justifyContent="space-between">
          <Box flex={1}>
            {data?.links?.prev && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img style={{ marginRight: "8px" }} src={leftArrowIcon} alt="arrow-left" /> Précédent
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.prev);

                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={leftArrowIcon} alt="arrow-left" />
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ flex: { xs: 8, sm: 8, md: 6, lg: 6 } }} display="flex" gap="10px" justifyContent="center">
            {data?.meta?.links?.map((link) => (
              <ButtonSquare
                onClick={() => {
                  handlePagination(link.url);
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                action={link?.active ? "number-active" : "number"}
                number={link.label}
              />
            ))}
          </Box>
          <Box flex={1}>
            {data?.links?.next && (
              <>
                <Button
                  sx={{ display: { xs: "none", sm: "none", lg: "inherit", md: "inherit" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  Suivant <img style={{ marginLeft: "8px" }} src={arrowRightIcon} alt="arrow-right" />
                </Button>
                <Button
                  sx={{ display: { xs: "inherit", sm: "inherit", lg: "none", md: "none" } }}
                  onClick={() => {
                    handlePagination(data?.links?.next);
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  variant="mj_secondary_md"
                >
                  <img src={arrowRightIcon} alt="arrow-right" />
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" gap="10px" alignItems="center">
                  <ButtonSquare onClick={() => setOpen(false)} action="close" />
                  <Typography variant="text_xxl" fontWeight="700">
                    Contrat #{idContrat?.id}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" gap="14px" alignItems="center">
                {idContrat?.status === "CURRENT" && <MyChip variant="active_offer" label="En cours" />}
                {idContrat?.status === "CANCELED" && <MyChip variant="rejected_offer" label="Annulé" />}
                {idContrat?.status === "SUSPENDED" && <MyChip variant="rejected_offer" label="Suspendu" />}
                {idContrat?.status === "CREATING" && <MyChip variant="skill" label="En cours de création" />}
                {idContrat?.status === "UPCOMING" && <MyChip variant="skill" label="À venir" />}
                {idContrat?.status === "FINISHED" && <MyChip variant="ended_offer" label="Terminé" />}
                {idContrat?.status === "PENDING" && <MyChip variant="pending_contract" label="En attente" />}{" "}
                <Typography fontSize="12px" color="#4A526C" fontWeight="400" lineHeight="160%">
                  {afficherDifferenceDate(date)}
                </Typography>
              </Box>
              <Typography marginTop="10px" fontSize="12px" fontWeight="600" color="red">
                {idContrat?.suspended_at && "Suspendu le : " + moment(idContrat?.suspended_at).format("DD MMMM YYYY")}
              </Typography>
            </Box>
            <DetailsListeContrat setDate={setDate} id={idContrat?.id} />
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openSigner} onClose={() => setOpenSigner(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Accepter l’offre
              </Typography>
              <ButtonSquare onClick={() => setOpenSigner(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(idContrat?.id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                      <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                      <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                        En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{idContrat?.id}
                      </Typography>
                      <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                        <Box width="100%">
                          <Box marginBottom="6px">
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                              Mot de passe
                            </Typography>
                            <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                              *
                            </Typography>
                          </Box>
                          <Field name="password" id="password">
                            {({ field, form, meta }) => (
                              <TextField
                                {...field}
                                fullWidth
                                placeholder="Saisir votre mot de passe"
                                autoComplete="Off"
                                type={showPasswordDel ? "text" : "password"}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                      <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                        {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          </Field>
                          {errors.password && touched.password ? (
                            <Typography color="red" fontSize="12px">
                              {errors.password}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenSigner(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ContratsM;
