import React from "react";
import PersonIcon from "@mui/icons-material/Person";

import { AppBar, Toolbar, Typography, Container, Box, styled, Button, Avatar, IconButton, TextField, Modal } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ViewListIcon from "@mui/icons-material/ViewList";
import { NavLink } from "react-router-dom";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";

import { colors } from "../../theme/Colors";
import IconFox from "../../imgs/imgV2/OhMyJob.svg";
import IconOffres from "../../imgs/offres.svg";
import IconMissions from "../../imgs/missions.svg";
import IconHome from "../../imgs/home.svg";
import { useNavigate } from "react-router-dom";
import Api from "../../api/Api";

import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Urls } from "../../api/Urls";
import { replace } from "formik";
import { useState, useEffect } from "react";
import NotificationIcon from "../../imgs/imgV2/notification.svg";

import profilMenuIcon from "../../imgs/imgV2/OpenMenuProfil.svg";
import ElipseIcon from "../../imgs/imgV2/Ellipse.svg";
import langueIcon from "../../imgs/imgV2/langue.svg";

import { toast } from "react-toastify";

const StyledToolbar = styled(Toolbar)({
  display: "flex",
});

const Logo = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Navlinks = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
});

const UserSection = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
});

const NavButton = styled(Button)({
  textTransform: "none",
  paddingLeft: "20px",
  paddingRight: "20px",
  borderRadius: "0px",
  color: "inherit",
  "&.hover": {
    backgroundColor: "lightgray",
  },
});

const MyIconButton = styled(IconButton)({
  textTransform: "none",
  padding: "0.65rem",
  borderRadius: "6px",
  "&.hover": {
    background: "orange",
  },
});

const NotificationButton = styled(IconButton)({
  textTransform: "none",
  "&.hover": {
    backgroundColor: "white",
  },
});
const ProfileButton = styled(IconButton)({
  padding: "4px 4px",
  textTransform: "none",
  borderRadius: "6px",
  "&.hover": {
    backgroundColor: "white",
  },
});

const NavbarCandidat = ({ pusher }) => {
  //invoke user for AT
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [userFetch, setUSerFetch] = useState();
  const [data, setData] = useState({});
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [newNotif, setNewNotif] = useState(false);
  const [anchorElNotif, setAnchorElNotif] = React.useState(null);
  const [change, setChange] = useState(false);
  const [notifs, setNotifs] = useState([]);
  useEffect(() => {
    if (pusher) {
      const channel = pusher.subscribe(`messaging.user.${user?.user?.id}`);
      channel?.bind("NewNotificationEvent", function (data) {
        let notif = { ...data.notification, new: true };
        setNotifs((notifs) => [notif, ...notifs]);
        setNewNotif(true);
      });
    }
    if (pusher) {
      return () => {
        pusher.unsubscribe(`messaging.user.${user?.user?.id}`);
      };
    }
  }, [pusher]);
  useEffect(() => {
    Api.get(Urls.GET_USER_NOTIFS + user?.user?.id + "/notifications", {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    })
      .then((res) => {
        setNotifs(res?.data?.notifications);
        setData(res?.data);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  }, [change]);
  let length = 0;
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        //console.log(res, "user");
        setUSerFetch(res?.data?.user);
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  //navigate Mon compte
  const navigateMonCompte = () => {
    setAnchorElUser(null);
    navigate("/renard/compte");
  };

  //navigate settings
  const navigateparams = () => {
    setAnchorElUser(null);
    navigate("/renard/parametres");
  };

  //navigate fiche de paie
  const navigateFicheDePaie = () => {
    setAnchorElUser(null);
    navigate("/renard/fiche-de-paie");
  };

  //navigate demande d'acompte
  const navigateAcompte = () => {
    setAnchorElUser(null);
    navigate("/renard/demande-acompte");
  };
  const navSelector = (nav) => {
    switch (nav) {
      case "NEW_PAYSLIP":
        navigate("/renard/fiches-de-paie");
      case "APPLICATION_REFUSED_BY_ENTERPRISE":
        navigate("/renard/mes-candidatures");
      case "NEW_MISSION_PROPOSAL":
        navigate("/renard/mes-candidatures");
    }
  };
  const handlePagination = () => {
    Api.get(`${data.links.next}`, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        perPage: 5,
      },
    }).then((res, err) => {
      if (res?.request?.status === 200) {
        setNotifs(notifs.concat(res?.data?.notifications));
        setData(res.data);
      }
      if (err) {
        toast.error(err?.response?.data?.message);
      }
    });
  };

  const logout = () => {
    setAnchorElUser(null);

    Api.post(
      Urls.LOGOUT,
      { access_token: access_token },
      {
        headers: { "Content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    ).then((response) => {
      //console.log("ResponseLogout //////", response);
      if (response.status === 200) {
        localStorage.removeItem("user");
        navigate("/login", replace);
        navigate(0, replace);
      }
    });
  };

  const [imageSrc, setImageSrc] = useState("");

  const getProfilePic = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const image = user?.data?.user?.profile?.photo;

    var oReq = new XMLHttpRequest();
    oReq.open("GET", `${image}`, true);
    oReq.setRequestHeader("authorization", `Bearer ${access_token}`);
    oReq.responseType = "arraybuffer";
    oReq.onload = function (oEvent) {
      var arrayBuffer = oReq.response; // Note: not oReq.responseText
      if (arrayBuffer) {
        var u8 = new Uint8Array(arrayBuffer);
        var b64encoded = btoa(String.fromCharCode.apply(null, u8));
        var mimetype = "image"; // or whatever your image mime type is
        // document.getElementById("yourimageidhere").src="data:"+mimetype+";base64,"+b64encoded;
        document.getElementById("imgNavbar").src = "data:;base64," + b64encoded;
      }
    };
    oReq.send(null);
  };
  const open = Boolean(anchorElNotif);

  useEffect(() => {
    getProfilePic();
  }, []);
  const handleClickNotifs = (e) => {
    setAnchorElNotif(e.currentTarget);
    setNewNotif(false);
  };
  const handleClose = () => {
    setAnchorElNotif(null);
    setChange(!change);
    setNewNotif(false);
  };
  const navigateToNotifications = () => {
    navigate("/renard/Notifications");
  };
  return (
    <Box position="relative">
      <AppBar variant="mj_navbar" sx={{ marginBottom: "2rem", background: "white", borderBottom: "1px solid lightgray", width: "100%" }} position="sticky">
        <Container maxWidth="100%" sx={{ height: "56px", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <StyledToolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box display="flex" alignItems="center">
              <Logo
                onClick={() => {
                  navigate("/renard/tableau-de-bord");
                }}
                sx={{ cursor: "pointer", display: { xs: "none", sm: "block", md: "block", lg: "block" } }}
              >
                <img src={IconFox} height={36} alt="malin job logo" style={{ marginRight: "1rem" }} />
              </Logo>

              <Box display="flex" gap="2rem" alignItems="center">
                <Navlinks display={{ xs: "none", sm: "none", md: "none", lg: "flex" }}>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/tableau-de-bord">
                    <NavButton sx={{ height: "56px", fontSize: "14px", fontWeight: "400" }}> Tableau de bord</NavButton>
                  </NavLink>

                  <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/offres/offres-suggerees">
                    <NavButton sx={{ height: "56px", fontSize: "14px", fontWeight: "400" }}>Trouver un job</NavButton>
                  </NavLink>

                  <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/mes-missions">
                    <NavButton sx={{ height: "56px", fontSize: "14px", fontWeight: "400" }}>Mes missions</NavButton>
                  </NavLink>

                  <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/mes-candidatures">
                    <NavButton sx={{ height: "56px", fontSize: "14px", fontWeight: "400" }}>Mes candidatures</NavButton>
                  </NavLink>

                  {/* <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/mes-fiches-de-paie">
                    <NavButton sx={{ height: "56px", fontSize: "14px", fontWeight: "400" }}>Mes Fiches de paie</NavButton>
                  </NavLink> */}
                </Navlinks>

                <Navlinks display={{ xs: "flex", sm: "flex", md: "flex", lg: "none" }}>
                  <NavLink className={({ isActive }) => (isActive ? "active" : "navlink")} style={{ textDecoration: "none" }} to="/renard/tableau-de-bord">
                    <MyIconButton sx={{ height: "56px" }}>
                      <img style={{ width: "15px", height: "15px" }} src={IconHome} />
                    </MyIconButton>
                  </NavLink>

                  <NavLink style={{ textDecoration: "none" }} className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/offres/offres-suggerees">
                    <MyIconButton sx={{ height: "56px" }}>
                      <img style={{ width: "15px", height: "15px" }} src={IconOffres} />
                    </MyIconButton>
                  </NavLink>

                  <NavLink style={{ textDecoration: "none" }} className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/mes-missions">
                    <MyIconButton sx={{ height: "56px" }}>
                      <HomeRepairServiceIcon sx={{ width: "15px", height: "15px", color: `${colors.main}` }} />
                    </MyIconButton>
                  </NavLink>

                  <NavLink style={{ textDecoration: "none" }} className={({ isActive }) => (isActive ? "active" : "navlink")} to="/renard/mes-candidatures">
                    <MyIconButton sx={{ height: "56px" }}>
                      <ViewListIcon sx={{ width: "15px", height: "15px", color: `${colors.main}` }} />
                    </MyIconButton>
                  </NavLink>
                </Navlinks>
              </Box>
            </Box>
            <UserSection>
              <Box sx={{ display: "flex", alignItems: "center", borderRight: "1px solid lightgray" }} paddingRight="20px">
                <NotificationButton sx={{ position: "relative" }} onClick={handleClickNotifs}>
                  {newNotif && <Box zIndex="999" top="3px" left="3px" position="absolute" height="7px" width="7px" backgroundColor="red" borderRadius="50%"></Box>}
                  <img style={{ width: "15px", height: "15px" }} src={NotificationIcon} alt="notif-icon" />
                </NotificationButton>
                <NotificationButton onClick={() => navigate("/renard/FAQ")}>
                  <img style={{ width: "15px", height: "15px" }} src={langueIcon} alt="langue-icon" />
                </NotificationButton>
              </Box>
              <Menu
                anchorEl={anchorElNotif}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    backgroundColor: `white`,
                    borderRadius: "10px",
                    overflow: "auto",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    maxHeight: "500px",
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                {" "}
                <Box sx={{ overflowY: "auto", width: { xs: "300px", md: "400px", sm: "400px", lg: "400px" }, padding: "18px 18px" }}>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography marginLeft="6px" fontSize="14px" fontWeight="bold">
                      Notifications
                    </Typography>

                    {notifs?.map((notif) => (
                      <Box onClick={() => navSelector(notif.type)} sx={{ padding: "5px", borderRadius: "6px", cursor: "pointer", ":hover": { filter: "brightness(0.95)", background: "white" } }} display="flex" alignItems="flex-start" gap="5px">
                        {notif?.new && <img src={ElipseIcon} alt="elipse-icon" style={{ marginTop: "8px" }} />}
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                          <Typography style={{ fontWeight: "400", fontSize: "14px", lineHeight: "160%", color: "black" }}>{notif?.title}</Typography>
                          <Typography sx={{ fontSize: "12px", fontWight: "400", color: "#666D83", lineHeight: "160%" }}>{notif?.created_at}</Typography>
                        </Box>
                      </Box>
                    ))}
                    {data?.links?.next && (
                      <Box sx={{ cursor: "pointer" }} display="flex" justifyContent="center" alignItems="center">
                        <ControlPointRoundedIcon fontSize="large" sx={{ color: `${colors.main}` }} onClick={handlePagination} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Menu>
              <Box display="flex" gap="10px" alignItems="center">
                <ProfileButton onClick={handleOpenUserMenu}>
                  <Box display="flex" gap="6px" alignItems="center">
                    <IconButton sx={{ p: 0 }}>
                      <Avatar sx={{ width: "24px", height: "24px" }}>{userFetch?.url_avatar === null ? <PersonIcon fontSize="small" /> : <img style={{ width: "24px", height: "24px" }} id="imgNavbar" src={userFetch?.url_avatar} alt="" />} </Avatar>
                    </IconButton>
                    <Box>
                      <Typography color="black" sx={{ display: { xs: "none", sm: "none", md: "inherit", lg: "inherit" }, lineHeight: "160%", fontSize: "12px", fontWeight: "400" }}>
                        {userFetch?.first_name} {userFetch?.last_name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "inherit", lg: "inherit", sm: "none" } }}>
                      <img src={profilMenuIcon} style={{ marginLeft: "2px" }} alt="profil-menu-icon" />
                    </Box>
                  </Box>
                </ProfileButton>
                <Menu
                  sx={{ mt: "40px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }} onClick={navigateMonCompte}>
                    <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Mon profil
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={navigateparams} sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                    <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Paramètres
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={navigateFicheDePaie} sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                    <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Fiches de paies
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={navigateAcompte} sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                    <Typography textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Demande d'acompte
                    </Typography>
                  </MenuItem>
                  <MenuItem sx={{ width: { xs: "inherit", lg: "200px", md: "200px", sm: "inherit" } }}>
                    <Typography onClick={logout} textAlign="center" sx={{ fontSize: "14px", fontWeight: "400" }}>
                      Se déconnecter
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
            </UserSection>
          </StyledToolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default NavbarCandidat;
