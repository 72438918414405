import React from "react";
import { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Grid } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { colors } from "../../../../theme/Colors";
import FormikErrorText from "../../../../components/ui/FormikErrorText";
import * as Yup from "yup";
import Api from "../../../../api/Api";
import { Urls } from "../../../../api/Urls";
import { useAuthContext } from "../../../../hooks/useAuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function OBInfosRecruteur({ setCurrentStep }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const navigate = useNavigate();
  //validation
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handlePrevious = () => {
    setCurrentStep(3);
  };

  const [initialValuesInfoRecruteur, setInitialValuesInfoRecruteur] = useState({
    recruiter_first_name: "",
    recruiter_email: "",
    recruiter_last_name: "",
    phone_number: "",
    recruiter_job_position: "",
  });

  //validation schema infoRecruteur
  const validationSchemaInfosRecruteur = Yup.object({
    recruiter_first_name: Yup.string().required("Ce champ est obligatoire"),
    recruiter_email: Yup.string().email("Le format de l'email est invalide").required("Ce champ est obligatoire"),
    recruiter_last_name: Yup.string().required("Ce champ est obligatoire"),
    phone_number: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide").required("Ce champs est obligatoire"),
    recruiter_job_position: Yup.string().required("Ce champ est obligatoire"),
  });

  //get existing info recruteur
  useEffect(() => {
    Api.get(Urls.GET_USER_REC, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
    })
      .then((res) => {
        setInitialValuesInfoRecruteur({
          recruiter_first_name: res?.data?.user?.enterprise?.recruiter_first_name ? res?.data?.user?.enterprise?.recruiter_first_name : "",
          recruiter_email: res?.data?.user?.enterprise?.recruiter_email ? res?.data?.user?.enterprise?.recruiter_email : "",
          recruiter_last_name: res?.data?.user?.enterprise?.recruiter_last_name ? res?.data?.user?.enterprise?.recruiter_last_name : "",
          phone_number: res?.data?.user?.enterprise?.phone_number ? res?.data?.user?.enterprise?.phone_number : "",
          recruiter_job_position: res?.data?.user?.enterprise?.recruiter_job_position ? res?.data?.user?.enterprise?.recruiter_job_position : "",
        });
      })
      .catch((err) => toast.error(err?.response?.data?.message));
  }, []);

  //function to modify the profile
  const onSubmitInfoRecruteur = (values) => {
    //console.log(values, "form values");
    Api.patch(
      Urls?.UPDATE_PROFILE_ENTREPRISE + user?.user?.enterprise?.id,
      {
        recruiter_first_name: values.recruiter_first_name,
        recruiter_email: values.recruiter_email,
        recruiter_last_name: values.recruiter_last_name,
        phone_number: values.phone_number,
        recruiter_job_position: values.recruiter_job_position,
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        navigate("/entreprise/tableau-de-bord");
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  return (
    <>
      <Formik enableReinitialize={true} initialValues={initialValuesInfoRecruteur} validationSchema={validationSchemaInfosRecruteur} onSubmit={onSubmitInfoRecruteur}>
        {({ values }) => (
          <Form>
            <Box sx={{ marginBottom: "32px" }}>
              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="recruiter_last_name">
                    Nom
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="recruiter_last_name" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="recruiter_last_name" type="text" placeholder="Doe" id="recruiter_last_name" as={TextField}></Field>
                <ErrorMessage name="recruiter_last_name" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="recruiter_first_name">
                    Prénom
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="name" name="recruiter_first_name" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="recruiter_first_name" type="text" placeholder="Doe" id="recruiter_first_name" as={TextField}></Field>
                <ErrorMessage name="recruiter_first_name" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="job" name="recruiter_job_position">
                    Poste
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="job" name="recruiter_job_position" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="recruiter_job_position" type="text" placeholder="Saisir votre poste..." id="recruiter_job_position" as={TextField}></Field>
                <ErrorMessage name="recruiter_job_position" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="recruiter_email" name="recruiter_email">
                    Email du recruteur
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="recruiter_email" name="recruiter_email" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="recruiter_email" type="text" placeholder="votre@email.com" id="recruiter_email" as={TextField}></Field>
                <ErrorMessage name="recruiter_email" component={FormikErrorText} />
              </Box>

              <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                <Box sx={{ marginBottom: "6px" }}>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="phone_number" name="phone_number">
                    Numéro de téléphone
                  </Typography>
                  <Typography variant="text_normal" fontWeight="600" htmlFor="phone_number" name="phone_number" style={{ color: colors.main }}>
                    *
                  </Typography>
                </Box>
                <Field autoComplete="off" name="phone_number" type="text" placeholder="+33 000 000 000" id="phone_number" as={TextField}></Field>
                <ErrorMessage name="phone_number" component={FormikErrorText} />
              </Box>
            </Box>

            <Grid container spacing={1}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} onClick={() => handlePrevious()} variant="mj_secondary_md">
                  Précédent
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button sx={{ width: "118px" }} type="submit" variant="mj_green_md">
                  Enregistrer
                </Button>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default OBInfosRecruteur;
