import React from "react";
import { Box, Typography } from "@mui/material";
import image1 from "../../../imgs/imgV2/lucas.png";
import { useState, useEffect } from "react";
import "./carouselSignup.scss";
import { colors } from "../../../theme/Colors";

const StepBar = ({ ...props }) => {
  return (
    <>
      <Box className={props.className} onClick={props.onClick} />
    </>
  );
};

const steps = [
  {
    paragraph: "“Photographe basé à Paris, je suis spécialisé dans une variété de sujets, de la vie quotidienne aux événements en passant par la photographie culinaire”",
    name: "Lucas",
    job: "photographe",
    image: image1,
  },
  {
    paragraph: "“Developpeur Un e-mail contenant un lien de réinitialisation de mot de passe a été envoyé à l'adresse e-mail que vous avez fournie. Veuillez vérifier votre boîte de réception (y compris le dossier spam) pour trouver l'e-mail.”",
    name: "Hamid",
    job: "Développeur",
    image: image1,
  },
  {
    paragraph: "“Designer basé à Simustapha, je suis spécialisé dans une variété de sujets, de la vie quotidienne aux événements en passant par la photographie culinaire”",
    name: "Said",
    job: "Designer",
    image: image1,
  },
];

function CarouselSignup() {
  useEffect(() => {
    const interval = setInterval(() => {
      setSelected((prevSelected) => (prevSelected + 1) % steps.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const [selected, setSelected] = useState(0);

  const handleSelect = (index) => {
    setSelected(index);
  };

  return (
    <Box display="flex" flexDirection="row" flex={1}>
      <Box position="relative" width="100%" sx={{ overflow: "hidden" }}>
        <Box sx={{ position: "absolute", bottom: "50px", left: "50%", transform: "translateX(-50%)", zIndex: "2", display: "flex", flexDirection: "row", gap: "4px" }}>
          {steps.map((s, index) => (
            <StepBar
              key={index}
              className={`${selected === index ? "activeStep" : "inactiveStep"}`}
              onClick={() => {
                handleSelect(index);
              }}
            ></StepBar>
          ))}
        </Box>
        <Box>
          <Box sx={{ padding: "0px 50px", width: "100%", position: "absolute", bottom: "86px", left: "50%", transform: "translateX(-50%)", zIndex: "2" }}>
            <Box display="flex" justifyContent="center">
              <Typography fontSize="20px" color="white" lineHeight="32px" sx={{ marginBottom: "12px" }} textAlign="center">
                {steps[selected].paragraph}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Typography variant="text_normal" color="white" mr>
                {steps[selected].name}
              </Typography>

              <Typography variant="text_normal" color={colors.main}>
                / {steps[selected].job}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box sx={{ borderRadius: "20px", padding: "40%", position: "absolute", bottom: "0px", right: "0", width: "100%", background: "linear-gradient(180deg, rgba(0, 11, 48, 0) 0%, rgba(0, 11, 48, 0.9) 100%)" }} />
            <img src={steps[selected].image} alt="" style={{ width: "100%", maxWidth: "100", borderRadius: "20px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default CarouselSignup;
