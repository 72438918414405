import React, { useState } from "react";
import { Autocomplete, Box, Button, Divider, Grid, InputAdornment, Modal, Typography, TextField, Radio, FormControl, RadioGroup, FormControlLabel, IconButton } from "@mui/material";
import BoxContainer from "./BoxContainer";
import MyChip from "./MyChip";
import pointIcon from "../../imgs/imgV2/point.svg";
import Xredicon from "../../imgs/imgV2/XredIcon.svg";

import ButtonSquare from "./ButtonSquare";
import moment from "moment";
import { modalStyle, modalStyle2 } from "../../theme/ModalStyle";
import TimeInput from "./TimeInput";
import RightArrowTime from "../../imgs/imgV2/RightArrowTime.svg";
import AddIcon from "../../imgs/imgV2/plusIcon.svg";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import RechercheIcon from "../../imgs/imgV2/RechercheIcon.svg";
import ArrowUpGreen from "../../imgs/imgV2/ArrowUpGreen.svg";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormikErrorText from "./FormikErrorText";
import { colors } from "../../theme/Colors";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import BoxContainerModal from "./BoxContainerModal";
import OmjDatePicker from "./OmjDatePicker";
import MySwitch from "./MySwitch/MySwitch";
import styled from "@emotion/styled";
import Api from "../../api/Api";
import { Urls } from "../../api/Urls";
import { useAuthContext } from "../../hooks/useAuthContext";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import DetailsProfileInterim from "../../pages/entreprise/content/DetailsProfileInterim";
import DetailOffreFinale from "../../pages/candidat/content/DetailOffreFinale";
import ThreeDIcon from "./ThreeDIcon";

const ItemContainerAgenda = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "18px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
const GrayContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "#F5F5F7", padding: "4px 4px 1px 4px", borderRadius: "6px" }}>
      {children}
    </Box>
  );
};

const ItemContainer = ({ children, ...props }) => {
  return (
    <Box {...props} sx={{ backgroundColor: "white", border: "1px solid #DEDFE4", padding: "9px 16px", borderRadius: "6px", marginBottom: "4px", boxShadow: "0px 2px 3px rgba(0,0,0,0.06)" }}>
      {children}
    </Box>
  );
};
function ProfilePropComponent({ idUser, contractID, etat, motifsR, setChange, change, id, start_date, nombre_de_mission, titre, matching, actions, offre }) {
  const { user } = useAuthContext();
  const access_token = user?.access_token;
  const [openDetailsProfile, setOpenDetailProfile] = useState(false);
  const MyTextField = styled(TextField)({
    width: "100%",
  });
  const [showPasswordDel, setShowPasswordDel] = useState(false);

  function handleClickShowPasswordDel() {
    setShowPasswordDel(!showPasswordDel);
  }
  function handleMouseDownPasswordDel(event) {
    event.preventDefault();
  }
  const [openAccepter, setOpenAccepter] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const [paniersLocal, setPaniersLocal] = useState([]);
  const [openAddPanier, setOpenAddPanier] = useState();
  const [openRefuser, setOpenRefuser] = useState(false);
  const [idContrat, setIdContrat] = useState(null);
  const [openOffre, setOpenOffre] = useState(false);
  //states
  const [formikValues, setFormikValues] = useState(""); //all form values
  const [motifs, setMotifs] = useState([]);
  const [facteurs, setFacteurs] = useState([]);
  const [selectedRisques, setSelectedRisques] = useState([]);
  const [risques, setRisques] = useState([]);
  const [errorPanier, setErrorPanier] = useState("");
  //switchs
  const [checkedLundi, setCheckedLundi] = useState(false);
  const [hasShiftLundi, setHasShiftLundi] = useState(false);

  const [checkedMardi, setCheckedMardi] = useState(false);
  const [hasShiftMardi, setHasShiftMardi] = useState(false);

  const [checkedMercredi, setCheckedMercredi] = useState(false);
  const [hasShiftMercredi, setHasShiftMercredi] = useState(false);

  const [checkedJeudi, setCheckedJeudi] = useState(false);
  const [hasShiftJeudi, setHasShiftJeudi] = useState(false);

  const [checkedVendredi, setCheckedVendredi] = useState(false);
  const [hasShiftVendredi, setHasShiftVendredi] = useState(false);

  const [checkedSamedi, setCheckedSamedi] = useState(false);
  const [hasShiftSamedi, setHasShiftSamedi] = useState(false);

  const [checkedDimanche, setCheckedDimanche] = useState(false);
  const [hasShiftDimanche, setHasShiftDimanche] = useState(false);
  const [value, setValue] = useState("");

  const [initialValues, setInitialValues] = useState({});
  useEffect(() => {
    let calendarOBJ = offre?.weekly_planner;
    //console.log("formik values before useState in the useEffect function", formikValues);
    let days = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    let daysToUse = [
      { id: 1, day_name: "monday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 2, day_name: "tuesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 3, day_name: "wednesday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 4, day_name: "thursday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 5, day_name: "friday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 6, day_name: "saturday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
      { id: 7, day_name: "sunday", start_time1: "", end_time1: "", start_time2: "", end_time2: "" },
    ];
    for (let index = 0; index < days.length; index++) {
      for (let i = 0; i < calendarOBJ.length; i++) {
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name === calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: calendarOBJ[i + 1]?.start_time, end_time2: calendarOBJ[i + 1]?.end_time };
          i++;
          index++;
        }
        if (calendarOBJ[i]?.day_name === days[index]?.day_name && calendarOBJ[i]?.day_name !== calendarOBJ[i + 1]?.day_name) {
          daysToUse[index] = { id: calendarOBJ[i]?.id, day_name: days[index]?.day_name, start_time1: calendarOBJ[i]?.start_time, end_time1: calendarOBJ[i]?.end_time, start_time2: "", end_time2: "" };
        }
      }
    }

    setInitialValues({
      //step1
      motif_recrutement: "",
      fileSize: "",
      facteurs: "",
      file: "",
      intitulePoste: offre.job_title,
      // secteurActivite: "",
      metier: offre.metier?.id,
      metier_name: offre.metier?.name,
      lieu: offre.location_address,
      latitude: "4.251548798",
      longitude: "46.2514789652",
      // departement: "",
      // ville: "",

      //step2
      experienceSouhaite: offre?.experience_years_wanted,
      certifications: [],
      certifications_names: [],
      equipementFournis: [],
      equipementFournis_names: [],
      equipementAFournir: [],
      equipementAFournir_names: [],
      description: offre?.tasks_to_do,
      diplomes: [],
      diplomes_names: [],
      skills: [],
      skills_names: [],

      //step3
      start_date_time: offre?.start_date,
      end_date_time: offre?.end_date,
      panier: [],
      tauxDeBase: offre?.basic_hourly_rate,
      tauxWeekend: offre?.weekend_hourly_rate,
      tauxNuit: offre?.night_hourly_rate,
      tauxIntemperie: offre?.severe_weather_hourly_rate,
      start_time_lundi: daysToUse?.find((obj) => obj.day_name === "monday")?.start_time1,
      end_time_lundi: daysToUse?.find((obj) => obj.day_name === "monday")?.end_time1,
      start_time_lundi_2: daysToUse?.find((obj) => obj.day_name === "monday")?.start_time2,
      end_time_lundi_2: daysToUse?.find((obj) => obj.day_name === "monday")?.end_time2,

      start_time_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday")?.start_time1,
      end_time_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday")?.end_time1,
      start_time_mardi_2: daysToUse?.find((obj) => obj.day_name === "tuesday")?.start_time2,
      end_time_mardi_2: daysToUse?.find((obj) => obj.day_name === "tuesday")?.end_time2,

      start_time_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday")?.start_time1,
      end_time_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday")?.end_time1,
      start_time_mercredi_2: daysToUse?.find((obj) => obj.day_name === "wednesday")?.start_time2,
      end_time_mercredi_2: daysToUse?.find((obj) => obj.day_name === "wednesday")?.end_time2,

      start_time_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday")?.start_time1,
      end_time_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday")?.end_time1,
      start_time_jeudi_2: daysToUse?.find((obj) => obj.day_name === "thursday")?.start_time2,
      end_time_jeudi_2: daysToUse?.find((obj) => obj.day_name === "thursday")?.end_time2,

      start_time_vendredi: daysToUse?.find((obj) => obj.day_name === "friday")?.start_time1,
      end_time_vendredi: daysToUse?.find((obj) => obj.day_name === "friday")?.end_time1,
      start_time_vendredi_2: daysToUse?.find((obj) => obj.day_name === "friday")?.start_time2,
      end_time_vendredi_2: daysToUse?.find((obj) => obj.day_name === "friday")?.end_time2,

      start_time_samedi: daysToUse?.find((obj) => obj.day_name === "saturday")?.start_time1,
      end_time_samedi: daysToUse?.find((obj) => obj.day_name === "saturday")?.end_time1,
      start_time_samedi_2: daysToUse?.find((obj) => obj.day_name === "saturday")?.start_time2,
      end_time_samedi_2: daysToUse?.find((obj) => obj.day_name === "saturday")?.end_time2,

      start_time_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday")?.start_time1,
      end_time_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday")?.end_time1,
      start_time_dimanche_2: daysToUse?.find((obj) => obj.day_name === "sunday")?.start_time2,
      end_time_dimanche_2: daysToUse?.find((obj) => obj.day_name === "sunday")?.end_time2,

      //ensure time stays in the form when pressing next/previous
      is_samedi: daysToUse?.find((obj) => obj.day_name === "saturday").start_time1 ? true : false,
      is_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday").start_time1 ? true : false,
      is_lundi: daysToUse?.find((obj) => obj.day_name === "monday").start_time1 ? true : false,
      is_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday").start_time1 ? true : false,
      is_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday").start_time1 ? true : false,
      is_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday").start_time1 ? true : false,
      is_vendredi: daysToUse?.find((obj) => obj.day_name === "friday").start_time1 ? true : false,
      //helperfield
      isPanier: offre?.basket ? true : false,
      nomPanier: "",
      montantPanier: "",
      panier: offre?.basket,
    });
    setPaniersLocal(offre?.basket);
    setFormikValues({
      //step1
      motif_recrutement: "",
      facteurs: "",
      fileSize: "",
      file: "",
      intitulePoste: offre?.job_title,
      // secteurActivite: "",
      metier: offre?.metier?.id,
      metier_name: offre?.metier?.name,
      lieu: offre?.location_address,
      latitude: "4.251548798",
      longitude: "46.2514789652",
      // departement: "",
      // ville: "",

      //step2
      experienceSouhaite: offre?.experience_years_wanted,
      certifications: [],
      certifications_names: [],
      equipementFournis: [],
      equipementFournis_names: [],
      equipementAFournir: [],
      equipementAFournir_names: [],
      description: offre?.tasks_to_do,
      diplomes: [],
      diplomes_names: [],
      skills: [],
      skills_names: [],

      //step3
      start_date_time: offre?.start_date,
      end_date_time: offre?.end_date,
      panier: [],
      tauxDeBase: offre?.basic_hourly_rate,
      tauxWeekend: offre?.weekend_hourly_rate,
      tauxNuit: offre?.night_hourly_rate,
      tauxIntemperie: offre?.severe_weather_hourly_rate,
      start_time_lundi: daysToUse?.find((obj) => obj.day_name === "monday")?.start_time1,
      end_time_lundi: daysToUse?.find((obj) => obj.day_name === "monday")?.end_time1,
      start_time_lundi_2: daysToUse?.find((obj) => obj.day_name === "monday")?.start_time2,
      end_time_lundi_2: daysToUse?.find((obj) => obj.day_name === "monday")?.end_time2,

      start_time_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday")?.start_time1,
      end_time_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday")?.end_time1,
      start_time_mardi_2: daysToUse?.find((obj) => obj.day_name === "tuesday")?.start_time2,
      end_time_mardi_2: daysToUse?.find((obj) => obj.day_name === "tuesday")?.end_time2,

      start_time_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday")?.start_time1,
      end_time_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday")?.end_time1,
      start_time_mercredi_2: daysToUse?.find((obj) => obj.day_name === "wednesday")?.start_time2,
      end_time_mercredi_2: daysToUse?.find((obj) => obj.day_name === "wednesday")?.end_time2,

      start_time_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday")?.start_time1,
      end_time_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday")?.end_time1,
      start_time_jeudi_2: daysToUse?.find((obj) => obj.day_name === "thursday")?.start_time2,
      end_time_jeudi_2: daysToUse?.find((obj) => obj.day_name === "thursday")?.end_time2,

      start_time_vendredi: daysToUse?.find((obj) => obj.day_name === "friday")?.start_time1,
      end_time_vendredi: daysToUse?.find((obj) => obj.day_name === "friday")?.end_time1,
      start_time_vendredi_2: daysToUse?.find((obj) => obj.day_name === "friday")?.start_time2,
      end_time_vendredi_2: daysToUse?.find((obj) => obj.day_name === "friday")?.end_time2,

      start_time_samedi: daysToUse?.find((obj) => obj.day_name === "saturday")?.start_time1,
      end_time_samedi: daysToUse?.find((obj) => obj.day_name === "saturday")?.end_time1,
      start_time_samedi_2: daysToUse?.find((obj) => obj.day_name === "saturday")?.start_time2,
      end_time_samedi_2: daysToUse?.find((obj) => obj.day_name === "saturday")?.end_time2,

      start_time_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday")?.start_time1,
      end_time_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday")?.end_time1,
      start_time_dimanche_2: daysToUse?.find((obj) => obj.day_name === "sunday")?.start_time2,
      end_time_dimanche_2: daysToUse?.find((obj) => obj.day_name === "sunday")?.end_time2,

      //ensure time stays in the form when pressing next/previous
      is_samedi: daysToUse?.find((obj) => obj.day_name === "saturday").start_time1 ? true : false,
      is_dimanche: daysToUse?.find((obj) => obj.day_name === "sunday").start_time1 ? true : false,
      is_lundi: daysToUse?.find((obj) => obj.day_name === "monday").start_time1 ? true : false,
      is_mardi: daysToUse?.find((obj) => obj.day_name === "tuesday").start_time1 ? true : false,
      is_mercredi: daysToUse?.find((obj) => obj.day_name === "wednesday").start_time1 ? true : false,
      is_jeudi: daysToUse?.find((obj) => obj.day_name === "thursday").start_time1 ? true : false,
      is_vendredi: daysToUse?.find((obj) => obj.day_name === "friday").start_time1 ? true : false,

      //helperfield
      isPanier: offre?.basket ? true : false,
      nomPanier: "",
      montantPanier: "",
      panier: offre?.basket,
    });
    if (daysToUse?.find((obj) => obj.day_name === "saturday").start_time2) {
      setHasShiftSamedi(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "sunday").start_time2) {
      setHasShiftDimanche(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "monday").start_time2) {
      setHasShiftLundi(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "tuesday").start_time2) {
      setHasShiftMardi(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "wednesday").start_time2) {
      setHasShiftMercredi(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "thursday").start_time2) {
      setHasShiftJeudi(true);
    }
    if (daysToUse?.find((obj) => obj.day_name === "friday").start_time2) {
      setHasShiftVendredi(true);
    }
    searchMotifs();
  }, []);
  //etat selector
  const chipSelector = (etat) => {
    switch (etat) {
      case "APPLIED":
        return "En attente";
        break;
      case "PENDING":
        return "En attente";
        break;
      case "OFFER_REJECTED":
        return "Proposition refusée";
        break;
      case "APPLICATION_REJECTED":
        return "Refusée";
        break;
      case "APPLICATION_CANCELED":
        return "Candidature Annulée";
        break;
      case "ACCEPTED":
        return "Offre envoyée";
        break;
      case "DOCUMENTS_PENDING_VALIDATION":
        return "Offre envoyée";
        break;
      case "WAITING_SIGNATURE":
        return "En attente de signature";
        break;
      case "CONTRACT_IN_CREATION":
        return "En cours de création";
        break;
    }
  };
  const [file, setFile] = useState();
  //validation schemas
  Yup.addMethod(Yup.array, "unique", function (message) {
    return this.test("unique", message, function (list) {
      const mapper = (x) => x.name;
      const set = [...new Set(list?.map(mapper))];
      const isUnique = list.length === set.length;
      if (isUnique) {
        return true;
      }

      const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
      return this.createError({
        path: `[${idx}].name`,
        message: message,
      });
    });
  });
  const FILE_SIZE = 5000 * 1024;
  const validationSchema3 = Yup.object({
    motif_recrutement: Yup.number().required("Ce champ est obligatoire"),
    fileSize: Yup.number().max(FILE_SIZE, "Fichier volumineux"),
    tauxDeBase: Yup.number().typeError("Ce champ doit être numérique").positive("Taux horaire doit être supérieure à 0").required("Ce champ est obligatoire"),

    panier: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("Le nom du panier est obligatoire"),
        })
      )
      .unique("Le nom du panier doit être unique"),

    isPanier: Yup.boolean(),

    montantPanier: Yup.number().typeError("Ce champ doit être numérique").positive("le montant doit être supérieure à 0"), //.required("Le montant du panier est obligatoire"),
    nomPanier: Yup.string(), //.required("Le nom du panier est obligatoire"),

    start_date_time: Yup.date("La date doit être valide").required("Ce champ est obligatoire"),
    end_date_time: Yup.date("La date doit être valide")
      .when("start_date_time", (start_date_time, schema) => start_date_time && schema.min(start_date_time, "La date de fin doit être supérieur à la date de début"))
      .nullable(true)
      .required("Ce champ est obligatoire"),

    // checked
    is_samedi: Yup.boolean(),
    is_dimanche: Yup.boolean(),
    is_lundi: Yup.boolean(),
    is_mardi: Yup.boolean(),
    is_mercredi: Yup.boolean(),
    is_jeudi: Yup.boolean(),
    is_vendredi: Yup.boolean(),
    description: Yup.string().required("Ce champ est obligatoire"),
    //validate heure début heure de fin matiné
    start_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi);
    }),

    start_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche);
    }),

    start_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi);
    }),

    start_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi);
    }),

    start_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi);
    }),

    start_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi);
    }),

    start_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi);
    }),

    //validate heure début heure de fin soir

    start_time_samedi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_samedi_2);
    }),
    end_time_samedi_2: Yup.string(),

    start_time_dimanche_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_dimanche_2);
    }),
    end_time_dimanche_2: Yup.string(),

    start_time_lundi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_lundi_2);
    }),
    end_time_lundi_2: Yup.string(),

    start_time_mardi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mardi_2);
    }),
    end_time_mardi_2: Yup.string(),

    start_time_mercredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_mercredi_2);
    }),
    end_time_mercredi_2: Yup.string(),

    start_time_jeudi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_jeudi_2);
    }),
    end_time_jeudi_2: Yup.string(),

    start_time_vendredi_2: Yup.string().test("start-time", "Crénau 2 :  L'heure de début doit être inférieure à l'heure de fin", function (value) {
      const { end_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, end_time_vendredi_2);
    }),
    end_time_vendredi_2: Yup.string(),

    //validate in between
    end_time_samedi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_samedi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_samedi_2);
    }),
    end_time_dimanche: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_dimanche_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_dimanche_2);
    }),
    end_time_lundi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_lundi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_lundi_2);
    }),
    end_time_mardi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mardi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mardi_2);
    }),
    end_time_mercredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_mercredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_mercredi_2);
    }),
    end_time_jeudi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_jeudi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_jeudi_2);
    }),
    end_time_vendredi: Yup.string().test("start-time", "Crénau 1 : L'heure de fin doit être inférieure à l'heure de début crénau 2", function (value) {
      const { start_time_vendredi_2 } = this.parent;
      return isStartTimeBeforeEndTime(value, start_time_vendredi_2);
    }),
  });
  function afficherDifferenceDate(date) {
    const now = moment();

    const diff = moment(date);
    const minutes = now.diff(diff, "minutes");
    const hours = now.diff(diff, "hours");
    const jours = now.diff(diff, "days");
    const semaines = now.diff(diff, "weeks");
    const mois = now.diff(diff, "months");

    if (minutes === 1) {
      return "Proposé il y a 1 minute";
    } else if (minutes > 1 && minutes < 60) {
      return `Proposé il y a ${minutes} minutes`;
    }
    if (hours === 1) {
      return "Proposé il y a 1 heure";
    } else if (hours > 1 && hours < 24) {
      return `Proposé il y a ${hours} heures`;
    }
    if (jours === 1) {
      return "Proposé il y a 1 jour";
    } else if (jours > 1 && jours < 7) {
      return `Proposé il y a ${jours} jours`;
    } else if (semaines === 1) {
      return "Proposé il y a 1 semaine";
    } else if (semaines > 1 && semaines < 4) {
      return `Proposé il y a ${semaines} semaines`;
    } else if (mois === 1) {
      return "Proposé il y a 1 mois";
    } else {
      return `Proposé il y a ${mois} mois`;
    }
  }
  const translateMatchig = {
    suitable: "matching_satisfaisant",
    perfect: "matching_parfait",
  };

  //agenda mapper
  const agendaMapper = [
    {
      id: 1,
      name_en: "saturday",
      name: "samedi",
      checked: checkedSamedi,
      setChecked: setCheckedSamedi,
      hasShift: hasShiftSamedi,
      setHasShift: setHasShiftSamedi,
    },
    {
      id: 2,
      name_en: "sunday",
      name: "dimanche",
      checked: checkedDimanche,
      setChecked: setCheckedDimanche,
      hasShift: hasShiftDimanche,
      setHasShift: setHasShiftDimanche,
    },
    {
      id: 3,
      name_en: "monday",
      name: "lundi",
      checked: checkedLundi,
      setChecked: setCheckedLundi,
      hasShift: hasShiftLundi,
      setHasShift: setHasShiftLundi,
    },
    {
      id: 4,
      name_en: "tuesday",
      name: "mardi",
      checked: checkedMardi,
      setChecked: setCheckedMardi,
      hasShift: hasShiftMardi,
      setHasShift: setHasShiftMardi,
    },
    {
      id: 5,
      name_en: "wednesday",
      name: "mercredi",
      checked: checkedMercredi,
      setChecked: setCheckedMercredi,
      hasShift: hasShiftMercredi,
      setHasShift: setHasShiftMercredi,
    },
    {
      id: 6,
      name_en: "thursday",
      name: "jeudi",
      checked: checkedJeudi,
      setChecked: setCheckedJeudi,
      hasShift: hasShiftJeudi,
      setHasShift: setHasShiftJeudi,
    },
    {
      id: 7,
      name_en: "friday",
      name: "vendredi",
      checked: checkedVendredi,
      setChecked: setCheckedVendredi,
      hasShift: hasShiftVendredi,
      setHasShift: setHasShiftVendredi,
    },
  ];
  const deletePanier = (form, panier) => {
    //console.log("FORM DELETE PANIER", form);
    //console.log("PANIER DELETE PANIER NAME", panier.name);
    //console.log("SETFORMIKVALUES DELETE PANIER", setFormikValues);
    //console.log("FORMIK VALUES", formikValues.panier);

    form?.setFieldValue(
      "panier",
      form?.values?.panier?.filter((pform) => pform.name !== panier.name)
    );
    setFormikValues(formikValues.panier.filter((fp) => fp.name !== panier.name));
  };
  const isStartTimeBeforeEndTime = (start, end) => {
    // skip validation if fields are empty
    if (!start || !end) return true;

    const startTime = start.split(":");
    const endTime = end.split(":");

    const startHour = parseInt(startTime[0]);
    const startMinute = parseInt(startTime[1]);

    const endHour = parseInt(endTime[0]);
    const endMinute = parseInt(endTime[1]);

    if (startHour < endHour) return true;
    if (startHour === endHour && startMinute < endMinute) return true;

    return false;
  };
  const [openConfirm, setOpenConfirm] = useState({
    state: false,
    values: null,
  });
  const searchMotifs = (search) => {
    Api.get(Urls.GET_RAISON_RECUTEMENT, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        ...(search && { search: search }),
      },
    })
      .then((res) => {
        //console.log(res);
        setMotifs(res?.data?.recruitment_reasons);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const searchFacteurs = (search) => {
    Api.get(Urls.GET_PENIBILITE_FACTORS, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: search,
      },
    })
      .then((res) => {
        setFacteurs(res?.data?.arduousness_factors);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const searchRisques = (search) => {
    Api.get(Urls.GET_RISQUES_PRO, {
      headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      params: {
        search: search,
      },
    })
      .then((res) => {
        setRisques(res?.data?.professional_risks);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const weeklyPlannerMaker = (values) => {
    let weeklyPlanner = [];

    agendaMapper?.map((day) => {
      if (values[`start_time_${day.name}`] && values[`end_time_${day.name}`]) {
        weeklyPlanner.push({
          day_name: day.name_en,
          start_time: values[`start_time_${day.name}`],
          end_time: values[`end_time_${day.name}`],
        });
        if (values[`start_time_${day.name}_2`] && values[`end_time_${day.name}_2`]) {
          weeklyPlanner.push({
            day_name: day.name_en,
            start_time: values[`start_time_${day.name}_2`],
            end_time: values[`end_time_${day.name}_2`],
          });
        }
      }
    });

    //console.log("my weekly planner before sending is :::::::::::::::::", weeklyPlanner);
    return weeklyPlanner;
  };

  const [openUnvailable, setOpenUnvailable] = useState(false);
  const updateCandidature = (values) => {
    let selectedIds = [];
    const weeklyPlanner = weeklyPlannerMaker(values);
    for (let index = 0; index < selectedRisques?.length; index++) {
      selectedIds.push(selectedRisques[index]?.id);
    }
    const formData = new FormData();
    formData.append("enterprise_status", "ACCEPTED");
    formData.append("contract[recruitment_reason_id]", values?.motif_recrutement);
    if (values?.facteurs) {
      formData.append("contract[arduousness_factor_id]", values?.facteurs);
    }
    formData.append("contract[tasks_to_do]", values?.description);
    formData.append("contract[basic_hourly_rate]", values?.tauxDeBase);
    formData.append("contract[weekend_hourly_rate]", values?.tauxWeekend);
    formData.append("contract[night_hourly_rate]", values?.tauxNuit);
    formData.append("contract[severe_weather_hourly_rate]", values?.tauxIntemperie);
    formData.append("contract[start_date]", values?.start_date_time);
    formData.append("contract[end_date]", values?.end_date_time);
    if (values?.panier?.length > 0) {
      for (let index = 0; index < values?.panier?.length; index++) {
        formData.append(`contract[baskets][${index}][name]`, values?.panier[index]?.name);
        formData.append(`contract[baskets][${index}][value]`, values?.panier[index]?.value);
      }
    }
    if (weeklyPlanner) {
      for (let index = 0; index < weeklyPlanner.length; index++) {
        formData.append(`contract[weekly_planner][${index}][day_name]`, weeklyPlanner[index].day_name);
        formData.append(`contract[weekly_planner][${index}][start_time]`, weeklyPlanner[index].start_time);
        formData.append(`contract[weekly_planner][${index}][end_time]`, weeklyPlanner[index].end_time);
      }
    }
    selectedIds?.forEach((id, index) => {
      formData.append(`contract[professional_risk_ids][${index}]`, id);
    });
    if (values?.file) {
      formData.append("contract[site_access_restriction]", values?.file);
    }
    Api.post(Urls.UPDATE_CANDIDATURE + id, formData, {
      headers: {
        "Content-type": "multipart/form-data",
        authorization: `Bearer ${access_token}`,
      },
    })
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setOpenAccept(false);
        setOpenConfirm({ state: false, values: null });
        setChange(!change);
        //console.log(formData.get("end_date"));
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
        if (err?.response?.status === 412) {
          setOpenUnvailable(true);
        }
      });
  };
  const RejeterCandidature = (id, selected, motif) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        enterprise_status: "REFUSED",
        ...(selected === "Autre" && { refusal_reason: motif }),
        ...(selected !== "Autre" && { refusal_reason_id: selected }),
      },
      {
        headers: { "content-type": "application/json", authorization: `Bearer ${access_token}` },
      }
    )
      .then((res) => {
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenRefuser(false);
        setOpenOffre(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  const AccepterCandidature = (id, password) => {
    Api.post(
      Urls.UPDATE_CANDIDATURE + id,
      {
        enterprise_status: "SIGNED",
        password: password,
      },
      {
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${user?.access_token}`,
        },
      }
    )
      .then((res) => {
        //console.log(res);
        toast.success(res?.data?.message);
        setChange(!change);
        setOpenAccepter(false);
      })
      .catch((err) => {
        //console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <Box sx={{ cursor: "pointer", ":hover": { filter: "brightness(0.95)", background: "white" } }}>
      <ToastContainer />
      <BoxContainer sx={{ cursor: "pointer" }} onClick={() => setOpenDetailProfile(true)}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" flexDirection="column" gap="20px">
            <Box>
              {matching != "weak" && <MyChip variant={translateMatchig[`${matching}`]} label={matching === "suitable" ? "👌 Matching satisfaisant" : "🥇 Matching parfait"} />}
              <Typography fontSize="18px" fontWeight="500" lineHeight="28.8px">
                {titre?.name ? titre?.name : null}
              </Typography>
              <Typography fontSize="12px" fontWeight="400" lineHeight="19.2px" color="#4A526C">
                {afficherDifferenceDate(start_date)}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" gap="8px">
              {titre?.experience_years && (
                <Box display="flex" alignItems="center" gap="8px">
                  {" "}
                  <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
                    🛠️ 3 ans d’expérience
                  </Typography>
                  <img src={pointIcon} alt="point-icon" />
                </Box>
              )}
              <Typography fontSize="14px" fontWeight="500" lineHeight="22.4px">
                💼️ {nombre_de_mission} missions travaillées
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap="10px" alignItems="center">
            {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
            {etat === "WAITING_SIGNATURE" && <MyChip variant="skill" label={chipSelector(etat)} />}
            {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
            {(etat === "ACCEPTED" || etat === "DOCUMENTS_PENDING_VALIDATION") && <MyChip icon={ArrowUpGreen} variant="ended_offer" label={chipSelector(etat)} />}
            {actions?.map((action) => (
              <Box key={action} flexDirection="row" display="flex" gap="10px" alignItems="center">
                {action === "SIGNED" && (
                  <Button variant="mj_primary_md" onClick={() => window.open("https://auth.coffreo.cc/", "_blank")}>
                    Signer
                  </Button>
                )}
                {action === "ACCEPTED" && (
                  <Button variant="mj_primary_md" onClick={() => setOpenAccept(true)}>
                    Recruter
                  </Button>
                )}
                {action === "REFUSED" && <ButtonSquare action="refuse" onClick={() => setOpenRefuser(true)} />}
              </Box>
            ))}
          </Box>
        </Box>
      </BoxContainer>
      <Modal open={openDetailsProfile} onClose={() => setOpenDetailProfile(false)}>
        <Box sx={modalStyle2}>
          <Box sx={{ padding: "24px" }}>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenDetailProfile(false)} action="close" />
                <Typography variant="text_xxl" fontWeight="700">
                  Profile #{idUser}
                </Typography>
              </Box>
              <Box display="flex" gap="10px" alignItems="center">
                {idContrat && (
                  <Button variant="mj_secondary_md" onClick={() => setOpenOffre(true)}>
                    Voir votre demande
                  </Button>
                )}
                {etat === "OFFER_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                {etat === "APPLICATION_REJECTED" && <MyChip icon={Xredicon} variant="rejected_offer" label={chipSelector(etat)} />}
                {etat === "WAITING_SIGNATURE" && <MyChip variant="skill" label={chipSelector(etat)} />}

                {(etat === "ACCEPTED" || etat === "DOCUMENTS_PENDING_VALIDATION") && <MyChip icon={ArrowUpGreen} variant="ended_offer" label={chipSelector(etat)} />}
                {actions?.map((action) => (
                  <Box key={action} flexDirection="row" display="flex" gap="10px" alignItems="center">
                    {action === "SIGNED" && (
                      <Button variant="mj_primary_md" onClick={() => window.open("https://auth.coffreo.cc/", "_blank")}>
                        Signer
                      </Button>
                    )}
                    {action === "ACCEPTED" && (
                      <Button variant="mj_primary_md" onClick={() => setOpenAccept(true)}>
                        Recruter
                      </Button>
                    )}
                    {action === "REFUSED" && <ButtonSquare action="refuse" onClick={() => setOpenRefuser(true)} />}
                  </Box>
                ))}
              </Box>
            </Box>
            <DetailsProfileInterim type="proposed" id={id} setIdContrat={setIdContrat} />
          </Box>
        </Box>
      </Modal>
      <Modal open={openOffre} onClose={() => setOpenOffre(false)}>
        <Box sx={modalStyle2}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="10px" alignItems="center">
                <ButtonSquare onClick={() => setOpenOffre(false)} action="close" />

                <Typography variant="text_xxl" fontWeight="700">
                  Offre finale #{idContrat}
                </Typography>
              </Box>
            </Box>
            <DetailOffreFinale filter="finalOffer" id={idContrat} type={"contrat"} />
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccept} onClose={() => setOpenAccept(false)}>
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="center" padding="40px">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={(values) => {
                //console.log(values);
                setOpenConfirm({
                  state: true,
                  values: values,
                });
              }}
              validationSchema={validationSchema3}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  {/* ================================================================Date début Date fin================================================================ */}
                  <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="40px">
                    <Typography fontSize="32px" lineHeight="160%" fontWeight="700">
                      Envoyer une offre
                    </Typography>
                    <ButtonSquare onClick={() => setOpenAccept(false)} action="close" />
                  </Box>
                  <Box marginBottom="10px">
                    <Typography fontSize="20px" fontWeight="700" lineHeight="160%">
                      Dates
                    </Typography>
                  </Box>
                  <Box display="flex" gap="20px">
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time">
                          Date de début
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="start_date_time" name="start_date_time" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Field width="100%" name="start_date_time" color={colors.main} type="input" maxDate={values?.end_date_time} id="start_date_time" component={OmjDatePicker} hasAsap={true} autoComplete="off" placeholder="saisr la date de début" />
                        <ErrorMessage component={FormikErrorText} name="start_date_time" />
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "32px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time">
                          Date de fin
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="end_date_time" name="end_date_time" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Field name="end_date_time" minDate={values.start_date_time} color={colors.main} type="input" id="end_date_time" component={OmjDatePicker} autoComplete="off" placeholder="saisr la date de fin" />
                        <ErrorMessage component={FormikErrorText} name="end_date_time" />
                      </Box>
                    </Box>
                  </Box>
                  {/* ================================================================Taux horraire ================================================================*/}

                  <Divider sx={{ marginBottom: "32px" }} />
                  <Box sx={{ marginBottom: "16px" }}>
                    <Typography variant="text_lg" fontWeight="700">
                      Taux horaire
                    </Typography>
                  </Box>

                  <Grid container spacing={0} sx={{ marginBottom: "32px" }}>
                    <Grid item xs={6}>
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase">
                            De base
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxDeBase" name="tauxDeBase" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="tauxDeBase" type="input" placeholder="0" id="tauxDeBase" as={MyTextField} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="tauxDeBase" />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend">
                            Weekend
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxWeekend" name="tauxWeekend" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="tauxWeekend" type="input" placeholder="0" id="tauxWeekend" as={MyTextField} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="tauxWeekend" />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px", width: "80px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit">
                            Nuit
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxNuit" name="tauxNuit" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="tauxNuit" type="input" placeholder="0" id="tauxNuit" as={MyTextField} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="tauxNuit" />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                            Heures intempéries
                          </Typography>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Box sx={{ width: "80px" }}>
                          <Field name="tauxIntemperie" type="input" placeholder="0" id="tauxIntemperie" as={MyTextField} autoComplete="off" />
                        </Box>
                        <ErrorMessage component={FormikErrorText} name="tauxIntemperie" />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{ marginBottom: "32px" }} />

                  <Box sx={{ marginBottom: "16px" }}>
                    <Typography variant="text_lg" fontWeight="700">
                      Agenda
                    </Typography>
                  </Box>

                  <Field>
                    {({ form }) => (
                      <Box className="Agenda" sx={{ marginBottom: "32px" }}>
                        <GrayContainer>
                          {agendaMapper.map((day) => (
                            <ItemContainerAgenda display="flex" flexDirection="column">
                              <Box display="flex" flexDirection="row" justifyContent="space-between">
                                <Box display="flex" flexDirection="row" justifyContent="center" gap="10px">
                                  <Box sx={{ paddingTop: "2px" }}>
                                    <MySwitch
                                      isOn={form.values[`is_${day.name}`]}
                                      onChange={() => {
                                        day.setChecked(!day.checked);
                                        form.setFieldValue(`is_${day.name}`, !day.checked);
                                        form.setFieldValue(`start_time_${day.name}`, "");
                                        form.setFieldValue(`end_time_${day.name}`, "");
                                        form.setFieldValue(`start_time_${day.name}_2`, "");
                                        form.setFieldValue(`end_time_${day.name}_2`, "");
                                        if (form.values[`is_${day.name}`] === true) {
                                          form.setFieldValue(`start_time_${day.name}`, "");
                                          form.setFieldValue(`end_time_${day.name}`, "");
                                        }
                                      }}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography variant="text_normal">{day.name}</Typography>
                                  </Box>
                                </Box>
                                {form.values[`is_${day.name}`] && (
                                  <Box display="flex" flexDirection="column" gap="10px">
                                    {/* Crénau matiné */}
                                    <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                      <Box width="40px" />
                                      <Field name={`start_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                      <Box width="15px">
                                        <img src={RightArrowTime} width="15px" alt="" />
                                      </Box>
                                      <Field name={`end_time_${day.name}`} width="60px" component={TimeInput} autoComplete="off" />
                                    </Box>
                                    {/* ajouter */}
                                    {day.hasShift === false ? (
                                      <Box display="flex" flexDirection="row-reverse">
                                        <Button variant="mj_secondary_md" sx={{ width: "146px" }} onClick={() => day.setHasShift(true)}>
                                          Ajouter
                                        </Button>
                                      </Box>
                                    ) : (
                                      ""
                                    )}
                                    {/* Crénau soir */}
                                    {day.hasShift && (
                                      <Box display="flex" flexDirection="row" alignItems="center" gap="5px">
                                        <ButtonSquare
                                          sx={{ height: "32px" }}
                                          action="close"
                                          onClick={() => {
                                            day.setHasShift(false);
                                            form.setFieldValue(`start_time_${day.name}_2`, "");
                                            form.setFieldValue(`end_time_${day.name}_2`, "");
                                          }}
                                        />
                                        <Box display="flex" flexDirection="row" alignItems="center" gap="6px">
                                          <Field name={`start_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                          <Box width="15px">
                                            <img src={RightArrowTime} width="15px" alt="" />
                                          </Box>
                                          <Field name={`end_time_${day.name}_2`} width="60px" component={TimeInput} autoComplete="off" />
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                )}
                              </Box>
                              {form.errors[`start_time_${day.name}`] && (
                                <Box sx={{ paddingTop: "16px" }}>
                                  <ErrorMessage name={`start_time_${day.name}`} component={FormikErrorText} />
                                </Box>
                              )}
                              {form.errors[`start_time_${day.name}_2`] && (
                                <Box sx={{ paddingTop: "16px" }}>
                                  <ErrorMessage name={`start_time_${day.name}_2`} component={FormikErrorText} />
                                </Box>
                              )}
                              {form.errors[`end_time_${day.name}`] && (
                                <Box sx={{ paddingTop: "16px" }}>
                                  <ErrorMessage name={`end_time_${day.name}`} component={FormikErrorText} />
                                </Box>
                              )}
                            </ItemContainerAgenda>
                          ))}
                        </GrayContainer>
                      </Box>
                    )}
                  </Field>

                  {/* ================================================================Taux horraire ================================================================*/}

                  {/* ================================================================Panier ================================================================*/}
                  {/* <pre>{JSON.stringify(formikValues, null, 2)}</pre> */}
                  <Divider sx={{ marginBottom: "32px" }} />

                  <Box sx={{ marginBottom: "16px" }}>
                    <Typography variant="text_lg" fontWeight="700">
                      Panier par jour
                    </Typography>
                  </Box>

                  {/* <Box sx={{ margin: "0.5rem 0.5rem" }}>
                <ErrorMessage component={ErrorText} name="nomPanier" />
                <ErrorMessage component={ErrorText} name="montantPanier" />
              </Box> */}

                  {/* <Field>
                {({ form }) => (
                  <Box display="flex" justifyContent="center">
                    <IconButton
                      sx={{ padding: "0.5rem", bgcolor: `${colors.paperLightOrange}`, "&:hover": { bgcolor: `${colors.paperLightOrangeHover}` } }}
                      onClick={() => {
                        setIsAddingPanier(true);
                        form?.setFieldValue("isPanier", !isAddingPanier);
                      }}
                    >
                      <AddRoundedIcon sx={{ color: `${colors.main}` }} fontSize="large" />
                    </IconButton>
                  </Box>
                )}
              </Field> */}

                  {/* modal add panier */}

                  {/* Modal add panier */}
                  <Modal open={openAddPanier} onClose={() => setOpenAddPanier(false)} unmountOnExit>
                    <Box sx={modalStyle}>
                      <BoxContainerModal>
                        <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="text_xxl" fontWeight="700">
                            Ajouter un panier
                          </Typography>
                          <ButtonSquare onClick={() => setOpenAddPanier(false)} action="close" />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                          <Box sx={{ marginBottom: "6px" }}>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="nomPanier" name="nomPanier">
                              Titre
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="nomPanier" name="nomPanier" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <Box>
                            <Field name="nomPanier" type="input" placeholder="Saisir le nom du panier" id="nomPanier" as={MyTextField} autoComplete="off" />
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="nomPanier" />
                          <FormikErrorText>{errorPanier}</FormikErrorText>
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                          <Box sx={{ marginBottom: "6px" }}>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="montantPanier" name="montantPanier">
                              Montant
                            </Typography>
                            <Typography variant="text_normal" fontWeight="600" htmlFor="montantPanier" name="montantPanier" style={{ color: colors.main }}>
                              *
                            </Typography>
                          </Box>
                          <Box sx={{ width: "80px" }}>
                            <Field name="montantPanier" type="input" placeholder="0" id="montantPanier" as={MyTextField} autoComplete="off" />
                          </Box>
                          <ErrorMessage component={FormikErrorText} name="montantPanier" />
                        </Box>

                        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                          <Field>
                            {({ form }) => (
                              <Box display="flex" flexDirection="row" justifyContent="center" gap="12px">
                                <Button
                                  variant="mj_secondary_md"
                                  onClick={() => {
                                    setOpenAddPanier(false);
                                    form?.setFieldValue("nomPanier", "");
                                    form?.setFieldValue("montantPanier", "");
                                  }}
                                >
                                  Annuler
                                </Button>
                                <Button
                                  variant="mj_primary_md"
                                  onClick={() => {
                                    const name = form.values.nomPanier;
                                    const value = form.values.montantPanier;
                                    //console.log("Name", name);
                                    //console.log("value", value);

                                    // if (form.values.nomPanier === "") {
                                    //   setErrorPanier("Le nom du panier est obligatoire");
                                    // }
                                    // if (form.values.montantPanier === "") {
                                    //   setErrorPanier("Le montant est obligatoire");
                                    // }

                                    if (form.values.panier.find((item) => item.name === name)) {
                                      //console.log("TRUE PANIER EXISTS");
                                      setErrorPanier("Le nom du panier doit être unique");
                                    } else {
                                      if (form.errors.nomPanier || form.errors.montantPanier) {
                                        return;
                                      } else {
                                        //console.log("FALSE PANIER DOESNT EXISTS NO DUPLICATE");
                                        form.values.panier.push({ name: name, value: value });
                                        setPaniersLocal((oldArray) => [...oldArray, { name, value }]);
                                        setOpenAddPanier(false);
                                        form?.setFieldValue("nomPanier", "");
                                        form?.setFieldValue("montantPanier", "");
                                        setErrorPanier("");
                                        form.errors.nomPanier = "";
                                        form.errors.montantPanier = "";
                                      }
                                    }
                                  }}
                                >
                                  Enregistrer
                                </Button>
                              </Box>
                            )}
                          </Field>
                        </Box>
                      </BoxContainerModal>
                    </Box>
                  </Modal>

                  <Box className="panier" sx={{ marginBottom: "32px" }}>
                    <GrayContainer>
                      <Field>
                        {({ form }) =>
                          values?.panier?.map((p) => {
                            return (
                              <ItemContainer key={p?.name} sx={{ margin: "1rem 0.5rem" }} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <Typography variant="text_normal">{p.name}</Typography>

                                <Box display="flex" gap="16px" alignItems="center">
                                  <Typography variant="text_normal" fontWeight="700">
                                    {p.value} €
                                  </Typography>
                                  <Box>
                                    <ButtonSquare action="delete" onClick={() => deletePanier(form, p)} />
                                  </Box>
                                </Box>
                              </ItemContainer>
                            );
                          })
                        }
                      </Field>
                      <Button onClick={() => setOpenAddPanier(true)} fullWidth sx={{ marginBottom: "4px", padding: "14px 14px", color: "#000B30", "&:hover": { backgroundColor: "transparent" }, textTransform: "none" }}>
                        <Box mr>
                          <img src={AddIcon} width="12px" alt="add-icon" />
                        </Box>
                        Ajouter
                      </Button>
                    </GrayContainer>
                  </Box>
                  <Divider sx={{ marginBottom: "32px" }} />
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                          Motif de recrutement
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          ListboxProps={{ style: { maxHeight: "180px" } }}
                          fullWidth
                          id="combo-box-demo"
                          value={values?.titreObj}
                          onChange={(e, value) => {
                            if (value) {
                              setFieldValue("motif_recrutement", value?.id);
                            }
                          }}
                          options={motifs}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={values.titre}
                              onChange={(e) => {
                                if (e.target.value) {
                                  if (e.target.value?.length > 0) {
                                    searchMotifs(e?.target?.value);
                                  } else {
                                    setMotifs([]);
                                  }
                                }
                              }}
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                              }}
                              placeholder="Saisir le motif de recrutement..."
                            />
                          )}
                        />
                      </Box>
                      <ErrorMessage component={FormikErrorText} name="motif_recrutement" />
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                          Risques professionnels
                        </Typography>
                        <Box>
                          <Autocomplete
                            disablePortal
                            fullWidth
                            id="combo-box-demo"
                            value={null}
                            blurOnSelect={true}
                            onChange={(e, value) => {
                              //console.log(value);
                              const exist = selectedRisques?.filter((select) => select.id === value.id);
                              if (!exist[0]) {
                                setSelectedRisques(selectedRisques.concat([value]));
                              }
                              setRisques([]);
                            }}
                            options={risques}
                            getOptionLabel={(Option) => Option.name}
                            renderInput={(params) => (
                              <TextField
                                sx={{ maxWidth: "100%" }}
                                value={value}
                                onChange={(e) => {
                                  //console.log(e.target.value);
                                  if (e.target.value.length > 0) {
                                    searchRisques(e.target.value);
                                  }
                                }}
                                {...params}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <img src={RechercheIcon} alt="end" />
                                    </InputAdornment>
                                  ),
                                  endAdornment: null,
                                }}
                                placeholder="Saisir les risques profesionnels..."
                              />
                            )}
                          />
                          <Box flexWrap="wrap" display="flex" gap="10px" margin="10px 0px">
                            {selectedRisques?.map((select) => (
                              <MyChip
                                variant="skill"
                                label={select.name}
                                onDelete={() => {
                                  setSelectedRisques(selectedRisques.filter((sel) => sel.id !== select.id));
                                  //console.log("hard skills after deleting a selected value", selectedRisques);
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                          Facteur de pénibilité
                        </Typography>
                      </Box>
                      <Box>
                        <Autocomplete
                          disablePortal
                          ListboxProps={{ style: { maxHeight: "180px" } }}
                          fullWidth
                          id="combo-box-demo"
                          value={values?.titreObj}
                          onChange={(e, value) => {
                            if (value) {
                              setFieldValue("facteurs", value?.id);
                            }
                          }}
                          options={facteurs}
                          getOptionLabel={(Option) => Option.name}
                          renderInput={(params) => (
                            <TextField
                              sx={{ maxWidth: "100%" }}
                              value={values.titre}
                              onChange={(e) => {
                                if (e.target.value) {
                                  if (e.target.value?.length > 0) {
                                    searchFacteurs(e?.target?.value);
                                  } else {
                                    setFacteurs([]);
                                  }
                                }
                              }}
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null,
                              }}
                              placeholder="Saisir le facteur pénibilité..."
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="tauxIntemperie" name="tauxIntemperie">
                          Contrainte d’accès au site
                        </Typography>
                      </Box>
                      <Box width="100%" position="relative">
                        <Field name="file">
                          {({ field, form }) => (
                            <>
                              <input
                                style={{ opacity: "0", width: "100%", height: "100%", position: "absolute", zIndex: "99999999999" }}
                                type="file"
                                name="file"
                                onChange={(e) => {
                                  const doc = e.target.files[0];
                                  form.setFieldValue("file", e.target.files[0]);
                                  form.setFieldValue("fileSize", e.target.files[0].size);
                                  setFile(doc?.name);
                                }}
                              />
                              <MyTextField setFieldValue={form.setFieldValue} value={file} endAdornment="download" name="file" placeholder="Télécharger..." />
                            </>
                          )}
                        </Field>
                        <ErrorMessage component={FormikErrorText} name="fileSize" />
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                          Tâches à réaliser
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field name="description" type="input" placeholder="Décrire les tâches à réaliser..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                      <ErrorMessage component={FormikErrorText} name="description" />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Button type="submit" variant="mj_primary_md">
                      Envoyer
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Modal>
      <Modal open={openConfirm.state} onClose={() => setOpenConfirm({ state: false, values: openConfirm?.values })}>
        <Box sx={modalStyle}>
          <Box padding="40px">
            <Box display="flex" justifyContent="flex-end" marginBottom="20px">
              <ButtonSquare action="close" onClick={() => setOpenConfirm({ state: false, values: openConfirm?.values })} />
            </Box>
            <Box>
              <Typography textAlign="center" marginBottom="10px" fontSize="25px" fontWeight="700" lineHeight="40px">
                Confirmer le recrutement
              </Typography>
            </Box>
            <Box marginBottom="20px">
              <Typography fontSize="14px" textAlign="center" fontWeight="400" lineHeight="22.4px">
                Attention le recrutement d'un intérimaire est irréversible, êtes-vous sur de votre recrutement ?
              </Typography>
            </Box>
            <Box display="flex" gap="8px" alignItems="center" justifyContent="flex-end">
              <Button variant="mj_secondary_md" onClick={() => setOpenConfirm({ state: false, values: openConfirm?.values })}>
                Annuler
              </Button>
              <Button variant="mj_primary_md" onClick={() => updateCandidature(openConfirm?.values)}>
                Envoyer
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Modal open={openRefuser} onClose={() => setOpenRefuser(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Refuser la candidature
              </Typography>
              <ButtonSquare onClick={() => setOpenRefuser(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                motif: "",
                selected: "Autre",
              }}
              onSubmit={(values) => {
                RejeterCandidature(id, values.selected, values.motif);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography variant="text_normal" fontWeight="600" htmlFor="description">
                      Motif du refus
                    </Typography>
                    <FormControl>
                      <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group" value={values.selected} onChange={(e) => setFieldValue("selected", e.target.value)}>
                        {motifsR?.map((mot) => (
                          <FormControlLabel value={mot?.id} control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label={mot?.name} />
                        ))}
                        <FormControlLabel value="Autre" control={<Radio checkedIcon={<CheckCircleRoundedIcon />} />} label="Autre" />
                      </RadioGroup>
                    </FormControl>
                    {values.selected === "Autre" && (
                      <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                        <Box sx={{ marginBottom: "6px" }}>
                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description">
                            Plus de détails
                          </Typography>

                          <Typography variant="text_normal" fontWeight="600" htmlFor="description" name="description" style={{ color: colors.main }}>
                            *
                          </Typography>
                        </Box>
                        <Field name="motif" type="input" placeholder="Veuillez donner plus de détails..." id="description" as={MyTextField} multiline fullWidth columns={5} rows={4} autoComplete="off" />
                        <ErrorMessage component={FormikErrorText} name="description" />
                      </Box>
                    )}
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenRefuser(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values.selected === "Autre" && values.motif === "" ? true : false} type="submit" variant="mj_danger_md">
                        Confirmer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
      <Modal open={openAccepter} onClose={() => setOpenAccepter(false)}>
        <Box sx={modalStyle}>
          <BoxContainer>
            <Box marginBottom="40px" display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="text_xxl" fontWeight="700">
                Signer le contrat
              </Typography>
              <ButtonSquare onClick={() => setOpenAccepter(false)} action="close" />
            </Box>
            <Formik
              initialValues={{
                password: "",
              }}
              onSubmit={(values) => {
                AccepterCandidature(id, values.password);
              }}
            >
              {({ values, errors, setFieldValue, touched }) => (
                <Form>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Typography sx={{ fontSize: "25px", fontWeight: "700", lineHeight: "160%" }}>Signature</Typography>
                    <Typography fontSize="14px" fontWeight="400" lineHeight="160%">
                      En introduisant votre mot de passe, vous confirmez avoir lu et accepté le contrat #{contractID}
                    </Typography>
                    <Box display="flex" gap="16px" sx={{ flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" } }}>
                      <Box width="100%">
                        <Box marginBottom="6px">
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" display="inline">
                            Mot de passe
                          </Typography>
                          <Typography fontSize="14px" fontWeight="600" lineHeight="160%" color={colors.main} display="inline">
                            *
                          </Typography>
                        </Box>
                        <Field name="password" id="password">
                          {({ field, form, meta }) => (
                            <TextField
                              {...field}
                              fullWidth
                              placeholder="Saisir votre mot de passe"
                              autoComplete="Off"
                              type={showPasswordDel ? "text" : "password"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                    <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPasswordDel} onMouseDown={handleMouseDownPasswordDel} edge="end">
                                      {showPasswordDel ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Field>
                        {errors.password && touched.password ? (
                          <Typography color="red" fontSize="12px">
                            {errors.password}
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" justifyContent="end" alignItems="center">
                      <Button onClick={() => setOpenAccepter(false)} variant="mj_secondary_md">
                        Annuler
                      </Button>
                      <Button disabled={values?.password?.length > 7 ? false : true} type="submit" variant="mj_green_md">
                        Signer
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )}
              <Modal open={openUnvailable} onClose={() => setOpenUnvailable}>
                <Box sx={modalStyle}>
                  <Box display="flex" justifyContent="center">
                    <Box display="flex" justifyContent="flex-end">
                      <ButtonSquare action="close" onClick={() => setOpenUnvailable(false)} />
                    </Box>
                    <Box maxWidth="500px">
                      <Box display="flex" justifyContent="center" sx={{ marginBottom: "32px" }}>
                        <ThreeDIcon name="error" />
                      </Box>
                      <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                        <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                          Intérimaire indisponible...
                        </Typography>
                      </Box>
                      <Box sx={{ marginBottom: "32px", gap: "20px", display: "flex", flexDirection: "column", textAlign: "justify" }}>
                        <Box>
                          <Typography variant="text_normal">Désolé, l’intérimaire n’est plus disponible sur les dates sélectionnés.</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Modal>
            </Formik>
          </BoxContainer>
        </Box>
      </Modal>
    </Box>
  );
}

export default ProfilePropComponent;
