import React, { useState } from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import * as Yup from "yup";
import { colors } from "../../theme/Colors";
import { TextField, IconButton, InputAdornment, FormControlLabel, Checkbox } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useSignupCandidat } from "../../hooks/useSignupCandidat";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import logo from "../../imgs/imgV2/OhMyJob.svg";
import CarouselSignup from "../../components/ui/CarouselSignup/CarouselSignup";
import FormikErrorText from "../../components/ui/FormikErrorText";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function SignupCandidat() {
  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  //navigate
  const navigate = useNavigate();

  const { user } = useAuthContext();

  //handle checkbox
  const [didAccept, setDidAccept] = useState(false);
  const handleChange = () => {
    setDidAccept(!didAccept);
  };

  //(CANDIDAT) get response, error and isLoading from useSignupEntreprise hook
  const { signupCandidat, errorSignupCandidat, isLoadingCandidat, successSignupCandidat } = useSignupCandidat();

  //validation for fields
  const validationSchemaCandidat = Yup.object({
    email: Yup.string().email("Le format de l'email est invalide").required("Ce champs est obligatoire"),
    password: Yup.string().required("Ce champs est obligatoire").min(8, "Mot de passe trop court"),
    // passwordConfirmation: Yup.string()
    //   .required("Ce champs est obligatoire")
    //   .oneOf([Yup.ref("password"), null], "Le mot de passe ne correspond pas"),
    // name: Yup.string().required("Ce champs est obligatoire"),
    first_name: Yup.string().required("Ce champs est obligatoire"),
    last_name: Yup.string().required("Ce champs est obligatoire"),
    phone_number: Yup.string().required("Ce champs est obligatoire"),
  });

  //Form initial values
  const initialValuesCandidat = {
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    // passwordConfirmation: "",
    phone_number: "",
  };

  //onSubmit CANDIDAT Methode
  const onSubmitSignupCandidat = async (values, errorSignupCandidat, isLoadingCandidat, successSignupCandidat) => {
    await signupCandidat(values.email, values.password, values.first_name, values.last_name, values.phone_number);
  };

  return (
    <Container maxWidth={"xl"}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
        <Box flex={1} display="flex" flexDirection="column" className="left" justifyContent="center" alignItems="center">
          <Box display="flex" width="100%" padding="32px 0px">
            <img width="210px" src={logo} alt="" />
          </Box>

          <Box sx={{ alignItems: "center", background: "white", width: "100%", maxWidth: "416px", padding: "16px 16px 64px 16px", minHeight: "586px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Box width="100%">
              <Box display="flex" flexDirection="column" textAlign="center" sx={{ marginBottom: "32px" }}>
                <Typography variant="text_xl" fontSize="25px" fontWeight="700">
                  Compte Candidat
                </Typography>
                <Typography variant="text_normal" color={colors.text_subtitle}>
                  Veuillez remplir le formulaire afin de créer votre compte
                </Typography>
              </Box>
              <Formik initialValues={initialValuesCandidat} validationSchema={validationSchemaCandidat} onSubmit={onSubmitSignupCandidat}>
                {({ values, error }) => (
                  <Form>
                    {/* Field for lastname (name) */}
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="Nom" name="last_name">
                          Nom
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="last_name" name="last_name" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="last_name" type="input" placeholder="Doe" id="last_name" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="last_name" />
                    </Box>
                    {/* Field for first_name */}
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="Nom" name="first_name">
                          Prénom
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="first_name" name="first_name" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="first_name" type="input" placeholder="Doe" id="first_name" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="first_name" />
                    </Box>
                    {/* Field for email */}
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="phone" name="phone_number">
                          Numéro de téléphone
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="phone" name="phone_number" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="phone_number" type="phone" placeholder="+33 000 000 0000" id="phone_number" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="phone_number" />
                    </Box>
                    {/* Field for phone */}
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="Prenom" name="first_name">
                          Email
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field autoComplete="off" name="email" type="input" placeholder="votre@email.com" id="email" as={TextField}></Field>
                      <ErrorMessage component={FormikErrorText} name="email" />
                    </Box>

                    {/* Field for password */}
                    <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="password" name="password">
                          Mot de passe
                        </Typography>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field name="password" id="password">
                        {({ field, form, meta }) => (
                          <TextField
                            {...field}
                            fullWidth
                            placeholder="Saisir votre mot de passe"
                            autoComplete="Off"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ paddingRight: "12px" }}>
                                  <IconButton size="small" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                                    {showPassword ? <Visibility fontSize="12px" htmlColor="#9499A8" /> : <VisibilityOff fontSize="12px" htmlColor="#9499A8" />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component={FormikErrorText} name="password" />
                    </Box>
                    {/* Field for password confirmation */}
                    {/* <Box sx={{ display: "flex", flexDirection: "column", marginBottom: "16px" }}>
                      <Box sx={{ marginBottom: "6px" }}>
                        <Typography variant="text_normal" fontWeight="600" htmlFor="password" name="passwordConfirmation">
                          Confirmer mot de passe
                        </Typography>{" "}
                        <Typography variant="text_normal" fontWeight="600" htmlFor="email" name="email" style={{ color: colors.main }}>
                          *
                        </Typography>
                      </Box>
                      <Field as={TextField} name="passwordConfirmation" placeholder="Saisir votre mot de passe" id="passwordConfirmation">
                        {({ field, form, meta }) => <TextField {...field} fullWidth placeholder="Saisir votre mot de passe" autoComplete="Off" type={showPassword ? "text" : "password"} />}
                      </Field>
                      <ErrorMessage component={FormikErrorText} name="passwordConfirmation" />
                    </Box> */}

                    <Box sx={{ marginBottom: "32px", display: "flex", alignItems: "start" }}>
                      <FormControlLabel
                        control={
                          <Box>
                            <Checkbox disableRipple checked={didAccept} onChange={handleChange} name="terms" />
                          </Box>
                        }
                        sx={{
                          "& .MuiFormControlLabel-label": { fontSize: 14 }, // set the font size to 16px
                        }}
                      />
                      <Box marginTop="10px">
                        <Typography variant="text_normal">
                          J’atteste avoir lu et accepté les{" "}
                          <Typography onClick={() => window.open("wwww.google.com", "_blank")} variant="text_normal" fontWeight="600" color={colors.main}>
                            conditions générales d’utilisations
                          </Typography>{" "}
                          ainsi que la{" "}
                          <Typography onClick={() => window.open("wwww.google.com", "_blank")} variant="text_normal" fontWeight="600" color={colors.main}>
                            Politique de confidentialité
                          </Typography>{" "}
                          d’OhMyJob.
                        </Typography>
                      </Box>
                    </Box>

                    <Box display="flex" gap="10px" flexDirection="column" paddingBottom="16px">
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Button disabled={isLoadingCandidat || didAccept === false} className="submit" type="submit" variant="mj_primary_md" fullWidth size="small">
                          Créer votre compte
                        </Button>
                      </Box>
                      <Box display="flex" justifyContent="center" alignItems="center">
                        <Button onClick={() => navigate("/signup")} className="signup" variant="mj_secondary_md" fullWidth>
                          Précédent
                        </Button>
                      </Box>
                    </Box>

                    {errorSignupCandidat && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjRedOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="error"
                      >
                        {errorSignupCandidat}
                      </Alert>
                    )}
                    {successSignupCandidat && (
                      <Alert
                        sx={{
                          border: `1px solid  ${colors.mjGreenOutline}`,
                          borderRadius: "12px",
                        }}
                        severity="success"
                      >
                        {successSignupCandidat}
                      </Alert>
                    )}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
        <Box flex={1.1} className="right" sx={{ padding: "32px 32px 32px 0px" }} width="50%" display={{ lg: "flex", xs: "none" }}>
          <CarouselSignup />
        </Box>
      </Box>
    </Container>
  );
}

export default SignupCandidat;
